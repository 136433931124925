export default async function createAlert(title, message, buttonName = 'OK') {
  const alert = document.createElement('ion-alert');
  alert.header = title;
  alert.message = message;
  alert.buttons = [
    {
      text: buttonName,
      role: 'confirm',
    },
  ];
  document.body.appendChild(alert);
  await alert.present();
  const { role } = await alert.onDidDismiss();
  return role;
}
