import SimplePeer from 'simple-peer';
import createModalPromptText from 'athlyzer-coach/classes/modal-prompt-text';

export default {
  async createOffer() {
    const peer = new SimplePeer({
      initiator: true,
    });
    const filenameResponse = await createModalPromptText(
      'Füge dies',
      'Dateiname (ohne .)',
      ''
    );
    console.log('filenameResponse', filenameResponse);

    if (
      filenameResponse.cancelled ||
      !filenameResponse.value ||
      filenameResponse.value == ''
    ) {
      return;
    }
  },
  createAnswer() {},
};
