import Service from './ffmpeg-ops';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import uuid from 'athlyzer-coach/application/uuid';

import * as Sentry from '@sentry/ember';

import { task, timeout, enqueueTask, waitForProperty } from 'ember-concurrency';

import FfmpegCommandBuilder from 'athlyzer-coach/cross/ffmpeg';

export default class MultiPartUploadService extends Service {
  async startMergeVideoFiles(
    videosPathesToMerge,
    videoexportpath,
    cutInternalOptions
  ) {
    console.log(
      'videosPathesToMerge',
      videosPathesToMerge,
      'videoexportpath',
      videoexportpath,
      'cutInternalOptions',
      cutInternalOptions
    );

    const mergeVideoFilesTask = this.mergeVideoFiles.perform(
      videosPathesToMerge,
      videoexportpath,
      cutInternalOptions
    );

    try {
      await mergeVideoFilesTask;
    } catch (error) {
      if (!error.name.includes('TaskCancelation')) {
        console.log('CutError', error);
        Sentry.captureException(error);
        return Promise.reject({ cancelled: false, errored: true });
      }
      return Promise.reject({ cancelled: true, errored: true });
    }

    return Promise.resolve(mergeVideoFilesTask.value);
  }

  mergeVideoFiles = task(
    { enqueue: 1, maxConcurrency: 1 },
    async (inputMergeFilesArray, outputPath, cutInternalOptions) => {
      const mergeVideoFiles = await FfmpegCommandBuilder.create(
        cutInternalOptions.beginnerFfmpegProfileName
      )
        .merge(inputMergeFilesArray)
        .makeMp4Streamable()
        .outputTo(outputPath, 'ts')
        .logCommand()
        .run();
      console.log('mergeVideoFiles', mergeVideoFiles);

      return mergeVideoFiles;
    }
  );
}
