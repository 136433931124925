export default function sortSzenen(a, b) {
  if (
    a.get('videoszene').get('videoclip') &&
    a.get('videoszene').get('videoclip').get('position') >
      b.get('videoszene').get('videoclip').get('position')
  ) {
    return 1;
  }
  if (
    a.get('videoszene').get('videoclip') &&
    a.get('videoszene').get('videoclip').get('position') <
      b.get('videoszene').get('videoclip').get('position')
  ) {
    return -1;
  }

  if (a.get('position') > b.get('position')) {
    return 1;
  }
  if (a.get('position') < b.get('position')) {
    return -1;
  }
}
