import { WebPlugin } from '@capacitor/core';

export class CrossWeb extends WebPlugin {
  constructor() {
    super();
  }
  echo() {}

  getFileSize(options) {
    return { size: 0, found: false };
  }

  async upload(options) {
    // const httpOptions = {
    //   url: 'https://example.com/path/to/upload.pdf',
    //   name: 'myFile',
    //   blob, options.blob,
    // };

    const formDataOptions = {
      key: options.key,
      bucket: options.bucket,
      acl: 'public-read',
      'X-Amz-Algorithm': options.algorithm,
      'X-Amz-Credential': options.credential,
      'X-Amz-Date': options.date,
      Policy: options.policy,
      'X-Amz-Signature': options.signature,
    };
    const formData = new FormData();
    for (const key in formDataOptions) {
      formData.append(key, formDataOptions[key]);
    }
    formData.append('file', options.file, options.key);
    console.log('form-data', formData);
    // const res = await axios.post(options.url, formData
    //   ,{
    //   headers: {
    //     "Content-Type": "multipart/form-data"
    //   }
    // });
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', options.url, true);

      xhr.onload = function () {
        console.log('onload', this.status);
        this.status === 204
          ? resolve({ value: '' })
          : reject(this.responseText);
      };
      xhr.upload.addEventListener('progress', function (e) {
        if (e.lengthComputable) {
          var uploadPercent = e.loaded / e.total;
          uploadPercent = uploadPercent * 100;
          console.log('uploadPercent', uploadPercent);
          window.dispatchEvent(
            new CustomEvent(`upload-${options.key}`, {
              detail: { progress: uploadPercent, uploadkey: options.key },
            })
          );
        }
      });
      xhr.send(formData);
    });

    // const response = await Http.uploadFile();
    // console.log("response",response);
    // return response.data;
  }
}
