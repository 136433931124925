import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { Share } from '@capacitor/share';
export default class ShareRoute extends Route {
  queryParams = {
    u: {
      refreshModel: true,
    },
  };

  model(params) {
    return Share.share({ url: params.u });
  }
}
