import Model from '../classes/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class VideobearbeitungModel extends Model {
  typ = 'videobearbeitung';
  icon = 'videocam';

  @attr('boolean', { defaultValue: false }) highlight;

  @attr('string') name;
  @attr('mdate') datum;
  @attr('mdate') createDate;
  @attr('string', { defaultValue: '' }) ansicht;
  @attr('string') notizen;
  @attr('boolean', { defaultValue: false }) liveTagging;

  // [standard (normale analyse), capture, livetagging, highlights, stream, videos]
  @attr('string', { defaultValue: 'standard' }) analysetyp;

  get analysetypLegacy() {
    return this.liveTagging ? 'livetagging' : this.analysetyp;
  }

  @belongsTo('ordner') ordner;
  @belongsTo('schema') schema;

  @hasMany('playlist') playlists;
  @hasMany('videoclip', { inverse: 'videobearbeitungShared' }) sharedVideoclips;
  @hasMany('videoclip', { inverse: 'videobearbeitung' }) videoclips;
  @hasMany('videoszene') videoszenen;

  @belongsTo('clipexportpreset') clipexportpreset;

  @attr() clips;

  @belongsTo('team') hometeam;
  @attr('string') homename;
  @belongsTo('team') awayteam;
  @attr('string') awayname;
  @attr('string') sportart;

  get home() {
    if (this.hometeam && this.hometeam.get('name')) {
      return this.hometeam;
    } else {
      return {
        name: this.homename,
      };
    }
  }

  get away() {
    if (this.awayteam && this.awayteam.get('name')) {
      return this.awayteam;
    } else {
      return {
        name: this.awayname,
      };
    }
  }

  @attr('boolean', { defaultValue: true }) change;
  @tracked aktiveLabels = [];

  getVideoszenenByClip(clipId) {
    if (this.videoszenen.length > 0) {
      return this.videoszenen.filter((a) => {
        return a.belongsTo('videoclip').id() == clipId;
      });
    } else {
      return [];
    }
  }

  get dateOfCreation() {
    if (this.createDate) {
      return this.createDate;
    } else {
      return this.datum;
    }
  }

  get videoclipsSortiert() {
    if (this.videoclips) {
      return this.videoclips.sortBy('position');
    } else {
      return [];
    }
  }

  get videoszenenSortiert() {
    if (this.videoszenen && this.videoszenen.length > 0) {
      return this.videoszenen.toArray().sort((a, b) => {
        if (a.get('videoclip.position') > b.get('videoclip.position'))
          return -1;
        if (a.get('videoclip.position') < b.get('videoclip.position')) return 1;

        if (a.startzeit > b.startzeit) return 1;
        if (a.startzeit < b.startzeit) return -1;
      });
    } else {
      return [];
    }
  }

  get playlistsSortiert() {
    return this.playlists.toArray().sortBy('position');
  }

  get videoszenenSelected() {
    if (this.videoszenen && this.videoszenen.length > 0) {
      return this.videoszenen.filter((vs) => vs.get('selected'));
    } else {
      return [];
    }
  }

  get datumjs() {
    if (this.datum) {
      return this.datum.toFormat('yyyy-LL-dd');
    } else {
      return null;
    }
  }
}
