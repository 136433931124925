import Service, { inject as service } from '@ember/service';
import { DateTime } from 'luxon';
import clipsPosition from 'athlyzer-coach/helper-functions/clips-position';
import { getNewDateAsStringWithTime } from 'athlyzer-coach/classes/date-helpers';
import createModalPromptText from 'athlyzer-coach/classes/modal-prompt-text';

export default class ModelCreateService extends Service {
  @service store;
  @service ordner;
  @service intl;

  returnModel(model, options) {
    if (options.save) {
      return model.save();
    } else {
      return model;
    }
  }

  analyseNamen = {
    standard: this.intl.t('Neue Analyse'),
    livetagging: this.intl.t('Neues Live-Tagging'),
    capture: this.intl.t('Neues Live-Capture'),
    highlights: this.intl.t('Neue Highlight-Sammlung'),
    videos: this.intl.t('Neue Videosammlung'),
  };

  // analysetyp =
  async analyse(properties = {}, options = { save: true }) {
    const analyseName = properties.analysetyp
      ? this.analyseNamen[properties.analysetyp]
      : this.analyseNamen['standard'];

    let defaults = {
      datum: new Date(),
      createDate: new Date(),
      liveTagging: properties.analysetyp == 'livetagging',
      analysetyp: 'standard',
      name: analyseName + ' ' + getNewDateAsStringWithTime(),
      ordner: this.ordner.aktuellerOrdner || null,
    };
    let analyse = this.store.createRecord(
      'videobearbeitung',
      Object.assign(defaults, properties)
    );
    return this.returnModel(analyse, options);
  }

  async team() {
    let team = await this.store.createRecord('team', {
      name:
        this.intl.t('Neues Team') + ' ' + DateTime.now().toFormat('dd.LL.yyyy'),
      ordner: this.ordner.aktuellerOrdner,
    });
    return team.save();
  }

  schema() {
    return this.store
      .createRecord('schema', {
        name:
          this.intl.t('Neues Schema') +
          ' ' +
          DateTime.now().toFormat('dd.LL.yyyy'),
        ordner: this.ordner.aktuellerOrdner,
        showtablet: false,
        showmobil1: false,
        showmobil2: false,
      })
      .save();
  }

  board() {
    return this.store
      .createRecord('board', {
        datum: DateTime.now(),
        name:
          this.intl.t('Neues Board') +
          ' ' +
          DateTime.now().toFormat('dd.LL.yyyy'),
        ordner: this.ordner.aktuellerOrdner || null,
        board_background_path: 'assets/tactics/logo/FieldHockey_Logo.png',
        layer: JSON.stringify({
          attrs: {},
          className: 'Layer',
          children: [
            {
              attrs: {
                id: 'backgroundImage',
                width: 1600,
                height: 1600,
                listening: false,
              },
              className: 'Image',
            },
            {
              attrs: {
                fill: 'white',
                id: 'background',
                width: 1600,
                height: 1600,
                listening: false,
              },
              className: 'Rect',
            },
          ],
        }),
      })
      .save();
  }

  async videoclip(
    videobearbeitung,
    optionalValues = {},
    options = { save: true }
  ) {
    let vc = this.store.createRecord(
      'videoclip',
      Object.assign(
        {
          web: '',
          desktop: '',
          ios: '',
          android: '',
          exists: false,
        },
        optionalValues
      )
    );

    vc.set('videobearbeitung', videobearbeitung);
    vc.set('position', clipsPosition(videobearbeitung));
    videobearbeitung.videoclips.pushObject(vc);

    await videobearbeitung.save();
    return this.returnModel(vc, options);
  }

  async createSharedVideoclip(
    analysisOrCollection,
    optionalValues = {},
    options = { save: true }
  ) {
    let vc = this.store.createRecord(
      'videoclip',
      Object.assign(
        {
          web: '',
          desktop: '',
          ios: '',
          android: '',
          exists: false,
        },
        optionalValues
      )
    );

    if (analysisOrCollection.typ === 'videocollection') {
      vc.set('videocollection', analysisOrCollection);
    } else {
      vc.set('videobearbeitungShared', analysisOrCollection);
    }

    analysisOrCollection.sharedVideoclips.pushObject(vc);

    await analysisOrCollection.save();
    return this.returnModel(vc, options);
  }

  async createTemporarySharedVideoclip(
    optionalValues = {},
    options = { save: false }
  ) {
    let vc = this.store.createRecord(
      'videoclip',
      Object.assign(
        {
          web: '',
          desktop: '',
          ios: '',
          android: '',
          exists: false,
        },
        optionalValues
      )
    );

    return this.returnModel(vc, options);
  }

  async playlist(videobearbeitung, name, options = { save: true }) {
    if (!name) {
      const { value } = await createModalPromptText(
        this.intl.t('Neue Playlist anlegen'),
        this.intl.t('Name'),
        name || ''
      );
      if (value) {
        name = value;
      } else {
        return;
      }
    }
    let playlist = await this.store.createRecord('playlist', {
      name: name,
      position: videobearbeitung.get('playlists').toArray().length + 1,
    });
    playlist.set('videobearbeitung', videobearbeitung);
    videobearbeitung.playlists.pushObject(playlist);
    await videobearbeitung.save();
    return this.returnModel(playlist, options);
  }
}
