import Service from './convert';
import { set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import * as Sentry from '@sentry/ember';
import { A } from '@ember/array';
import Cross from 'athlyzer-coach/cross/plugin';

import { task, waitForProperty } from 'ember-concurrency';

import FfmpegCommandBuilder from 'athlyzer-coach/cross/ffmpeg';
import VideoCutter from 'athlyzer-coach/utils/video-cutter';

export default class TaskmanagerCutService extends Service {
  @tracked cutProcesses = A([]);
  @tracked cutTaskInstances = A([]);

  @tracked runningFfmpegVideoProcesses = A([]);
  @tracked runningFfmpegVideoTasks = A([]);
  @tracked cutStatus = '';
  @tracked cutProgress = 0;
  @tracked cutProgressDetail = null;
  @tracked cutInstance = null;
  @tracked numCutTasksEnqueued = 0;

  get queuedCutTaskInstances() {
    return this.downloadClipTaskInstances.filter((taskInstance) => {
      return !taskInstance.hasStarted;
    });
  }

  cleanCompletedCutProcesses = task(
    { enqueue: true, maxConcurrency: 1 },
    async (taskKey) => {
      const foundProcess = this.cutProcesses.find((process) => {
        return process.taskKey === taskKey;
      });

      if (foundProcess) {
        this.cutProcesses.removeObject(foundProcess);
      }
    }
  );

  cutClip = task(
    { enqueue: true, maxConcurrency: 1 },
    async (cutInternalOptions) => {
      let taskKey = null;
      let videourl = '';
      let filepath = '';
      this.cutStatus = '';
      this.cutProgress = 0;
      this.cutProgressDetail = null;

      this.incrementProperty('numCutTasksEnqueued');
      try {
        const { filepath: inputFilePath } =
          await Cross.videourlToAbsoluteFilepath({
            videourl: cutInternalOptions.videourl,
          });
        console.log('CUT FILEPATH', cutInternalOptions.videourl, inputFilePath);
        const mediaInfo = await this.video.getVideoMediaInfo({
          filepath: inputFilePath,
        });

        cutInternalOptions = Object.assign(cutInternalOptions, {
          inputWidth: mediaInfo.width,
          inputHeight: mediaInfo.height,
        });

        let cutter = new VideoCutter(cutInternalOptions);

        cutter
          .onProgress(({ percentage }) => {
            console.log('cutter.onProgress', percentage);
            set(this, 'cutProgress', percentage);
          })
          .onError((error) => {
            if (
              !error.message ||
              !error.message.includes('Cut canceled by user')
            ) {
              Sentry.captureException(error);
            }
          });

        cutter.start();

        this.cutInstance = cutter;

        await waitForProperty(this, 'cutProgress', 100);
        console.log('waitForProperty cutProgress done');
        await waitForProperty(this.cutInstance, 'finished', true);
        console.log('waitForProperty cutInstance finished');

        videourl = this.cutInstance.videourl;
        filepath = this.cutInstance.filepath;
      } catch (e) {
        videourl = null;
        Sentry.captureException(e);
      } finally {
        await this.cleanCompletedCutProcesses.perform(taskKey);
        this.decrementProperty('numCutTasksEnqueued');
      }
      return {
        videourl: videourl,
        relativeVideourl: videourl,
        filepath: filepath,
      };
    }
  );

  async startCut(cutInternalOptions) {
    console.log('services/video/cut.js @startCut', cutInternalOptions);
    const cutTask = this.cutClip.perform(cutInternalOptions);
    this.cutTaskInstances.pushObject(cutTask);
    try {
      await cutTask;
      console.log('after cutTask');
    } catch (error) {
      if (!error.name.includes('TaskCancelation')) {
        console.log('CutError', error);
        Sentry.captureException(error);
        return Promise.reject({ cancelled: false, errored: true });
      }
      return Promise.reject({ cancelled: true, errored: true });
    }
    console.log('Cut Task', cutTask.value);
    return Promise.resolve(cutTask.value);
  }

  async startCreateFrameFromVideo(
    inputVideoFilePath,
    timestamp,
    outputPathImage,
    cutInternalOptions
  ) {
    const createFrameFromVideoTask = this.createFrameFromVideo.perform(
      inputVideoFilePath,
      timestamp,
      outputPathImage,
      cutInternalOptions
    );

    try {
      await createFrameFromVideoTask;
    } catch (error) {
      if (!error.name.includes('TaskCancelation')) {
        console.log('CutError', error);
        Sentry.captureException(error);
        return Promise.reject({ cancelled: false, errored: true });
      }
      return Promise.reject({ cancelled: true, errored: true });
    }

    return Promise.resolve(createFrameFromVideoTask.value);
  }

  createFrameFromVideo = task(
    { enqueue: 1, maxConcurrency: 1 },
    async (filepath, timestamp, outputPathImage, cutInternalOptions) => {
      const freezeFrame = await FfmpegCommandBuilder.create(
        cutInternalOptions.beginnerFfmpegProfileName
      )
        .addVideoInput(filepath)
        .createImage(this.video.secondsToTime(timestamp))
        .outputTo(outputPathImage)
        .logCommand()
        .run();

      return freezeFrame;
    }
  );

  async startCreateVideoFromImageWithOverlay(
    inputImagePath,
    overlayImageUri,
    videoOutputPath,
    cutInternalOptions
  ) {
    const createFrameFromVideoTask =
      this.createVideoFromImageWithOverlay.perform(
        inputImagePath,
        overlayImageUri,
        videoOutputPath,
        cutInternalOptions
      );

    try {
      console.log('createFrameFromVideoTask', createFrameFromVideoTask);
      await createFrameFromVideoTask;
      console.log('createFrameFromVideoTask after');
    } catch (error) {
      if (!error.name.includes('TaskCancelation')) {
        console.log('CutError', error);
        Sentry.captureException(error);
        return Promise.reject({ cancelled: false, errored: true });
      }
      return Promise.reject({ cancelled: true, errored: true });
    }

    return Promise.resolve(createFrameFromVideoTask.value);
  }

  createVideoFromImageWithOverlay = task(
    { enqueue: 1, maxConcurrency: 1 },
    async (
      inputImagePath,
      overlayImageURI,
      videoOutputPath,
      cutInternalOptions
    ) => {
      console.log(
        'createVideoFromImageWithOverlay',
        inputImagePath,
        overlayImageURI,
        videoOutputPath,
        cutInternalOptions
      );
      let freezeFrameVideo = FfmpegCommandBuilder.create(
        cutInternalOptions.beginnerFfmpegProfileName
      )
        .addImageInput(inputImagePath, '2')
        .addOverlay(overlayImageURI, 'centermiddle', 100);

      if (cutInternalOptions.overlays?.length > 0) {
        cutInternalOptions.overlays.forEach((overlay) => {
          freezeFrameVideo = freezeFrameVideo.addOverlay(
            overlay.url,
            overlay.position,
            overlay.percent,
            10,
            10
          );
        });
      }

      const response = await freezeFrameVideo
        .outputTo(videoOutputPath, 'ts')
        .logCommand()
        .run();
      console.log('Video from freeze After', response);

      return freezeFrameVideo;
    }
  );
}
