import {
  applyPolyfills as applyPolyfillsIonicCore,
  defineCustomElements as defineIonicCore
} from '@ionic/core/loader';
import {
  applyPolyfills as applyPolyfillsPorscheofficialCookieConsentBanner,
  defineCustomElements as definePorscheofficialCookieConsentBanner
} from '@porscheofficial/cookie-consent-banner/loader';

applyPolyfillsIonicCore().then(function() {
  defineIonicCore(window);
});
applyPolyfillsPorscheofficialCookieConsentBanner().then(function() {
  definePorscheofficialCookieConsentBanner(window);
});

export function initialize() {
  // No-op
};

export default {
  initialize
};