import Service from './cut';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

import * as Sentry from '@sentry/ember';

import { task, waitForProperty } from 'ember-concurrency';
import Downloader from 'athlyzer-coach/utils/downloader';

export default class TaskmanagerDownloadService extends Service {
  @service intl;

  @tracked downloadProcesses = A([]);
  @tracked downloadClipTaskInstances = A([]);
  @tracked downloadProcessTaskMap = {};

  @tracked uploadStatus = '';
  @tracked downloadProgress = 0;
  @tracked downloadProgressDetail;

  get queuedDownloadTaskInstances() {
    return this.downloadClipTaskInstances.filter((taskInstance) => {
      return !taskInstance.hasStarted;
    });
  }

  cleanCompletedDownloads = task(
    { enqueue: true, maxConcurrency: 1 },
    async () => {
      this.downloadProcesses = this.downloadProcesses.filter(
        (downloadProcess) => {
          return !downloadProcess.finished && !downloadProcess.aborted;
        }
      );

      this.downloadClipTaskInstances = this.downloadClipTaskInstances.filter(
        (downloadTaskInstance) => {
          console.log('Download Task', downloadTaskInstance);
          return (
            !downloadTaskInstance.isError && !downloadTaskInstance.isFinished
          );
        }
      );
    }
  );

  downloadClip = task(
    { enqueue: true, maxConcurrency: 1 },
    async (webURL, taskName = 'Download', videoclipid) => {
      let progressId = videoclipid;
      let localVideoUrl = null;

      const fileName = webURL.substring(webURL.lastIndexOf('/') + 1);

      let downloader = new Downloader({
        taskKey: videoclipid,
        webURL,
        fileName,
        progressId,
        taskName: taskName,
        name: videoclipid,
        taskInstanceMap: this.downloadProcessTaskMap,
      });

      downloader.onError((error) => {
        if (
          !error.message ||
          !error.message.includes('Download canceled by user')
        ) {
          Sentry.captureException(error);
        }
      });

      this.downloadProcesses.pushObject(downloader);

      try {
        downloader.start();

        await waitForProperty(downloader, 'finished', true);
        await waitForProperty(downloader, 'progressPercentage', 100);

        localVideoUrl = downloader.relativeVideourl;
      } catch (e) {
        localVideoUrl = null;
        Sentry.captureException(e);
      } finally {
        if (downloader && !downloader.finished) {
          downloader.abort();
          this.downloadProcesses.removeObject(downloader);
        }

        if (localVideoUrl) {
          return {
            relativeVideourl: localVideoUrl,
            fileName: fileName,
          };
        } else {
          return {};
        }
      }
    }
  );

  async startDownload(webURL, taskName, videoclipid) {
    const downloadTask = this.downloadClip.perform(
      webURL,
      taskName,
      videoclipid
    );

    this.downloadProcessTaskMap[videoclipid] = downloadTask;

    try {
      this.downloadClipTaskInstances.pushObject(downloadTask);
      this.metrics.track('taskmanager.video.download.start');

      await downloadTask;
    } catch (error) {
      if (!error.name.includes('TaskCancelation')) {
        Sentry.captureException(error);
      } else {
        this.downloadClipTaskInstances.removeObject(downloadTask);
        delete this.downloadProcessTaskMap[videoclipid];
      }
    }

    this.metrics.track('taskmanager.video.download.finished');

    if (downloadTask.value) {
      return Promise.resolve(downloadTask.value);
    } else {
      return Promise.resolve({
        relativeVideourl: null,
        fileName: null,
      });
    }
  }
}
