import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
import { inject as service } from '@ember/service';

export default class SchemaTaggenComponent extends Component {
  @tracked isOpacity;
  @tracked isZooming;
  @service addonCore;
  @service video;

  standardzoom = 1;

  constructor(owner, args) {
    super(owner, args);
    this.video.setSchema(this.args.model);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.video.unsetSchema();
  }

  get styling() {
    let styleString = '';
    if (this.isOpacity) {
      styleString += 'opacity: 0.6; ';
    } else {
      // styleString = styleString + "background-color: red; ";
      styleString += 'opacity: 0.6; ';
    }
    styleString += 'position: relative; width:100%; height: 100%;';
    return htmlSafe(styleString);
  }
}
