import Model from '../classes/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class VideoszeneModel extends Model {
  @tracked isHidden = false;
  @tracked isSelectedForAssignment = false;

  @belongsTo('videobearbeitung') videobearbeitung;
  @attr('string')
  name;
  @attr('number')
  startzeit;

  @attr('number')
  endzeit;

  @attr('string')
  farbe;

  @attr('string')
  text;

  @attr('string', { defaultValue: 'videoszene' }) type;

  @hasMany('playlistszene') playlistszenen;

  @attr('string')
  untertext;

  @attr('boolean', { defaultValue: true }) change;

  @computed('name', 'text', 'untertext')
  get textgesamt() {
    if (this.text && this.untertext) {
      return this.text + ' ' + this.untertext;
    } else if (this.text) {
      return this.text;
    } else if (this.untertext) {
      return this.untertext;
    } else {
      return this.name;
    }
  }

  @attr('number')
  tmpID;

  @attr('string') exID;

  @attr('string')
  tastenkombination;

  @attr('string')
  taste;

  @attr('boolean', { defaultValue: false })
  stern;

  @attr('string')
  kommentar;

  @attr('tag', { defaultValue: () => [] })
  tags;

  @computed('change', 'tags')
  get tagslist() {
    return this.tags;
  }

  @attr('string')
  gruppe;

  @attr('number')
  typ;

  @attr('boolean', { defaultValue: false })
  is;

  @attr('number', { defaultValue: 0 })
  clip;

  @belongsTo('videoclip') videoclip;

  @computed('startzeit', 'endzeit')
  get laenge() {
    if ((this.startzeit || this.startzeit == 0) && this.endzeit) {
      return parseInt(this.endzeit) - parseInt(this.startzeit);
    } else {
      return 0;
    }
  }

  @attr('boolean', { defaultValue: false })
  ispraesentation;

  @attr('number')
  praesentationsposition;

  @attr('string')
  praesentationsname;

  @attr('string')
  uuid;

  @attr('string')
  schemaelementid;

  @attr('string')
  schemaid;

  @attr('number')
  liveid; //kurzfristige id zum import von liveszenen
}
