import Component from '@glimmer/component';
import { action } from '@ember/object';
export default class AddonCoreQrcodeComponent extends Component {
  @action onClick() {
    if (typeof this.args.onClick == 'function') {
      this.args.onClick();
    }
  }
  initQr(elem, [link]) {
    elem.innerHTML = '';
    if (elem && link) {
      let newLink = link;
      if (link.length >= 192 && link.length <= 217) {
        if (link.indexOf('?') > -1) {
          newLink = link + '&qr=lessthan192morethan217';
        } else {
          newLink = link + '?qr=lessthan192morethan217';
        }
      }
      new QRCode(elem, newLink);
    }
  }
}
