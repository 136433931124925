import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import * as Sentry from '@sentry/ember';

export default class CommunityService extends Service {
  @service router;
  @service ordner;
  @service store;
  @service addonUser;
  @service intl;
  @service paperToaster;
  @service daten;
  @service metrics;

  @tracked staticSchemaTemplates = [
    {
      typ: 'schemavorlage',
      name: this.intl.t('Hockey-Schema Allgemein'),
      description: this.intl.t(
        'Feldhockey-Schema für den schnellen Tagging-Einstieg'
      ),
      url: '/schema/feldhockey.vas',
      levels: ['beginner', 'advanced', 'expert'],
      sports: ['field-hockey'],
    },
    {
      typ: 'schemavorlage',
      name: this.intl.t('Hockey-Spielprinzip von Robert Richter'),
      description: this.intl.t(
        'Feldhockey-Schema für fortgeschrittenes Tagging'
      ),
      url: '/schema/feldhockey-jazz.vas',
      levels: ['advanced', 'expert'],
      sports: ['field-hockey'],
    },
    {
      typ: 'schemavorlage',
      name: this.intl.t('Hockey-Schema inkl. Torwartspiel'),
      description: this.intl.t(
        'Feldhockey-Schema für Feldspieler und Torhüter'
      ),
      url: '/schema/feldhockey_torwart.vas',
      levels: ['advanced', 'expert'],
      sports: ['field-hockey'],
    },
    {
      typ: 'schemavorlage',
      name: this.intl.t('Fußball (Basic)'),
      description: this.intl.t(
        'Fussball-Schema für den schnellen Tagging-Einstieg'
      ),
      url: '/schema/fussball_basis.vas',
      levels: ['beginner', 'advanced'],
      sports: ['soccer'],
    },
    {
      typ: 'schemavorlage',
      name: this.intl.t('Fußball (Fortgeschritten)'),
      description: this.intl.t(
        'Fussball-Schema für den schnellen Tagging-Einstieg'
      ),
      url: '/schema/fussball_fortgeschritten.vas',
      levels: ['advanced', 'expert'],
      sports: ['soccer'],
    },
    {
      typ: 'schemavorlage',
      name: this.intl.t('Basketball-Schema von Reiner Chromik'),
      description: this.intl.t(
        'Basketball-Schema für fortgeschrittenes Tagging'
      ),
      url: '/schema/basketball_fortgeschritten.vas',
      levels: ['advanced', 'expert'],
      sports: ['basketball'],
    },
    {
      typ: 'schemavorlage',
      name: this.intl.t('Handball-Schema Eigenanalyse'),
      description: this.intl.t('Handball-Schema für fortgeschrittenes Tagging'),
      url: '/schema/handball_eigenanalyse.vas',
      levels: ['advanced', 'expert'],
      sports: ['handball'],
    },
    {
      typ: 'schemavorlage',
      name: this.intl.t('Handball-Schema Spielanalyse'),
      description: this.intl.t('Handball-Schema für fortgeschrittenes Tagging'),
      url: '/schema/handball_spielanalyse.vas',
      levels: ['advanced', 'expert'],
      sports: ['handball'],
    },
    {
      typ: 'schemavorlage',
      name: this.intl.t('Eishockey (Fortgeschritten)'),
      description: this.intl.t(
        'Eishockey-Schema für fortgeschrittenes Tagging'
      ),
      url: '/schema/eishockey_fortgeschritten.vas',
      levels: ['beginner', 'advanced', 'expert'],
      sports: ['ice-hockey'],
    },
    {
      typ: 'schemavorlage',
      name: this.intl.t('Eishockey (Experte)'),
      description: this.intl.t('Eishockey-Schema für komplexe Analysen'),
      url: '/schema/eishockey_experte.vas',
      levels: ['advanced', 'expert'],
      sports: ['ice-hockey'],
    },
    {
      typ: 'schemavorlage',
      name: this.intl.t('Hallenfußball'),
      description: this.intl.t(
        'Hallenfussball-Schema für den schnellen Tagging-Einstieg'
      ),
      url: '/schema/hallenfussball.vas',
      levels: ['beginner', 'advanced'],
      sports: ['soccer'],
    },
  ];

  get schemaTemplatesFilteredBySports() {
    const filteredSchemaTemplates = this.staticSchemaTemplates.filter(
      (schemaTemplate) => {
        return schemaTemplate.sports.includes(
          this.addonUser.settings.sport_code
        );
      }
    );

    if (filteredSchemaTemplates.length > 0) {
      return filteredSchemaTemplates;
    } else {
      return this.staticSchemaTemplates;
    }
  }

  @tracked staticAnalysisExamples = [
    {
      name: this.intl.t('Fußball-Analyse FSV Frankfurt U19'),
      description: this.intl.t('Analyse der U19 Mannschaft des FSV Frankfurt'),
      analysisUrl: '/analysis/testspiel_fsv_u19.vaa',
      schemaUrl: '/schema/fsv_frankfurt_nlz_vorlage_22_23.vas',
    },
    {
      name: this.intl.t('Spielanalyse von Robert Richter'),
      description: this.intl.t('Hockey'),
      analysisUrl: '/analysis/richter.vaa',
      schemaUrl: '/schema/feldhockey-jazz.vas',
    },
    {
      name: this.intl.t('Handball-Analyse Bordeaux'),
      description: this.intl.t('Handball Gegneranalyse'),
      analysisUrl: '/analysis/handball_beispielanalyse.vaa',
      schemaUrl: '/schema/handball_spielanalyse.vas',
    },
    {
      name: this.intl.t('Basketball-Analyse fortgeschritten'),
      description: this.intl.t('Basketball-Analyse'),
      analysisUrl: '/analysis/basketball_demoanalyse.vaa',
      schemaUrl: '/schema/basketball_fortgeschritten.vas',
    },
  ];

  @action async createSchemaFromStaticSchemaTemplate(
    url,
    returnSchema = false
  ) {
    let schemaVorlage = await fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    });

    try {
      schemaVorlage = await schemaVorlage.json();

      delete schemaVorlage.rev;
      delete schemaVorlage.id;
      let schema = this.store.createRecord('schema', schemaVorlage.schema);
      schema.set('ordner', this.ordner.aktuellerOrdner);
      await schema.save();

      for (let handlung of schemaVorlage.handlungen) {
        handlung.schema = null;
        handlung.rev = null;
        let tmp = this.store.createRecord('handlung', handlung);
        tmp.set('schema', schema);
        schema.handlungen.pushObject(tmp);
        await tmp.save();
      }

      for (let tag of schemaVorlage.tags) {
        tag.schema = null;
        tag.rev = null;
        let tmp = this.store.createRecord('tag', tag);
        tmp.set('schema', schema);
        schema.tags.pushObject(tmp);
        await tmp.save();
      }

      await schema.save();

      if (returnSchema) {
        return schema;
      } else {
        this.router.transitionTo('user.bibliothek.schema', schema.id);
      }
    } catch (e) {
      console.log('Fehler beim Herunterladen einer Vorlage: ', e);

      Sentry.captureException(e);
    }
  }

  @action async createExampleAnalysisFromStaticFile(
    url,
    returnAnalysis = false
  ) {
    try {
      let analysisImport = await fetch(url, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      });

      let analysisJSON = await analysisImport.json();
      let analysis = await this.daten.importVAA(analysisJSON, '(Demo)');

      if (returnAnalysis) {
        return analysis;
      } else {
        this.router.transitionTo('user.bibliothek.analyse-mobile', analysis.id);
      }
    } catch (e) {
      this.paperToaster.show(this.intl.t('Import fehlgeschlagen'));
      console.log('Import fehlgeschlagen', e);
    }
  }

  @action async createExampleAnalysisFromStaticFileWithSchema(
    analysisURL,
    schemaURL
  ) {
    let schema = await this.createSchemaFromStaticSchemaTemplate(
      schemaURL,
      true
    );
    let analysis = await this.createExampleAnalysisFromStaticFile(
      analysisURL,
      true
    );

    this.metrics.track('workspace.template.analysis.created', {
      schemaName: schema.name,
      analysisName: analysis.name,
    });

    if (analysis) {
      analysis.schema = schema;
      await analysis.save();

      this.router.transitionTo('user.bibliothek.analyse-mobile', analysis.id);
    }
  }
}
