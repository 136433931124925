import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class TeamsTeamAuthenticatedIndexRoute extends Route {
  @service store;

  async model() {
    return await this.store.findAll('teamplaylist');
  }
  async afterModel() {
    for (let playlist in this.model) {
      console.log(playlist);
      await playlist.get('playlistszenen');
    }
  }
}
