import Model from '../classes/model';
import { tracked } from '@glimmer/tracking';
import { attr, belongsTo, hasMany } from '@ember-data/model';

export default class TeamlineupModel extends Model {
  @attr('string') name;
  @attr('string') notizen;
  @belongsTo('team') team;

  @hasMany('teamlineupplayer', { save: true }) teamlineupplayers;

  get sortedTeammembers() {
    return this.teamlineupplayers.sortBy('number');
  }
}
