import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class OrdnerService extends Service {
  @tracked aktuellerOrdner = '';

  @tracked draggabbleItem = null;

  @service store;

  @action setDraggable(draggabbleItem, e) {
    this.draggabbleItem = draggabbleItem;
  }

  @action async findRecordsForModelnameAndFolder(modelName) {
    const models = await this.store.findAll(modelName);

    if (models) {
      if (this.aktuellerOrdner) {
        return models.filter((model) => {
          if (model.ordner) {
            return model.ordner.get('id') === this.aktuellerOrdner.get('id');
          } else {
            return false;
          }
        });
      } else {
        console.log('hasNoFolder');
        return models.filter((model) => {
          const belongs = model.belongsTo('ordner').id();
          return !Boolean(belongs);
        });
      }
    } else {
      return models;
    }
    // if(this.aktuellerOrdner){
    //   return this.store.query(modelName, { filter : {
    //     ordner : { '$eq' : this.aktuellerOrdner.id }
    //   }});
    // }else{
    //   return this.store.query(modelName, { filter : {
    //     ordner : { '$type' : 'null' }
    //   }});
    // }
  }
}
