import Service from './player';
import { tracked } from '@glimmer/tracking';
import getMax from '../../classes/schema-get-max';
export default class VideoSchemaService extends Service {
  schema;
  schemaTasten = {};

  async setSchema(sche) {
    let schema = await sche;
    if (!schema) {
      return;
    }
    this.schemaTasten = {};
    this.schema = schema;
    this.schema.get('handlungen').forEach((handlung) => {
      if (handlung.get('tastenkombination')) {
        if (!this.schemaTasten[handlung.get('tastenkombination')]) {
          this.schemaTasten[handlung.get('tastenkombination')] = {
            ereignisse: [],
            labels: [],
          };
        }
        this.schemaTasten[handlung.get('tastenkombination')].ereignisse.push(
          handlung
        );
      }
    });
    this.schema.get('tags').forEach((tag) => {
      if (tag.get('tastenkombination')) {
        if (!this.schemaTasten[tag.get('tastenkombination')]) {
          this.schemaTasten[tag.get('tastenkombination')] = {
            ereignisse: [],
            labels: [],
          };
        }
        this.schemaTasten[tag.get('tastenkombination')].labels.push(tag);
      }
    });
  }

  unsetSchema() {
    this.schema = false;
    this.schemaTasten = false;
  }

  async schemaAutoFit(
    schema,
    ansicht,
    containerName,
    additionalMargin = 0,
    takeVisibleAreaOnly = true
  ) {
    let zoom = 1;
    let testElement = document.getElementById(containerName);
    console.log('found container?', testElement, containerName);
    if (!testElement) {
      return 1;
    }

    const schemaResult = await schema;

    if (!schemaResult) {
      return 1;
    }

    const rect = testElement.getBoundingClientRect();
    if (rect != undefined) {
      let taggingWidth = rect.width;
      let taggingHeight = rect.height;
      if (additionalMargin) {
        taggingWidth = taggingWidth - parseInt(additionalMargin);
        taggingHeight = taggingHeight - parseInt(additionalMargin);
      }

      let width, height;
      await schema;

      if (takeVisibleAreaOnly) {
        await schema.get('alle');
        let max = await getMax(schema, ansicht);
        width = max.breite;
        height = max.hoehe;
      } else {
        height = 1;
        width = 1;

        if (schema) {
          switch (ansicht) {
            case 'tablet':
              width = schema.get('breitetablet');
              height = schema.get('hoehetablet');
              break;
            case 'mobil1':
              width = schema.get('breitemobil1');
              height = schema.get('hoehemobil1');
              break;
            case 'mobil2':
              width = schema.get('breitemobil2');
              height = schema.get('hoehemobil2');
              break;
            default:
            case '':
              width = schema.get('breite');
              height = schema.get('hoehe');
          }
        }
      }

      let widthRatio = taggingWidth / width;
      let heightRatio = taggingHeight / height;
      if (widthRatio < heightRatio) {
        zoom = widthRatio;
      } else {
        zoom = heightRatio;
      }
      zoom = Math.floor(zoom * 100) / 100;

      return zoom;
    } else {
      return 1;
    }
  }
}
