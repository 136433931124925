import Service from './video-check';

export default class VideoVideoConvertService extends Service {
  async iosConvert(videoAbsoluteFilepath, vc, qualityCode) {
    vc.set('state', 'converting');
    let filename = vc.get('id');
    console.log('iosConvert 2', videoAbsoluteFilepath);
    let convertPromise = this.taskmanager.startConvert(
      videoAbsoluteFilepath,
      String(filename),
      vc.id,
      qualityCode
    );
    try {
      const neuesVideo = await convertPromise;
      console.log('NEUES VIDEO', neuesVideo);
      if (neuesVideo.videourl) {
        vc.set('ios', neuesVideo.videourl);
        vc.set('exists', true);
      }
      await vc.save();
      this.paperToaster.show(this.intl.t('Konvertierung erfolgreich!'));
      vc.set('state', 'idle');
    } catch (error) {
      vc.set('state', 'idle');
    }
    this.checkIfClipOnDevice(vc).then((clipExists) => {
      vc.set('exists', clipExists.exists);
      vc.set('existsOnNAS', clipExists.existsOnNAS);
    });
  }

  async androidConvert(videoAbsoluteFilepath, vc) {
    vc.set('state', 'converting');
    let filename = vc.get('id');
    let convertPromise = this.taskmanager.startConvert(
      videoAbsoluteFilepath,
      String(filename),
      vc.id
    );
    convertPromise
      .then(async (neuesVideo) => {
        if (neuesVideo.videourl) {
          vc.set('android', neuesVideo.videourl);
          vc.set('exists', true);
        }
        vc.set('state', 'idle');
        await vc.save();
      })
      .catch((err) => {
        console.log('err at convert', err);
      });
    vc.set('state', 'converting');
    this.checkIfClipOnDevice(vc).then((clipExists) => {
      vc.set('exists', clipExists.exists);
      vc.set('existsOnNAS', clipExists.existsOnNAS);
    });
  }
}
