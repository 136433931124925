import Service from './settings';
import { inject as service } from '@ember/service';
import config from 'athlyzer-coach/config/environment';
import { tracked } from '@glimmer/tracking';
import { action, setProperties } from '@ember/object';
import { DateTime } from 'luxon';
import { Preferences } from '@capacitor/preferences';
import Pakete from 'athlyzer-coach/utils/pakete';

export default class AddonUserSubscrioptionService extends Service {
  @service store;
  @service network;

  pakete = new Pakete();
  @tracked paketName = 'Viewer';
  @tracked signUp;
  @tracked stripeCustomerId;
  @tracked referralData;

  @tracked limitedSection;

  @tracked daysSinceSignUp;
  @tracked daysToDie;

  @action async restoreSubscription() {
    try {
      await this.loadLocalSubscription();
    } catch (error) {}
    try {
      await this.loadSubscription();
    } catch (error) {}

    if (config.OVERRIDE_PAKET && config.OVERRIDE_PAKET_NAME) {
      this.paketName = config.OVERRIDE_PAKET_NAME;
      this.paket = config.OVERRIDE_PAKET;
    }

    if (config.OVERRIDE_SIGNUP) {
      this.signUp = config.OVERRIDE_SIGNUP;
    }

    const today = new Date();
    this.daysSinceSignUp = this.daysBetweenDates(this.signUp, today);
    this.daysToDie = 30 - this.daysSinceSignUp;
  }

  daysBetweenDates(date1, date2) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    const differenceInTime = Math.abs(secondDate - firstDate);
    const differenceInDays = Math.ceil(differenceInTime / oneDay);

    return differenceInDays;
  }

  get isNonPaidSubscription() {
    if (
      this.paketName &&
      (this.paketName === 'Viewer' || this.paketName === 'Trial')
    ) {
      return true;
    } else {
      return false;
    }
  }

  @action async loadLocalSubscription() {
    const localData = await Preferences.get({
      key: `${this.user_uid}_subscriptions`,
    });
    console.log('@loadLocalSubscription', this.user_uid, localData);
    if (localData?.value) {
      const data = JSON.parse(localData.value);
      if (data.paketName) {
        setProperties(this, data);
      }
    }
  }

  @action async loadSubscription() {
    if (!this.network.isConnectedToInternet) return;

    try {
      const access = await this.getHttp().get('user/access');
      if (access.data) {
        const { data } = access;
        console.log('@loadSubscription', data);
        const dataToSave = {
          paketName: data.paketName,
          signUp: data.signUp,
          stripeCustomerId: data.stripe_customer_id,
          referralData: data.referral,
        };
        setProperties(this, dataToSave);
        console.log(
          '@loadSubscription',
          `${this.user_uid}_subscriptions`,
          dataToSave
        );
        await Preferences.set({
          key: `${this.user_uid}_subscriptions`,
          value: JSON.stringify(dataToSave),
        });
        return dataToSave;
      }
    } catch (error) {
      return;
    }
  }

  get licenseLevel() {
    let paketNamen = this.pakete.paketNamen;

    if (this.paketName === 'Trial') {
      return 'Expert';
    }

    if (paketNamen.free.includes(this.paketName)) {
      return 'Free';
    } else if (paketNamen.rookie.includes(this.paketName)) {
      return 'Rookie';
    } else if (paketNamen.professional.includes(this.paketName)) {
      return 'Professional';
    } else if (paketNamen.expert.includes(this.paketName)) {
      return 'Expert';
    } else if (paketNamen.level1.includes(this.paketName)) {
      return 'Level 1';
    } else if (paketNamen.level2.includes(this.paketName)) {
      return 'Level 2';
    } else if (paketNamen.level3.includes(this.paketName)) {
      return 'Level 3';
    } else {
      return 'Free';
    }
  }

  unsetValues() {
    this.settings = null;
    this.workspaces = null;
    this.invites = null;
    this._session = null;
    this.signUp = null;
    this.stripeCustomerId = null;
    this.paketName = 'Viewer';
  }

  @action logging(string) {
    this.addonCore.closePurchase();
    console.log('string', string);
  }

  @action canExecute(string) {
    if (this.paketName === 'Viewer') {
      return false;
    }

    let check = string.split('.');

    let neededPackagesForFeature = check.reduce((base, right) => {
      if (base) {
        return base[right];
      } else {
        return false;
      }
      // initial Value for reduce
    }, this.pakete.pakete);

    if (neededPackagesForFeature) {
      return neededPackagesForFeature.includes(this.paketName);
    } else {
      return true;
    }
  }

  checkForAccessToFeatureAndSetInfoModal(
    neededFeatureString,
    showModal = true
  ) {
    let isAllowedToAccess = true;

    if (Array.isArray(neededFeatureString)) {
      neededFeatureString.forEach((feature) => {
        isAllowedToAccess = this.canExecute(feature);
      });
    } else {
      isAllowedToAccess = this.canExecute(neededFeatureString);
    }

    if (!isAllowedToAccess && showModal) {
      this.addonCore.isFeatureAccessRestrictedModalOpen = true;
    }

    return isAllowedToAccess;
  }
}
