customElements.define(
  'modal-confirm',
  class extends HTMLElement {
    connectedCallback() {
      const modalElement = document.getElementById('modal-confirm');
      this.innerHTML = `
          <div class="flex justify-center items-center">
            <div
              class="flex w-full h-auto relative justify-center items-end bg-white px-4 pt-5 pb-4 max-h-screen transform transition-all sm:items-center sm:max-w-lg sm:p-6"
            >
              <div class="mt-5 sm:mt-2 md:mt-0">
                <div class="px-0 md:px-4 text-lg font-medium">
                ${modalElement.componentProps.title}
                </div>
                <div class="px-0 md:px-4 py-5 bg-white">
                  <p class="text-sm">
                  ${modalElement.componentProps.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="grid gap-2 px-4 py-3 text-center grid-cols-2">
            <button id='modal-confirm-close-2' class="inline-flex justify-center items-center border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none transition ease-in-out duration-150 bg-white text-primary-600 hover:bg-primary-50 w-auto px-3 py-2" type="button">    
              Abbrechen
            </button>
            <button id="modal-confirm-confirm" class="inline-flex justify-center items-center border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none transition ease-in-out duration-150 text-white bg-primary-500 hover:bg-primary-700 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 w-auto px-3 py-2" type="button">
              OK
            </button> 
          </div>
       `;

      const closeButton2 = modalElement.querySelector('#modal-confirm-close-2');
      const confirmButton = modalElement.querySelector(
        '#modal-confirm-confirm'
      );
      const getDataAndCloseModal = () => {
        const value = true;
        modalElement.componentProps.dismiss({
          value,
          cancelled: false,
        });
      };
      confirmButton.addEventListener('click', () => {
        getDataAndCloseModal();
      });
      closeButton2.addEventListener('click', () => {
        modalElement.componentProps.dismiss({
          cancelled: true,
        });
      });
    }

    disconnectedCallback() {}
  }
);

export default async function createModalPromptText(title, name) {
  const modalElement = document.createElement('ion-modal');
  modalElement.component = 'modal-confirm';
  modalElement.id = 'modal-confirm';
  modalElement.componentProps = {
    name,
    title,
    dismiss: (opt) => {
      modalElement.dismiss(opt);
    },
  };
  document.body.appendChild(modalElement);
  await modalElement.present();
  const { data, role } = await modalElement.onDidDismiss();
  modalElement.parentElement.removeChild(modalElement);

  if (role === 'backdrop') {
    return { value: false, cancelled: true };
  } else {
    return data;
  }
}
