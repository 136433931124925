import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { Preferences } from '@capacitor/preferences';
import Cross from 'athlyzer-coach/cross/plugin';
import runPlaylistMigrations from '../../models/migrations/playlist';
import * as Sentry from '@sentry/ember';

export default class UserBibliothekAnalyseRoute extends Route {
  @service video;
  @service router;
  @service addonCore;
  @service store;

  async checkModel(model) {
    await model.get('videoszenen');
  }
  async repairAnalysisScenes(analyseid) {
    const db = this.store.adapterFor('application').db;
    const videobearbeitung = await db.get('videobearbeitung_2_' + analyseid);
    const arr = videobearbeitung.data.videoszenen.map((szenenid) => {
      return db.get('videoszene_2_' + szenenid);
    });
    const promises = await Promise.allSettled(arr);
    videobearbeitung.data.videoszenen = promises
      .filter((s) => {
        return s.status !== 'fulfilled';
      })
      .map((s) => {
        const id = s.value._id.replace('videoszene_2_', '');
        return id;
      });
    await db.put(videobearbeitung).save();
  }

  async model({ analyseid }) {
    let model = await this.store.findRecord('videobearbeitung', analyseid);

    try {
      await this.checkModel(model);
      this.video.setAnalyse(model);
      await this.video.updateClipsmanager();
      await this.video.selectFirstExistingClip();
      return model;
    } catch (e) {
      Sentry.captureException(e);
    }

    try {
      await this.store.unloadRecord(model);
      await this.repairAnalysisScenes(analyseid);

      let playlists = await model.get('playlists');
      playlists.forEach(async (playlist) => {
        await runPlaylistMigrations(playlist);
      });

      model = await this.store.findRecord('videobearbeitung', analyseid);
      await this.checkModel(model);
      this.video.setAnalyse(model);
      await this.video.updateClipsmanager();
      await this.video.selectFirstExistingClip();
    } catch (error) {
      Sentry.captureException(error);
    }
    return model;
  }

  async afterModel(model) {
    let settings = ['up', 'down', 'left', 'right'];
    for (let settingName of settings) {
      let key = 'athlyzer-hotkey-' + settingName;
      let hotkey = await Preferences.get({ key: key });

      if (!hotkey.value) {
        hotkey = {
          icon: 'fast',
          function: 1,
        };
      } else {
        hotkey = JSON.parse(hotkey.value);
      }

      this.video.setHotkey(hotkey, settingName);
    }

    if (model.get('clips')) {
      this.router.transitionTo(
        'user.bibliothek.alte-analyse-konvertieren',
        model.get('id')
      );
    }

    if (this.addonCore.isLoading) {
      this.addonCore.isLoading = false;
    }
  }

  @action async didTransition() {
    // await this.video.updateClipsmanager();
    this.video.selectFirstExistingClip();

    const { value: modeLabelBar } = await Preferences.get({
      key: 'modeLabelBar',
    });
    const { value: modeExtendedTimeResolution } = await Preferences.get({
      key: 'modeExtendedTimeResolution',
    });

    try {
      let scheme = await this.controller.model.get('schema');
      if (scheme) {
        this.controller.allLabels = await scheme.get('tags');
      } else {
        let allLabels = {};
        let vscenes = await this.controller.model.get('videoszenen');
        if (vscenes) {
          for (let vscene of vscenes.toArray()) {
            let tags = vscene.get('tags');
            if (tags) {
              for (let label of tags) {
                if (!allLabels[label.name]) {
                  allLabels[label.name] = {
                    name: label.name,
                    farbe: label.farbe,
                    farbetext: label.farbetext,
                    textgesamt: label.textgesamt,
                  };
                }
              }
            }
          }
          let allLabelsArray = Object.keys(allLabels).map(function (key) {
            return {
              name: key,
              farbe: allLabels[key].farbe,
              farbetext: allLabels[key].farbetext,
              textgesamt: allLabels[key].textgesamt,
            };
          });
          let allTags = [];
          for (let tag of allLabelsArray) {
            allTags.push(
              this.store.createRecord('tag', {
                name: tag.name,
                textgesamt: tag.textgesamt,
                farbetext: tag.farbetext,
                farbe: tag.farbe,
              })
            );
          }
          this.controller.allLabels = allTags;
        }
      }
    } catch (error) {
      console.log('something happend while setting labels', error);
    }

    if (this.controller.model.get('liveTagging')) {
      this.controller.modifyLiveTagging = true;
    }
    if (modeLabelBar == 'olympic') {
      this.video.showLabelBar = true;
    } else {
      this.video.showLabelBar = false;
    }
  }
}
