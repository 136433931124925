import Service from './video-add';
import Cross from 'athlyzer-coach/cross/plugin';
import { CapacitorHttp } from '@capacitor/core';
import linkUtil from 'athlyzer-coach/classes/link-util';
import { action } from '@ember/object';
import deviceutil from 'athlyzer-coach/classes/deviceutil';
import FfmpegCommandBuilder from 'athlyzer-coach/cross/ffmpeg';

export default class VideoVideoCheckService extends Service {
  @action checkLink(link) {
    return new Promise((resolve, reject) => {
      if (linkUtil.isYoutubeVideoLink(link)) {
        this.errorMessage && (this.errorMessage = undefined);
        resolve();
      } else if (linkUtil.isTwitchVideoLink(link)) {
        this.errorMessage && (this.errorMessage = undefined);
        resolve();
      } else if (link.indexOf('https://www.twitch.tv') == 0) {
        console.log('twitch 2');
        this.errorMessage = this.intl.t(
          'Zur Zeit werden nur Twitch-Videos untersützt. Clips folgen in der nächsten Version'
        );
        reject(this.errorMessage);
      } else {
        this.errorMessage = this.intl.t(
          'Derzeit werden nur Youtube- und Twitch-Videos unterstützt'
        );
        reject(this.errorMessage);
      }
    });
  }

  @action async getVideoclipDetails(videoclip) {
    let details = {
      web: { exists: false },
      nas: { exists: false },
      desktop: { exists: false },
      youtubetwitch: { exists: false },
    };
    if (videoclip.originalLink) {
      // TODO needs a real check of existense
      details.youtubetwitch.exists = true;
    }

    if (videoclip.web) {
      try {
        const videoclipExistsCheckResponse = await CapacitorHttp.request({
          url: videoclip.web,
          method: 'GET',
          headers: {
            'Cache-Control': 'no-cache',
            'Access-Control-Expose-Headers':
              'E-Tag Access-Control-Allow-Origin',
            Origin: 'https://www.athlyzer.com',
            'Access-Control-Allow-Origin': '*',
            Range: 'bytes=0-201',
          },
        });

        if (
          videoclipExistsCheckResponse.status === 200 ||
          videoclipExistsCheckResponse.status === 206
        ) {
          details.web.exists = true;

          const contentLength = Number(
            videoclipExistsCheckResponse.headers['content-length']
          );
          details.web.exists = true;
          details.web.url = videoclip.web;
          details.web.size = contentLength;
          if (contentLength < 200) {
            details.web.error =
              'Die Datei scheint defekt zu sein. Lade die Datei erneut hoch';
          }
        } else if (videoclipExistsCheckResponse.status === 404) {
          details.web.exists = false;
        } else {
          details.web.exists = false;
        }
      } catch (error) {
        details.web.exists = false;
      }
    } else {
      details.web.exists = false;
    }

    if (deviceutil.isElectron && videoclip.desktop) {
      const { exists } = await Cross.fileExists({
        videourl: videoclip.desktop,
      });
      console.log('exists', exists);
      if (!exists) {
        details.desktop.error =
          'Datei ist nicht auf der Festplatte gespeichert';
      } else {
        details.desktop.exists = true;
        details.desktop.url = videoclip.desktop;
        try {
          const videoinformation = await Cross.getVideofileMetadata({
            videourl: videoclip.desktop,
          });

          if (videoinformation.general?.FileSize) {
            const size = Number(videoinformation.general.FileSize);
            details.desktop.size = size;
            if (size < 2000) {
              details.desktop.error =
                'Die Datei scheint kaputt zu sein. Du solltest das Video erneut konvertieren.';
            } else if (details.web.size && details.web.size < size) {
              details.desktop.error =
                'Deine Upload könnte defekt sein. Lade das Video erneut hoch';
            }
          }
        } catch (error) {}
      }
    }
    if (deviceutil.isElectron && videoclip.nas) {
      const { exists } = await Cross.fileExists({
        videourl: videoclip.nas,
      });
      console.log('exists', exists);
      if (!exists) {
        details.nas.error = 'Datei ist nicht auf der Festplatte gespeichert';
      } else {
        details.nas.exists = true;
        details.nas.url = videoclip.nas;
        try {
          const videoinformation = await Cross.getVideofileMetadata({
            videourl: videoclip.nas,
          });

          if (videoinformation.general?.FileSize) {
            const size = Number(videoinformation.general.FileSize);
            details.nas.size = size;
            if (size < 2000) {
              details.nas.error =
                'Die Datei scheint kaputt zu sein. Du solltest das Video erneut konvertieren.';
            } else if (details.web.size && details.web.size < size) {
              details.nas.error =
                'Deine Upload könnte defekt sein. Lade das Video erneut hoch';
            }
          }
        } catch (error) {}
      }
    }
    if (deviceutil.isIos && videoclip.ios) {
      const { exists } = await Cross.fileExists({
        videourl: videoclip.ios,
      });
      console.log('exists', exists);
      if (!exists) {
        details.desktop.error =
          'Datei ist nicht auf der Festplatte gespeichert';
      } else {
        details.desktop.exists = true;
        details.desktop.url = videoclip.ios;
      }
    }
    if (deviceutil.isIos && videoclip.iosexternal) {
      const url = await videoclip.get('iosexternal');
      const { videourl } = await Cross.getBookmark({
        videourl: url,
      });
      if (videourl) {
        const { exists } = await Cross.fileExists({
          filepath: videourl,
        });
        if (!exists) {
          details.nas.error = 'Datei ist nicht auf der Festplatte gespeichert';
        } else {
          details.nas.exists = true;
          details.nas.url = videoclip.iosexternal;
        }
      }
    }
    if (deviceutil.isAndroid && videoclip.android) {
      const { exists } = await Cross.fileExists({
        videourl: videoclip.android,
      });
      console.log('exists', exists);
      if (!exists) {
        details.desktop.error =
          'Datei ist nicht auf der Festplatte gespeichert';
      } else {
        details.desktop.exists = true;
        details.desktop.url = videoclip.android;
      }
    }
    if (deviceutil.isAndroid && videoclip.androidexternal) {
      console.log('before get Bookmark3');
      const videourl = await videoclip.get('androidexternal');
      const { exists } = await Cross.fileExists({
        filepath: videourl,
      });
      if (!exists) {
        details.nas.error = 'Datei ist nicht auf der Festplatte gespeichert';
      } else {
        details.nas.exists = true;
        details.nas.url = videoclip.androidexternal;
      }
    }

    return details;
  }

  @action async getVideoMediaInfo(options) {
    let encodedURL;
    if (options.videourl) {
      encodedURL = await Cross.videourlToAbsoluteFilepath({
        videourl: options.videourl,
      });
    } else if (options.filepath) {
      encodedURL = options;
    }

    console.log('Encoded URL', encodedURL, options);

    const run = await FfmpegCommandBuilder.create('FULLHD').runStats(
      encodedURL.filepath
    );

    console.log('athlyzer-vc-getVideoMediaInfo', JSON.stringify(run));

    return {
      ...run,
    };
  }

  @action async checkQualityOfVideoclipsInAnalysis(analysis) {
    const qualityOfOtherVideoclips = analysis
      .get('videoclips')
      .map((videoclip) => {
        if (videoclip.convertedVideoHeight) {
          return {
            height: videoclip.convertedVideoHeight,
            width: videoclip.convertedVideoWidth,
            bitrate: videoclip.convertedVideoBitrate,
          };
        } else {
          return {
            height: null,
            width: null,
            bitrate: null,
            constant: true,
          };
        }
      })
      .reduce(
        (accumulator, currentVideoclipHeight) => {
          if (accumulator.width) {
            if (accumulator.width != currentVideoclipHeight.width) {
              accumulator.constant = false;
            }
          } else {
            accumulator.width = currentVideoclipHeight.width;
          }

          if (accumulator.height) {
            if (accumulator.height != currentVideoclipHeight.height) {
              accumulator.constant = false;
            }
          } else {
            accumulator.height = currentVideoclipHeight.height;
          }

          if (accumulator.bitrate) {
            if (accumulator.bitrate != currentVideoclipHeight.bitrate) {
              accumulator.constant = false;
            }
          } else {
            accumulator.bitrate = currentVideoclipHeight.bitrate;
          }

          return accumulator;
        },
        {
          height: null,
          width: null,
          bitrate: null,
          constant: true,
        }
      );

    return qualityOfOtherVideoclips;
  }
}
