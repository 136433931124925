import Component from '@glimmer/component';

import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Cross from 'athlyzer-coach/cross/plugin';
import { Capacitor } from '@capacitor/core';
import deviceutil from '../../classes/deviceutil';

export default class VideoComponent extends Component {
  @service addonCore;
  @service video;
  @service router;
  @service visual;

  @tracked showTagging = false;

  info;
  tmp;

  constructor(owner, args) {
    super(owner, args);
    this.setFolder();
  }

  get getCssClass() {
    return this.video.isFullscreen
      ? 'fixed top-0 left-0 h-screen w-screen z-index-max'
      : 'relative bg-black flex justify-center object-contain';
  }

  async setFolder() {
    let folder = {};
    if (deviceutil.isNative) {
      folder = await Cross.folder();
    } else {
      folder.path = '';
    }
    this.info = {};
    this.info.folder = folder.path;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.video.unsetPlayer();

    if (this.tmp && this.tmp.destroy) {
      this.tmp.destroy();
    }
  }

  /* @aktiverVideoclip */
  async sources(
    videoContainer,
    [self, aktiverVideoclip, calcSizeByThisElementId, isInitService]
  ) {
    if (isInitService) {
      self.video.setPlayerContainer(videoContainer, calcSizeByThisElementId);
    }

    if (self.video.calcSizeByThisElementId != calcSizeByThisElementId) {
      if (calcSizeByThisElementId) {
        self.video.calcSizeByThisElementId = calcSizeByThisElementId;
      } else {
        self.video.removeStylesFromPlayer();
      }
    }

    const sources = await self.getVideoSources(aktiverVideoclip);

    if (sources && sources.length > 0) {
      self.video.updatePlayer({
        url: sources,
      });
    }
  }

  @action async getVideoSources(aktiverVideoclip) {
    let url = [];

    if (!aktiverVideoclip) {
      // video.load();
      return;
    } else {
      await aktiverVideoclip;
    }

    if (!this.info || this.info.folder) {
      const folder = await Cross.folder();
      this.info = {
        folder: folder.path,
      };
    }

    if (aktiverVideoclip && aktiverVideoclip.tmp) {
      // let src = document.createElement('source');
      // src.setAttribute('src', aktiverVideoclip.tmp);
      // video.appendChild(src);
      url.push(aktiverVideoclip.tmp);
    }

    if (this.tmp) {
      this.tmp.destroy();
    }

    if (this.addonCore.isAndroid && aktiverVideoclip) {
      if (aktiverVideoclip.get('android')) {
        let path = aktiverVideoclip.get('android');
        const { exists } = await Cross.fileExists({ filepath: path });
        if (exists) {
          let uri = Capacitor.convertFileSrc(path);
          if (uri.indexOf('localhost') === -1) {
            uri = uri.replace(
              /\b(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)(?::\d{0,4})?\b/,
              'localhost'
            );
          }

          url.push(uri + '#t=0.1');
        }
      } else if (aktiverVideoclip.get('androidexternal')) {
        let path = aktiverVideoclip.get('androidexternal');
        const { exists } = await Cross.fileExists({ filepath: path });
        if (exists) {
          let uri = Capacitor.convertFileSrc(path);
          if (uri.indexOf('localhost') === -1) {
            uri = uri.replace(
              /\b(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)(?::\d{0,4})?\b/,
              'localhost'
            );
          }

          url.push(uri + '#t=0.1');
        }
      } else if (aktiverVideoclip.get('androidhls') && Hls.isSupported()) {
        let uri =
          this.info.folder +
          '/' +
          aktiverVideoclip.get('androidhls') +
          '#t=0.1';
        uri = window.Ionic.WebView.convertFileSrc(uri);
        url.push(uri);
      }
    } else if (this.addonCore.isIos) {
      if (aktiverVideoclip.get('ios')) {
        console.log('@ios');
        let videourl = aktiverVideoclip.get('ios');
        videourl = videourl.replace('//', '/');
        const { exists } = await Cross.fileExists({
          videourl,
        });

        if (exists) {
          let directory = await Cross.folder();

          let uri = Capacitor.convertFileSrc(directory.path + videourl);

          uri = uri.replace(
            'capacitor://localhost/_capacitor_file_/',
            'http://localhost:8080/'
          );

          url.push(uri + '#t=0.1');
        }
      }
      if (aktiverVideoclip.get('iosexternal')) {
        console.log('@ios external');
        let filepath = await aktiverVideoclip.get('iosexternal');
        if (filepath) {
          const { exists } = await Cross.fileExists({
            filepath,
          });

          if (exists) {
            let { videourl } = await Cross.absoluteFilepathToVideourl({
              filepath,
            });

            let uri = Capacitor.convertFileSrc(videourl);

            if (uri.indexOf('localhost') === -1) {
              uri = uri.replace(
                /\b(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)(?::\d{0,4})?\b/,
                'localhost'
              );
            }

            uri = uri.replace(
              'capacitor://localhost/_capacitor_file_/',
              'http://localhost:8080/'
            );

            url.push(uri + '#t=0.1');
          }
        }
      } else if (aktiverVideoclip.get('ioshls')) {
        url.push(
          'http://localhost:8080' + '/' + aktiverVideoclip.get('ioshls')
        );
      }
    } else if (deviceutil.isElectron) {
      if (aktiverVideoclip.get('desktop')) {
        const { exists } = await Cross.fileExists({
          videourl: aktiverVideoclip.get('desktop'),
        });
        if (exists) {
          const desktopClip = aktiverVideoclip.get('desktop');
          url.push(
            `athlyzer-stream://${desktopClip}?version=${Date.now()}#t=0.1`
          );
        }
      }

      if (aktiverVideoclip.get('nas')) {
        const { exists } = await Cross.fileExists({
          videourl: aktiverVideoclip.get('nas'),
        });

        if (exists) {
          const nasClip = aktiverVideoclip.get('nas');
          url.push(`athlyzer-stream://${nasClip}#t=0.1`);
        }
      }
      if (aktiverVideoclip.get('desktophls')) {
        const { exists } = await Cross.fileExists({
          videourl: aktiverVideoclip.get('desktophls'),
        });
        if (exists) {
          url.push('athlyzer-stream://' + aktiverVideoclip.get('desktophls'));
        }
      }
    }
    if (aktiverVideoclip && aktiverVideoclip.web) {
      url.push(aktiverVideoclip.web + '#t=0.1');
    }
    if (aktiverVideoclip && aktiverVideoclip.originalLink && url.length == 0) {
      url = aktiverVideoclip.originalLink;
    }

    // Workaround bis dieser PR released ist: https://github.com/cookpete/react-player/pull/1612
    // if (url.length === 1) {
    //   url = url.pop();
    // } else if (url.length === 0) {
    //   url = '';
    // }

    return url;
  }
}
