export default async (playlist) => {
  let schemaVersion = await playlist.get('version');

  if (!schemaVersion || schemaVersion === 0) {
    /* Sort Playlist Scenes by parent array */
    let playlistszenen = await playlist.get('playlistszenen');
    playlist.playlistszenen = playlistszenen.toArray().sort((a, b) => {
      return Number(a.get('position')) - Number(b.get('position'));
    });

    playlist.version = 1;
    schemaVersion = 1;
    await playlist.save();
  }
};
