import Service from './analyse';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class VideoCaptureService extends Service {
  captureVideoclip = null;
  captureUrl = null;
  @tracked iscapture = false;
  @tracked mediaRecorder = null;
}
