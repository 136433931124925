import Service from './video-convert';
import { inject as service } from '@ember/service';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import Cross from 'athlyzer-coach/cross/plugin';
import replaceSpecialChars from 'athlyzer-coach/helper-functions/regex/replaceSpecialCharsURL';
import VIDEOEXPORT from 'athlyzer-coach/classes/videoexport';
import deviceutil from 'athlyzer-coach/classes/deviceutil';
import { action } from '@ember/object';
import uuid from 'athlyzer-coach/application/uuid';
import Konva from 'konva';
import FfmpegCommandBuilder from 'athlyzer-coach/cross/ffmpeg';
import * as Sentry from '@sentry/ember';

export default class VideoVideoCutService extends Service {
  @service paperToaster;
  @action async askForExportType(szenenCount, exclude = []) {
    const askForShareChannelActionSheet =
      document.createElement('ion-action-sheet');
    askForShareChannelActionSheet.header = this.intl.t('Wähle einen Kanal');
    askForShareChannelActionSheet.mode = 'md';
    askForShareChannelActionSheet.animated = true;
    askForShareChannelActionSheet.buttons = [];
    if (!exclude.includes('teams')) {
      askForShareChannelActionSheet.buttons.push({
        text: this.intl.t('Zu Team-Link hinzufügen'),
        icon: 'athlyzer-team',
        data: 'teams',
        role: 'teams',
      });
    }

    if (!exclude.includes('link')) {
      askForShareChannelActionSheet.buttons.push({
        text: this.intl.t('Als Link teilen'),
        icon: 'globe-outline',
        data: 'link',
        role: szenenCount == 1 ? 'link' : 'merge-link',
      });
    }

    if (!exclude.includes('powerpoint')) {
      askForShareChannelActionSheet.buttons.push({
        text: this.intl.t('Powerpoint'),
        icon: 'powerpoint',
        data: 'powerpoint',
        role: 'powerpoint',
      });
    }

    askForShareChannelActionSheet.buttons.push({
      text: this.intl.t('Als ein Videoclip exportieren'),
      icon: 'document-outline',
      role: szenenCount == 1 ? 'file' : 'merge-files',
    });
    if (szenenCount > 1) {
      askForShareChannelActionSheet.buttons.push({
        text: this.intl.t('Als mehrere Videoclips exportieren'),
        icon: 'documents-outline',
        role: 'files',
      });
    }

    askForShareChannelActionSheet.buttons.push({
      text: this.intl.t('Abbrechen'),
      icon: 'close',
      role: 'cancel',
      handler: () => {
        console.log('Cancel clicked');
      },
    });

    document.body.appendChild(askForShareChannelActionSheet);
    await askForShareChannelActionSheet.present();
    const askForShareChannelActionSheetResult =
      await askForShareChannelActionSheet.onDidDismiss();
    if (
      askForShareChannelActionSheetResult.role === 'cancel' ||
      askForShareChannelActionSheetResult.role === 'backdrop'
    ) {
      return { cancelled: true };
    }
    this.metrics.track(
      `analysis.${askForShareChannelActionSheetResult.role}.chosen`
    );
    return {
      cancelled: false,
      exportType: askForShareChannelActionSheetResult.role,
    };
  }

  secondsToTime(secs) {
    return new Date(Number(secs) * 1000).toISOString().substr(11, 11);
  }
  getCutPoints(szene) {
    let seek =
      Number(szene.get('startzeit')) - 10 > 0
        ? Number(szene.get('startzeit')) - 10
        : 0;
    let seekString = new Date(seek * 1000).toISOString().substr(11, 8);
    let start = new Date(Number(szene.get('startzeit')) * 1000)
      .toISOString()
      .substr(11, 11);
    let end = new Date(Number(szene.get('endzeit')) * 1000)
      .toISOString()
      .substr(11, 11);
    let sceneName = replaceSpecialChars(szene.get('textgesamt'));

    return { seekString, start, end, sceneName };
  }

  @action async canvasToFile(width, height, serializedKonvaStage, filename) {
    const container = document.createElement('div');
    let stageProps = {
      container: container,
      width,
      height,
      scaleX: 1,
      scaleY: 1,
    };

    const stage = new Konva.Stage(stageProps);
    let deserializedLayer = JSON.parse(serializedKonvaStage);
    let denormalizedLayer = this.denormalizeLayer(
      deserializedLayer,
      width,
      height
    );
    let newlayer = Konva.Node.create(denormalizedLayer);
    stage.add(newlayer);
    stage.draw();

    const dataurl = stage.toDataURL({
      pixelRatio: 2,
    });
    // console.log("canvasToFile dataurl", dataurl);
    // const result = await Cross.writeStringToFile({
    //   filepath: exportFolder + '/tmp.jpg',
    //   value: base64,
    // });
    if (deviceutil.isElectron) {
      const fetchImage = await fetch(dataurl);
      const imageBlob = await fetchImage.blob();
      const savedImageURI = await Cross.crosswrite(
        filename + '.png',
        imageBlob
      );
      return savedImageURI;
    } else if (deviceutil.isApp) {
      const savedImageURI = await Filesystem.writeFile({
        path: 'tmp.png',
        data: dataurl,
        directory: Directory.Documents,
      });
      return savedImageURI;
    }
  }

  /* exportQuality - { TEXT : STRING BITRATE[] : STRING, SCALING[] : STRING } */
  @action async cutSzenenInListe(
    szenenliste,
    prefix,
    exportLogoOverlayImageArray,
    exportQualityString,
    includeDrawings = true
  ) {
    let exportQuality = VIDEOEXPORT.isValidExportQualityString(
      exportQualityString
    )
      ? VIDEOEXPORT.QUALITIES[exportQualityString]
      : VIDEOEXPORT.QUALITIES[VIDEOEXPORT.defaultQualityString];

    if (prefix) {
      prefix = prefix + '_';
    }

    let exportierteSzenen = [],
      exportierteSzenenListe = [];
    let index = 0,
      count = 1;

    for (const playlistszene of szenenliste) {
      const szene = await playlistszene.get('videoszene');

      exportierteSzenenListe.push({
        uuid: uuid(),
        position: count,
        name: await szene.get('textgesamt'),
        state: 'Vorbereitung',
        szenenid: szene.id,
        playlistszenenid: playlistszene.id,
      });
    }

    this.exportierteSzenenListe = exportierteSzenenListe;

    for (const playlistszene of szenenliste) {
      const szene = await playlistszene.get('videoszene');
      let clip = await szene.get('videoclip');
      console.log('VIDEOCLIP', clip);
      let src = clip.anyAvailableVideo;

      if (!src) {
        console.log('src video not availabe');
        this.paperToaster.show(
          'Leider ist eine der Videodateien nicht verfügbar. Bitte überprüfe die Videos.'
        );
        return;
      }

      if (
        this.exportierteSzenenListe[index] &&
        this.exportierteSzenenListe[index].state
      ) {
        this.exportierteSzenenListe[index].state = 'Schneiden';
      }
      const cutPointsResponse = this.getCutPoints(szene);
      let fileName = `${count}_${
        cutPointsResponse.sceneName
      }_${Date.now()}`.replace(' ', '_');

      let cutInternalOptions = {
        videourl: src,
        start: cutPointsResponse.start,
        end: cutPointsResponse.end,
        seekString: cutPointsResponse.seekString,
        fileName: fileName,
        outputFileExtension: 'ts',
        beginnerFfmpegProfileName: exportQuality.BEGINNERFFMPEGPROFILE,
      };

      const overlays = [];

      for (let presetImage of exportLogoOverlayImageArray) {
        let url;
        if (deviceutil.isApp) {
          const writtenLogo = await Filesystem.writeFile({
            path: `logo_overlay_${presetImage.name}.${
              presetImage.content_type.split('/')[1]
            }`,
            data: `data:${presetImage.content_type};base64,${presetImage.data}`,
            directory: Directory.Documents,
          });
          url = writtenLogo.uri;
          console.log('@overlays url', url);
        } else if (deviceutil.isElectron) {
          const fetchImage = await fetch(
            `data:${presetImage.content_type};base64,${presetImage.data}`
          );
          const imageBlob = await fetchImage.blob();
          const savedImageURI = await Cross.crosswrite(
            `logo_overlay_${presetImage.name}.${
              presetImage.content_type.split('/')[1]
            }`,
            imageBlob
          );
          url = savedImageURI.uri;
        }
        overlays.push({
          url,
          position: presetImage.name.toLowerCase(),
          percent: 15,
        });
      }

      cutInternalOptions.overlays = overlays;

      console.log('overlays', overlays);
      let exportTask;

      let layer = playlistszene.layer;

      if (includeDrawings && layer && Object.keys(layer).length > 0) {
        // this scene has konva layers
        // divide the scene into multiple scenes
        const start = Number(szene.startzeit);
        let end = Number(szene.endzeit);

        let layerKeys = Object.keys(layer).sort(
          (a, b) => Number(a) - Number(b)
        );

        let fileIndex = 1;

        const { filepath } = await Cross.videourlToAbsoluteFilepath({
          videourl: cutInternalOptions.videourl,
        });

        let videoexportpath;
        if (deviceutil.isElectron) {
          videoexportpath = (await Cross.folder()).videosexportpath + '/';
        } else if (deviceutil.isAndroid) {
          videoexportpath = (await Cross.folder()).path + '/';
        } else if (deviceutil.isApp) {
          videoexportpath = (await Cross.folder()).path;
        }
        videoexportpath += cutInternalOptions.fileName;

        const videoexportpathTmp = videoexportpath + '_';

        // video bis zum ersten Layer.secondsToTime
        const videosPathesToMerge = [];

        if (Number(layerKeys[0]) > 0) {
          const fileName = cutInternalOptions.fileName + '_' + fileIndex;
          const cutResult = await this.taskmanager.startCut({
            ...cutInternalOptions,
            fileName,
            videourl: filepath,
            start: this.secondsToTime(start),
            end: this.secondsToTime(start + Number(layerKeys[0])),
          });

          let absoluteFilepath = await Cross.videourlToAbsoluteFilepath({
            videourl: cutResult.videourl,
          });

          videosPathesToMerge.push(absoluteFilepath.filepath);
          fileIndex++;
        }

        let imagesToDelete = [];

        for (let i = 0; i < layerKeys.length; i++) {
          const layerTime = Number(layerKeys[i]);

          const outputPathFreezeFrame = videoexportpathTmp + fileIndex;
          const outputFreezedFramePathImage = outputPathFreezeFrame + '.png';
          const outputPathVideo = outputPathFreezeFrame + '.ts';

          const serializedKonvaStage = layer[layerKeys[i]];
          // save konva stage as image

          let aspectRatio = 1.77;

          const clip = await szene.get('videoclip');

          if (clip?.convertedVideoWidth && clip?.convertedVideoHeight) {
            aspectRatio = clip.convertedVideoWidth / clip.convertedVideoHeight;
          } else if (clip?.externalVideoWidth && clip?.externalVideoHeight) {
            aspectRatio = clip.externalVideoWidth / clip.externalVideoHeight;
          }

          const savedImageURI = await this.canvasToFile(
            1600,
            1600 / aspectRatio,
            serializedKonvaStage,
            fileIndex
          );

          imagesToDelete.push(savedImageURI.uri);
          console.log('savedImageURI', savedImageURI);

          let cutWithFullOverlayOptions = null;
          let doesNextLayerExists = layerKeys[i + 1];
          let isCurrentLayerEmpty =
            JSON.parse(layer[layerKeys[i]]).children.length === 0;
          let isNextLayerEmpty = doesNextLayerExists
            ? JSON.parse(layer[layerKeys[i + 1]]).children.length === 0
            : true;

          if (
            (!doesNextLayerExists && !isCurrentLayerEmpty) ||
            !isNextLayerEmpty ||
            (isNextLayerEmpty && layerKeys[i + 1] - layerKeys[i] >= 0.21)
          ) {
            cutWithFullOverlayOptions = {
              ...cutInternalOptions,
              coverOverlay: {
                url: savedImageURI.uri,
              },
            };
          } else if (
            isNextLayerEmpty &&
            layerKeys[i + 1] - layerKeys[i] < 0.21
          ) {
            await this.taskmanager.startCreateFrameFromVideo(
              filepath,
              start + layerTime,
              outputFreezedFramePathImage,
              cutInternalOptions
            );

            imagesToDelete.push(outputFreezedFramePathImage);

            await this.taskmanager.startCreateVideoFromImageWithOverlay(
              outputFreezedFramePathImage,
              savedImageURI.uri,
              outputPathVideo,
              cutInternalOptions
            );

            videosPathesToMerge.push(outputPathVideo);
            fileIndex++;
          }

          let endTime =
            i + 1 < layerKeys.length ? start + Number(layerKeys[i + 1]) : end;

          const fileName = cutInternalOptions.fileName + '_' + fileIndex;

          let usedCuttingOptions = cutWithFullOverlayOptions
            ? cutWithFullOverlayOptions
            : cutInternalOptions;

          const cutResult = await this.taskmanager.startCut({
            ...usedCuttingOptions,
            fileName,
            videourl: filepath,
            start: this.secondsToTime(start + layerTime),
            end: this.secondsToTime(endTime),
          });

          let absoluteFilepath = await Cross.videourlToAbsoluteFilepath({
            videourl: cutResult.videourl,
          });

          videosPathesToMerge.push(absoluteFilepath.filepath);

          fileIndex++;
        }

        await this.taskmanager.startMergeVideoFiles(
          videosPathesToMerge,
          videoexportpath,
          cutInternalOptions
        );

        console.log('videosPathesToMerge', videosPathesToMerge, imagesToDelete);

        try {
          videosPathesToMerge.forEach((filepath) => {
            Cross.deleteFile({ file_path: filepath });
          });

          imagesToDelete.forEach((filepath) => {
            Cross.deleteFile({ file_path: filepath });
          });
        } catch (error) {
          Sentry.captureException(error);
        }

        exportTask = new Promise(async (resolve) => {
          let response = await Cross.absoluteFilepathToVideourl({
            filepath: videoexportpath + '.ts',
          });

          let relative = await Cross.videourlToAbsoluteFilepath({
            videourl: response.videourl,
          });
          console.log('absoluteFilepathToVideourl', relative, response);
          if (!response.relativeVideourl) {
            response.relativeVideourl = relative.videourl;
          }
          (response.filepath = videoexportpath + '.ts'), resolve(response);
        });
        exportierteSzenen.push(exportTask);
      } else {
        exportTask = this.taskmanager.startCut(cutInternalOptions);
        exportierteSzenen.push(exportTask);
      }

      let actualIndex = parseInt(index);

      exportTask
        .catch((e) => {
          console.log('leider e', e);
          if (
            this.exportierteSzenenListe[actualIndex] &&
            this.exportierteSzenenListe[actualIndex].state
          ) {
            this.exportierteSzenenListe[actualIndex].state = 'Fehler';
            this.exportierteSzenenListe[actualIndex].done = false;
          }
        })
        .then((cutResponse) => {
          if (
            this.exportierteSzenenListe[actualIndex] &&
            this.exportierteSzenenListe[actualIndex].state
          ) {
            this.exportierteSzenenListe[actualIndex].state = 'Fertigstellung';
            this.exportierteSzenenListe[actualIndex].done = true;
            this.exportierteSzenenListe[actualIndex].videourl =
              cutResponse.videourl;
            this.exportierteSzenenListe[actualIndex].filepath =
              cutResponse.filepath;
          }
          szene.videourl = cutResponse.relativeVideourl;
          szene.filepath = cutResponse.filepath;
        });
      index++;
      count++;
    }

    if (this.addonCore.isLoading) {
      this.addonCore.isLoading = false;
    }
    const answer = await Promise.all(exportierteSzenen);
    console.log(this.exportierteSzenenListe);
    return this.exportierteSzenenListe;
  }

  @action async convertTSFilesIntoMP4(exportierteSzenen, exportQualityString) {
    console.log('convertTSFilesIntoMP4 1');
    let exportQuality = VIDEOEXPORT.isValidExportQualityString(
      exportQualityString
    )
      ? VIDEOEXPORT.QUALITIES[exportQualityString]
      : VIDEOEXPORT.QUALITIES[VIDEOEXPORT.defaultQualityString];
    // let cutInternalOptions = {
    //   videourl: src,
    //   start: cutPointsResponse.start,
    //   end: cutPointsResponse.end,
    //   seekString: cutPointsResponse.seekString,
    //   fileName: fileName,
    //   outputFileExtension: 'ts',
    //   beginnerFfmpegProfileName: exportQuality.BEGINNERFFMPEGPROFILE,
    // };

    for (let szene of exportierteSzenen) {
      const inputFilePath = szene.filepath;

      szene.filepath = szene.filepath.replace('.ts', '.mp4');
      szene.videourl = szene.videourl.replace('.ts', '.mp4');
      console.log('CONVERT TS TO MP4', inputFilePath, szene.filepath);
      let command = FfmpegCommandBuilder.create(exportQuality)
        .addVideoInput(inputFilePath)
        .copyCodecToMp4()
        .makeMp4Streamable()
        .outputTo(szene.filepath)
        .logCommand();

      let convertResult = await command.run();
      console.log('Convert Result', convertResult);

      Cross.deleteFile({ file_path: inputFilePath });
    }
  }
}
