export function denormalizeCoordinatesRelativeToStage(
  positionX,
  positionY,
  stage
) {
  return {
    x: (positionX * stage.width()) / stage.scaleX(),
    y: (positionY * stage.height()) / stage.scaleX(),
  };
}
export function denormalizeLayer(layerJSON, stage, pixelBaseUnit) {
  for (let childID = 0; childID < layerJSON.children.length; childID++) {
    // DENORMALIZE COORDINATES

    if (
      layerJSON.children[childID].attrs.x &&
      layerJSON.children[childID].attrs.y
    ) {
      let denormalizedCoordinates = denormalizeCoordinatesRelativeToStage(
        layerJSON.children[childID].attrs.x,
        layerJSON.children[childID].attrs.y,
        stage
      );
      layerJSON.children[childID].attrs.x = denormalizedCoordinates.x;
      layerJSON.children[childID].attrs.y = denormalizedCoordinates.y;
    } else if (layerJSON.children[childID].attrs.points) {
      let denormalizedPoints = [];

      for (
        let i = 0;
        i < layerJSON.children[childID].attrs.points.length;
        i += 2
      ) {
        let denormalizedCoordinates = denormalizeCoordinatesRelativeToStage(
          layerJSON.children[childID].attrs.points[i],
          layerJSON.children[childID].attrs.points[i + 1],
          stage
        );

        denormalizedPoints.push(
          denormalizedCoordinates.x,
          denormalizedCoordinates.y
        );
      }

      layerJSON.children[childID].attrs.points = denormalizedPoints;
    }

    // DENORMALIZE REPRESENTATION

    if (layerJSON.children[childID].attrs.strokeWidth) {
      layerJSON.children[childID].attrs.strokeWidth *= pixelBaseUnit;
    }

    if (
      layerJSON.children[childID].attrs.pointerLength &&
      layerJSON.children[childID].attrs.pointerWidth
    ) {
      layerJSON.children[childID].attrs.pointerLength *= pixelBaseUnit;
      layerJSON.children[childID].attrs.pointerWidth *= pixelBaseUnit;
    }

    if (layerJSON.children[childID].attrs.radius) {
      layerJSON.children[childID].attrs.radius *= pixelBaseUnit;
    }
  }

  return layerJSON;
}

export default {
  denormalizeCoordinatesRelativeToStage,
  denormalizeLayer,
};
