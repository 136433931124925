import Model from '../classes/model';
import { attr, belongsTo } from '@ember-data/model';

class VideobearbeitunfastModel extends Model {
  typ = 'videobearbeitung';
  icon = 'videocam';

  @attr('boolean', { defaultValue: false }) highlight;
  get unhighlighted() {
    return !this.highlight;
  }

  @attr('boolean', { defaultValue: false }) liveTagging;
  @attr('string') name;
  @attr('mdate') datum;
  @attr('mdate') createDate;

  @belongsTo('ordner') ordner;
  get dateOfCreation() {
    if (this.createDate) {
      return this.createDate;
    } else {
      return this.datum;
    }
  }
  @attr('boolean', { defaultValue: false }) liveTagging;
  @attr('string', { defaultValue: 'standard' }) analysetyp;

  get analysetypLegacy() {
    return this.liveTagging ? 'livetagging' : this.analysetyp;
  }
}

VideobearbeitunfastModel.reopenClass({
  documentType: 'videobearbeitung',
});

export default VideobearbeitunfastModel;
