import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import PouchDB from 'pouchdb-browser';
import { Preferences } from '@capacitor/preferences';
import config from 'athlyzer-coach/config/environment';

export default class TeamService extends Service {
  datenbankurl;
  aktivesTeam;

  pouchDbSyncActiveEvent = false;
  pouchDbSyncChangeEvent = false;
  @tracked lastTeamDatenbankSyncTimestamp = null;
  localDB;
  localDBSync;
  remoteDB;

  @service store;
  @service addonUser;

  @tracked isErstellen;

  setCreate(bool) {
    this.isErstellen = bool;
  }

  teamDBSchema = [
    {
      singular: 'playlist',
      plural: 'playlists',
      relations: {
        playlistszenen: { hasMany: 'playlistszene' },
      },
    },
    {
      singular: 'fastPlaylist',
      plural: 'fastPlaylists',
      documentType: 'playlist',
    },
    {
      singular: 'playlistszene',
      plural: 'playlistszenen',
      relations: {
        playlist: { belongsTo: 'playlist' },
      },
    },
  ];

  async createTeamDBConnection(
    teamDBName,
    auth,
    dbhost,
    readOnly = false,
    liveSync = false
  ) {
    return new Promise((resolve) => {
      let local = new PouchDB(teamDBName, { adapter: 'indexeddb' });
      let remote = new PouchDB('https://' + dbhost + '/' + teamDBName, {
        auth,
      });

      local.setMaxListeners(30);
      if (readOnly) {
        this.localDBSync = local.replicate
          .from(remote, {
            live: liveSync,
            retry: liveSync,
            batch_size: 100,
          })
          .on('active', () => {
            this.pouchDbSyncActiveEvent = true;
          })
          .on('change', () => {
            this.addonUser.showLoading();
            this.pouchDbSyncChangeEvent = true;
          })
          .on('paused', () => {
            if (
              this.pouchDbSyncActiveEvent == true &&
              this.pouchDbSyncChangeEvent == true
            ) {
              Preferences.set({
                key: `${this.aktiveDatenbank}_last_sync`,
                value: new Date().toISOString(),
              });
              this.lastTeamDatenbankSyncTimestamp = new Date();
            }

            this.pouchDbSyncActiveEvent = false;
            this.pouchDbSyncChangeEvent = false;
          })
          .on('error', (err) => {
            if (!config.offline) {
              this.addonUser.refreshSession();
            }

            console.log('pouch sync error', err);
          });
      } else {
        this.localDBSync = local
          .sync(remote, {
            live: liveSync,
            retry: liveSync,
            batch_size: 100,
          })
          .on('active', () => {
            this.pouchDbSyncActiveEvent = true;
          })
          .on('change', () => {
            this.addonUser.showLoading();
            this.pouchDbSyncChangeEvent = true;
          })
          .on('paused', () => {
            if (
              this.pouchDbSyncActiveEvent == true &&
              this.pouchDbSyncChangeEvent == true
            ) {
              Preferences.set({
                key: `${this.aktiveDatenbank}_last_sync`,
                value: new Date().toISOString(),
              });
              this.lastTeamDatenbankSyncTimestamp = new Date();
            }

            this.pouchDbSyncActiveEvent = false;
            this.pouchDbSyncChangeEvent = false;
          })
          .on('error', (err) => {
            if (!config.offline) {
              this.addonUser.refreshSession();
            }
            console.log('pouch sync error', err);
          });
      }

      local.setSchema(this.teamDBSchema);

      this.localDB = local;
      this.remoteDB = remote;

      return resolve(local);
    });
  }

  async cancelTeamDBSync() {
    return new Promise((resolve) => {
      if (this.localDBSync) {
        this.localDBSync.cancel();
        this.localDBSync = null;
      }

      if (this.localDB) {
        this.localDB.close();
        this.localDB = null;
      }

      if (this.remoteDB) {
        this.remoteDB.close();
        this.remoteDB = null;
      }

      resolve({ cancelled: true });
    });
  }

  async removeFromTeam(team, playerToRemove, isSaveTeam) {
    const playerTeams = await playerToRemove.get('teams');
    const teamMembers = await team.get('teammembers');
    console.log('playerTeams', team, playerToRemove);
    playerToRemove.get('teams').removeObject(team);
    team.get('teammembers').removeObject(playerToRemove);

    await playerToRemove.save();
    if (isSaveTeam) {
      await team.save();
      team.toggleProperty('isChange');
    }
  }

  @action checkMail(member) {
    let mailtest =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (mailtest.test(member.mail)) {
      member.borderColor = 'border-color: green;';
      return true;
    } else {
      member.borderColor = 'border-color: red;';
      return false;
    }
  }
}
