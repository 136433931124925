import Transform from '@ember-data/serializer/transform';
import { DateTime } from 'luxon';

export default Transform.extend({
  deserialize: function (serialized) {
    if (serialized) {
      return DateTime.fromISO(serialized);
    } else {
      return null;
    }
  },

  serialize: function (deserialized) {
    if (deserialized) {
      if (deserialized instanceof Date) {
        console.log('instance of date');
        return DateTime.fromJSDate(deserialized).toISO();
      } else if (typeof deserialized == 'string') {
        return DateTime.fromISO(deserialized).toISO();
      } else {
        return deserialized.toISO();
      }
    } else {
      return null;
    }
  },
});
