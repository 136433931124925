import Service from '@ember/service';

export default class PaperToasterService extends Service {
  show(text, options = {}) {
    this.presentToastWithOptions(text, options.position);
  }

  async presentToastWithOptions(text, position = 'bottom') {
    const toast = document.createElement('ion-toast');
    //toast.header = 'Toast header';
    toast.message = text;
    //toast.icon = 'information-circle',
    toast.position = position || 'bottom';
    toast.duration = 2000;
    // toast.buttons = [
    //   {
    //     side: 'start',
    //     icon: 'star',
    //     text: 'Favorite',
    //     handler: () => {
    //       console.log('Favorite clicked');
    //     }
    //   }, {
    //     text: 'Done',
    //     role: 'cancel',
    //     handler: () => {
    //       console.log('Cancel clicked');
    //     }
    //   }
    // ];

    document.body.appendChild(toast);
    await toast.present();

    const { role } = await toast.onDidDismiss();
  }
}
