import Model from '../classes/model';
import { attr, belongsTo } from '@ember-data/model';

class TeamplayerfastModel extends Model {
  typ = 'teamplayer';
  @attr('string') nachname;
  @attr('string') vorname;
  @attr('boolean', { defaultValue: false }) highlight;
  get unhighlighted() {
    return !this.highlight;
  }

  get name() {
    return this.vorname + ' ' + this.nachname;
  }
  icon = 'person';
  @belongsTo('ordner') ordner;
}
TeamplayerfastModel.reopenClass({
  documentType: 'teamplayer',
});

export default TeamplayerfastModel;
