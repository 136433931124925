import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class AddonCoreLayoutAlertComponent extends Component {
  @action
  onClick() {
    if (this.args.onClick && typeof this.args.onClick == 'function') {
      this.args.onClick();
    }
  }
}
