import Service from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
export default class CapturecameracontrolService extends Service {
  @tracked isConnectedToCaptureWindow = false;
  @tracked isRecording = false;
  captureWindow;

  get status() {
    if (this.isConnectedToCaptureWindow && this.isRecording) {
      return 'recording';
    } else if (this.isConnectedToCaptureWindow) {
      return 'connected';
    } else {
      return '';
    }
  }

  @action async start() {
    let listener;
    const response = await new Promise((resolve, reject) => {
      listener = (e) => {
        resolve({
          data: {
            success: true,
          },
        });
      };
      this.captureWindow.addEventListener(
        'capturecamerastarted',
        listener,
        true
      );
      this.command('startRecording');
    });
    this.captureWindow.removeEventListener(
      'capturecamerastarted',
      listener,
      true
    );
    if (response.data.success) {
      this.isRecording = true;
    }
  }

  @action async stop() {
    let listener;
    const response = await new Promise((resolve, reject) => {
      listener = (e) => {
        console.log('stopped', e);
        resolve({
          data: {
            success: true,
            error: false,
            videourl: e.detail.videourl,
          },
        });
      };
      this.captureWindow.addEventListener(
        'capturecamerastopped',
        listener,
        true
      );
      this.command('stopRecording');
      this.isRecording = false;
    });
    this.captureWindow.removeEventListener(
      'capturecamerastopped',
      listener,
      true
    );
    return response;
  }

  @action async connect() {
    const url = window.location.href.split('#')[0] + '#/capture';
    const captureWindow = window.open(url, '_capture', 'nodeIntegration=true');

    captureWindow.beforeunload = async (event) => {
      event.preventDefault();

      if (this.isRecording) {
        try {
          await this.stop();
        } catch (error) {}
      }

      this.captureWindow = null;
      this.isConnectedToCaptureWindow = false;

      this.isRecording = false;
    };

    captureWindow.onload = () => {
      this.isConnectedToCaptureWindow = true;
      console.log('Capture Window Loaded', this);
    };

    this.captureWindow = captureWindow;

    return this.isConnectedToCaptureWindow;
  }

  @action async disconnect() {
    if (this.isRecording) {
      try {
        await this.stop();
      } catch (error) {}
    }
    try {
      this.captureWindow && this.captureWindow.close();
    } catch (error) {
      console.log('disconnect error', error);
    }
    this.captureWindow = null;
    this.isRecording = false;
    this.isConnectedToCaptureWindow = false;
  }

  @action async command(command) {
    console.log('sendCommand', command);
    const response = this.captureWindow.dispatchEvent(
      new CustomEvent('capturecamera', {
        detail: {
          timecode: DateTime.now(),
          command: command,
        },
      })
    );
    return response;
  }
}
