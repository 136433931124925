import Model from '../classes/model';
import { attr, belongsTo } from '@ember-data/model';

class VideocollectionfastModel extends Model {
  @attr('boolean', { defaultValue: false }) highlight;
  get unhighlighted() {
    return !this.highlight;
  }

  @attr('string') name;
  @attr('mdate') datum;
  @belongsTo('ordner') ordner;
  icon = 'collections_bookmark';
  typ = 'videocollection';
  get datumjs() {
    if (this.datum) {
      return this.datum.toFormat('yyyy-LL-dd');
    } else {
      return null;
    }
  }
}

VideocollectionfastModel.reopenClass({
  documentType: 'videocollection',
});

export default VideocollectionfastModel;
