import Service from './second-screen';
import Cross from 'athlyzer-coach/cross/plugin';
import clipsPosition from 'athlyzer-coach/helper-functions/clips-position';
import linkUtil from 'athlyzer-coach/classes/link-util';
import { action, setProperties } from '@ember/object';

export default class VideoVideoAddService extends Service {
  /*  
  ADD VIDEOS TO ANALYSIS 
  addVideoToAnalysis: Create and add Videoclip to Analysis
  pickVideo: Select Video from native Files
  cancelAddVideo: Delete videoclip model if adding is cancelled
  addVideoByPath: Add video with metadata by path from video (filepath/videourl)
  */

  @action async addVideoToAnalysis(videoverarbeitung) {
    this.addonCore.setText(this.intl.t('Füge Video hinzu'));
    this.addonCore.isLoading = true;
    let vc = this.store.createRecord('videoclip', {
      position: clipsPosition(videoverarbeitung),
    });
    videoverarbeitung = this.store.peekRecord(
      'videobearbeitung',
      videoverarbeitung.get('id')
    );
    vc.set('videobearbeitung', videoverarbeitung);
    await vc.save();

    videoverarbeitung.videoclips.pushObject(vc);
    await videoverarbeitung.save();

    vc = await this.pickVideo(videoverarbeitung, vc);
    return vc;
  }

  @action async pickVideo(
    videoverarbeitung,
    vc,
    options = { deleteOnCancel: true, deleteOtherProperties: false }
  ) {
    if (this.addonCore.isWeb) {
      this.paperToaster.show(
        this.intl.t('Diese Funktion ist im Web nicht enthalten')
      );
      if (options.deleteOnCancel) {
        await this.cancelAddVideo(videoverarbeitung, vc);
      }

      return;
    } else {
      const freeSpaceResponse = await this.checkFreeSpace();

      if (freeSpaceResponse.cancel) {
        if (options.deleteOnCancel) {
          await this.cancelAddVideo(videoverarbeitung, vc);
        }

        return;
      }

      this.addonCore.showIsLoadingCloseButton();

      let video;
      video = await Cross.pick({
        filename: vc.id,
      });

      console.log('video', video);

      this.addonCore.hideIsLoadingCloseButton();
      this.addonCore.isLoading = false;

      if (video.cancelled || Object.keys(video).length === 0) {
        if (video.errored) {
          this.paperToaster.show(
            this.intl.t('Leider ist beim Hinzufügen ein Fehler aufgetreten.')
          );
        } else {
          this.paperToaster.show(this.intl.t('Hinzufügen wurde abgebrochen.'));
        }
        if (options.deleteOnCancel) {
          await this.cancelAddVideo(videoverarbeitung, vc);
        }
        return;
      }

      return this.addVideoByPath(videoverarbeitung, vc, video, options);
    }
  }

  async cancelAddVideo(videoverarbeitung, vc) {
    this.addonCore.isLoading = false;
    await videoverarbeitung.videoclips.removeObject(vc);
    await vc.destroyRecord();
    await videoverarbeitung.save();
    return;
  }

  @action async addVideoByPath(videoverarbeitung, vc, video, options = {}) {
    if (options.deleteOtherProperties) {
      setProperties(vc, {
        desktop: null,
        nas: null,
        web: null,
        originalLink: null,
        ios: null,
        iosexternal: null,
        android: null,
        androidexternal: null,
        convertedVideoHeight: null,
        convertedVideoWidth: null,
        convertedVideoBitrate: null,
        externalVideoBitrate: null,
        externalVideoWidth: null,
        externalVideoHeight: null,
        convertRequired: false,
        duration: null,
      });
    }

    if (this.addonCore.isAndroid) {
      if (!video.filepath) {
        if (options.deleteOnCancel) {
          await this.cancelAddVideo(videoverarbeitung, vc);
        }
        return;
      }

      let mediaFileInfo = await this.getVideoMediaInfo({
        videourl: video.filepath,
      });

      setProperties(vc, {
        duration: mediaFileInfo.duration,
        androidexternal: video.filepath,
        existsOnNAS: true,
      });
    } else if (this.addonCore.isIos) {
      if (video.videourl) {
        let mediaFileInfo = await this.getVideoMediaInfo({
          videourl: video.videourl,
        });

        let propertiesToSet = {};
        if (mediaFileInfo) {
          // Mixpanel Tracking: Event Videoconvert general.Format (Container Format), audio.Format (Audio Format) und video.Format (VideoCodex)
          this.metrics.track('Added Video', {
            Duration: mediaFileInfo.duration,
            // 'File Size': mediaFileInfo.general?.FileSize,
            // Container: mediaFileInfo.general?.CodecID,
            // Format: mediaFileInfo.general?.Format,
            Codec: mediaFileInfo.codec,
            Width: mediaFileInfo.width,
            Height: mediaFileInfo.height,
            Bitrate: mediaFileInfo.bitrate,
            FPS: mediaFileInfo.fps,
          });

          propertiesToSet = {
            externalVideoBitrate: mediaFileInfo.bitrate,
            externalVideoWidth: mediaFileInfo.width,
            externalVideoHeight: mediaFileInfo.height,
            duration: mediaFileInfo.duration,
          };
        }

        setProperties(vc, {
          ...propertiesToSet,
          iosexternal: video.videourlAbsolute,
          existsOnNAS: true,
        });
      } else if (video.filepathAbsolute) {
        console.log('before get Bookmark2');
        const { videourl } = await Cross.getBookmark({
          videourl: video.filepathAbsolute,
        });

        let mediaFileInfo = await this.getVideoMediaInfo({
          filepath: videourl,
        });

        let propertiesToSet = {};
        if (mediaFileInfo) {
          // Mixpanel Tracking: Event Videoconvert general.Format (Container Format), audio.Format (Audio Format) und video.Format (VideoCodex)
          this.metrics.track('Added Video', {
            Duration: mediaFileInfo.duration,
            // 'File Size': mediaFileInfo.general?.FileSize,
            // Container: mediaFileInfo.general?.CodecID,
            // Format: mediaFileInfo.general?.Format,
            Codec: mediaFileInfo.codec,
            Width: mediaFileInfo.width,
            Height: mediaFileInfo.height,
            Bitrate: mediaFileInfo.bitrate,
            FPS: mediaFileInfo.fps,
          });

          propertiesToSet = {
            externalVideoBitrate: mediaFileInfo.bitrate,
            externalVideoWidth: mediaFileInfo.width,
            externalVideoHeight: mediaFileInfo.height,
            duration: mediaFileInfo.duration,
          };
        }
        setProperties(vc, {
          ...propertiesToSet,
          iosexternal: videourl,
          existsOnNAS: true,
        });
      }
    } else {
      let response = await Cross.canPlay({
        videourl: video.videourl,
      });

      let mediaFileInfo = await this.getVideoMediaInfo({
        videourl: video.videourl,
      });

      console.log('video.videourl', video.videourl);

      let propertiesToSet = {};
      if (mediaFileInfo) {
        // Mixpanel Tracking: Event Videoconvert general.Format (Container Format), audio.Format (Audio Format) und video.Format (VideoCodex)
        this.metrics.track('Added Video', {
          Duration: mediaFileInfo.duration,
          // 'File Size': mediaFileInfo.general?.FileSize,
          // Container: mediaFileInfo.general?.CodecID,
          // Format: mediaFileInfo.general?.Format,
          Codec: mediaFileInfo.codec,
          Width: mediaFileInfo.width,
          Height: mediaFileInfo.height,
          Bitrate: mediaFileInfo.bitrate,
          FPS: mediaFileInfo.fps,
        });

        propertiesToSet = {
          externalVideoBitrate: mediaFileInfo.bitrate,
          externalVideoWidth: mediaFileInfo.width,
          externalVideoHeight: mediaFileInfo.height,
          duration: mediaFileInfo.duration,
        };
      }

      const fileName = video.videourl.split('\\').pop().split('/').pop();

      propertiesToSet.name = fileName;

      if (response.canPlay) {
        setProperties(vc, {
          ...propertiesToSet,
          nas: '/EXTERNAL' + video.videourl,
          existsOnNAS: true,
          convertRequired: false,
        });

        this.clip = vc;
      } else {
        setProperties(vc, {
          ...propertiesToSet,
          nas: '/EXTERNAL' + video.videourl,
          existsOnNAS: true,
          convertRequired: true,
          // state: 'converting',
        });
      }
    }
    await vc.save();

    return vc;
  }

  /* 
    Video Downloads
    downloadVideoFromYoutubeOrTwitch
  */

  async downloadVideoFromYoutubeOrTwitch(videoclip, link) {
    this.metrics.track('video.youtube.download.start');
    videoclip.state = 'downloading';

    let response = await this.taskmanager.startDownload(
      link,
      this.intl.t('Video Download'),
      videoclip.id
    );

    this.metrics.track('video.youtube.download.finished');

    console.log('relativeVideourl', response.relativeVideourl);

    if (linkUtil.isTwitchVideoLink(link)) {
      response = await this.taskmanager.startConvert(
        response.relativeVideourl,
        videoclip.id,
        videoclip.id,
        true
      );
      console.log('endConvert', response);
    }
    videoclip.set('desktop', response.relativeVideourl);
    await videoclip.save();

    videoclip.state = 'idle';
    videoclip.exists = true;
  }

  async downloadVideoFromVeo(videoclip, link) {
    this.metrics.track('video.veo.download.start');
    videoclip.state = 'downloading';

    let response = await this.taskmanager.startDownload(
      link,
      this.intl.t('Video Download'),
      videoclip.id
    );

    this.metrics.track('video.veo.download.finished');

    console.log('relativeVideourl', response.relativeVideourl);

    videoclip.set('desktop', response.relativeVideourl);
    videoclip.originalLink = null;
    await videoclip.save();

    videoclip.state = 'idle';
    videoclip.exists = true;
  }
}
