import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import uuid from 'athlyzer-coach/application/uuid';
import MediastreamUtils from '../../../classes/mediastream-utils';
import * as Sentry from '@sentry/ember';

export default class VideoCaptureComponent extends Component {
  @service paperToaster;
  @service intl;

  @tracked isReady = false;
  @tracked recordingStart;

  get isRecording() {
    return this.recordingStart;
  }

  chunkSize = 256 * 1024;

  videoElement;
  stream;
  count = 0;

  @tracked audioInputs = A([]);
  @tracked audioOutputs = A([]);
  @tracked videoInputs = A([]);

  audioInputIndex = 0;
  videoInputIndex = 0;

  didInsertHookActionbar(elem, [addonCore]) {
    addonCore.resize();
  }

  @action changeAudioInput(option, index) {
    this.audioInputIndex = index;
    this.startCam();
  }

  @action changeVideo(option, index) {
    this.videoInputIndex = Number(index);
    this.startCam();
  }

  @action async startStop() {
    if (this.isRecording) {
      this.stopRecording();
      this.recordingStart = null;
    } else {
      this.startRecording();
    }
  }

  @action willDestroy() {
    super.willDestroy(...arguments);
    this.resetAll();
  }

  @action resetAll() {
    this.audioInputIndex = 0;
    this.videoInputIndex = 0;
    if (this.videoElement) {
      this.videoElement.removeEventListener('canplay', this.canplayListener);
      this.videoElement.removeEventListener('timeupdate', this.timeupdate);
    }
    if (this.stream) {
      this.stream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
    if (this.mediaRecorder) {
      if (this.mediaRecorder.state != 'inactive') {
        this.mediaRecorder.stopRecording();
      }
      this.mediaRecorder = null;
    }
  }

  canplayListener() {
    // this.addonCore.resize();
  }

  @action async didInsertHook(videoElement, [self]) {
    console.log('inserthook');
    self.count = 0;
    self.videoElement = videoElement;
    videoElement.addEventListener('canplay', self.canplayListener.bind(self));
    videoElement.addEventListener('timeupdate', self.timeupdate.bind(self));
    self.initCamera();
    self.videoElement.muted = true;
    self.videoElement.volume = 0;
    self.mimeType = 'video/webm;codecs=vp8';
    self.fileEnding = '.webm';
    self.initEventlistener();
  }

  @action initEventlistener() {
    console.log('initEventlisteners');
    window.addEventListener('capturecamera', (e) => {
      if (this.isRecording) {
        this.stopRecording();
        this.recordingStart = null;
      } else {
        this.startRecording();
        window.dispatchEvent(new CustomEvent('capturecamerastarted'));
      }
    });
  }

  timeupdate() {
    const capture = document.getElementById('athlyzer-time-capture');
    if (capture && this.recordingStart) {
      let duration = Date.now() / 1000 - this.recordingStart;
      let hhmmss = this.hhmmsst(duration);
      capture.innerHTML = hhmmss;
    }
  }

  hhmmsst(milliseconds) {
    let time = new Date(Number(milliseconds) * 1000).toISOString();
    let hours = Number(time.substr(11, 2));
    let minutes = Number(time.substr(14, 2));
    let seconds = Number(time.substr(17, 2));
    if (seconds <= 9) {
      seconds = String(seconds);
      seconds = '0' + seconds;
    }
    minutes = hours * 60 + minutes;
    if (minutes <= 9) {
      minutes = String(minutes);
      minutes = '0' + minutes;
    }
    let returnString = minutes + ':' + seconds;
    return returnString;
  }

  @action async initCamera() {
    const devices = await MediastreamUtils.findAvailableDevices();
    this.audioInputs = devices.audioInputs;
    this.videoInputs = devices.videoInputs;

    this.startCam();
  }

  @action setNewStream(stream) {
    this.stream = stream;
    this.videoElement.srcObject = stream;
  }

  @action async startCam() {
    console.log('startCam', this.videoInputs, this.videoInputIndex);
    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    //const audioInputSelect = this.audioInputs.objectAt(this.audioInputIndex);
    let constraints = MediastreamUtils.getConstraints(
      this.videoInputs,
      this.videoInputIndex
    );

    let mediaResponse = await navigator.mediaDevices.getUserMedia(constraints);

    await this.setNewStream(mediaResponse);

    const supportedRecordingFormats = MediastreamUtils.getSupportedMimeTypes();
    console.log('supportedRecordingFormats', supportedRecordingFormats);
  }

  handleError(error) {
    console.log(
      'navigator.MediaDevices.getUserMedia error: ',
      error.message,
      error.name
    );
  }

  @action startRecording() {
    let mediaRecorder;

    const { supportedTypes, supportedCodecs } =
      MediastreamUtils.getSupportedMimeTypes();
    console.log(
      'supportedMimeTypes.supportedTypes',
      supportedTypes,
      supportedCodecs
    );

    if (supportedTypes.length > 0) {
      this.mimeType = supportedCodecs[0];
    }

    let count = 0;
    let randomId = uuid() + this.fileEnding;
    console.log('this.stream', this.stream);
    try {
      mediaRecorder = new MediaRecorder(this.stream, {
        type: 'video',
        // recorderType: 'MediaStreamRecorder',
        timeSlice: 2000,
        mimeType: this.mimeType,
        videoBitsPerSecond: 64000000, // 2MB pro sekunde
        audioBitsPerSecond: 128000,
      });

      mediaRecorder.ondataavailable = function (event) {
        if (event.data && event.data.size > 0) {
          if (count == 0) {
            console.log('write', randomId);
            window.cross.write(randomId, event.data);
          } else {
            console.log('append', randomId);
            window.cross.append(randomId, event.data);
          }
          count++;
        }
      };

      mediaRecorder.athlyzerFileName = randomId;
    } catch (e) {
      console.error('Exception while creating MediaRecorder:', e);
      Sentry.captureException(e);

      this.paperToaster.show(
        this.intl.t(
          'Es ist ein Fehler bei der Aufnahme aufgetreten. Versuche es noch einmal.'
        )
      );
      return;
    }
    this.recordingStart = Math.floor(Date.now() / 1000);
    console.log('started Recording', this.isRecording, this.recordingStart);
    this.mediaRecorder = mediaRecorder;
    mediaRecorder.start(2000);
  }

  @action async stopRecording() {
    if (this.mediaRecorder && this.mediaRecorder.state != 'inactive') {
      this.mediaRecorder.stop();
      window.dispatchEvent(
        new CustomEvent('capturecamerastopped', {
          detail: {
            videourl: '/DOCUMENTS/' + this.mediaRecorder.athlyzerFileName,
          },
        })
      );

      this.mediaRecorder = null;
      this.recordingStart = null;
      const captureTimeSpan = document.getElementById('athlyzer-time-capture');
      captureTimeSpan.innerHTML = '';
    }
  }
}
