import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class TeamsTeamAuthenticatedPlaylistRoute extends Route {
  @service video;
  @service store;

  beforeModel() {
    document.body.style.paddingTop = 0;
    document.body.style.paddingLeft = 0;
  }

  async model(params) {
    return await this.store.findRecord('teamplaylist', params.playlistid);
  }
  async afterModel(model) {
    this.video.doentEditDraw = true;
    this.video.readOnlyDrawMode = true;
    await model.get('playlistszenen');
  }
}
