import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import { DateTime } from 'luxon';

export default class SchemaTaggenMarkerDarstellungComponent extends Component {
  @service video;
  @service addonCore;
  @service addonUser;
  @service paperToaster;
  @service intl;

  @tracked isActive = false;

  breite = 0;

  get getClasses() {
    let classes = '';
    if (this.isActive) {
      classes += 'athlyzer-activity-pulse ';
    }
    classes += 'athlyzer-coach-schema-buttons select-none';
    return htmlSafe(classes);
  }

  @action activateMarker() {
    this.isActive = true;
    setTimeout(() => {
      this.isActive = false;
    }, 2000);
    next(this, function () {
      if (this.args.isExternalWindow) {
        window.dispatchEvent(
          new CustomEvent('tagging-external-window', {
            detail: {
              timecode: DateTime.now(),
              modelName: this.args.handlung.typ,
              modelId: this.args.handlung.id,
            },
          })
        );
      } else {
        if (this.args.handlung.get('typ') == 'handlung') {
          this.video.addEventToAnalysis(this.args.handlung);
        } else if (this.args.handlung.get('typ') == 'tag') {
          this.video.addLabelToScene(this.args.handlung);
        }
      }
    });
    return;
  }

  @action tastenkombination(key) {
    let newKey = key.key;
    switch (key.key) {
      case '!':
        newKey = 1;
        break;
      case '"':
        newKey = 2;
        break;
      case '§':
        newKey = 3;
        break;
      case '$':
        newKey = 4;
        break;
      case '%':
        newKey = 5;
        break;
      case '&':
        newKey = 6;
        break;
      case '/':
        newKey = 7;
        break;
      case '(':
        newKey = 8;
        break;
      case ')':
        newKey = 9;
        break;
      case '=':
        newKey = 0;
        break;
      case '?':
        newKey = 'ß';
        break;
      default:
        break;
    }
    newKey = parseInt(newKey);
    if (this.video.schema && this.addonCore.isKeyboardBlocked == false) {
      if (key && this.args.handlung.taste) {
        if (isNaN(newKey)) {
          if (key.key.toLowerCase() == this.args.handlung.taste.toLowerCase()) {
            const hotkeysAllowed =
              this.addonUser.checkForAccessToFeatureAndSetInfoModal(
                'scheme.hotkeys',
                false
              );

            if (hotkeysAllowed) {
              this.activateMarker();
            } else {
              this.paperToaster.show(
                this.intl.t(
                  'Hotkeys sind im Rookie-Paket leider nicht verfügbar. Jetzt auf Professional oder Expert upgraden.'
                )
              );
              return;
            }
          }
        } else {
          if (newKey == this.args.handlung.taste) {
            const hotkeysAllowed =
              this.addonUser.checkForAccessToFeatureAndSetInfoModal(
                'scheme.hotkeys',
                false
              );

            if (hotkeysAllowed) {
              this.activateMarker();
            } else {
              this.paperToaster.show(
                this.intl.t(
                  'Hotkeys sind im Rookie-Paket leider nicht verfügbar. Jetzt auf Professional oder Expert upgraden.'
                )
              );
              return;
            }
          }
        }
      }
    }
  }

  get stylingHandlung() {
    let handlung = this.args.handlung;
    let ansicht = this.args.ansicht || '';
    let zoom = this.args.zoom || 1;
    let styleString = '';
    if (handlung.typ == 'handlung') {
      styleString =
        'border:1px solid black;background-color:' +
        handlung.farbe +
        ';color:' +
        handlung.farbeText +
        ';position:absolute;left:';
      switch (ansicht) {
        case 'tablet':
          styleString =
            styleString +
            handlung.xtablet * zoom +
            'px;top:' +
            handlung.ytablet * zoom +
            'px;width:' +
            handlung.widthtablet * zoom +
            'px;height:' +
            handlung.heighttablet * zoom +
            'px;transform:rotate(' +
            (handlung.rotationtablet * 1.111111111).toFixed(2) +
            'grad);transform-origin: 0% 0%;';
          break;
        case 'mobil1':
          styleString =
            styleString +
            handlung.xmobil1 * zoom +
            'px;top:' +
            handlung.ymobil1 * zoom +
            'px;width:' +
            handlung.widthmobil1 * zoom +
            'px;height:' +
            handlung.heightmobil1 * zoom +
            'px;transform:rotate(' +
            (handlung.rotationmobil1 * 1.111111111).toFixed(2) +
            'grad);transform-origin: 0% 0%;';
          break;
        case 'mobil2':
          styleString =
            styleString +
            handlung.xmobil2 * zoom +
            'px;top:' +
            handlung.ymobil2 * zoom +
            'px;width:' +
            handlung.widthmobil2 * zoom +
            'px;height:' +
            handlung.heightmobil2 * zoom +
            'px;transform:rotate(' +
            (handlung.rotationmobil2 * 1.111111111).toFixed(2) +
            'grad);transform-origin: 0% 0%;';
          break;
        case '':
          styleString =
            styleString +
            handlung.x * zoom +
            'px;top:' +
            handlung.y * zoom +
            'px;width:' +
            handlung.width * zoom +
            'px;height:' +
            handlung.height * zoom +
            'px;transform:rotate(' +
            (handlung.rotation * 1.111111111).toFixed(2) +
            'grad);transform-origin: 0% 0%;';
          break;
      }
    } else {
      styleString =
        'border-radius:' +
        zoom +
        'rem;border:1px solid black;background-color:' +
        handlung.farbe +
        ';color:' +
        handlung.farbeText +
        ';position:absolute;left:';
      switch (ansicht) {
        case '':
          styleString =
            styleString +
            handlung.x * zoom +
            'px;top:' +
            handlung.y * zoom +
            'px;width:' +
            handlung.width * zoom +
            'px;height:' +
            handlung.height * zoom +
            'px;transform:rotate(' +
            (handlung.rotation * 1.111111111).toFixed(2) +
            'grad);transform-origin: 0% 0%;';
          break;
        case 'tablet':
          styleString =
            styleString +
            handlung.xtablet * zoom +
            'px;top:' +
            handlung.ytablet * zoom +
            'px;width:' +
            handlung.widthtablet * zoom +
            'px;height:' +
            handlung.heighttablet * zoom +
            'px;transform:rotate(' +
            (handlung.rotationtablet * 1.111111111).toFixed(2) +
            'grad);transform-origin: 0% 0%;';
          break;
        case 'mobil1':
          styleString =
            styleString +
            handlung.xmobil1 * zoom +
            'px;top:' +
            handlung.ymobil1 * zoom +
            'px;width:' +
            handlung.widthmobil1 * zoom +
            'px;height:' +
            handlung.heightmobil1 * zoom +
            'px;transform:rotate(' +
            (handlung.rotationmobil1 * 1.111111111).toFixed(2) +
            'grad);transform-origin: 0% 0%;';
          break;
        case 'mobil2':
          styleString =
            styleString +
            handlung.xmobil2 * zoom +
            'px;top:' +
            handlung.ymobil2 * zoom +
            'px;width:' +
            handlung.widthmobil2 * zoom +
            'px;height:' +
            handlung.heightmobil2 * zoom +
            'px;transform:rotate(' +
            (handlung.rotationmobil2 * 1.111111111).toFixed(2) +
            'grad);transform-origin: 0% 0%;';
          break;
      }
    }
    styleString = styleString + '--athlyzer-duration-event: 2s;';
    styleString += '--athlyzer-marker-background: ' + handlung.farbe;
    styleString = htmlSafe(styleString);
    return styleString;
    // schema-lean-item handlung ansicht zoom "handlung"
  }

  get stylingTastenkombination() {
    let zoom = this.args.zoom;
    let fontSize = 10;
    let size = zoom * fontSize;
    size = Math.floor(size);
    let styleString = 'font-size:' + (size + 1) + 'px;';
    styleString = htmlSafe(styleString);
    return styleString;
  }

  get stylingText() {
    let zoom = this.args.zoom;
    let fontSize;
    if (this.args.handlung.nameFontSize) {
      fontSize = this.args.handlung.nameFontSize;
      let size = zoom * fontSize;
      size = Math.floor(size);
      let styleString = 'font-size:' + (size + 1) + 'px;';
      styleString = htmlSafe(styleString);
      return styleString;
    } else {
      return '';
    }
  }

  get stylingSubText() {
    let zoom = this.args.zoom;
    if (this.args.handlung.textFontSize) {
      let fontSize = this.args.handlung.textFontSize;
      let size = zoom * fontSize;
      size = Math.floor(size);
      let styleString = 'font-size:' + (size + 1) + 'px;';
      styleString = 'width: auto; ' + styleString;
      styleString = htmlSafe(styleString);
      return styleString;
    } else {
      return '';
    }
  }

  get stylingLines() {
    let zoom = this.args.zoom;
    let fontSize = this.args.handlung.nameFontSize;
    let size = zoom * fontSize;
    size = Math.ceil(size);
    let styleString = 'line-height: ' + (size + 1) + 'px; ';
    styleString = htmlSafe(styleString);
    return styleString;
  }

  get getPulse() {
    let styleString = '';
    styleString +=
      'width: ' +
      2.5 * this.args.zoom +
      'em;' +
      'height: ' +
      2.5 * this.args.zoom +
      'em;';

    return htmlSafe(styleString);
  }

  get getSize() {
    return this.args.zoom * 25;
  }

  hyphenation() {
    return '-moz-hyphens: auto; -o-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto; hyphens: auto; ';
  }
}
