import Component from '../../component';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';

export default class VideoPreviewSmallComponent extends Component {
  playerOptionals = { controls: false }; // wird in video/preview/responsive benutzt

  @tracked noVideo = false;

  preventClick(e) {
    e.preventDefault();
  }

  get videoWidth() {
    return 'w-full';
  }

  get getHeight() {
    return htmlSafe(`height:100px`);
  }

  async sources(
    videoContainerElement,
    [self, aktiverVideoclip, exists, existsOnNAS, existsOnWeb]
  ) {
    if (exists || existsOnNAS || existsOnWeb) {
      console.log('triggered change on exists');
    }
    const sources = await self.getVideoSources(aktiverVideoclip);
    const playerStandardState = Object.assign(
      {
        url: sources,
        pip: false,
        playing: false,
        controls: false,
        light: false,
        volume: 0,
        muted: true,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
        progressInterval: 100,
        width: '100%',
        height: '100%',
      },
      self.playerOptionals
    );
    if (sources && sources.length > 0) {
      self.noVideo && (self.noVideo = false);
      renderReactPlayer(videoContainerElement, playerStandardState);
    } else if (!self.noVideo) {
      self.noVideo = true;
    }
  }
}
