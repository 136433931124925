import MediaInfo from 'mediainfo.js';
import Cross from 'athlyzer-coach/cross/plugin';
import { Capacitor } from '@capacitor/core';

export default {
  async getMediaInfoFromFile(url) {
    const encodeURL = (fileUrl) => {
      console.log('encodeURL', fileUrl);
      if (fileUrl.includes('athlyzer-stream:///EXTERNAL')) {
        return fileUrl;
      } else if (
        fileUrl.includes('/EXTERNAL') &&
        !fileUrl.includes('athlyzer-stream://')
      ) {
        return 'athlyzer-stream://' + fileUrl;
      } else if (
        fileUrl.includes('/DOCUMENTS') &&
        !fileUrl.includes('athlyzer-stream://')
      ) {
        return 'athlyzer-stream://' + fileUrl;
      } else if (
        fileUrl.startsWith('file:///private') ||
        fileUrl.startsWith('/private')
      ) {
        const encodedUrl = Capacitor.convertFileSrc(fileUrl);
        console.log('encodedUrl', encodedUrl);
        return encodedUrl;
      } else {
        return 'athlyzer-stream:///EXTERNAL' + fileUrl;
      }
    };

    const getSize = async () => {
      let fileSize = null;
      if (url.includes('athlyzer-stream://')) {
        const { size, found } = await Cross.getFileSize({
          videourl: url.replace('athlyzer-stream://', ''),
        });
        fileSize = size;
      } else if (url.includes('https://')) {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Range: 'bytes=0-1',
          },
        });
        const contentRange = response.headers.get('Content-Range').split('/');
        fileSize = Number(contentRange[contentRange.length - 1]);
      } else if (
        url.startsWith('file:///private') ||
        url.startsWith('/private')
      ) {
        const { size } = await Cross.getFileSize({
          filepath: url,
        });

        fileSize = size;
      } else {
        const { size } = await Cross.getFileSize({
          videourl: url,
        });
        fileSize = size;
      }

      return fileSize;
    };

    const readChunk = (chunkSize, offset) => {
      const chunk = fetch(encodeURL(url), {
        headers: new Headers({
          Range: `bytes=${offset}-${offset + chunkSize}`,
        }),
      })
        .then((response) => {
          const reader = response.body.getReader();
          return new ReadableStream({
            start(controller) {
              return pump();

              function pump() {
                return reader.read().then(({ done, value }) => {
                  // When no more data needs to be consumed, close the stream
                  if (done) {
                    controller.close();
                    return;
                  }

                  // Enqueue the next data chunk into our target stream
                  controller.enqueue(value);
                  return pump();
                });
              }
            },
          });
        }) // Create a new response out of the stream
        .then((stream) => {
          const responseStream = new Response(stream);
          return responseStream;
        })
        // Create an object URL for the response
        .then((response) => {
          const responseBuffer = response.arrayBuffer();
          return responseBuffer;
        })
        .then((buffer) => {
          const uint = new Uint8Array(buffer);
          return uint;
        });
      console.log('chunk read');
      return chunk;
    };

    const mediainfo = await MediaInfo({
      format: 'object',
      coverData: false,
    });

    try {
      const mediainfoResult = await mediainfo.analyzeData(getSize, readChunk);

      console.log('RESULT', mediainfoResult);

      const generalMediainfo = mediainfoResult.media.track.find(
        (t) => t['@type'] === 'General'
      );
      const videoTrackMediainfo = mediainfoResult.media.track.find(
        (t) => t['@type'] === 'Video'
      );
      const audioTrackMediainfo = mediainfoResult.media.track.find(
        (t) => t['@type'] === 'Audio'
      );

      const fileName = url.substring(url.lastIndexOf('/') + 1);

      return {
        general: generalMediainfo,
        video: videoTrackMediainfo,
        audio: audioTrackMediainfo,
        fileName: fileName,
      };
    } catch (error) {
      console.error('ERROR mediaInfo', error);
    }
  },
};
