import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class TeamsTeamIndexRoute extends Route {
  @service team;
  async model() {
    return (
      await fetch(`${this.team.datenbankurl}/${this.team.aktivesTeam}`)
    ).json();
  }
}
