export default function (ms) {
  let timeout, cancel;
  let promise = new Promise(function (resolve) {
    timeout = setTimeout(resolve('1'), ms);

    cancel = function () {
      clearTimeout(timeout);
      resolve('0');
    };
  });
  return { promise: promise, cancel: cancel };
}
