import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import { assert } from '@ember/debug';
import _transformQueryParams from '../utils/transform-query-params';

export default class TransitionTo extends Helper {
  @service router;

  compute([routeName, ...params]) {
    const router = this.router;
    assert('[ember-transition-helper] Unable to lookup router', router);
    return function (...invocationArgs) {
      const args = params.concat(invocationArgs);
      const transitionArgs = params.length
        ? [routeName, ...params]
        : [routeName];
      router.transitionTo(..._transformQueryParams(transitionArgs));
      return args;
    };
  }
}
