import Service from './keys';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';

import {
  task,
  restartableTask,
  timeout,
  enqueueTask,
  keepLatestTask,
  dropTask,
} from 'ember-concurrency';

const playerStandardState = {
  url: null,
  pip: false,
  playing: false,
  playsinline: true,
  controls: false,
  light: false,
  volume: 1.0,
  muted: false,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
  progressInterval: 100,
  width: '100%',
  height: '100%',
  config: {
    file: {
      attributes: {
        crossorigin: 'anonymous',
      },
    },
  },
};

export default class VideoPlayerService extends Service {
  @service router;

  playerRef;
  playerContainer;
  playerResizeObserver;

  @tracked state = Object.assign({}, playerStandardState);
  @tracked ready;
  @tracked isChromecastMode = false;

  // @tracked isVideoBuffering = false;

  @action setChromecastMode() {
    this.isChromecastMode = true;
    set(
      this,
      'state',
      Object.assign(this.state, {
        controls: true,
      })
    );
  }
  @action unsetChromecastMode() {
    this.isChromecastMode = false;
    set(
      this,
      'state',
      Object.assign(this.state, {
        controls: false,
      })
    );
  }

  player = null;
  slider = null;
  time = null;
  duration = null;

  @action setPlaybackRate(value) {
    this.updatePlayer({ playbackRate: value });
  }

  @action setPlayerRef(player) {
    this.playerRef = player;
  }
  @action resetState() {
    this.state = playerStandardState;
  }

  @action isPlayerLoaded() {
    return Boolean(this.playerRef);
  }

  @action getVideoDuration() {
    if (this.isPlayerLoaded()) {
      return this.playerRef.getDuration();
    }
    return 0;
  }

  setPlayerContainer(video, elementId) {
    this.playerContainer = video;
    this.calcSizeByThisElementId = elementId;
  }

  initKonvaStageOverReactPlayer(video) {
    let aspectRatio = 1.77;
    const htmlVideoTag = this.playerRef?.getInternalPlayer();

    if (
      htmlVideoTag &&
      htmlVideoTag instanceof HTMLVideoElement &&
      htmlVideoTag.videoWidth &&
      htmlVideoTag.videoHeight
    ) {
      aspectRatio = htmlVideoTag.videoWidth / htmlVideoTag.videoHeight;
    } else if (
      this.clip?.convertedVideoWidth &&
      this.clip?.convertedVideoHeight
    ) {
      aspectRatio =
        this.clip.convertedVideoWidth / this.clip.convertedVideoHeight;
    } else if (
      this.clip?.externalVideoWidth &&
      this.clip?.externalVideoHeight
    ) {
      aspectRatio =
        this.clip.externalVideoWidth / this.clip.externalVideoHeight;
    }

    console.log('ASPECT RATIO', aspectRatio);

    let stageProps = {
      container: this.getDrawContainer(),
      width: 1600,
      height: 1600 / aspectRatio,
      scaleX: 1,
      scaleY: 1,
    };
    this.stage = new Konva.Stage(stageProps);

    this.initWidth = 1600;
    this.initHeight = 1600 / aspectRatio;

    this.stage.draw();
    this.layer = new Konva.Layer();
    this.layer.name('videolayer');
    this.stage.add(this.layer);

    if (this.addonCore.isIos) {
      this.stage.on('touchstart', this.mousedownCanvasHandler);
      this.stage.on('touchmove', this.mousemoveCanvasHandler);
      this.stage.on('touchend', this.mouseupCanvasHandler);
    } else {
      this.stage.on('mousedown touchstart', this.mousedownCanvasHandler);
      this.stage.on('mousemove touchmove', this.mousemoveCanvasHandler);
      this.stage.on('mouseup touchend', this.mouseupCanvasHandler);
    }

    const playerWrapper = document.getElementById(
      'athlyzer-video-player-container'
    );

    if (this.playerResizeObserver) {
      this.playerResizeObserver.disconnect();
    }

    this.playerResizeObserver = new ResizeObserver((entries) => {
      const isPlayerAlreadyResized = entries.find((entry) => {
        return entry.target.id === 'athlyzer-video-player';
      });

      entries.forEach((entry) => {
        if (entry.target.id === 'athlyzer-video-player') {
          this.setSizeOfDrawContainer(
            0,
            this.calcOffsetFromVideoplayerToContainer(playerWrapper, video),
            entry.contentRect.width,
            entry.contentRect.height
          );
        } else if (!isPlayerAlreadyResized) {
          const video = document.getElementById('athlyzer-video-player');
          if (video) {
            const videoSize = video.getBoundingClientRect();

            this.setSizeOfDrawContainer(
              0,
              this.calcOffsetFromVideoplayerToContainer(playerWrapper, video),
              videoSize.width,
              videoSize.height
            );
          }
        }
      });
    });

    this.playerResizeObserver.observe(video);
    this.playerResizeObserver.observe(playerWrapper);
  }

  calcOffsetFromVideoplayerToContainer(containerElement, videoPlayerElement) {
    var containerElementRect = containerElement.getBoundingClientRect();
    var videoPlayerElementRect = videoPlayerElement.getBoundingClientRect();

    return (
      Number(videoPlayerElementRect.left) - Number(containerElementRect.left)
    );
  }

  @action setSizeOfSchemaOverlay(top, left, width, height) {
    const container = document.getElementById('schema-taggen-video-overlay');
    if (!container) {
      return;
    }
    container.setAttribute(
      'style',
      `left:${left}px;top:${top}px;width:${width}px;height:${height}px;`
    );
  }

  @action setSizeOfDrawContainer(top, left, width, height) {
    const drawContainer = this.getDrawContainer();
    if (drawContainer && this.stage != undefined) {
      let stageWidth = parseFloat(this.stage.width());
      let stageHeight = parseFloat(this.stage.height());

      let scaleX = width / stageWidth;
      let scaleY = height / stageHeight;

      drawContainer.setAttribute(
        'style',
        `left:${left}px;top:${top}px;width:${width}px;height:${height}px;`
      );

      this.stage.height(height);
      this.stage.width(width);

      this.stage.scale({
        x: this.stage.scaleX() * scaleX,
        y: this.stage.scaleY() * scaleY,
      });

      this.stage.draw();
    }
  }

  getDrawContainer() {
    return document.getElementById('athlyzer-video-player-draw-container');
  }

  removeStylesFromPlayer() {
    const elem = document.getElementById('analyse-video-container');
    if (elem) {
      elem.setAttribute('style', ``);
      this.playerContainer && this.playerContainer.setAttribute('style', ``);
    }
  }

  @action updatePlayerSize() {
    let heightAndWidth = {};
    if (this.calcSizeByThisElementId && this.playerContainer) {
      const calcSizeByThisElementId = document.getElementById(
        this.calcSizeByThisElementId
      );
      if (calcSizeByThisElementId) {
        let { width, height } = calcSizeByThisElementId.getBoundingClientRect();
        const elem = document.getElementById('analyse-video-container');
        if (elem) {
          elem.setAttribute('style', `height:${height}px;width:${width}px;`);
          height = height - 60; // Place for the action bar
          if (height < width) {
            heightAndWidth = {
              height: `${height}px;`,
              width: `auto`,
            };
          } else {
            heightAndWidth = {
              height: `auto`,
              width: `${width}px`,
            };
          }
          this.playerContainer &&
            this.playerContainer.setAttribute(
              'style',
              `max-height:${height}px;max-width:${width}px;height:${heightAndWidth.height};width:${heightAndWidth.width}`
            );
        }
      }
    }
  }

  @action updatePlayer(changes) {
    let readyPromiseResolve;
    this.ready = new Promise((resolve) => {
      readyPromiseResolve = resolve;
    });
    const self = this;

    set(
      this,
      'state',
      Object.assign(this.state, changes, {
        ref: self.setPlayerRef,
        onReady(e) {
          console.log(
            'OnReady',
            self.playerRef,
            self.changeClipPromiseResolve,
            self.playerNeedsToBeResized
          );
          // this.isVideoBuffering = false;
          readyPromiseResolve();

          if (self.changeClipPromiseResolve || self.playerNeedsToBeResized) {
            if (self.changeClipPromiseResolve) {
              self.changeClipPromiseResolve(true);
              self.changeClipPromiseResolve = null;
            } else if (self.playerNeedsToBeResized) {
              self.playerNeedsToBeResized = false;
            }

            self.updateSecondScreen();

            if (
              self.playerContainer &&
              !self.router.currentRoute.name.includes('taggen') &&
              !self.router.currentRoute.name.includes('analyse-live')
            ) {
              console.log('initKonva', self.playerContainer);
              self.initKonvaStageOverReactPlayer(self.playerContainer);
            }

            self.updatePlayerSize();

            if (self.playerContainer) {
              //self.initKonvaStageOverReactPlayer(self.playerContainer);
              const playerWrapper = document.getElementById(
                'athlyzer-video-player-container'
              );

              const left = self.calcOffsetFromVideoplayerToContainer(
                playerWrapper,
                self.playerContainer
              );

              self.setSizeOfSchemaOverlay(
                0,
                left,
                self.playerContainer.offsetWidth,
                self.playerContainer.offsetHeight
              );

              self.setSizeOfDrawContainer(
                0,
                left,
                self.playerContainer.offsetWidth,
                self.playerContainer.offsetHeight
              );
            }
          }
        },
        onProgress({ played, playedSeconds, loaded, loadedSeconds }) {
          //self.playerUpdateTime(loadedSeconds,"stay");
          // loaded < 0.005
          //   ? (self.isVideoBuffering = true)
          //   : (self.isVideoBuffering = false);

          if (playedSeconds) {
            self.updateCurrenttimeOnTimeElement(playedSeconds);
            self.updateCurrenttimeOnSliderElement(playedSeconds);
            let playSzene = self.playSzene;
            if (
              playSzene &&
              playSzene.get('endzeit') &&
              playSzene.get('endzeit') < playedSeconds
            ) {
              if (self.isReplay) {
                self.seekToSeconds(playSzene.get('startzeit'));
              } else {
                self.pause();
              }
            }
          }
        },
        onPlay() {
          self.startAnimationTracking();
        },
        onPause() {
          self.stopAnimationTracking();
        },
        onError() {
          console.log('Error playing media');
        },
      })
    );

    if (this.playerContainer) {
      // this.isVideoBuffering = true;
      renderReactPlayer(this.playerContainer, this.state);
    }
  }

  @action updateCurrenttimeOnTimeElement(currentTime) {
    let hhmmss = this.hhmmsst(currentTime);

    let timer = document.getElementById('athlyzer-time');
    if (timer) {
      timer.innerHTML = hhmmss;
    }

    let playSzeneTimer = document.getElementById('athlyzer-play-szene-time');
    if (playSzeneTimer) {
      playSzeneTimer.innerHTML = hhmmss;
    }
  }

  @action updateCurrenttimeOnSliderElement(currentTime) {
    let slider = document.getElementById('athlyzer-slider-time');
    if (slider) {
      slider.value = currentTime;
      setTimeout(() => {
        slider.blur();
      }, 150);
    }
    let sceneSlider = document.getElementById('athlyzer-slider-time-scene');
    if (sceneSlider && this.playSzene) {
      sceneSlider.value = currentTime;
    }
  }

  @action getCurrentTime() {
    return Number(this.playerRef.getCurrentTime());
  }

  @action playerUpdateTime(currentTime) {}

  @keepLatestTask *playAfterRewind(videoService) {
    yield timeout(750);
    yield videoService.play();
    videoService.videoPlayedOnPress = false;
  }

  @dropTask *pauseBeforeRewind(videoService) {
    yield videoService.pause();
    videoService.videoPlayedOnPress = true;
  }

  stopTime(shift, ctrl) {
    let seek = 0.1;
    if (shift) {
      seek = 0.3;
    } else if (ctrl) {
      seek = 0.2;
    }
    let tempZeit = this.getCurrentTime();
    if (tempZeit > 0) {
      tempZeit = tempZeit - seek;
      this.seekToSeconds(tempZeit);
    }
  }

  hhmmss(milliseconds) {
    return new Date(Number(milliseconds) * 1000).toISOString().substr(11, 8);
  }

  hhmmsst(milliseconds) {
    let time = new Date(Number(milliseconds) * 1000).toISOString();
    let hours = Number(time.substr(11, 2));
    let minutes = Number(time.substr(14, 2));
    let seconds = Number(time.substr(17, 2));
    if (seconds <= 9) {
      seconds = String(seconds);
      seconds = '0' + seconds;
    }
    minutes = hours * 60 + minutes;
    if (minutes <= 9) {
      minutes = String(minutes);
      minutes = '0' + minutes;
    }
    let returnString = minutes + ':' + seconds;
    return returnString;
  }

  hhmmsstt(date) {
    let datum = new Date(date * 1000).toISOString();
    let hours = Number(datum.substr(11, 2));
    let minutes = Number(datum.substr(14, 2));
    let seconds = Number(datum.substr(17, 2));
    let tenths = Number(datum.substr(20, 2));
    if (seconds <= 9) {
      seconds = String(seconds);
      seconds = '0' + seconds;
    }
    minutes = hours * 60 + minutes;
    let returnString = minutes + ':' + seconds;
    returnString = returnString + '.' + tenths;

    return String(returnString);
  }

  roundToHalfSeconds(seconds) {
    let modulo = seconds % 1;
    let digits = modulo - 0.5;

    if (digits >= 0) {
      seconds -= digits;
    } else {
      seconds -= modulo;
    }
    return seconds;
  }

  unsetPlayer() {
    this.playing = false;
    try {
      this.stopAnimationTracking();
    } catch (error) {}
    if (this.playerResizeObserver) {
      this.playerResizeObserver.disconnect();
    }
    if (this.stage) {
      this.stage.destroy();
      this.stage = null;
    }
  }

  onplay() {
    this.playing = true;
  }
  onpause() {
    this.playing = false;
  }

  durationchange(event) {
    this.duration.innerHTML = '/ ' + this.hhmmsst(event.target.duration);
    this.slider.max = event.target.duration;
  }

  @action getPlayerSize() {
    const rect = this.playerContainer.getBoundingClientRect();
    return {
      top: Number(rect.top),
      left: Number(rect.left),
      width: Number(rect.width),
      height: Number(rect.height),
      style: htmlSafe(
        'height:' +
          rect.height +
          'px;' +
          'width:' +
          rect.width +
          'px;' +
          'top:0px;left:0px;'
      ),
    };
  }

  @action
  async playpause() {
    if (this.playerContainer) {
      if (this.state.playing) {
        this.pause();
      } else {
        this.play();
      }
    }
  }

  resetSzenen() {
    this.playSzene = null;
    this.szene = null;
    this.playlistSzene = null;
  }

  play() {
    if (this.checkNotPlayable()) return this.alertNotPlayable();
    this.updatePlayer({
      playing: true,
    });
    // if (this.player.paused) {
    //   return this.player.play();
    // }
  }
  pause() {
    this.updatePlayer({
      playing: false,
    });
  }

  seekToSeconds(seconds) {
    if (this.checkNotPlayable()) return this.alertNotPlayable();
    const s = Number(seconds);
    this.playerRef.seekTo(s, 'seconds');
    this.updateCurrenttimeOnTimeElement(s);
  }

  checkNotPlayable() {
    return !Boolean(this.playerRef);
  }

  alertNotPlayable() {
    if (this.analyse && !this.analyse.liveTagging) {
      return this.paperToaster.show('Das Video kann nicht abgespielt werden');
    } else {
      return;
    }
  }

  mute() {
    const muted = !this.state.muted;
    this.updatePlayer({
      volume: muted ? 0 : 1,
      muted,
    });
  }

  @action videoSprung(code) {
    code = Number(code);
    let szene = this.szene;
    this.drawLayer = null;

    if (!this.isPlayerLoaded()) {
      console.log('no player!');
    }

    if (code == 0) {
      this.playVideoszene(szene);
    }

    if (code == 1) {
      this.seekToSeconds(this.szene.get('endzeit'));
      this.play();
    }
  }
}
