import { Capacitor } from '@capacitor/core';
const platform = Capacitor.getPlatform();
const isAndroid = platform === 'android';
const isIos = platform === 'ios';
const platformname = isIos ? 'ios' : isAndroid ? 'android' : 'desktop';

export default {
  platform,
  platformname,
  isNative: Capacitor.isNativePlatform(),
  isAndroid,
  isIos,
  isApp: isAndroid || isIos,
  isElectron: platform === 'electron',
  isNotElectron: platform !== 'electron',
  isWeb: !Capacitor.isNativePlatform(),
};
