import Model from '../classes/model';
import { attr, hasMany, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class VideocollectionModel extends Model {
  @attr('boolean', { defaultValue: false }) highlight;

  @attr('string') name;
  @tracked isToBeUpdated = false;

  @attr('boolean', { defaultValue: false }) isOnline;

  typ = 'videocollection';
  @attr('mdate') datum;
  @attr('string') notizen;
  @hasMany('playlist') playlists;

  @hasMany('videoclip', { inverse: 'videocollection' }) sharedVideoclips;

  @belongsTo('ordner') ordner;
  icon = 'collections_bookmark';

  get datumjs() {
    if (this.datum) {
      return this.datum.toFormat('yyyy-LL-dd');
    } else {
      return null;
    }
  }

  get playlistsSortiert() {
    return this.playlists.toArray().sortBy('position');
  }
}
