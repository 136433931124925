import { createPeerConnection } from './common';
let peerConnection;

function lasticecandidate() {
  console.log('lasticecandidate');
  let offer = peerConnection.localDescription;
  console.log('let offer', offer);
}

export default {
  async createoffer() {
    console.log('createOffer1');
    peerConnection = createPeerConnection(lasticecandidate);
    let localStream = await window.navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    //document.getElementById("video").srcObject = localStream;
    localStream.getTracks().forEach((track) => {
      peerConnection.addTrack(track, localStream);
    });
    const videoElement = document.getElementById('video-pip');
    videoElement.srcObject = localStream;
    videoElement.setAttribute('style', 'visibility:visible;max-width:300px;');
    videoElement.onerror = (error) => {
      console.log('video error', error);
    };
    videoElement.load();
    peerConnection.ontrack = ({ streams: [stream] }) =>
      (videoElement.srcObject = stream);
    // peerConnection.ontrack = (event) => {
    //   console.log('ontrack');

    //   let remoteStream = new MediaStream();
    //   event.streams[0].getTracks().forEach((track) => {
    //     console.log('tracks', track);
    //     remoteStream.addTrack(track);
    //   });
    //   console.log('remoteStream', remoteStream);
    //   videoElement.srcObject = event.streams[0];
    //   videoElement.onloadedmetadata = (loaded) => {
    //     console.log('loaded', loaded);
    //     //videoElement.requestPictureInPicture();
    //   };
    //   videoElement.load();
    // };

    console.log('here 2');
    let dataChannel = peerConnection.createDataChannel('chat');

    dataChannel.onopen = () => {
      console.log('datachannel is also open');
    };
    dataChannel.onmessage = () => {
      console.log('datachannel sended');
    };
    console.log('here3');
    const offerOptions = {
      // New spec states offerToReceiveAudio/Video are of type long (due to
      // having to tell how many "m" lines to generate).
      // http://w3c.github.io/webrtc-pc/#idl-def-RTCOfferAnswerOptions.
      offerToReceiveAudio: 1,
      offerToReceiveVideo: 1,
    };
    const offer = await peerConnection.createOffer(offerOptions);
    console.log('offer', offer);
    await peerConnection.setLocalDescription(offer);
    return offer; // as json
  },
  async addAnswer(answer) {
    console.log('before setRemoteDone', typeof answer);
    await peerConnection.setRemoteDescription(answer);
    console.log('peerConnection', peerConnection);
    console.log('setRemoteDone');
  },
};
