import Service from './video/video-upload';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import * as Sentry from '@sentry/ember';
import Cross from 'athlyzer-coach/cross/plugin';
import { deepTracked } from 'ember-deep-tracked';
import { action, setProperties } from '@ember/object';
import { Capacitor, CapacitorHttp } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Dialog } from '@capacitor/dialog';
import { Preferences } from '@capacitor/preferences';

export default class VideoService extends Service {
  @service addonCore;
  @service addonUser;
  @service paperToaster;
  @service store;
  @service intl;
  @service taskmanager;
  @service transfer;
  @service metrics;
  @service router;
  @service modals;

  @deepTracked exportierteSzenenListe = [];
  @tracked isFullscreen = false;
  @tracked clip; // aktiver video clip!
  @tracked clipHasChanged = 1;
  @tracked szene; // aktive szene!
  @tracked playSzene = null; // aktiv abgespielte szene
  @tracked sharedSzene = null; // aktive alleinstehende Szene
  @tracked playing = false;
  @tracked muted = true;

  @tracked videoPlayedOnPress;

  @tracked liveAction = false; //necessary for playing the time-video on the first scene

  @tracked isRecording = false;
  @tracked playlistSzene = false;

  @tracked isReplay = false;

  @tracked change = false;

  @tracked isLive = false;

  @tracked cutLoading = null; // number

  @tracked changeClipPromise = null;
  @tracked changeClipPromiseResolve = null;
  @tracked playerNeedsToBeResized = false;

  constructor() {
    super(...arguments);
    this.initKeys();
    this.initSettings();
  }

  async initSettings() {
    const { value: modeLabelBar } = await Preferences.get({
      key: 'modeLabelBar',
    });

    if (modeLabelBar == 'not-olympic') {
      this.showLabelBar = false;
    }
  }
  //reserved for video/capture

  @action toggleFullscreen() {
    this.isFullscreen = !Boolean(this.isFullscreen);
    this.addonCore.resize();
  }

  resetToStandard() {
    this.clip = null;
    this.szene = null;
    this.playSzene = null;
    this.playing = false;
    this.playlistSzene = false;
    this.isReplay = false;
  }

  async setPlaylistszene(playlistszene) {
    this.drawLayer = null;
    this.playlistSzene = playlistszene;
  }

  async setSharedSzene(sharedSzene) {
    this.drawLayer = null;
    this.sharedSzene = sharedSzene;
  }

  toggleReplay() {
    this.isReplay = !this.isReplay;
  }

  unplay() {
    this.playSzene = null;
  }

  @action async playPlaylistSzene(playlistSzene) {
    console.log('playlistSzene', playlistSzene);
    this.playlistSzene = playlistSzene;
    const videoszene = await playlistSzene.get('videoszene');
    this.playVideoszene(videoszene);
  }

  @action notifyPlayerInitForChange() {
    this.playerNeedsToBeResized = true;
  }

  @action changeClipWithPromise(clip) {
    this.changeClipPromise = new Promise((resolve) => {
      this.changeClipPromiseResolve = resolve;
    });
    this.clip = clip;
    this.changeClipPromise.then(() => {
      this.updateSecondScreen();
      this.clipHasChanged++;
      console.log('clipChange successfull');
    });

    return this.changeClipPromise;
  }

  @action async selectFirstExistingClip() {
    let clips = await this.analyse.videoclipsSortiert;

    const clipsArray = clips.toArray();
    if (clipsArray.length > 0) {
      const existingClip = clips.find((clip) => {
        const value = clip.exists || clip.existsOnNAS || clip.existsOnWeb;
        return value;
      });

      if (existingClip) {
        this.changeClipWithPromise(existingClip);
      }
    }
  }

  @action async playVideoszene(szene, autoplay = true) {
    if (this.isRecording == 'live') {
      return true;
    } else {
      this.changeClipPromise && (this.changeClipPromise = null);
      const playSzene = szene;
      const newClipId = szene.belongsTo('videoclip').id();

      if (!this.clip || (newClipId && this.clip.get('id') != newClipId)) {
        if (newClipId) {
          console.log('new Clip');
          const newClip = await this.store.findRecord('videoclip', newClipId);
          if (newClip) {
            this.changeClipWithPromise(newClip);
          } else {
            this.paperToaster.show('Es gab einen Fehler mit dem Videoclip');
            Sentry.captureException(new Error('Clip not found'));
          }
        } else {
          Sentry.captureException(new Error('Clip ID not found'));
        }
        // await playPromise;
      } else {
      }
      console.log('before', this.changeClipPromiseResolve);
      if (this.changeClipPromise) {
        console.log('haschangeClipPromise and wait for resolve');
        await this.changeClipPromise;
      }
      console.log('after changeClipPromise');
      this.playSzene = playSzene;
      this.seekToSeconds(szene.get('startzeit'));

      if (autoplay) {
        await this.play();
      }

      return true;
    }
  }

  async playSharedSzene(szene) {
    this.sharedSzene = szene;
    console.log('szene', szene);
    //let player = this.player;
    this.clip = {
      web: szene.web,
      duration: szene.endzeit - szene.startzeit,
      // calcSizeByThisElementId: 'teams-video-player',
    };
    // let playPromise = new Promise((resolve, reject) => {
    //   function listener() {
    //     player.removeEventListener('loadedmetadata', listener);
    //     resolve();
    //   }
    //   player.addEventListener('loadedmetadata', listener);
    // });
    // await playPromise;
    // this.seekToSeconds(0);
    // await this.play();
    return true;
  }

  extensions = [
    '3gpp',
    'mpeg',
    'mpg',
    'mov',
    'flv',
    'mng',
    'mp4',
    'm4v',
    'avi',
    'wmv',
    'asf',
    'asx',
  ];
  getInfoFromVideourl(url) {
    var filename = url.substring(url.lastIndexOf('/') + 1).split('.');
    let extension = filename.pop();
    extension = extension.toLowerCase();
    filename = filename.join('.');
    let type;
    switch (extension.toLowerCase()) {
      case 'mov':
        type = 'video/quicktime';
        break;
      case '3gpp':
        type = 'video/3gpp';
        break;
      case 'mpeg':
      case 'mpg':
        type = 'video/mpeg';
        break;
      case 'flv':
        type = 'video/x-flv';
        break;
      case 'mng':
        type = 'video/x-mng';
        break;
      case 'asx':
      case 'asf':
        type = 'video/x-ms-asf';
        break;
      case 'wmv':
        type = 'video/x-ms-wmv';
        break;
      case 'avi':
        type = 'video/x-msvideo';
        break;
      case 'm4v':
      case 'mp4':
        type = 'video/mp4';
        if (this.addonCore.isIos) {
          type = 'video/quicktime';
        }
        break;
      default:
        type = 'video/mp4';
    }
    return {
      filename,
      extension,
      type,
    };
  }

  async getS3PutUrl(filename, extension) {
    let url =
      '/sign-s3?file-name=' +
      filename +
      '.' +
      extension +
      '&file-type=' +
      extension;
    let response = await this.addonUser.getHttp().get(url);
    return response.data;
  }

  async getS3PostUrl(filepath, filename, extension) {
    let url =
      '/sign-s3?file-name=' +
      filename +
      '.' +
      extension +
      '&file-type=' +
      extension +
      '&POST=TRUE';
    let response = await this.addonUser.getHttp().get(url);
    return {
      filename: filename + '.' + extension,
      filepath: filepath,
      url: response.data.url,
      key: response.data.fields.key,
      bucket: response.data.fields.bucket,
      algorithm: response.data.fields['X-Amz-Algorithm'],
      credential: response.data.fields['X-Amz-Credential'],
      date: response.data.fields['X-Amz-Date'],
      policy: response.data.fields['Policy'],
      signature: response.data.fields['X-Amz-Signature'],
      acl: 'public-read',
    };
  }

  async updateClipsmanager() {
    let analyse = await this.analyse;
    if (analyse) {
      let videoclips = await analyse.get('videoclips');

      const promises = videoclips.map(async (clip) => {
        let clipExists = await this.checkIfClipOnDevice(clip);

        const clipProperties = {
          exists: clipExists.exists,
          existsOnNAS: clipExists.existsOnNAS,
          existsOnWeb: clipExists.existsOnWeb,
          availableOnWeb: clipExists.availableOnWeb,
        };
        setProperties(clip, clipProperties);
        return clipProperties;
      });
      await Promise.all(promises);
    } else {
      console.log('updateClipManager - keine Analyse');
    }
  }

  async checkWeb(clip) {
    if (clip.web) {
      try {
        const checkWebExistsResponse = await CapacitorHttp.request({
          url: clip.web,
          method: 'GET',
          headers: {
            'Cache-Control': 'no-cache',
            'Access-Control-Expose-Headers':
              'E-Tag Access-Control-Allow-Origin',
            Origin: 'https://www.athlyzer.com',
            'Access-Control-Allow-Origin': '*',
            Range: 'bytes=0-1',
          },
        });

        if (
          checkWebExistsResponse.status === 200 ||
          checkWebExistsResponse.status === 206
        ) {
          return {
            existsOnWeb: true,
            availableOnWeb: true,
            onlineCheckFailed: false,
          };
        } else if (checkWebExistsResponse.status === 404) {
          return {
            existsOnWeb: false,
            availableOnWeb: false,
            onlineCheckFailed: false,
          };
        } else {
          return {
            existsOnWeb: false,
            availableOnWeb: false,
            onlineCheckFailed: true,
          };
        }
      } catch (error) {
        if (error.message === 'Failed to fetch') {
          return {
            existsOnWeb: false,
            availableOnWeb: false,
            onlineCheckFailed: false,
          };
        } else {
          return {
            existsOnWeb: false,
            availableOnWeb: false,
            onlineCheckFailed: true,
          };
        }
      }
    } else {
      return {
        existsOnWeb: false,
        availableOnWeb: false,
        onlineCheckFailed: false,
      };
    }
  }

  async checkIfClipOnDevice(clip) {
    if (clip.web) {
      const replacedWeb = clip.web.replace(/%2F/g, '/');
      if (replacedWeb !== clip.web) {
        clip.web = replacedWeb;
        if (clip.get('hasDirtyAttributes')) {
          await clip.save();
        }
      }
    }

    let clipOnlineCheck = await this.checkWeb(clip);

    if (Capacitor.getPlatform() == 'web') {
      clipOnlineCheck = Object.assign(clipOnlineCheck, {
        exists: false,
        existsOnNAS: false,
      });
      return clipOnlineCheck;
    }

    let device = this.addonCore.platformname;
    let clipPlatform = clip[device];

    let clipExists = {
      exists: false,
      existsOnNAS: false,
      existsOnWeb: false,
      availableOnWeb: false,
      onlineCheckFailed: false,
    };

    clipExists.existsOnWeb = clipOnlineCheck.existsOnWeb;
    clipExists.availableOnWeb = clipOnlineCheck.availableOnWeb;

    if (clipPlatform) {
      let clipExistsAthlyzer = await Cross.fileExists({
        videourl: clipPlatform,
      });

      if (
        clipExistsAthlyzer.exists &&
        (!clip.convertedVideoHeight || !clip.convertedVideoWidth)
      ) {
        try {
          const mediaInfo = await this.getVideoMediaInfo({
            videourl: clipPlatform,
          });

          let convertedMetadata = {
            width: mediaInfo.width,
            height: mediaInfo.height,
            bitrate: mediaInfo.bitrate,
          };

          clip.set('convertedVideoBitrate', convertedMetadata.bitrate);
          clip.set('convertedVideoWidth', convertedMetadata.width);
          clip.set('convertedVideoHeight', convertedMetadata.height);
          clip.set('convertRequired', false);
          await clip.save();
        } catch (error) {
          console.log('Error', error);
          Sentry.captureException(error);
        }
      }

      clipExists.exists = clipExistsAthlyzer.exists;
    }

    // Get Videourl to check for External Video Width
    let absoluteVideoURLToCheck = null;

    if (this.addonCore.isDesktop) {
      let clipExistsOnNAS = await Cross.fileExists({
        videourl: clip.nas,
      });
      absoluteVideoURLToCheck = clip.nas;
      clipExists['existsOnNAS'] = clipExistsOnNAS.exists;
    } else if (this.addonCore.isAndroid) {
      let clipExistsOnNAS = await Cross.fileExists({
        filepath: '' + clip.androidexternal,
      });

      absoluteVideoURLToCheck = clip.androidexternal;
      clipExists['existsOnNAS'] = clipExistsOnNAS.exists;
    } else if (this.addonCore.isIos) {
      console.log('before get Bookmark1');
      const { videourl } = await Cross.getBookmark({
        videourl: clip.get('iosexternal'),
      });
      if (videourl) {
        const { exists } = await Cross.fileExists({
          filepath: videourl,
        });
        if (exists) {
          absoluteVideoURLToCheck = videourl;
          clipExists['existsOnNAS'] = exists;
        }
      }
    }

    // if (
    //   absoluteVideoURLToCheck &&
    //   clipExists.existsOnNAS &&
    //   (!clip.externalVideoHeight || !clip.externalVideoWidth)
    // ) {
    //   try {
    //     const mediaInfo = await this.getVideoMediaInfo({
    //       filepath: absoluteVideoURLToCheck,
    //     });

    //     let convertedMetadata = {
    //       width: mediaInfo.width,
    //       height: mediaInfo.height,
    //       bitrate: mediaInfo.bitrate,
    //     };

    //     clip.set('convertedVideoBitrate', convertedMetadata.bitrate);
    //     clip.set('convertedVideoWidth', convertedMetadata.width);
    //     clip.set('convertedVideoHeight', convertedMetadata.height);
    //     clip.set('convertRequired', false);
    //     await clip.save();
    //   } catch (error) {
    //     console.log('Error', error);
    //     Sentry.captureException(error);
    //   }
    // }

    return clipExists;
  }

  // Source: https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string/10420404
  humanFileSize(bytes, si) {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    console.log('bytes', bytes, units[u]);
    return bytes.toFixed(1) + ' ' + units[u];
  }

  async checkFreeSpace(minimumSpaceInBytes = 500000000, individualMessage) {
    let diskFree,
      cancel = false;
    if (!individualMessage) {
      individualMessage = this.intl.t(
        'Möchtest du das Video trotzdem hinzufügen?'
      );
    }

    const message =
      this.intl.t('Du hast wenig Speicherplatz übrig') +
      '. ' +
      individualMessage;

    if (this.addonCore.isApp) {
      const info = await Device.getInfo();
      diskFree = info.realDiskFree;
    } else if (this.addonCore.isDesktop) {
      const space = await Cross.diskSpace();
      diskFree = space.diskFree;
    }

    if (diskFree && diskFree <= minimumSpaceInBytes) {
      const { value } = await Dialog.confirm({
        title:
          this.intl.t('Speicherplatz knapp') +
          ` ${this.humanFileSize(diskFree, true)}`,
        message,
      });
      cancel = !value;
    }

    return {
      freeSpace: diskFree,
      cancel,
    };
  }
}
