import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import PouchDB from 'pouchdb-browser';

export default class NewwindowTaggenRoute extends Route {
  // @service store;

  // queryParams = {
  //   db: '',
  //   schema: '',
  // };
  async model({ db, schema }) {
    console.log('db', schema, db);
  }
}
