import { modifier } from 'ember-modifier';

export default modifier(function autofocusModifier(element) {
  try {
    if (typeof element.setFocus === 'function') {
      // FOCUS IONIC INPUT
      element.setFocus();
    } else {
      // FOCUS NATIVE DOM INPUT
      element.focus();
    }
  } catch (e) {}
});
