import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';

export default class BasicsModalButtonsComponent extends Component {
  @tracked isLoading = false;
  @tracked isError = false;
  @action
  async primaryButtonAction() {
    this.isError = false;
    this.isLoading = true;
    if (typeof this.args.primaryBeforeAction == 'function') {
      console.log('primaryBeforeAction');
      try {
        await this.args.primaryBeforeAction();
      } catch (e) {
        this.isLoading = false;
        this.isError = e;
        console.log('e', e);
        return;
      }
    }
    if (typeof this.args.primaryAction == 'function') {
      await this.args.primaryAction();
    } else {
      this.args.onClose();
    }
    if (window.document.activeElement) {
      window.document.activeElement.blur();
    }
    this.isLoading = false;
    if (typeof this.args.primaryAfterAction == 'function') {
      await this.args.primaryAfterAction();
    }
  }

  @action
  async secondaryButtonAction() {
    this.isLoading = true;
    if (typeof this.args.secondaryBeforeAction == 'function') {
      await this.args.secondaryBeforeAction();
    }
    if (typeof this.args.secondaryAction == 'function') {
      await this.args.secondaryAction();
    } else {
      this.args.onClose();
    }
    this.isLoading = false;
    if (typeof this.args.secondaryAfterAction == 'function') {
      await this.args.secondaryAfterAction();
    }
  }
}
