import Service from './session';
import { inject as service } from '@ember/service';
import config from 'athlyzer-coach/config/environment';
import { tracked } from '@glimmer/tracking';
import { action, setProperties } from '@ember/object';
import { DateTime } from 'luxon';
import { Preferences } from '@capacitor/preferences';
import * as Sentry from '@sentry/ember';

export default class AddonUserSubscrioptionService extends Service {
  @service store;
  @service paperToaster;
  @service intl;
  @service network;

  @tracked settings;

  @tracked availableChannels = [
    {
      code: 'search_engines',
      name: this.intl.t('Suchmaschinen (Google, Bing usw.)'),
    },
    {
      code: 'recommendation',
      name: this.intl.t('Empfehlung von einem Freund oder Kollegen'),
    },
    {
      code: 'social_media',
      name: this.intl.t('Soziale Medien'),
    },
    {
      code: 'blog',
      name: this.intl.t('Blog oder Artikel'),
    },
    {
      code: 'education',
      name: this.intl.t('Seminar / Weiterbildung'),
    },
    {
      code: 'other',
      name: this.intl.t('Andere'),
    },
  ];

  @tracked availableRoles = [
    { code: 'coach', name: this.intl.t('Trainer') },
    { code: 'sports-director', name: this.intl.t('Sportlicher Leiter') },
    { code: 'assistant-coach', name: this.intl.t('Co-Trainer') },
    { code: 'videoanalyst', name: this.intl.t('Videoanalyst') },
    { code: 'scout', name: this.intl.t('Scout') },
    { code: 'other', name: this.intl.t('Andere') },
  ];

  @tracked availableSports = [
    { code: 'soccer', name: this.intl.t('Fussball') },
    { code: 'field-hockey', name: this.intl.t('Feldhockey') },
    { code: 'ice-hockey', name: this.intl.t('Eishockey') },
    { code: 'handball', name: this.intl.t('Handball') },
    { code: 'basketball', name: this.intl.t('Basketball') },
    { code: 'taekwondo', name: this.intl.t('Taekwondo') },
    { code: 'volleyball', name: this.intl.t('Volleyball') },
    { code: 'rugby', name: this.intl.t('Rugby') },
    { code: 'american-football', name: this.intl.t('Football') },
    { code: 'table-tennis', name: this.intl.t('Tischtennis') },
    { code: 'lacrosse', name: this.intl.t('Lacrosse') },
    { code: 'futsal', name: this.intl.t('Futsal') },
    { code: 'floorball', name: this.intl.t('Floorball') },
  ];

  @tracked availableExperienceLevels = [
    {
      code: 'beginner',
      name: this.intl.t('Anfänger'),
      description: this.intl.t(
        'Ich habe bisher noch nicht mit Videoanalyse gearbeitet.'
      ),
    },
    {
      code: 'advanced',
      name: this.intl.t('Fortgeschritten'),
      description: this.intl.t(
        'Ich arbeite regelmäßig, aber nicht intensiv mit Videoanalyse.'
      ),
    },
    {
      code: 'expert',
      name: this.intl.t('Experte'),
      description: this.intl.t(
        'Videoanalyse ist fester, regelmäßiger Bestandteil meines Alltags.'
      ),
    },
  ];

  @action async restoreSettings() {
    try {
      await this.loadLocalSettings();
    } catch (error) {}

    try {
      await this.loadSettings();
    } catch (error) {}
    return this.settings;
  }

  @action async loadLocalSettings() {
    const localData = await Preferences.get({
      key: `${this.user_uid}_settings`,
    });
    console.log('loadLocalSettings', localData);
    if (localData?.value) {
      const data = JSON.parse(localData.value);
      if (data) {
        if (!this.settings) {
          this.settings = data;
        } else {
          setProperties(this.settings, data);
        }
      }
    }
  }

  @action async loadSettings() {
    if (!this.network.isConnectedToInternet) return;

    try {
      const settings = await this.getHttp().get('user/settings');

      if (settings.data) {
        const { data } = settings;
        if (!this.settings) {
          this.settings = data;
        } else {
          setProperties(this.settings, data);
        }

        await Preferences.set({
          key: `${this.user_uid}_settings`,
          value: JSON.stringify(data),
        });
        return data;
      }
    } catch (error) {
      console.log('error', error);
      if (error.response && error.response.status == 401) {
        this.checkExpired();
      }
      Sentry.captureException(error);
      return null;
    }
  }

  @action openProductTourDemoBookingLink(tourName, step) {
    this.addonCore.openBrowser(this.intl.t('demobooking.link'));
    this.metrics.track(`user.workspace.tour.demobooking.open`, {
      tourName,
      step,
    });
    this.showDemoBooking = false;
  }

  @action async saveAndAddFinishedProductTour(tourName) {
    try {
      await this.getHttp().post('/user/settings/tours', {
        tours: [tourName],
      });
      if (!this.settings.tours) {
        this.settings.tours = [];
      }
      this.settings.tours.push(tourName);

      await this.loadSettings();
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  @action async dismissAllTours() {
    try {
      const settings = this.settings;
      const toursToBeDismissed = [
        'dashboard',
        'workspace',
        'analyse-mobile-overview',
        'analyse-schema-select',
        'clip-manager',
        'analyse-tagging',
        'analyse-mobile-prepare',
      ];

      if (settings && settings.tours) {
        let atLeastOneTourNotDoneYet = false;

        toursToBeDismissed.every((tourName) => {
          const tourDoneAlready = settings.tours.includes(tourName);

          if (!tourDoneAlready) {
            atLeastOneTourNotDoneYet = true;
            return false;
          }

          return true;
        });

        if (atLeastOneTourNotDoneYet) {
          await this.getHttp().post('/user/settings/tours', {
            tours: toursToBeDismissed,
          });
          await this.loadSettings();
        }
      } else if (settings) {
        await this.getHttp().post('/user/settings/tours', {
          tours: toursToBeDismissed,
        });
        await this.loadSettings();
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  @action checkIfTourAlreadyFinished(tourName) {
    if (this.settings && this.settings.tours) {
      return this.settings.tours.includes(tourName);
    } else {
      return false;
    }
  }

  get name() {
    if (this.settings && this.settings.vorname && this.settings.nachname) {
      return this.settings.vorname + ' ' + this.settings.nachname;
    } else if (this.settings && this.settings.vorname) {
      return this.settings.vorname;
    } else {
      return this.intl.t('noname');
    }
  }

  get hasFinishedCoachesOnboarding() {
    const settings = this.settings;

    if (
      settings &&
      (!settings.vorname ||
        !settings.nachname ||
        !settings.acquisition_channel ||
        !settings.sport_code ||
        !settings.funktion ||
        !settings.telefon ||
        !settings.videoanalysis_experience ||
        !settings.organisation_name ||
        this.workspaces.length === 0)
    )
      return false;

    return true;
  }

  get hasFinishedAthleteOnboarding() {
    const settings = this.settings;

    if (settings && (!settings.vorname || !settings.nachname)) return false;

    return true;
  }

  get currentDeployTarget() {
    return config.DEPLOY_TARGET_ENVIRONMENT;
  }

  async updateUserProfileSettings(settings = {}) {
    try {
      await this.getHttp().patch('/user/settings', settings);

      await this.loadSettings();
    } catch (e) {
      Sentry.captureException(e);
      this.paperToaster.show(
        this.intl.t(
          'Speichern fehlgeschlagen. Bitte überprüfe deine Internetverbindung.'
        )
      );
    }
  }
}
