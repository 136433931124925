import Component from '@glimmer/component';
import { importSync } from '@embroider/macros';
import { ensureSafeComponent } from '@embroider/util';

export default class BasicsDynamicIconComponent extends Component {
  get dynamicIconComponent() {
    let module = importSync(`../../icons/${this.args.customIcon}`);
    return ensureSafeComponent(module.default, this);
  }
}
