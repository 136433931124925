import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';

export default class AddonCoreLayoutConfirmComponent extends Component {
  @service addonCore;

  @tracked isLoading = false;
  @tracked isError = false;

  get show() {
    if (this.args.isOpen) {
      return htmlSafe('dialog--is-visible');
    } else {
      return '';
    }
  }

  get style() {
    if (this.args.isOpen) {
      return htmlSafe('visibility:visible;z-index:9002;opacity:1;');
    } else {
      return htmlSafe('visibility:hidden;z-index:0;opacity:0;');
    }
  }

  @action stopPropagation(e) {
    e.preventDefault();
  }

  @action close() {
    this.args.onClose();
  }
}
