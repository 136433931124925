import Model from '../classes/model';
import { attr, belongsTo } from '@ember-data/model';

class TeamFastModel extends Model {
  typ = 'team';
  icon = 'people';

  @attr('boolean', { defaultValue: false }) highlight;
  get unhighlighted() {
    return !this.highlight;
  }

  @attr('string') name;
  @attr('mdate') datum;

  @belongsTo('ordner') ordner;
}

TeamFastModel.reopenClass({
  documentType: 'team',
});

export default TeamFastModel;
