import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'athlyzer-coach/config/environment';
import { Preferences } from '@capacitor/preferences';
import axios from 'axios';
import _debug from 'debug';
import { action } from '@ember/object';
import * as Sentry from '@sentry/ember';

const debug = {
  log: _debug('superlogin:log'),
  info: _debug('superlogin:info'),
  warn: _debug('superlogin:warn'),
  error: _debug('superlogin:error'),
};
const httpConfigure = {
  serverUrl: config.hostaddress,
  baseUrl: '/auth',
  socialUrl: config.hostaddress + '/auth',
  noDefaultEndpoint: false,
  checkExpired: true,
  timeout: 0,
  refreshThreshold: 0.5,
};

// session is now only used for the login state
export default class AddonUserSessionService extends Service {
  @service store;
  @service metrics;
  @service router;

  @tracked user_uid;
  get user_uid_flat() {
    if (!this.user_uid) return null;
    return this.user_uid.replace(/-/g, '');
  }
  @tracked _session;

  @action async logout() {
    this.router.transitionTo('logout');
  }

  refreshSession() {
    return this.checkRefresh();
  }

  async restoreSession() {
    // Setup the new session
    const { value } = await Preferences.get({ key: 'superloginsession' });
    if (value) {
      this._session = JSON.parse(value);
      console.log('@restoreSession2', this._session);
      this.configure(httpConfigure);
      this.user_uid = this._session?.user_uid;
    }

    return;
  }

  // Capitalizes the first letter of a string
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  parseHostFromUrl(url) {
    const parsedURL = new URL(url);
    return parsedURL.host;
  }

  checkEndpoint(url, endpoints) {
    const host = this.parseHostFromUrl(url);

    for (let i = 0; i < endpoints.length; i += 1) {
      if (host === endpoints[i]) {
        return true;
      }
    }
    return false;
  }

  parseError(err) {
    // if no connection can be established we don't have any data thus we need to forward the original error.
    if (err && err.response && err.response.data) {
      return err.response.data;
    }
    return err;
  }

  constructor() {
    super(...arguments);
    this._oauthComplete = false;
    this._config = {};
    this._refreshInProgress = false;
    this.configure(httpConfigure);
  }

  async configure(config = {}) {
    this._http = axios.create({
      baseURL: config.serverUrl,
      timeout: config.timeout,
    });
    config.baseUrl = config.baseUrl || '/auth';
    config.baseUrl = config.baseUrl.replace(/\/$/, ''); // remove trailing /
    config.socialUrl = config.socialUrl || config.baseUrl;
    config.socialUrl = config.socialUrl.replace(/\/$/, ''); // remove trailing /
    config.local = config.local || {};
    config.local.usernameField = config.local.usernameField || 'username';
    config.local.passwordField = config.local.passwordField || 'password';

    if (!config.endpoints || !(config.endpoints instanceof Array)) {
      config.endpoints = [];
    }
    config.endpoints.push(this.parseHostFromUrl(config.serverUrl));

    config.providers = config.providers || [];
    config.timeout = config.timeout || 0;

    this._config = config;
    this._initializeHttpInterceptor();

    if (config.checkExpired) {
      this.checkExpired();
    }
  }

  _initializeHttpInterceptor() {
    const requestInterceptor = (req) => {
      const config = this.getConfig();
      const session = this.getSession();
      if (!session || !session.token) {
        return Promise.resolve(req);
      }

      if (req.skipRefresh) {
        return Promise.resolve(req);
      }

      return this.checkRefresh().then(() => {
        if (this.checkEndpoint(req.baseURL, config.endpoints)) {
          req.headers.Authorization = `Bearer ${session.token}:${session.password}`;
        }

        return req;
      });
    };

    const responseError = (error) => {
      const config = this.getConfig();

      // if there is not config obj in in the error it means we cannot check the endpoints.
      // This happens for example if there is no connection at all because axion just forwards the raw error.
      if (!error || !error.config) {
        return Promise.reject(error);
      }

      // If there is an unauthorized error from one of our endpoints and we are logged in...
      if (
        this.checkEndpoint(error.config.baseURL, config.endpoints) &&
        error.response &&
        error.response.status === 401 &&
        this.authenticated()
      ) {
        debug.warn('Not authorized');
        this._onLogout('Session expired');
      }
      return Promise.reject(error);
    };
    // clear interceptors from a previous configure call
    this._http.interceptors.request.eject(this._httpRequestInterceptor);
    this._http.interceptors.response.eject(this._httpResponseInterceptor);

    this._httpRequestInterceptor = this._http.interceptors.request.use(
      requestInterceptor.bind(this)
    );
    this._httpResponseInterceptor = this._http.interceptors.response.use(
      null,
      responseError.bind(this)
    );
  }

  authenticated() {
    return !!(this._session && this._session.token);
  }

  getConfig() {
    return this._config;
  }

  validateSession() {
    if (!this.authenticated()) {
      return Promise.reject();
    }
    return this._http.get(`${this._config.baseUrl}/session`).catch((err) => {
      this._onLogout('Session expired');
      throw this.parseError(err);
    });
  }

  getSession() {
    return this._session ? Object.assign(this._session) : null;
  }

  async setSession(session) {
    this._session = session;
    this.user_uid = session.user_uid;
    const done = await Preferences.set({
      key: 'superloginsession',
      value: JSON.stringify(session),
    });

    debug.info('New session set');
  }

  async deleteSession() {
    await Preferences.clear();
    this._session = null;
  }

  getDbUrl(dbName) {
    if (this._session.userDBs && this._session.userDBs[dbName]) {
      return this._session.userDBs[dbName];
    }
    return null;
  }

  getHttp() {
    return this._http;
  }

  getApiClient() {
    return this._http;
  }

  confirmRole(role) {
    if (!this._session || !this._session.roles || !this._session.roles.length)
      return false;
    return this._session.roles.indexOf(role) !== -1;
  }

  confirmAnyRole(roles) {
    if (!this._session || !this._session.roles || !this._session.roles.length)
      return false;
    for (let i = 0; i < roles.length; i += 1) {
      if (this._session.roles.indexOf(roles[i]) !== -1) return true;
    }
    return false;
  }

  confirmAllRoles(roles) {
    if (!this._session || !this._session.roles || !this._session.roles.length)
      return false;
    for (let i = 0; i < roles.length; i += 1) {
      if (this._session.roles.indexOf(roles[i]) === -1) return false;
    }
    return true;
  }

  checkRefresh() {
    // Get out if we are not authenticated or a refresh is already in progress
    if (this._refreshInProgress) {
      return Promise.resolve();
    }
    if (!this._session || !this._session.expires) {
      return Promise.reject();
    }
    // try getting the latest refresh date, if not available fall back to issued date
    let refreshed = this._session.refreshed || this._session.issued;
    const expires = this._session.expires;

    if (expires && !this._session.refreshed) {
      refreshed = expires - 864000 * 1000;
    }

    const threshold = isNaN(this._config.refreshThreshold)
      ? 0.5
      : this._config.refreshThreshold;
    const duration = expires - refreshed;
    let timeDiff = this._session.serverTimeDiff || 0;
    if (Math.abs(timeDiff) < 5000) {
      timeDiff = 0;
    }
    const estimatedServerTime = Date.now() + timeDiff;
    const elapsed = estimatedServerTime - refreshed;
    const ratio = elapsed / duration;
    if (ratio > threshold) {
      debug.info('Refreshing session');
      return this.refresh()
        .then((session) => {
          debug.log('Refreshing session sucess', session);
          return session;
        })
        .catch((err) => {
          debug.error('Refreshing session failed', err);
          throw err;
        });
    }
    return Promise.resolve();
  }

  checkExpired() {
    // This is not necessary if we are not authenticated
    if (!this.authenticated()) {
      return;
    }
    const expires = this._session.expires;
    let timeDiff = this._session.serverTimeDiff || 0;
    // Only compensate for time difference if it is greater than 5 seconds
    if (Math.abs(timeDiff) < 5000) {
      timeDiff = 0;
    }
    const estimatedServerTime = Date.now() + timeDiff;
    if (estimatedServerTime > expires) {
      this._onLogout('Session expired');
    }
  }

  refresh() {
    const session = this.getSession();
    this._refreshInProgress = true;
    return new Promise((resolve) => {
      this._http
        .post(`${this._config.baseUrl}/refresh`, {})
        .then((res) => {
          this._refreshInProgress = false;
          if (res.data.token && res.data.expires) {
            Object.assign(session, res.data);
            res.data = session;
            this.setSession(session);
            this._onRefresh(res);
          }
          resolve(res);
        })
        .catch((err) => {
          this._refreshInProgress = false;
          resolve(err.response || err);
        });
    });
  }

  authenticate() {
    return new Promise((resolve) => {
      const session = this.getSession();
      if (session) {
        resolve(session);
      } else {
        this.on('login', (newSession) => {
          resolve(newSession);
        });
      }
    });
  }

  login(credentials) {
    const { usernameField, passwordField } = this._config.local;
    if (!credentials[usernameField] || !credentials[passwordField]) {
      return Promise.reject({ error: 'Username or Password missing...' });
    }
    return this._http
      .post(`${this._config.baseUrl}/login`, credentials, { skipRefresh: true })
      .then(async (res) => {
        res.data.serverTimeDiff = res.data.issued - Date.now();
        await this.setSession(res.data);
        this._onLogin(res.data);
        return res.data;
      })
      .catch((err) => {
        this.deleteSession();

        throw this.parseError(err);
      });
  }

  async loginExistingSession(token, password) {
    const refreshHttpInstance = axios.create({
      baseURL: this._config.serverUrl,
      timeout: this._config.timeout,
      headers: {
        Authorization: `Bearer ${token}:${password}`,
      },
    });
    this._refreshInProgress = true;
    var session = { token, password };
    return new Promise((resolve) => {
      refreshHttpInstance
        .post(`${this._config.baseUrl}/refresh`, {})
        .then((res) => {
          this._refreshInProgress = false;
          if (res.data.token && res.data.expires) {
            Object.assign(session, res.data);
            res.data = session;
            this.setSession(res.data);
            this._onLogin(res.data);
          }
          resolve(res);
        })
        .catch((err) => {
          this.deleteSession();
          this._refreshInProgress = false;
          resolve(err.response || err);
        });
    });
  }

  register(registration) {
    return this._http
      .post(`${this._config.baseUrl}/register`, registration, {
        skipRefresh: true,
      })
      .then((res) => {
        if (res.data.user_uid && res.data.token) {
          res.data.serverTimeDiff = res.data.issued - Date.now();
          this.setSession(res.data);
          this._onLogin(res.data);
        }
        this._onRegister(registration);
        return res.data;
      })
      .catch((err) => {
        throw this.parseError(err);
      });
  }

  logoutSession(msg) {
    return this._http
      .post(`${this._config.baseUrl}/logout`, {})
      .then((res) => {
        this._onLogout(msg || 'Logged out');
        return res.data;
      })
      .catch((err) => {
        this._onLogout(msg || 'Logged out');
        if (!err.response || err.response.data.status !== 401) {
          throw this.parseError(err);
        }
      });
  }

  logoutAll(msg) {
    return this._http
      .post(`${this._config.baseUrl}/logout-all`, {})
      .then((res) => {
        this._onLogout(msg || 'Logged out');
        return res.data;
      })
      .catch((err) => {
        this._onLogout(msg || 'Logged out');
        if (!err.response || err.response.data.status !== 401) {
          throw this.parseError(err);
        }
      });
  }

  logoutOthers() {
    return this._http
      .post(`${this._config.baseUrl}/logout-others`, {})
      .then((res) => res.data)
      .catch((err) => {
        throw this.parseError(err);
      });
  }

  socialAuth(provider) {
    const providers = this._config.providers;
    if (providers.indexOf(provider) === -1) {
      return Promise.reject({ error: `Provider ${provider} not supported.` });
    }
    const url = `${this._config.socialUrl}/${provider}`;
    return this._oAuthPopup(url, {
      windowTitle: `Login with ${capitalizeFirstLetter(provider)}`,
    });
  }

  tokenSocialAuth(provider, accessToken) {
    const providers = this._config.providers;
    if (providers.indexOf(provider) === -1) {
      return Promise.reject({ error: `Provider ${provider} not supported.` });
    }
    return this._http
      .post(`${this._config.baseUrl}/${provider}/token`, {
        access_token: accessToken,
      })
      .then((res) => {
        if (res.data.user_uid && res.data.token) {
          res.data.serverTimeDiff = res.data.issued - Date.now();
          this.setSession(res.data);
          this._onLogin(res.data);
        }
        return res.data;
      })
      .catch((err) => {
        throw this.parseError(err);
      });
  }

  tokenLink(provider, accessToken) {
    const providers = this._config.providers;
    if (providers.indexOf(provider) === -1) {
      return Promise.reject({ error: `Provider ${provider} not supported.` });
    }
    const linkURL = `${this._config.baseUrl}/link/${provider}/token`;
    return this._http
      .post(linkURL, { access_token: accessToken })
      .then((res) => res.data)
      .catch((err) => {
        throw this.parseError(err);
      });
  }

  link(provider) {
    const providers = this._config.providers;
    if (providers.indexOf(provider) === -1) {
      return Promise.reject({ error: `Provider ${provider} not supported.` });
    }
    if (this.authenticated()) {
      const session = this.getSession();
      const token = `bearer_token=${session.token}:${session.password}`;
      const linkURL = `${this._config.socialUrl}/link/${provider}?${token}`;
      const windowTitle = `Link your account to ${capitalizeFirstLetter(
        provider
      )}`;
      return this._oAuthPopup(linkURL, { windowTitle });
    }
    return Promise.reject({ error: 'Authentication required' });
  }

  unlink(provider) {
    const providers = this._config.providers;
    if (providers.indexOf(provider) === -1) {
      return Promise.reject({ error: `Provider ${provider} not supported.` });
    }
    if (this.authenticated()) {
      return this._http
        .post(`${this._config.baseUrl}/unlink/${provider}`)
        .then((res) => res.data)
        .catch((err) => {
          throw this.parseError(err);
        });
    }
    return Promise.reject({ error: 'Authentication required' });
  }

  confirmEmail(token) {
    if (!token || typeof token !== 'string') {
      return Promise.reject({ error: 'Invalid token' });
    }
    return this._http
      .get(`${this._config.baseUrl}/confirm-email/${token}`)
      .then((res) => res.data)
      .catch((err) => {
        throw this.parseError(err);
      });
  }

  forgotPassword(email) {
    return this._http
      .post(
        `${this._config.baseUrl}/forgot-password`,
        { email },
        { skipRefresh: true }
      )
      .then((res) => res.data)
      .catch((err) => {
        throw this.parseError(err);
      });
  }

  resetPassword(form) {
    return this._http
      .post(`${this._config.baseUrl}/password-reset`, form, {
        skipRefresh: true,
      })
      .then((res) => {
        if (res.data.user_uid && res.data.token) {
          this.setSession(res.data);
          this._onLogin(res.data);
        }
        return res.data;
      })
      .catch((err) => {
        throw this.parseError(err);
      });
  }

  changePassword(form) {
    if (this.authenticated()) {
      return this._http
        .post(`${this._config.baseUrl}/password-change`, form)
        .then((res) => res.data)
        .catch((err) => {
          throw this.parseError(err);
        });
    }
    return Promise.reject({ error: 'Authentication required' });
  }

  changeEmail(newEmail) {
    if (this.authenticated()) {
      return this._http
        .post(`${this._config.baseUrl}/change-email`, { newEmail })
        .then((res) => res.data)
        .catch((err) => {
          throw this.parseError(err);
        });
    }
    return Promise.reject({ error: 'Authentication required' });
  }

  validateUsername(username) {
    return this._http
      .get(
        `${this._config.baseUrl}/validate-username/${encodeURIComponent(
          username
        )}`
      )
      .then(() => true)
      .catch((err) => {
        throw this.parseError(err);
      });
  }

  validateEmail(email) {
    return this._http
      .get(
        `${this._config.baseUrl}/validate-email/${encodeURIComponent(email)}`
      )
      .then(() => true)
      .catch((err) => {
        throw this.parseError(err);
      });
  }

  _oAuthPopup(url, options) {
    return new Promise((resolve, reject) => {
      this._oauthComplete = false;
      options.windowName = options.windowTitle || 'Social Login';
      options.windowOptions =
        options.windowOptions || 'location=0,status=0,width=800,height=600';
      const _oauthWindow = window.open(
        url,
        options.windowName,
        options.windowOptions
      );

      if (!_oauthWindow) {
        reject({ error: 'Authorization popup blocked' });
      }

      const _oauthInterval = setInterval(() => {
        if (_oauthWindow.closed) {
          clearInterval(_oauthInterval);
          if (!this._oauthComplete) {
            this.authComplete = true;
            reject({ error: 'Authorization cancelled' });
          }
        }
      }, 500);

      window.superlogin = {};
      window.superlogin.oauthSession = (error, session, link) => {
        if (!error && session) {
          session.serverTimeDiff = session.issued - Date.now();
          this.setSession(session);
          this._onLogin(session);
          return resolve(session);
        } else if (!error && link) {
          this._onLink(link);
          return resolve(`${capitalizeFirstLetter(link)} successfully linked.`);
        }
        this._oauthComplete = true;
        return reject(error);
      };
    });
  }

  _onLogin(msg) {
    debug.info('Login', msg);
    // this.emit('login', msg);
  }

  async _onLogout(msg) {
    this.unsetValues();

    this.deleteSession();
    this.metrics.logoutIdentifiedUser();

    try {
      let db = this.store.adapterFor('application').db;
      if (db) {
        await db.close();
      }
    } catch (err) {
      console.log('Error', err);
      Sentry.captureException(err);
    }

    debug.info('Logout', msg);

    if (this.router.currentRouteName !== 'login') {
      this.router.transitionTo('login');
    }
    // this.emit('logout', msg);
  }

  _onLink(msg) {
    debug.info('Link', msg);
    // this.emit('link', msg);
  }

  _onRegister(msg) {
    debug.info('Register', msg);
    // this.emit('register', msg);
  }

  _onRefresh(msg) {
    debug.info('Refresh', msg);
    // this.emit('refresh', msg);
  }
}
