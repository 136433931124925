// roundToHalfSeconds
export default function (seconds) {
  let modulo = seconds % 1;
  let digits = modulo - 0.5;

  if (digits >= 0) {
    seconds -= digits;
  } else {
    seconds -= modulo;
  }
  return seconds;
}
