import Service from './video-share';
import { action } from '@ember/object';

export default class VideoVideoUploadService extends Service {
  // upload videoclip to server by a fixed property (like nas) or if you leave it blank by the standard for this platform
  @action async uploadVideoclip(
    clip,
    property = null,
    options = { save: true }
  ) {
    const file = clip[property] || clip[this.addonCore.platformname];
    const uploadPromise = this.taskmanager.startMultipartUpload({
      file: file,
      taskName: this.intl.t('Videoupload'),
      videoclipid: clip.id,
    });

    let upload = await uploadPromise;

    if (upload.weburl) {
      clip.set('web', upload.weburl);
    }

    if (options.save) {
      await clip.save();
    }
  }
}
