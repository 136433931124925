import Service from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { validate } from 'fast-xml-parser';
import { DateTime } from 'luxon';
import { restartableTask, timeout } from 'ember-concurrency';
import { Share } from '@capacitor/share';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { A } from '@ember/array';
import write_blob from 'capacitor-blob-writer';

export default class DatenService extends Service {
  @service store;
  @service router;
  @service addonCore;
  @service ordner;
  @service video;
  @service intl;
  @service paperToaster;
  @service taskmanager;

  @tracked aktuellerOrdner;
  @tracked toDuplicate;
  @tracked isDuplication = false;
  @tracked videoclips;
  @tracked addresses = [];

  @tracked refreshBibItemsAfterImportToggle = false;

  @action async export(type, modelElement) {
    if (type) {
      switch (type) {
        case 'xml':
          await this.exportToXML(modelElement);
          break;
        case 'vaa':
          await this.exportToVAA(modelElement);
          break;
        case 'vas':
          this.exportToVAS(modelElement);
          break;
        case 'schemaXML':
          this.exportSchemaToXML();
          break;
        case 'vat':
          this.exportToVAT(modelElement);
          break;
        case 'vab':
          this.exportToVAB(modelElement);
          break;
        case 'vac':
          this.exportToVAC(modelElement);
          break;
        default:
          console.log('kein Exporttyp übergeben!');
          break;
      }
    } else {
      console.log('kein Exporttyp übergeben!');
    }
    return;
  }

  @action async import(name, file, ordner) {
    if (name) {
      this.addonCore.isLoading = true;
      name = name.split('.');

      name = name.splice(-1, 1).join();
      let importObject;
      let json;
      this.ordner.aktuellerOrdner = ordner;
      switch (name) {
        case 'xml':
          this.addonCore.setText(this.intl.t('Lade XML'));
          importObject = await this.importXML(file);
          break;
        case 'vaa':
          this.addonCore.setText(this.intl.t('Lade Analyse'));
          json = JSON.parse(file);
          importObject = await this.importVAA(json);
          break;
        case 'vas':
          this.addonCore.setText(this.intl.t('Lade Schema'));
          json = JSON.parse(file);
          if (json.data && typeof json.data == 'string') {
            json = JSON.parse(json.data);
          }
          importObject = await this.importVAS(json);
          break;
        case 'csv':
          this.addonCore.setText(this.intl.t('Lade CSV'));
          importObject = await this.importCSV(file);
          break;
        case 'vat':
          this.addonCore.setText(this.intl.t('Lade Team'));
          importObject = await this.importVAT(file);
          break;
        case 'vab':
          this.addonCore.setText(this.intl.t('Lade Board'));
          importObject = await this.importVAB(file);
          break;
        case 'vac':
          this.addonCore.setText(this.intl.t('Lade Collection'));
          json = JSON.parse(file);
          importObject = await this.importVAC(json);
          break;
        default:
          console.log('kein Importtyp übergeben!');
          break;
      }
      this.addonCore.isLoading = false;
      this.addonCore.setText('');

      return importObject;
    } else {
      console.log('kein Importtyp übergeben!');
    }
  }

  async liveImport(name, file, folder) {
    if (name) {
      this.addonCore.isLoading = true;
      name = name.split('.');

      name = name[name.length - 1];
      let suffix = name.split('.');
      suffix = suffix[suffix.length - 1];
      let scenelist = [];
      switch (suffix) {
        case 'xml':
          scenelist = await this.importXmlLive(file);
          break;

        case 'csv':
          scenelist = await this.importCsvLive(file);
          break;

        case 'vaa':
          scenelist = await this.importVaaLive(JSON.parse(file));
          break;

        default:
          break;
      }
      this.addonCore.isLoading = false;
      this.addonCore.setText('');
      return scenelist;
    } else {
      return { error: true };
    }
  }

  //************* DUPLICATION **********************//

  @action async duplicate(analyse) {
    this.addonCore.setText(this.intl.t('Dupliziere Analyse'));
    this.addonCore.isLoading = true;
    this.isDuplication = true;
    await this.exportToVAA(analyse);
    this.toDuplicate = JSON.parse(this.toDuplicate);
    let videoanalyse = await this.importVAA(this.toDuplicate);
    this.router.transitionTo('user.bibliothek.analyse-mobile', videoanalyse.id);
    this.isDuplication = false;
    this.videoclips = null;
  }

  @action async schemaDuplizieren(schema, name) {
    this.addonCore.setText(this.intl.t('Dupliziere Schema'));
    this.addonCore.isLoading = true;
    this.isDuplication = true;
    await this.exportToVAS(schema);
    this.toDuplicate = JSON.parse(this.toDuplicate);
    let schema1 = await this.importVAS(this.toDuplicate, name);
    this.router.transitionTo('user.bibliothek.schema', schema1.id);
    this.isDuplication = false;
  }

  //************* IMPORTER **********************//

  async importVaaLive(json) {
    let scenelist = [];
    for (let scene of json.videoszenen) {
      let vscene = this.store.createRecord('videoszene', {
        endzeit: scene.endzeit,
        farbe: scene.farbe,
        kommentar: scene.kommentar,
        startzeit: scene.startzeit,
        tags: scene.tags,
        text: scene.text,
        untertext: scene.untertext,
      });
      scenelist.push(vscene);
    }
    return scenelist;
  }

  @action async importVAA(jsonData, customName = '') {
    try {
      /* Import Analyse */
      this.addonCore.isLoading = true;

      let analyseName;
      if (jsonData.analyse) {
        if (jsonData.analyse.name) {
          analyseName = jsonData.analyse.name;
        } else {
          analyseName = 'VAA-Import';
        }
        if (this.isDuplication) {
          analyseName = analyseName + ' ' + this.intl.t('(Duplikat)');
        } else if (customName) {
          analyseName = analyseName + ' ' + customName;
        } else {
          analyseName = analyseName + ' ' + this.intl.t('(Import)');
        }
        this.addonCore.setText(this.intl.t('Lade Metadaten'));
      } else {
        throw new Error('File does not contain an analysis');
      }

      /* CREATE ANALYSE */
      let analyse = this.store.createRecord('videobearbeitung', {
        name: analyseName,
        datum: jsonData.analyse.datum,
        createDate: jsonData.analyse.createDate,
        notizen: jsonData.analyse.notizen,
        ordner: this.ordner.aktuellerOrdner,
        liveTagging: jsonData.analyse.liveTagging || false,
        analysetyp: jsonData.analyse.analysetyp || 'standard',
      });
      let playlists = [];
      let playlistsAfter = [];

      /* PLAYLISTEN */
      this.addonCore.setText(this.intl.t('Lade Playlisten'));
      if (jsonData.playlists) {
        for (let playlist of jsonData.playlists) {
          let pliste = await this.store.createRecord('playlist', {
            name: playlist.playlist.name,
            videobearbeitung: analyse,
            zugangscode: playlist.playlist.zugangscode,
          });
          pliste.set('oldID', playlist.playlist.plistid);
          analyse.get('playlists').pushObject(pliste);
          playlists.push(pliste);
        }
      }

      try {
        await Promise.all(
          playlists.map((playlist) => {
            return playlist.save();
          })
        );
      } catch (error) {
        console.log('import-error-playlist', error);
      }

      /* VIDEOCLIPS */
      this.addonCore.setText(this.intl.t('Lade Clips'));

      if (jsonData.videoclips) {
        let vszenen = A([]);
        if (jsonData.videoszenen) {
          vszenen = jsonData.videoszenen;
          let clipArray = A([]);
          let sceneArray = A([]);
          let psceneArray = A([]);

          for (let clip of jsonData.videoclips) {
            let vclip = this.store.createRecord('videoclip', {
              android: clip.android,
              desktop: clip.desktop,
              ios: clip.ios,
              nas: clip.nas,
              web: clip.web,
              position: clip.position,
              videobearbeitung: analyse,
              duration: clip.duration,
              name: clip.name || '',
            });
            analyse.get('videoclips').pushObject(vclip);
            for (let vszene of vszenen) {
              let szene;
              if (vszene.videoclip == clip.id) {
                szene = this.store.createRecord('videoszene', {
                  endzeit: vszene.endzeit,
                  farbe: vszene.farbe,
                  kommentar: vszene.kommentar,
                  schemaelementid: vszene.schemaelementid,
                  schemaid: vszene.schemaid,
                  startzeit: vszene.startzeit,
                  stern: vszene.stern,
                  tags: vszene.tags,
                  text: vszene.text,
                  untertext: vszene.untertext,
                  videobearbeitung: analyse,
                  videoclip: vclip,
                });

                this.addonCore.setText(this.intl.t('Lade Spielszenen'));

                analyse.get('videoszenen').pushObject(szene);
                vclip.get('videoszenen').pushObject(szene);
                if (jsonData.playlists) {
                  for (let plist of jsonData.playlists) {
                    for (let pszene of plist.plistszenen) {
                      if (pszene.videoszene == vszene.id) {
                        let correctPlaylist;
                        playlists = await analyse.get('playlists');
                        for (let plist1 of playlists.toArray()) {
                          if (plist1.get('oldID') == pszene.playlistID) {
                            correctPlaylist = plist1;
                          }
                        }
                        let plistszene = this.store.createRecord(
                          'playlistszene',
                          {
                            geteiltevideoszene: pszene.geteiltevideoszene,
                            layer: pszene.layer,
                            playlist: correctPlaylist,
                            position: pszene.position,
                            videoszene: szene,
                            web: pszene.web,
                          }
                        );
                        psceneArray.pushObject(plistszene);
                        correctPlaylist
                          .get('playlistszenen')
                          .pushObject(plistszene);
                        playlistsAfter.pushObject(correctPlaylist);
                        szene.get('playlistszenen').pushObject(plistszene);
                      }
                    }
                  }
                }
                sceneArray.pushObject(szene);
              }
            }
            clipArray.pushObject(vclip);
          }

          try {
            await Promise.all(
              clipArray.uniqBy('id').map((clip) => {
                return clip.save();
              })
            );
          } catch (error) {
            console.log('import-error-clips', error);
          }

          try {
            await Promise.all(
              sceneArray.uniqBy('id').map((scene) => {
                return scene.save();
              })
            );
          } catch (error) {
            console.log('import-error-scenes', error);
          }

          try {
            await Promise.all(
              playlistsAfter.uniqBy('id').map((playlist) => {
                return playlist.save();
              })
            );
          } catch (error) {
            console.log('import-error-playlist-after', error);
          }

          try {
            await Promise.all(
              psceneArray.uniqBy('id').map((pscene) => {
                return pscene.save();
              })
            );
          } catch (error) {
            console.log('import-error-playlistscenes', error);
          }

          await analyse.save();
          this.refreshBibItemsAfterImportToggle =
            !this.refreshBibItemsAfterImportToggle;
          this.addonCore.isLoading = false;
        }
      }
      return analyse;
    } catch (e) {
      console.log(e);
    }
  }

  @action async importVAS(json, name) {
    return new Promise(async (resolve) => {
      try {
        if (json.schema) {
          json.schema.id = null;
          json.schema.rev = null;
          if (name) {
            json.schema.name = name;
          }
          if (this.ordner.aktuellerOrdner) {
            json.schema.ordner = this.ordner.aktuellerOrdner;
          } else {
            json.schema.ordner = null;
          }
          this.addonCore.setText(this.intl.t('Lade Metadaten'));
          let schema = await this.store
            .createRecord('schema', json.schema)
            .save();

          this.addonCore.setText(this.intl.t('Lade Ereignisse'));

          if (json.handlungen && json.handlungen.length > 0) {
            for (let jsonHandlung of json.handlungen) {
              let handlung = Object.assign(jsonHandlung, {
                id: '',
                schema: '',
                revision: '',
              });
              delete handlung.id;
              delete handlung.schema;
              delete handlung.revision;
              delete handlung.rev;
              console.log('handlung', handlung);
              let handlungRecord = this.store.createRecord(
                'handlung',
                handlung
              );
              schema.get('handlungen').pushObject(handlungRecord);
              await handlungRecord.save();
              // promises.push(tmp);
            }
          }

          this.addonCore.setText(this.intl.t('Lade Label'));
          if (json.tags && json.tags.length > 0) {
            for (let jsonLabel of json.tags) {
              let label = Object.assign(jsonLabel, {
                id: '',
                schema: '',
                revision: '',
              });
              delete label.id;
              delete label.schema;
              delete label.revision;
              delete label.rev;
              let labelRecord = this.store.createRecord('tag', label);
              schema.get('tags').pushObject(labelRecord);
              await labelRecord.save();
            }
          }

          await schema.save();
          this.refreshBibItemsAfterImportToggle =
            !this.refreshBibItemsAfterImportToggle;
          return resolve(schema);
        } else {
          throw 'err';
        }
      } catch (err) {
        console.log('Fehler bei fileImport()', err);
      }
    });
  }

  @action async importVAB(json) {
    return new Promise(async (resolve) => {
      try {
        if (json.board) {
          this.addonCore.setText(this.intl.t('Lade Metadaten'));
          json.board.id = null;
          json.board.rev = null;
          if (this.aktuvellerOrdner) {
            json.board.ordner = this.aktuellerOrdner;
          } else {
            json.board.ordner = null;
          }
          this.addonCore.setText(this.intl.t('Lade Board'));
          let board = await this.store
            .createRecord('board', {
              name: json.board.name,
              notes: json.board.notes,
              notizen: json.board.notizen,
              board_background_path: json.board.board_background_path,
              datum: json.board.datum,
              ordner: json.board.ordner,
              layer: json.board.layer,
            })
            .save();

          this.refreshBibItemsAfterImportToggle =
            !this.refreshBibItemsAfterImportToggle;
          return resolve(board);
        }
      } catch (error) {
        console.log('oh no! Error beim import eines boards!', error);
      }
    });
  }

  @action async importVAT(json) {
    return new Promise(async (resolve) => {
      try {
        if (json.team) {
          this.addonCore.setText(this.intl.t('Lade Metadaten'));
          json.team.id = null;
          json.team.rev = null;
          if (this.aktuellerOrdner) {
            json.team.ordner = this.aktuellerOrdner;
          } else {
            json.team.ordner = null;
          }
          let team = await this.store
            .createRecord('team', {
              name: json.team.name,
              notizen: json.team.notizen,
              ordner: json.team.ordner,
            })
            .save();
          this.addonCore.setText(this.intl.t('Lade Teammitglieder'));
          if (json.teammembers && json.teammembers.length > 0) {
            for (let member of json.teammembers) {
              member.rev = null;
              member.id = null;
              member.teams = null;
              let tmp = await this.store
                .createRecord('teamplayer', {
                  nachname: member.nachname,
                  vorname: member.vorname,
                  mail: member.mail,
                })
                .save();
              tmp.get('teams').pushObject(team);
              team.get('teammembers').pushObject(tmp);
              await tmp.save();
            }
            await team.save();
            this.refreshBibItemsAfterImportToggle =
              !this.refreshBibItemsAfterImportToggle;
            return resolve(team);
          }
        }
      } catch (error) {
        console.log('importfehler VAT', error);
      }
    });
  }

  @action async importVAC(jsonData) {
    return new Promise(async (resolve) => {
      try {
        this.addonCore.setText(this.intl.t('Lade Metadaten'));
        let collection;
        if (jsonData.collection) {
          collection = await this.store
            .createRecord('videocollection', {
              name: jsonData.collection.name,
              datum: jsonData.collection.datum,
              notizen: jsonData.collection.notizen,
              ordner: this.aktuellerOrdner,
              isOnline: true,
            })
            .save();
          this.addonCore.setText(this.intl.t('Lade Playlisten'));
          if (jsonData.playlists.length > 0) {
            for (let playlist of jsonData.playlists) {
              let plist = await this.store.createRecord('playlist', {
                videocollection: collection,
                zugangscode: playlist.playlist.zugangscode || null,
                name: playlist.playlist.name,
              });
              collection.get('playlists').pushObject(plist);
              console.log('plist', plist);
              await plist.save();
              this.addonCore.setText(this.intl.t('Lade Spielszenen'));
              for (let pszene of playlist.pszenen) {
                let szene = await this.store.createRecord('playlistszene', {
                  position: pszene.position,
                  geteiltevideoszene: pszene.geteiltevideoszene,
                  web: pszene.web,
                  layer: pszene.layer,
                  playlist: plist,
                });
                await szene.save();
                plist.get('playlistszenen').pushObject(szene);
                await plist.save();
                await szene.save();
              }
            }
          }
          await collection.save();
          this.refreshBibItemsAfterImportToggle =
            !this.refreshBibItemsAfterImportToggle;
          return resolve(collection);
        }
      } catch (error) {
        console.log('something went wrong while importing Collection', error);
      }
    });
  }

  rGBToHex(r, g, b) {
    console.log('rgb2hex color');

    var red = parseInt(r, 10).toString(16).slice(-2);
    if (red.length == 1) red = '0' + red;
    var green = parseInt(g, 10).toString(16).slice(-2);
    if (green.length == 1) green = '0' + green;
    var blue = parseInt(b, 10).toString(16).slice(-2);
    if (blue.length == 1) blue = '0' + blue;
    return '#' + red + green + blue;
  }

  async stringReplacer(file) {
    console.log('stringReplacer');
    return file.replace(/_/g, '');
  }

  stringReplacer2(file) {
    console.log('replacer2');
    return file.replace(/<[f,l,a,g]{4,4}>/g, '');
  }

  @restartableTask *saveAddSzeneToClip(clip) {
    yield timeout(250);
    yield clip.save();
  }

  async importXmlLive(file) {
    return new Promise(async (resolve, reject) => {
      let validation = validate(file);
      if (validation != true) {
        return resolve(validation);
      } else {
        let parsedDoc = await this.parseXML(file);
        let xmlDoc = parsedDoc.xmlDoc;
        let instances = parsedDoc.instances;
        let rows = xmlDoc.getElementsByTagName('row');
        let codes = await this.generateColors(rows);

        let promises = [];
        this.addonCore.setText(this.intl.t('Lade Spielszenen'));
        for (let instance of instances) {
          let name = instance.getElementsByTagName('code')[0].innerHTML;
          let color = codes[name];
          let start = parseFloat(
            instance.getElementsByTagName('start')[0].innerHTML
          );
          let end = parseFloat(
            instance.getElementsByTagName('end')[0].innerHTML
          );
          if (start == end || end <= start) {
            end += 1;
          }
          let tags = [];
          if (
            instance.getElementsByTagName('label').length > 0 ||
            instance.getElementsByTagName('lable').length > 0
          ) {
            let query;
            if (instance.getElementsByTagName('label').length > 0) {
              query = 'label';
            } else {
              query = 'lable';
            }
            for (var label of instance.getElementsByTagName(String(query))) {
              let tag;
              let text = label.getElementsByTagName('text')[0].innerHTML;
              let farbeText = '#000000';
              let farbe = codes[text];
              tag = {
                name: text,
                farbeText: farbeText,
                farbe: farbe,
              };
              tags.push(tag);
            }
          }
          let videoszene = this.store.createRecord('videoszene', {
            startzeit: start,
            endzeit: end,
            name: name,
            text: name,
            tags: tags,
            farbe: color || '#000000',
          });

          promises.push(videoszene);
          // clip.get('videoszenen').pushObject(videoszene);
          // this.saveAddSzeneToClip.perform(clip);
        }
        return resolve(promises);
      }
    });
  }

  async parseXML(file) {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(file, 'text/xml');
    let instances = xmlDoc.getElementsByTagName('instance');

    return { xmlDoc: xmlDoc, instances: instances };
  }

  async generateColors(rows) {
    let codes = {};
    for (let code of rows) {
      let key = code.getElementsByTagName('code')[0].innerHTML;
      let r = code.getElementsByTagName('R')[0].innerHTML;
      let g = code.getElementsByTagName('G')[0].innerHTML;
      let b = code.getElementsByTagName('B')[0].innerHTML;
      r = +r;
      g = +g;
      b = +b;
      if (r > 255) {
        r = r / 100;
      }
      if (g > 255) {
        g = g / 100;
      }
      if (b > 255) {
        b = b / 100;
      }
      codes[key] = this.rGBToHex(parseInt(r), parseInt(g), parseInt(b));
    }
    return codes;
  }

  @action async importXML(file) {
    return new Promise(async (resolve, reject) => {
      this.addonCore.setText(this.intl.t('Lade Metadaten'));

      let validation = validate(file);
      if (validation != true) {
        return resolve(validation);
      } else {
        file = await this.stringReplacer2(file);
        file = await this.stringReplacer(file);

        let parsedDoc = await this.parseXML(file);
        let xmlDoc = parsedDoc.xmlDoc;
        let instances = parsedDoc.instances;
        let analysename;
        let datum;
        let nextClip = 7200;

        if (xmlDoc.getElementsByTagName('start_time')[0]) {
          analysename = xmlDoc.getElementsByTagName('starttime')[0].innerHTML;
        } else {
          analysename = 'XML Import';
        }
        datum = new Date();

        let analyse = {
          name: analysename,
          datum: datum,
          ordner: this.ordner.aktuellerOrdner,
          liveTagging: true,
          analysetyp: 'livetagging',
        };
        analyse = await this.store
          .createRecord('videobearbeitung', analyse)
          .save();
        let clipData = {
          web: 'https://athlyzer.s3.eu-central-1.amazonaws.com/files/time.mp4',
          desktop: '/DOCUMENTS/time.mp4',
          ios: 'time.mp4',
          android: 'time.mp4',
          exists: true,
          videobearbeitung: analyse,
        };

        let clip = await this.store.createRecord('videoclip', clipData).save();

        analyse.get('videoclips').pushObject(clip);

        let rows = xmlDoc.getElementsByTagName('row');
        let codes = await this.generateColors(rows);
        let promises = [];
        this.addonCore.setText(this.intl.t('Lade Spielszenen'));
        for (let instance of instances) {
          let name = instance.getElementsByTagName('code')[0].innerHTML;
          let color = codes[name];
          let start = parseFloat(
            instance.getElementsByTagName('start')[0].innerHTML
          );
          let end = parseFloat(
            instance.getElementsByTagName('end')[0].innerHTML
          );

          if (start > nextClip) {
            clipData = {
              web: 'https://athlyzer.s3.eu-central-1.amazonaws.com/files/time.mp4',
              desktop: '/DOCUMENTS/time.mp4',
              ios: 'time.mp4',
              android: 'time.mp4',
              exists: true,
              videobearbeitung: analyse,
            };

            clip = await this.store.createRecord('videoclip', clipData).save();
            analyse.get('videoclips').pushObject(clip);
            nextClip = nextClip + 7200;
          }

          let tags = [];
          if (
            instance.getElementsByTagName('label').length > 0 ||
            instance.getElementsByTagName('lable').length > 0
          ) {
            let query;
            if (instance.getElementsByTagName('label').length > 0) {
              query = 'label';
            } else {
              query = 'lable';
            }
            for (var label of instance.getElementsByTagName(String(query))) {
              let tag;
              let text = label.getElementsByTagName('text')[0].innerHTML;
              let farbeText = '#000000';
              let farbe = codes[text];
              tag = {
                name: text,
                farbeText: farbeText,
                farbe: farbe,
              };
              tags.push(tag);
            }
          }
          let videoszene = this.store.createRecord('videoszene', {
            startzeit: start,
            endzeit: end,
            name: name,
            text: name,
            tags: tags,
            farbe: color || '#000000',
            videobearbeitung: analyse,
          });

          promises.push(videoszene);
          clip.get('videoszenen').pushObject(videoszene);
          this.saveAddSzeneToClip.perform(clip);
        }

        let result = await this.store.adapterFor('application').updateRecords(
          this.store,
          this.store.modelFor('videoszene'),
          promises.map((record) => {
            return record._createSnapshot();
          })
        );
        this.store.pushPayload('videoszene', result);

        let newRecords = [];
        for (let index = 0; index < promises.length; index++) {
          let record = promises[index];
          newRecords.push(
            this.store.peekRecord('videoszene', result.videoszenes[index].id)
          );
        }
        analyse.set(
          'videoszenen',
          newRecords.filter((szene) => {
            return szene && szene.get('id') ? true : false;
          })
        );
        //analyse.set("videoszenen",newRecords);
        await analyse.save();
        return resolve(analyse);
      }
    });
  }

  async importCsvLive(file) {
    return new Promise(async (resolve) => {
      let scenelist = [];
      let tags = [];
      var allRows = file.split(/\r?\n|\r/);
      allRows.shift();
      for (var singleRow of allRows) {
        var cell = singleRow.split(/,|;/);
        if (cell[0] != '') {
          let label = {
            name: cell[3],
            farbe: '#000000',
            farbetext: '#ffffff',
          };
          tags.push(label);
          this.addonCore.setText(this.intl.t('Lade Spielszenen'));
          let start = parseInt(cell[1]) / 1000;
          let end = cell[1] / 1000 + cell[2] / 1000;
          console.log('start, dur, end', start, cell[2], end);
          let videoszene = await this.store.createRecord('videoszene', {
            startzeit: start,
            endzeit: end,
            name: cell[0],
            text: cell[0],
            farbe: '#000000',
            tags: tags,
          });
          scenelist.push(videoszene);
          tags = [];
        }
      }
      resolve(scenelist);
    });
  }

  @action async importCSV(file) {
    return new Promise(async (resolve) => {
      this.addonCore.setText(this.intl.t('Lade Metadaten'));
      let analyse = await this.store.createRecord('videobearbeitung', {
        name: 'csv-Import',
        datum: new Date(),
        ordner: this.ordner.aktuellerOrdner,
      });
      let nextClip = 7200;

      let clipData = {
        web: 'https://athlyzer.s3.eu-central-1.amazonaws.com/files/time.mp4',
        desktop: '/DOCUMENTS/time.mp4',
        ios: 'time.mp4',
        android: 'time.mp4',
        exists: true,
        videobearbeitung: analyse,
      };

      let clip = await this.store.createRecord('videoclip', clipData).save();

      analyse.get('videoclips').pushObject(clip);
      let videoszenen = [];
      let tags = [];
      analyse.save();
      var allRows = file.split(/\r?\n|\r/);
      allRows.shift();
      for (var singleRow of allRows) {
        var cell = singleRow.split(';');
        if (cell[0] != '') {
          let label = {
            name: cell[3],
            farbe: '#000000',
            farbetext: '#ffffff',
          };
          tags.push(label);
          this.addonCore.setText(this.intl.t('Lade Spielszenen'));
          let start = parseInt(cell[1]) / 1000;
          let end = cell[1] / 1000 + cell[2] / 1000;
          if (start > nextClip) {
            clipData = {
              web: 'https://athlyzer.s3.eu-central-1.amazonaws.com/files/time.mp4',
              desktop: '/DOCUMENTS/time.mp4',
              ios: 'time.mp4',
              android: 'time.mp4',
              exists: true,
              videobearbeitung: analyse,
            };

            clip = await this.store.createRecord('videoclip', clipData).save();
            analyse.get('videoclips').pushObject(clip);
            nextClip = nextClip + 7200;
          }
          let videoszene = await this.store.createRecord('videoszene', {
            startzeit: start,
            endzeit: end,
            name: cell[0],
            text: cell[0],
            farbe: '#000000',
            videobearbeitung: analyse,
            tags: tags,
          });
          tags = [];
          analyse.get('videoszenen').pushObject(videoszene);
          clip.get('videoszenen').pushObject(videoszene);
          await videoszene.save();
          this.saveAddSzeneToClip.perform(clip);
          videoszenen.push(videoszene);
        }
      }
      await analyse.save();
      return resolve(analyse);
    });
  }

  importMails(file) {
    let addresses = [];
    var allRows = file.split(/\r?\n|\r/);
    for (let singleRow of allRows) {
      var cells = singleRow.split(';');
      for (let cell of cells) {
        if (cell.match(/([A-z])+@+([A-z])+.([A-z])/g)) {
          addresses.push(cell.replace(/,/g, ''));
        }
      }
    }
    this.addresses = addresses;
    return this.addresses;
  }

  //************* EXPORTER **********************//

  @action async multiExport(selectedItems, exportType) {
    console.log('selectedItems', selectedItems);
    let exportArray = [];
    for (var exportObject of selectedItems) {
      if (exportObject.typ == 'schema') {
        exportObject = await this.exportToVAS(exportObject, true);
      } else if (
        exportObject.typ == 'videobearbeitung' &&
        exportType == 'xml'
      ) {
        exportObject = await this.exportToXML(exportObject, true);
      } else if (
        exportObject.typ == 'videobearbeitung' &&
        exportType == 'vaa'
      ) {
        exportObject = await this.exportToVAA(exportObject, true);
      } else if (exportObject.typ == 'team') {
        exportObject = await this.exportToVAT(exportObject, true);
      } else if (exportObject.typ == 'board') {
        exportObject = await this.exportToVAB(exportObject, true);
      } else if (exportObject.typ == 'videocollection') {
        exportObject = await this.exportToVAC(exportObject, true);
      }
      exportArray.push(exportObject);
      // console.log("exportArray", exportObject.data);
    }

    const jszip = await import('./jszip');
    console.log('jszip', jszip);
    var zipper = new jszip.default();
    for (let exporter of exportArray) {
      zipper.file(exporter.name, exporter.data);
    }
    let tmp = await zipper.generateAsync({ type: 'base64' });
    let dataUri = 'data:application/zip;base64,' + tmp;
    let exportFileDefaultName = 'export.zip';
    this.datauriToDownload(dataUri, exportFileDefaultName);
  }

  @action async datauriToDownload(datauri, filename) {
    if (this.addonCore.isApp) {
      console.log('datauri', datauri);
      const writeResponse = await Filesystem.writeFile({
        path: filename,
        data: datauri,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });
      console.log('write', writeResponse);
      await Share.share({
        title: 'Export',
        text: 'Export',
        url: writeResponse.uri,
      });
    } else {
      let linkElement = document.createElement('a');
      linkElement.setAttribute('href', datauri);
      linkElement.setAttribute('download', filename);
      linkElement.click();
    }
    return;
  }

  @action async exportDownLoadVAS(file, exportObject) {
    console.log('exportDOwnloadVAS');
    let dataUri =
      'data:application/json;charset=utf-8,' + encodeURIComponent(file);
    let exportFileDefaultName = exportObject.name + '.vas';
    await this.datauriToDownload(dataUri, exportFileDefaultName);
    return;
  }

  @action async exportDownLoadXML(file, exportObject) {
    let dataUri =
      'data:application/json;charset=utf-8,' + encodeURIComponent(file);
    let exportFileDefaultName = exportObject.name + '.xml';
    await this.datauriToDownload(dataUri, exportFileDefaultName);
  }

  @action async exportDownLoadVAA(file, exportObject) {
    let dataUri =
      'data:application/json;charset=utf-8,' + encodeURIComponent(file);
    let exportFileDefaultName = exportObject.name + '.vaa';
    await this.datauriToDownload(dataUri, exportFileDefaultName);
  }

  @action async exportDownLoadVAT(file, exportObject) {
    let dataUri =
      'data:application/json;charset=utf-8,' + encodeURIComponent(file);
    let exportFileDefaultName = exportObject.name + '.vat';
    await this.datauriToDownload(dataUri, exportFileDefaultName);
  }

  @action async exportDownLoadVAB(file, exportObject) {
    let dataUri =
      'data:application/json;charset=utf-8,' + encodeURIComponent(file);
    let exportFileDefaultName = exportObject.name + '.vab';
    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
    console.log('objekt exportiert');
  }

  @action async exportDownLoadVAC(file, exportObject) {
    let dataUri =
      'data:application/json;charset=utf-8,' + encodeURIComponent(file);
    let exportFileDefaultName = exportObject.name + '.vac';
    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
    console.log('objekt exportiert');
  }

  @action async exportToVAS(schema, multiExport) {
    this.addonCore.setText(this.intl.t('Bereite Export des Schemas vor'));
    this.addonCore.isLoading = true;
    let jsonData = await this.schemaExportVorbereiten(schema);
    this.addonCore.isLoading = false;
    this.addonCore.setText('');
    let dataStr = JSON.stringify(jsonData);
    console.log('schemaExportVorbereiten 2', dataStr);
    if (this.isDuplication) {
      this.toDuplicate = dataStr;
    } else {
      if (multiExport) {
        return {
          data: dataStr,
          name: schema.name + '.vas',
        };
      } else {
        await this.exportDownLoadVAS(dataStr, schema);
      }
    }
  }

  @action async schemaExportVorbereiten(exportSchema) {
    let schema = await this.store.findRecord('schema', exportSchema.id);
    const handlungen = await schema.get('handlungen');
    const tags = await schema.get('tags');
    let schema1 = schema.serialize({ includeId: false });
    delete schema1.handlungen;
    delete schema1.tags;
    delete schema1.rev;
    let ordner = schema.get('parent') ? schema.get('parent.id') : null;

    let serializedHandlungen = await Promise.all(
      handlungen.map(async (tmp) => {
        const automatedCollections = (await tmp.automatedCollections).map(
          (collection) => {
            return { id: collection.id, name: collection.name };
          }
        );

        tmp = tmp.serialize({ includeId: false });

        delete tmp.automatedCollectionPlaylistMap;
        delete tmp.automatedCollections;
        // tmp.automatedCollections = automatedCollections;
        delete tmp.rev;

        return tmp;
      })
    );

    let serializedTags = await Promise.all(
      tags.map(async (tmp) => {
        const automatedCollections = (await tmp.automatedCollections).map(
          (collection) => {
            return { id: collection.id, name: collection.name };
          }
        );

        tmp = tmp.serialize({ includeId: false });

        delete tmp.automatedCollectionPlaylistMap;
        delete tmp.automatedCollections;
        // tmp.automatedCollections = automatedCollections;
        delete tmp.rev;

        return tmp;
      })
    );

    let jsonData = {
      typ: 'schema',
      schema: schema1,
      ordneralt: ordner,
      handlungen: serializedHandlungen,
      tags: serializedTags,
    };

    console.log('schemaExportVorbereiten', jsonData);
    return jsonData;
  }

  @action async exportToVAA(analyse, multiExport) {
    this.addonCore.isLoading = true;
    if (this.isDuplication) {
      this.addonCore.setText(this.intl.t('Dupliziere'));
    } else {
      this.addonCore.setText(this.intl.t('Bereite Analyse für  Export vor'));
    }

    let jsonData = await this.analyseExportVorbereiten(analyse);

    if (!this.isDuplication) {
      this.addonCore.isLoading = false;
      this.addonCore.setText('');
    }

    if (this.isDuplication) {
      this.toDuplicate = JSON.stringify(jsonData);
      //return JSON.stringfy(jsonData);
    } else {
      let dataStr = JSON.stringify(jsonData);
      if (multiExport) {
        return {
          data: dataStr,
          name: analyse.name + '.vaa',
        };
      } else {
        await this.exportDownLoadVAA(dataStr, analyse);
      }
    }
  }

  @action async exportVATVorbereiten(exportTeam) {
    let team = await this.store.findRecord('team', exportTeam.get('id'));
    let teammembers = await team.get('teammembers');
    let team1 = team.serialize({ includeId: false });
    delete team1.rev;
    let ordner = team.get('parent') ? team.get('parent.id') : null;
    let jsonData = {
      typ: 'team',
      team: team1,
      ordneralt: ordner,
      teammembers: teammembers.map((tmp) => {
        tmp = tmp.serialize({ includeId: false });
        delete tmp.rev;
        return tmp;
      }),
    };
    return jsonData;
  }

  @action async exportToVAT(exportTeam, multiExport) {
    this.addonCore.isLoading = true;
    this.addonCore.setText(this.intl.t('Bereite Team für Export vor'));
    let team = await this.exportVATVorbereiten(exportTeam);
    this.addonCore.isLoading = false;
    this.addonCore.setText('');

    let dataStr = JSON.stringify(team);
    if (multiExport) {
      return {
        data: dataStr,
        name: team.name + '.vat',
      };
    } else {
      await this.exportDownLoadVAT(dataStr, team);
    }
  }

  @action async exportVABVorbereiten(exportBoard) {
    let board = await this.store.findRecord('board', exportBoard.get('id'));

    let board1 = board.serialize({ includeId: false });
    console.log('board1', board1);
    delete board1.rev;
    delete board1.id;
    delete board1._id;
    delete board1._rev;
    let ordner = board.get('parent') ? board.get('parent.id') : null;
    let jsonData = {
      typ: 'board',
      board: board1,
      ordneralt: ordner,
    };
    return jsonData;
  }

  @action async exportToVAB(exportBoard, multiExport) {
    this.addonCore.isLoading = true;
    this.addonCore.setText(this.intl.t('Bereite Board für Export vor'));
    let board = await this.exportVABVorbereiten(exportBoard);
    this.addonCore.isLoading = false;
    this.addonCore.setText('');

    let dataStr = JSON.stringify(board);
    if (multiExport) {
      return {
        data: dataStr,
        name: board.name + '.vab',
      };
    } else {
      await this.exportDownLoadVAB(dataStr, board);
    }
  }

  @action async exportVACVorbereiten(exportCollection) {
    console.log('exportCollection', exportCollection);
    let collection = await this.store.findRecord(
      'videocollection',
      exportCollection.get('id')
    );
    let plists = await collection.get('playlists');
    for (let plist23 of plists.toArray()) {
      let plistszenen = await plist23.get('playlistszenen');
      for (let plistszene of plistszenen.toArray()) {
        await plistszene.get('videoszene');
      }
    }
    collection.set('isOnline', true);
    let playlists = await collection.get('playlists');
    let collection1 = collection.serialize({ includeId: false });
    let plistArray = [];
    for (let playlist of playlists.toArray()) {
      let pszenenArray = [];
      let playszenen = await playlist.get('playlistszenen').toArray();
      let exSzenen = [];
      for (let exSzene of playszenen) {
        let web = await exSzene.get('web');
        if (!web) {
          exSzenen.push(exSzene);
        }
      }
      let exportierteSzenen = await this.video.cutSzenenInListe(exSzenen);
      for (let exportSzene of exportierteSzenen) {
        await exportSzene.save();
      }
      for (let szene of playszenen) {
        try {
          let websource = await szene.get('web');
          let collectionplaylistszene;
          if (websource) {
            collectionplaylistszene = szene.serialize({ includeId: false });
          } else {
            collectionplaylistszene = szene.serialize({ includeId: false });
            let serialisierteSzene = (await szene.get('videoszene')).serialize({
              includeId: false,
            });
            serialisierteSzene.textgesamt = szene
              .get('videoszene')
              .get('textgesamt');
            collectionplaylistszene.geteiltevideoszene = serialisierteSzene;
            if (
              !collectionplaylistszene.web ||
              collectionplaylistszene.isVideochange
            ) {
              let response = await this.taskmanager.startMultipartUpload(
                await szene.get('videoszene').get('videourl'),
                this.intl.t('Upload Collection Playlistszene')
              );
              if (response.weburl) {
                collectionplaylistszene.web = response.weburl;
              }
            }
          }
          pszenenArray.push(collectionplaylistszene);
          szene.set('web', collectionplaylistszene.web);
          await szene.save();
        } catch (e) {
          console.log('e6325123', e);
          this.paperToaster.show(
            this.intl.t('upload Fehler Video '),
            szene.textgesamt
          );
        }
      }
      playlist = playlist.serialize({ includeId: false });
      playlist.zugangscode = null;
      let plistObject = {
        playlist: playlist,
        pszenen: pszenenArray,
      };
      plistArray.push(plistObject);
    }
    let jsonData = {
      collection: collection1,
      playlists: plistArray,
      typ: 'videocollection',
      state: 'online',
    };
    console.log('addonCore', this.addonCore.isLoading);
    if (this.addonCore.isLoading) {
      this.addonCore.isLoading = false;
    }
    console.log('jsonDATA', jsonData);

    return jsonData;
  }

  @action async exportToVAC(exportCollection, multiExport) {
    this.addonCore.isLoading = true;
    this.addonCore.setText(this.intl.t('Bereite Collection für Export vor'));
    let collection = await this.exportVACVorbereiten(exportCollection);
    this.addonCore.isLoading = false;
    let dataStr = JSON.stringify(collection);
    if (multiExport) {
      return {
        data: dataStr,
        name: collection.name + '.vac',
      };
    } else {
      await this.exportDownLoadVAC(dataStr, collection);
    }
  }

  @action async analyseExportVorbereiten(exportAnalyse) {
    console.log('exportAnalyse', exportAnalyse);
    exportAnalyse = await this.store.findRecord(
      'videobearbeitung',
      exportAnalyse.get('id')
    );
    let analyse = exportAnalyse.serialize({ includeId: false });

    let videoclips = await exportAnalyse.get('videoclips');
    let playlists = await exportAnalyse.get('playlists');
    let videoszenen = await exportAnalyse.get('videoszenen');
    let vclipsArray = [];
    let plistArray = [];
    let vszenenArray = [];

    if (videoclips && videoclips.toArray().length > 0) {
      for (let clip of videoclips.toArray()) {
        let clipID = await clip.get('id');
        clip = clip.serialize({ includeId: false });
        delete clip.rev;

        clip.id = clipID;
        vclipsArray.push(clip);
      }
    }

    if (videoszenen && videoszenen.toArray().length > 0) {
      for (let szene of videoszenen.toArray()) {
        let szenenID = await szene.get('id');
        szene = szene.serialize({ includeId: false });
        szene.id = szenenID;
        vszenenArray.push(szene);
      }
    }

    if (playlists && playlists.toArray().length > 0) {
      for (let playlist of playlists.toArray()) {
        let pszenenArray = [];
        let playlistID = await playlist.get('id');
        let playlistszenen = await playlist.get('playlistszenen');
        console.log('playlistszenen', playlistszenen);
        let pliste = playlist.serialize({ includeId: false });
        pliste.zugangscode = null;
        pliste.plistid = playlistID;
        if (playlistszenen && playlistszenen.toArray().length > 0) {
          for (let pszene of playlistszenen.toArray()) {
            pszene = pszene.serialize({ includeId: false });
            pszene.playlistID = playlistID;
            pszenenArray.push(pszene);
          }
        }
        let plist = {
          playlist: pliste,
          plistszenen: pszenenArray,
        };
        plistArray.push(plist);
      }
    }

    let jsonData = {
      analyse: analyse,
      videoclips: vclipsArray,
      videoszenen: vszenenArray,
      playlists: plistArray,
      typ: 'analyse',
    };

    return jsonData;
  }

  @action async exportToXML(analyse, multiExport) {
    this.addonCore.isLoading = true;
    this.addonCore.setText(this.intl.t('Bereite Analyse für XML-Export vor'));
    let file =
      '<file><SESSION_INFO><start_time>' +
      // moment().format('YYYY-MM-DD HH:mm:ss')
      DateTime.fromISO(analyse.datum).toFormat('dd.LL.yyyy, HH:mm') +
      '</start_time></SESSION_INFO>';
    file += '<ALL_INSTANCES>';
    analyse = await this.store.find('videobearbeitung', analyse.id);
    await analyse.get('videoszenen');
    analyse.videoszenen.forEach((szene, index) => {
      file += '<instance>';
      file += '<ID>';
      file += index + 1;
      file += '</ID>';
      file += '<start>';
      file += szene.startzeit.toFixed(2);
      file += '</start>';
      file += '<end>';
      file += szene.endzeit.toFixed(2);
      file += '</end>';
      file += '<code>';
      file += szene.text;
      file += '</code>';
      if (szene.tags) {
        for (let tag of szene.tags) {
          file += '<label>';
          file += '<text>';
          file += tag.name;
          file += '</text>';
          file += '</label>';
        }
      }
      file += '</instance>';
    });
    file += '</ALL_INSTANCES>';
    file += '<ROWS>';
    if (analyse.codes) {
      for (var code in analyse.codes) {
        if (analyse.codes.hasOwnProperty(code)) {
          file += '<row>';
          file += '<code>';
          file += code;
          file += '</code>';
          file += '<R>';
          file += analyse.codes[code].r;
          file += '</R>';
          file += '<G>';
          file += analyse.codes[code].g;
          file += '</G>';
          file += '<B>';
          file += analyse.codes[code].b;
          file += '</B>';
          file += '</row>';
        }
      }
    }
    file += '</ROWS>';
    file += '</file>';
    this.addonCore.isLoading = false;
    if (this.isDuplication) {
      console.log('datastring', file);
      this.toDuplicate = file;
    } else {
      if (multiExport) {
        return {
          data: file,
          name: analyse.name + '.xml',
        };
      } else {
        await this.exportDownLoadXML(file, analyse);
      }
    }
  }
}
