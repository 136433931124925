//import EmberRouter from '@ember/routing/router';
import EmberRouter from '@embroider/router';

import config from './config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('share');
  this.route('session');
  this.route('user', function () {
    this.route('subscription', function () {
      this.route('index');
    });

    this.route('invite');

    this.route('bibliothek', { path: '/:dbname' }, function () {
      this.route('schema', { path: '/schema/:schemaid' });
      this.route('board', { path: '/board/:boardid' }, function () {
        this.route('edit', function () {});
        this.route('reload', { path: '/reload/:boardid' });
      });

      this.route('alte-analyse-konvertieren', {
        path: '/alte-analyse-konvertieren/:analyseid',
      });

      this.route('verwalten', function () {
        this.route('marketing');
      });
      this.route('import');
      this.route(
        'collections',
        { path: '/collections/:collectionid' },
        function () {
          this.route('bearbeiten', {
            path: '/bearbeiten/:analyseid',
          });
          this.route('veredeln');
          this.route('praesentieren');
        }
      );
      this.route('xmlImport', { path: '/import/:importid' });
      this.route('reload');
      this.route('capture');
      this.route(
        'analyse-mobile',
        { path: '/analyse-mobile/:analyseid' },
        function () {
          this.route('taggen', function () {
            this.route('overlay');
            this.route('portrait');
            this.route('chromecast');
            this.route('desktopsecondscreen');
            this.route('scenes-right');
            this.route('triple');
          });

          this.route('veredeln');
          this.route('praesentieren');
          this.route('videos');
          this.route('videos-tauschen');
          this.route('vorbereiten');
          this.route('schema');
          this.route('auswerten', function () {
            this.route('sankey');
            this.route('timeline');
          });
          this.route('ausrichten');
        }
      );
      this.route(
        'analyse-video-only',
        { path: '/analyse-video-only/:analyseid' },
        function () {
          this.route('videos-tauschen');
          this.route('share');
        }
      );
      this.route(
        'analyse-highlight',
        { path: '/analyse-highlight/:analyseid' },
        function () {
          this.route('taggen', function () {
            this.route('overlay');
            this.route('scenes-right');
            this.route('triple');
          });
          this.route('details');
          this.route('videos');
          this.route('videos-tauschen');
          this.route('veredeln');
          this.route('teams');
        }
      );
      this.route(
        'analyse-live',
        { path: '/analyse-live/:analyseid' },
        function () {
          this.route('schema');
          this.route('taggen');
          this.route('liste');
          this.route('ausrichten');
        }
      );

      this.route(
        'analyse-capture',
        { path: '/analyse-capture/:analyseid' },
        function () {
          this.route('schema');
          this.route('camera');
          this.route('taggen');
          this.route('videos');
        }
      );
      this.route(
        'analyse-stream',
        { path: '/analyse-stream/:analyseid' },
        function () {
          this.route('taggen');
          this.route('live');
          this.route('liste');
          this.route('videos');
          this.route('veredeln');
          this.route('praesentieren');
          this.route('videos');
          this.route('vorbereiten');
          this.route('schema');
        }
      );
      this.route('analyse-schema');
      this.route('team-manager', function () {
        this.route('teams');
        this.route('players');
        this.route('team', { path: '/team/:teamid' }, function () {
          this.route('feed');
          this.route('players');
          this.route('notes');
          this.route('lineup');
          this.route('delete');
        });
        this.route('player', { path: '/player/:playerid' }, function () {
          this.route('details');
          this.route('personal');
          this.route('notes');
        });
        this.route('settings');
      });
      this.route('video-manager');
    });

    this.route('analyse', function () {
      this.route('bibliothek', function () {
        this.route('reload');
      });
    });
    this.route('einstellungen', function () {
      this.route('selfcare');
    });
  });
  this.route('register');
  this.route('passwort');
  this.route('passwort-reset');
  this.route('login');
  this.route('logout');
  this.route('register-erfolgreich');
  this.route('teams', function () {
    this.route('team', { path: '/:team' }, function () {
      this.route('authenticated', { path: '/:session' }, function () {
        //this.route('index');
        this.route('playlist', { path: '/:playlistid' });
      });
    });
  });

  this.route('liveticker');
  this.route('capture');
  this.route('newwindow', function () {
    this.route('taggen');
    this.route('video');
  });
  this.route('web-app');
  this.route('hub', function () {
    this.route('space', function () {
      this.route('index', { path: '/:spaceid' });
      this.route('feed', { path: '/:spaceid/feed' });
      this.route('bookmarks', { path: '/:spaceid/bookmarks' }, function () {
        this.route('list', { path: '/:bookmarklistid' });
      });
      this.route('members', { path: '/:spaceid/members' });
      this.route('settings', { path: '/:spaceid/settings' });
    });
    this.route('profile');
  });
});
