import Cross from 'athlyzer-coach/cross/plugin';
import linkUtil from 'athlyzer-coach/classes/link-util';

import deviceutil from 'athlyzer-coach/classes/deviceutil';
import { tracked } from '@glimmer/tracking';

export default class Downloader {
  @tracked finished = false;
  @tracked aborted = false;

  @tracked progressPercentage = 0;
  @tracked progressDetail = null;
  @tracked progressId = null;
  @tracked fileName = null;
  @tracked webURL = null;
  @tracked receivedSize = 0;
  @tracked fileSize = 0;
  @tracked taskKey = '';
  @tracked taskName = '';
  @tracked taskInstanceMap = null;

  constructor(options) {
    this.taskKey = options.taskKey;
    this.progressId = options.progressId;
    this.taskName = options.taskName;
    this.fileName = options.fileName;
    this.name = options.name;
    this.webURL = options.webURL;
    this.taskInstanceMap = options.taskInstanceMap;

    this.progressListener = null;
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
  }

  start() {
    this.initialize();
  }

  initialize = async () => {
    this.setProgressListener();
    try {
      let tmp = null;
      if (linkUtil.isYoutubeVideoLink(this.webURL)) {
        console.log('YOUTUBE DOWNLOAD');
        tmp = await Cross.youtubeDownload({
          url: this.webURL,
          name: this.name,
          progressid: this.taskKey,
        });
        console.log('YOUTUBE DOWNLOAD FINISHED');
      } else if (linkUtil.isTwitchVideoLink(this.webURL)) {
        const vodid = this.webURL.replace('https://www.twitch.tv/videos/', '');

        tmp = await Cross.twitchDownloadVOD({
          vodid,
          name: this.name,
          progressid: this.taskKey,
        });
      } else {
        tmp = await Cross.download({
          url: encodeURI(this.webURL),
          progressid: this.taskKey,
          // key: this.fileName,
          newFileName: this.name,
        });
      }

      if (tmp) {
        this.relativeVideourl = tmp.relativeVideourl;
      }
      this.complete();
    } catch (error) {
      this.complete(error);
    }
  };

  progressListenerFunction = async (options) => {
    if (this.aborted) {
      return;
    }

    this.progressDetail = options.detail;

    try {
      this.handleProgress(options.detail);
    } catch (error) {
      this.onErrorFn(error);
    }
  };

  setProgressListener = () => {
    this.progressListener = this.progressListenerFunction.bind(this);
    window.addEventListener(
      `download-${this.progressId}`,
      this.progressListener
    );
  };

  removeProgressListener = () => {
    if (this.progressListener) {
      window.removeEventListener(
        `download-${this.progressId}`,
        this.progressListener
      );
    }
  };

  finishDownloadRequest = () => {
    this.finished = true;

    if (this.taskKey) {
      const progressElement = document.getElementById(
        `video-progress-${this.taskKey}`
      );
      if (progressElement) {
        progressElement.innerHTML = ` `;
      }
    }
  };

  complete = async (error) => {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.finishDownloadRequest();
    } catch (error) {
      this.onErrorFn(error);
    }
  };

  handleProgress = (event) => {
    this.progressPercentage = event.progress;
    this.receivedSize = event.transferredBytes;
    this.fileSize = event.totalBytes;

    this.onProgressFn({
      received: event.transferredBytes || 0,
      total: event.totalBytes || 0,
      percentage: event.progress,
    });

    if (this.taskKey) {
      const progressElement = document.getElementById(
        `video-progress-${this.taskKey}`
      );

      if (progressElement) {
        progressElement.innerHTML = `${this.progressPercentage.toFixed(1)}%`;
      }
    }
  };

  onProgress(onProgress) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError) {
    this.onErrorFn = onError;
    return this;
  }

  cancelTask = () => {
    const taskInstance = this.taskInstanceMap[this.taskKey];
    if (taskInstance && !taskInstance.isCanceled) {
      taskInstance.cancel();
    }
  };

  abort = async () => {
    try {
      await Cross.downloadCancel({
        downloadkey: this.taskKey,
      });

      if (this.progressPercentage < 100) {
        try {
          let folder = '';
          if (deviceutil.isElectron) {
            folder = '/DOCUMENTS/';
          }
          let exists = await Cross.fileExists({
            videourl: folder + this.fileName,
          });

          if (exists.exists) {
            await Cross.deleteFile({
              file_path: folder + this.fileName,
            });
          }
        } catch (e) {
          console.log('file not removed', e);
        }
      }

      this.removeProgressListener();
      this.aborted = true;
      this.finishDownloadRequest();
      this.cancelTask();
    } catch (error) {
      this.onErrorFn(error);
    }
  };
}
