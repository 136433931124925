import Service from '@ember/service';
import { action, set } from '@ember/object';
import InAnalyseVerwandeln from 'athlyzer-coach/components/modals/capture/in-analyse-verwandeln';
import mmss from 'athlyzer-coach/helper-functions/mmss';
import * as Sentry from '@sentry/ember';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import {
  restartableTask,
  timeout,
  enqueueTask,
  keepLatestTask,
} from 'ember-concurrency';

export default class VideoAnalyseService extends Service {
  analyse;
  currentRoute; // used for transition back from schema to analyse
  preferredTaggingRoute = '';

  @tracked showLabelBar = true;

  setAnalyse(analyse) {
    this.analyse = analyse;
  }
  unsetAnalyse() {
    this.analyse = null;
  }

  @action async unselectPlayszene() {
    this.szene = null;
    this.playSzene = null;
    this.playlistSzene = false;
    let timeFrame = document.getElementById('athlyzer-duration');
    if (this.clip) {
      let end = await this.clip.get('duration');
      if (timeFrame) {
        timeFrame.innerHTML = mmss(end);
      }
    }
  }

  @action async openModalToTransformAnalysisAndTransitionToRightRoute(model) {
    const answer = await this.modals.open(InAnalyseVerwandeln);
    if (!answer || !answer.next) {
      return;
    } else if (answer.next == 'standard') {
      model.set('analysetyp', 'standard');
      model.set('liveTagging', false);
      await model.save();
      this.router.transitionTo(
        'user.bibliothek.analyse-mobile.index',
        model.id
      );
      this.paperToaster.show(this.intl.t('Du bist jetzt im Analysemodus'));
    } else if (answer.next == 'highlights') {
      model.set('analysetyp', 'highlights');
      this.router.transitionTo(
        'user.bibliothek.analyse-highlight.index',
        model.id
      );
      this.paperToaster.show(this.intl.t('Du bist jetzt im Highlightmodus'));
    } else if (answer.next == 'workspace') {
      this.paperToaster.show(this.intl.t('Video gespeichert'));
      this.router.transitionTo('user.bibliothek');
    }
  }

  async addEventToAnalysis(ereignis) {
    if (!this.clip) return;

    let time = this.clip.islive
      ? new Date().getTime() / 1000 - this.clip.start.toMillis() / 1000
      : this.getCurrentTime();

    const vorlaufzeit = parseInt(ereignis.get('vorlaufzeit')) || 0;
    const nachlaufzeit = parseInt(ereignis.get('nachlaufzeit')) || 1;
    let startzeit = Math.max(time - vorlaufzeit, 0);
    let endzeit = null;
    let isStartzeitSet;

    const clickType = ereignis.get('clickType');
    const CLICK_BEENDEN = 'isClickBeenden';
    const CLICK_EXTENDING = 'isClickExtending';
    if (ereignis.get('isClickBeenden') || clickType === CLICK_BEENDEN) {
      const elems = (await this.analyse.get('videoszenen'))
        .toArray()
        .filterBy('endzeit', null);
      const matchingElem = elems.findBy('schemaelementid', ereignis.get('id'));

      if (matchingElem) {
        matchingElem.set('endzeit', time);
        await matchingElem.save();
        matchingElem.set('isStartzeitSet', false);
        return;
      } else {
        isStartzeitSet = true;
      }
    } else if (clickType === CLICK_EXTENDING) {
      const elems = (await this.analyse.get('videoszenen'))
        .toArray()
        .filter(
          (element) =>
            element.get('startzeit') < this.getCurrentTime() &&
            element.get('endzeit') > this.getCurrentTime()
        );
      const matchingElem = elems.findBy('schemaelementid', ereignis.get('id'));

      if (matchingElem) {
        matchingElem.set('endzeit', matchingElem.get('endzeit') + nachlaufzeit);
        await matchingElem.save();
        return;
      }
    }

    if (!(ereignis.get('isClickBeenden') || clickType === CLICK_BEENDEN)) {
      endzeit = nachlaufzeit ? Number(time + nachlaufzeit) : Number(time);
    }

    const clipID = await this.clip.get('id');
    const clip = await this.store.peekRecord('videoclip', clipID);

    if (!clip) return;

    const szene = this.store.createRecord('videoszene', {
      videobearbeitung: this.analyse,
      startzeit: startzeit,
      endzeit: endzeit,
      text: ereignis.get('name'),
      untertext: ereignis.get('text'),
      tastenkombination: ereignis.get('tastenkombination'),
      labels: [],
      gruppe: ereignis.get('gruppe'),
      farbe: ereignis.get('farbe'),
      schemaid: this.schema ? this.schema.get('id') : '',
      schemaelementid: ereignis.get('id'),
      videoclip: clip,
    });

    this.szene = szene;
    if (this.analyse && szene) {
      this.analyse.get('videoszenen').pushObject(szene);
    }
    if (clip && szene) {
      clip.get('videoszenen').pushObject(szene);
    }

    await this.saveScene.perform(szene);
    await this.addAndSave.perform(this.analyse, clip, szene);

    if (ereignis.get('intelPlaylist').length > 0) {
      await this.addSceneToIntelligentPlaylist.perform(ereignis, szene);
    }

    await ereignis.automatedCollections;

    if (
      ereignis.automatedCollections &&
      ereignis.automatedCollections.length > 0
    ) {
      await this.addSceneToAutomatedCollection.perform(ereignis, szene);
    }

    szene.set('isStartzeitSet', isStartzeitSet);
  }

  saveScene = task({ enqueue: true }, async (scene) => {
    await scene.save();
  });

  szenen = [];

  @keepLatestTask *addAndSave(analyse, clip, szene) {
    /*if (szene) {
      szene.save();
    }*/

    if (clip) {
      yield clip.save();
    }
    if (analyse) {
      yield analyse.save();
    }
  }

  @restartableTask *saveAddSzene(analyse, clip) {
    yield timeout(250);
    yield clip.save();
    yield analyse.save();
  }

  @enqueueTask *saveSzene(szene) {
    if (szene.get('isDirty')) {
      yield szene.save();
    }
  }

  @restartableTask *saveAnalyse() {
    let analyse = this.analyse;
    yield timeout(250);
    for (const clip of this.analyse.videoclips.toArray()) {
      if (clip.get('isDirty')) {
        yield clip.save();
      }
    }
    if (this.analyse.get('isDirty')) {
      yield analyse.save();
    }
  }

  async addLabelToScene(tag) {
    if (!this.szene) return;

    const tagName = tag.get('name');
    const tagText = tag.get('text');
    const textgesamt = [tagName, tagText].filter(Boolean).join(' ');

    const tags = this.szene.tags || [];
    let allowed = false;

    if (tags.length === 0) {
      allowed = true;
    } else {
      for (const label of tags) {
        if (tag.mehrfach) {
          allowed = true;
        } else if (label.textgesamt !== textgesamt) {
          allowed = true;
        } else {
          allowed = false;
          break;
        }
      }
    }

    if (allowed) {
      const schemaid = this.schema ? this.schema.get('id') : null;

      tags.push({
        name: tagName,
        text: tagText,
        textgesamt: textgesamt,
        farbe: tag.get('farbe'),
        farbetext: tag.get('farbeText'),
        schemaid: schemaid,
        schemaelementid: tag.get('id'),
        intelPlaylist: tag.intelPlaylist,
      });

      if (tag.intelPlaylist && tag.intelPlaylist.length > 0) {
        this.addSceneToIntelligentPlaylist.perform(tag, this.szene);
      }

      if (tag.automatedCollections && tag.automatedCollections.length > 0) {
        this.addSceneToAutomatedCollection.perform(tag, this.szene);
      }
    } else {
      const labelToRemove = tags.find((label) => label.name === tagName);
      if (labelToRemove) {
        tags.splice(tags.indexOf(labelToRemove), 1);
      }
    }

    set(this.szene, 'tags', tags);
    this.szene.set('change', !this.szene.get('change'));
    this.saveScene.perform(this.szene);
  }
}
