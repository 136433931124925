import Service from './capture';
import * as Sentry from '@sentry/ember';
import { task, enqueueTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default class VideoCountersService extends Service {
  @service addonUser;

  addSceneToIntelligentPlaylist = task(
    { enqueue: true },
    async (ereignis, szene) => {
      const isAllowedToAddAutoPlaylists =
        this.addonUser.checkForAccessToFeatureAndSetInfoModal(
          'playlist.automated',
          false
        );

      if (!isAllowedToAddAutoPlaylists) {
        return;
      }

      try {
        const intelligentPlaylistsDefinedInEreignis = ereignis.intelPlaylist;
        const playlistsInAnalysis = await this.analyse.get('playlists');

        // Convert playlistsInAnalysis to a Map for efficient lookup.
        const playlistMap = new Map();
        for (const analysePlaylist of playlistsInAnalysis.toArray()) {
          const playlistName = await analysePlaylist.get('name');
          playlistMap.set(playlistName, analysePlaylist);
        }

        for (const intelligentPlaylistName of intelligentPlaylistsDefinedInEreignis) {
          if (playlistMap.has(intelligentPlaylistName)) {
            // Playlist already exists, transfer to it.
            await this.transfer.transferToPlaylist(
              [szene],
              playlistMap.get(intelligentPlaylistName)
            );
          } else {
            // Create new playlist.
            const newList = this.store.createRecord('playlist', {
              name: intelligentPlaylistName,
              position: this.analyse.get('playlists').toArray().length,
              videobearbeitung: this.analyse,
            });

            this.analyse.playlists.pushObject(newList);
            await newList.save();
            await this.transfer.transferToPlaylist([szene], newList);
          }
        }

        this.paperToaster.show(
          this.intl.t('Szene zur automatischen Playlist hinzugefügt.')
        );
      } catch (error) {
        console.error('Error in addSceneToIntelligentPlaylist task:', error);
        // Handle the error appropriately, maybe show a user-facing error message.
        Sentry.captureException(error);
      }
    }
  );

  addSceneToAutomatedCollection = task(
    { enqueue: true },
    async (ereignis, scene) => {
      const isAllowedToAddAutoCollections =
        this.addonUser.checkForAccessToFeatureAndSetInfoModal(
          'collection.automated',
          false
        );

      if (!isAllowedToAddAutoCollections) {
        return;
      }

      try {
        const automatedCollections = await ereignis.automatedCollections;

        for (let collection of automatedCollections) {
          const { id } = collection;
          const collectionPlaylistMap = new Map();
          const playlistsInCollection = await collection.get('playlists');
          let automatedPlaylistForCollection = [];

          if (
            ereignis.automatedCollectionPlaylistMap[id] &&
            ereignis.automatedCollectionPlaylistMap[id].length > 0
          ) {
            automatedPlaylistForCollection =
              ereignis.automatedCollectionPlaylistMap[id];
          }

          for (const collectionPlaylist of playlistsInCollection.toArray()) {
            const playlistName = await collectionPlaylist.get('name');
            collectionPlaylistMap.set(playlistName, collectionPlaylist);
          }

          for (const intelligentPlaylistName of automatedPlaylistForCollection) {
            if (collectionPlaylistMap.has(intelligentPlaylistName)) {
              await this.transfer.transferToPlaylist(
                [scene],
                collectionPlaylistMap.get(intelligentPlaylistName)
              );
            } else {
              let playlist = await this.store.createRecord('playlist', {
                name: intelligentPlaylistName,
                position: collection.get('playlists').toArray().length + 1,
              });

              playlist.set('videocollection', collection);
              collection.playlists.pushObject(playlist);

              await playlist.save();
              await collection.save();

              await this.transfer.transferToPlaylist([scene], playlist);
            }
          }
        }
      } catch (error) {
        console.log('ERROR', error);
        Sentry.captureException(error);
      }
    }
  );
}
