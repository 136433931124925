import { Network } from '@capacitor/network';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class NetworkService extends Service {
  @service paperToaster;
  @service intl;

  @tracked status;
  @tracked isConnectedToInternet = null;
  @tracked currentConnectionType = null;

  constructor() {
    super(...arguments);

    Network.addListener('networkStatusChange', (status) => {
      console.log('Network status changed', status);
      if (!this.isConnectedToInternet && status.connected) {
        this.paperToaster.show(
          this.intl.t('Du bist jetzt wieder mit dem Internet verbunden.')
        );
      } else if (this.isConnectedToInternet && !status.connected) {
        this.paperToaster.show(
          this.intl.t(
            'Du arbeitest jetzt im Offline-Modus. Deine Änderungen werden synchronisiert, sobald du wieder online bist.'
          )
        );
      }

      this.status = status;
      this.setCurrentConnectionStatus(status);
    });
  }

  checkCurrentNetworkStatus = async () => {
    const status = await Network.getStatus();
    this.status = status;
    this.setCurrentConnectionStatus(status);
    console.log(
      'Network status:',
      status,
      this.isConnectedToInternet,
      this.currentConnectionType
    );
  };

  setCurrentConnectionStatus(status) {
    this.isConnectedToInternet = status.connected;
    this.currentConnectionType = status.connectionType;
  }

  get connected() {
    return this.status.connected;
  }

  get online() {
    return this.connected == this.onlineStatus;
  }
}
