import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';

export default class AddonCoreLayoutScrollComponent extends Component {
  id = 'scroll-' + guidFor(this);

  @service addonCore;

  constructor() {
    super(...arguments);
    if (!this.args.ignore) {
      this.sizeCalculatorEventListener =
        this.calculateComponentHeight.bind(this);
      window.addEventListener(
        'custom-resize',
        this.sizeCalculatorEventListener
      );
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    if (!this.args.ignore) {
      window.removeEventListener(
        'custom-resize',
        this.sizeCalculatorEventListener
      );
    }
  }

  get style() {
    if (this.args.xhidden) {
      return htmlSafe(
        'overflow-y:scroll;white-space:nowrap;overflow-x:hidden;'
      );
    } else {
      return htmlSafe('overflow:scroll;');
    }
  }

  calculateComponentHeight() {
    const thisScrollElement = document.getElementById(this.id);
    if (this.args.isParent) {
      const parentElement = thisScrollElement.parentElement;
      let siblingHeight = 0;

      if (this.args.includeUpperSiblingHeights) {
        for (let sibling of parentElement.children) {
          if (!sibling.id.startsWith('ember') && !(sibling.id === this.id)) {
            siblingHeight += sibling.getBoundingClientRect().height;
          }
          if (sibling.id === this.id) break;
        }
      }

      const totalHeight =
        parentElement.getBoundingClientRect().height - siblingHeight;

      thisScrollElement.style.height = totalHeight + 'px';
    } else {
      thisScrollElement.style.height =
        this.addonCore.size.innerHeight -
        thisScrollElement.getBoundingClientRect().top -
        1 +
        'px';
    }
  }

  setHeight(
    element,
    [
      innerHeight,
      isParent,
      includeUpperSiblingHeights,
      includeLowerSiblingHeights,
      self,
    ]
  ) {
    if (isParent) {
      const parentElement = element.parentElement;
      let siblingHeight = 0;

      if (includeUpperSiblingHeights) {
        for (let sibling of parentElement.children) {
          if (!sibling.id.startsWith('ember') && !(sibling.id === self.id)) {
            siblingHeight += sibling.getBoundingClientRect().height;
          }
          if (sibling.id === self.id && !includeLowerSiblingHeights) break;
        }
      }
      const totalHeight =
        parentElement.getBoundingClientRect().height - siblingHeight;

      element.style.height = totalHeight + 'px';
    } else {
      element.style.height =
        innerHeight - element.getBoundingClientRect().top - 1 + 'px';
    }
  }
}
