import Service from './subscription';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import config from 'athlyzer-coach/config/environment';
import { tracked } from '@glimmer/tracking';
import PouchDB from 'pouchdb-browser';
import { Preferences } from '@capacitor/preferences';
import { htmlSafe } from '@ember/template';
import * as Sentry from '@sentry/ember';

export default class AddonUserWorkspacesService extends Service {
  @service store;
  @service network;

  // Datenbanken
  @tracked workspaces = [];
  @tracked invites = [];

  @tracked aktiveDatenbank;
  @tracked aktiveDatenbankSync;
  @tracked privateDB;
  @tracked lastAktiveDatenbankSyncTimestamp = null;

  // Datenbanken sync
  @tracked pouchDbSyncActiveEvent = false;
  @tracked pouchDbSyncChangeEvent = false;
  @tracked isSyncingFromRemoteDatabase = false;
  @tracked syncFromRemoteDatabaseIsFinished = false;

  async loadProfile() {}

  @action setWorkspaces(workspaces) {
    console.log('workspaces', workspaces);
    this.workspaces = workspaces.sortBy('name');
  }

  @action async restoreWorkspaces() {
    try {
      await this.loadLocalWorkspaces();
    } catch (error) {}
    try {
      this.loadWorkspaces();
    } catch (error) {}
  }

  @action async loadLocalWorkspaces() {
    const localData = await Preferences.get({
      key: `${this.user_uid}_workspaces`,
    });
    console.log('loadLocalWorkspaces', localData);
    if (localData?.value) {
      const workspaces = JSON.parse(localData.value);
      if (workspaces && workspaces.length > 0) {
        this.workspaces = workspaces;
      }
    }
  }

  @action async loadWorkspaces() {
    if (!this.network.isConnectedToInternet) return;

    try {
      const workspacesResponse = await this.getHttp().get('/user/workspaces');
      if (workspacesResponse.data) {
        this.setWorkspaces(workspacesResponse.data);
        await Preferences.set({
          key: `${this.user_uid}_workspaces`,
          value: JSON.stringify(this.workspaces),
        });
      }
    } catch (error) {
      console.log('error', error);
      if (error.response && error.response.status == 401) {
        this.checkExpired();
      }
      Sentry.captureException(error);
    }
  }

  @action async loadInvites() {
    if (!this.network.isConnectedToInternet) return;

    try {
      const workspacesResponse = await this.getHttp().get(
        '/user/workspaces/invite/list'
      );
      if (workspacesResponse.data && Object.keys(workspacesResponse.data)) {
        this.invites = Object.values(workspacesResponse.data);
      }
    } catch (error) {
      console.log('error', error);
      if (error.response && error.response.status == 401) {
        this.checkExpired();
      }
      Sentry.captureException(error);
    }
  }

  get isSharedDB() {
    return this.workspaces[0]?.dbname != this.aktiveDatenbank;
  }
  get aktiveDatenbankName() {
    let arr = this.workspaces.map((a) => {
      if (a.dbname) {
        return a.dbname;
      } else {
        return a;
      }
    });
    let index = arr.indexOf(this.aktiveDatenbank);
    if (index > -1) {
      return this.workspaces[index].name;
    } else {
      return this.intl.t('Private Bibliothek');
    }
  }

  async replicateTo(datenbankName, ids, promiseCallback) {
    let extern = new PouchDB(datenbankName, { adapter: 'indexeddb' });
    let intern = this.store.adapterFor('application').db;
    await intern.replicate.to(extern, {
      filter: function (doc) {
        let id = doc._id.split('_').pop();
        console.log('filter');
        if (ids.indexOf(id) > -1) {
          console.log('id', doc, id);
          return true;
        } else {
          return false;
        }
      },
    });
    let remote = new PouchDB(
      'https://' +
        this.getSession().token +
        ':' +
        this.getSession().password +
        '@' +
        config.dbhost +
        '/' +
        datenbankName
    );
    if (promiseCallback) {
      await promiseCallback(extern, remote);
    }
    await extern.replicate.to(remote);
    console.log('replicate to erfolgreich');
  }

  resetDatenbank(modelNamen) {
    return this.datenbankWechseln(this.getSession().datenbanken[0].dbname);
  }

  @action datenbankWechseln({ databaseName, offline, remoteLink }) {
    return new Promise(async (resolve) => {
      let localDatabase = new PouchDB(databaseName, {
        adapter: 'indexeddb',
        revs_limit: 500,
      });
      localDatabase.setMaxListeners(30);
      await this.store.adapterFor('application').changeDb(localDatabase);
      this.aktiveDatenbank = databaseName;

      if (config.offline || offline) {
        return resolve(localDatabase);
      }

      await this.syncDatabaseWithRemote({
        localDatabase,
        optionalRemoteLink: remoteLink,
      });
      resolve(localDatabase);
    });
  }

  async removeSyncFromLocalDatabases() {
    if (
      this.aktiveDatenbankSync &&
      typeof this.aktiveDatenbankSync.cancel == 'function'
    ) {
      await this.aktiveDatenbankSync.cancel();
    }
    this.aktiveDatenbankSync = null;
  }

  syncDatabaseWithRemote({ localDatabase, optionalRemoteLink }) {
    // console.log('@syncDatabaseWithRemote session', this.getSession());
    // console.log('@syncDatabaseWithRemote config.dbhost', config.dbhost);
    const remoteLink =
      optionalRemoteLink ||
      `https://${this.getSession().token}:${this.getSession().password}@${
        config.dbhost
      }/${this.aktiveDatenbank}`;
    // console.log('@syncDatabaseWithRemote remoteLink', remoteLink);
    let remoteDatabase = new PouchDB(remoteLink);
    this.syncFromRemoteDatabaseIsFinished = new Promise((resolveIsSynced) => {
      this.aktiveDatenbankSync = localDatabase
        .sync(remoteDatabase, {
          live: true,
          retry: true,
          batch_size: 100,
        })
        .on('active', () => {
          this.pouchDbSyncActiveEvent = true;
        })
        .on('change', () => {
          this.showLoading();
          this.pouchDbSyncChangeEvent = true;
        })
        .on('paused', () => {
          if (
            this.pouchDbSyncActiveEvent == true &&
            this.pouchDbSyncChangeEvent == true
          ) {
            Preferences.set({
              key: `${this.aktiveDatenbank}_last_sync`,
              value: new Date().toISOString(),
            });
            this.lastAktiveDatenbankSyncTimestamp = new Date();
          }
          resolveIsSynced();
          this.pouchDbSyncActiveEvent = false;
          this.pouchDbSyncChangeEvent = false;
        })
        .on('error', (err) => {
          if (!config.offline) {
            this.refreshSession();
          }
          Sentry.captureException(err);
          console.log('pouch sync error', err);
        });
    });
    return localDatabase;
  }

  get lastSync() {
    this.pouchDbSyncActiveEvent;
    this.pouchDbSyncChangeEvent;

    if (this.aktiveDatenbank && this.lastAktiveDatenbankSyncTimestamp) {
      return htmlSafe(
        new Date(this.lastAktiveDatenbankSyncTimestamp).toLocaleString(
          this.intl.locale,
          {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          }
        )
      );
    } else {
      return htmlSafe('');
    }
  }
}
