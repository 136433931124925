import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import * as Sentry from '@sentry/ember';
import SelectSpaceModal from 'athlyzer-coach/components/modals/teams-hub/select-space';
import CreatePostInSpace from 'athlyzer-coach/components/modals/teams-hub/create-post-in-space';
import SelectBookmarkList from 'athlyzer-coach/components/modals/teams-hub/select-bookmark-list';

export default class TeamsHubService extends Service {
  @service addonUser;
  @service modals;
  @service paperToaster;
  @service network;

  @tracked spaceData = [];

  fetchSpaceData = task({ enqueue: true }, async () => {
    if (!this.network.isConnectedToInternet) return;

    const apiClient = this.addonUser.getApiClient();
    let { data: userSpaces } = await apiClient.get('/user/hub/spaces');

    userSpaces = userSpaces.map((space) => {
      const amIAdmin = this.checkIfIAmAdminOfSpace(space);
      space.admin = amIAdmin;
      return space;
    });

    this.spaceData = userSpaces;
  });

  updateSpaceDataById(spaceId, updatedSpaceData) {
    this.spaceData = this.spaceData.map((space) => {
      if (space.id === parseInt(spaceId)) {
        return {
          ...space,
          ...updatedSpaceData,
        };
      }

      return space;
    });
  }

  checkIfIAmAdminOfSpace(space) {
    const amIAdmin = space.users.reduce((acc, user) => {
      if (user.userId === this.addonUser.user_uid) {
        return user.role === 'ADMIN' || user.role === 'OWNER';
      }

      return acc;
    }, false);

    return amIAdmin;
  }

  @action async postToSpace(athlyzerLink) {
    const selectedSpace = await this.modals.open(
      SelectSpaceModal,
      {
        spaceData: this.spaceData,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );

    if (!selectedSpace.spaceId) return;

    await this.modals.open(
      CreatePostInSpace,
      {
        spaceName: 'Post: ' + selectedSpace.spaceName,
        spaceId: selectedSpace.spaceId,
        predefinedAthlyzerVideo: athlyzerLink,
      },
      {
        className: 'modal--create-post-in-space',
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  }

  @action async managePostBookmarks(post, postBookmarks) {
    try {
      const apiClient = this.addonUser.getApiClient();
      let { data: bookmarkLists } = await apiClient.get(
        `/user/hub/spaces/${post.spaceId}/bookmarks/`
      );

      bookmarkLists = bookmarkLists.map((bookmarkList) => {
        const isAlreadyBookmarked = postBookmarks.some(
          (bookmark) => bookmark.bookmarkListId === bookmarkList.id
        );

        bookmarkList.isAlreadyBookmarked = isAlreadyBookmarked;
        bookmarkList.shouldBeBookmarked = isAlreadyBookmarked;

        return bookmarkList;
      });

      const selectedBookmarkList = await this.modals.open(
        SelectBookmarkList,
        {
          bookmarkLists: bookmarkLists,
        },
        {
          focusTrapOptions: {
            clickOutsideDeactivates: false,
          },
        }
      );

      if (!selectedBookmarkList || selectedBookmarkList.cancelled)
        return { cancelled: true };

      const newBookmarksAssignment = selectedBookmarkList.data.bookmarkLists;

      for (let bookmarkListAssignment of newBookmarksAssignment) {
        if (
          bookmarkListAssignment.isAlreadyBookmarked &&
          !bookmarkListAssignment.shouldBeBookmarked
        ) {
          await apiClient.delete(
            `/user/hub/spaces/${post.spaceId}/posts/${post.id}/bookmark/${bookmarkListAssignment.id}`
          );
        } else if (
          !bookmarkListAssignment.isAlreadyBookmarked &&
          bookmarkListAssignment.shouldBeBookmarked
        ) {
          await apiClient.post(
            `/user/hub/spaces/${post.spaceId}/posts/${post.id}/bookmark`,
            {
              bookmarkListId: bookmarkListAssignment.id,
            }
          );
        }
      }

      return { successful: true };
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      this.paperToaster.show('Error adding post to bookmark list');
      return { successful: false };
    }
  }
}
