import Model from '../classes/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';

export default class TeamplayerModel extends Model {
  typ = 'teamplayer';
  @attr('string') nachname;
  @attr('string') vorname;
  @attr('string') mail;
  @attr('boolean', { defaultValue: false }) highlight;
  @attr('string') note;
  @belongsTo('ordner') ordner;
  @hasMany('teamposition', { save: true }) positions;
  @hasMany('team', { save: true }) teams;
  icon = 'person';
  @hasMany('videoclip', { save: true }) videoclips;

  get name() {
    return this.vorname + ' ' + this.nachname;
  }

  @attr('attachment', {
    defaultValue: function () {
      return null;
    },
  })
  image;
}
