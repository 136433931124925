import MixinVideoszenen from '../mixin-videoszenen';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AnalyseSzenenListenTeamPlaylistComponent extends MixinVideoszenen {
  // Die Szenenliste erhält von außen mehrere Parameter.
  // Es wird die Szenenliste übergeben
  // Es wird ein Modus übergeben (gültige Modi (displayMode): "taggen", "prepare", "playlist", "present")
  // Es wird eine Sortierung übergeben (gültige Modi (sortMode): "asc", "dsc")

  @service addonCore;
  @service visual;
  @service video;

  get sortedSzenen() {
    let szenen = [];
    if (this.args.szenen && this.args.szenen.length > 0) {
      szenen = this.args.szenen.toArray();
    }
    if (szenen) {
      szenen = szenen.sort((a, b) => {
        return a.get('position') - b.get('position');
      });
      return szenen;
    }
    return szenen;
  }

  @action async clickPlaySzene(playszene) {
    await playszene;
    this.visual.resetTap();
    if (this.args.playlist) {
      this.args.playlist.aktivePlaylistszene = playszene;
      this.video.changeClipWithPromise(playszene.fake.clip);
      this.video.szene = playszene.fake.videoszene;
      this.video.playSzene = playszene;
      this.video.playlistSzene = playszene;
    }
  }
}
