import Model from '../classes/model';
import { tracked } from '@glimmer/tracking';
import { attr, belongsTo, hasMany } from '@ember-data/model';

export default class TeamlineupplayerModel extends Model {
  typ = 'teamlineupplayer';
  @attr('number') number;
  @attr('string') playername;
  @attr('string') notizen;
  @belongsTo('teamlineup') teamlineup;
  @belongsTo('teamplayer', { save: true }) teamplayer;

  get player() {
    if (this.teamplayer && this.teamplayer.get('name')) {
      return this.teamplayer;
    } else {
      return {
        name: this.playername,
      };
    }
  }
}
