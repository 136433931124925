import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { menuController } from '@ionic/core';

export default class TeamsTeamAuthenticatedPlaylistController extends Controller {
  @service addonCore;
  @service video;
  @service visual;

  @tracked aktivePlaylistszene;
  @tracked isMetaOpen = false;
  @tracked aktivePlaylist;

  @tracked isOffListe = false;

  @action toggleOffListe() {
    this.isOffListe = !Boolean(this.isOffListe);
  }

  @action async timechangeScene() {
    this.video.play();
  }
  @action async timeinputScene(event) {
    this.video.seekToSeconds(event.target.value, 'pause');
  }

  @action openMenu() {
    this.visual.resetTap();
    menuController.enable(true, 'first');
    menuController.open('first');
  }

  @action async clickPlaySzene(playszene) {
    this.aktivierePlaylistszene(playszene);
    // if (this.args.playlist) {
    //   this.args.playlist.aktivePlaylistszene = playszene;
    // }
  }

  get getRange() {
    let range =
      this.video.sharedSzene.videoszene.endzeit -
      this.video.sharedSzene.videoszene.startzeit;
    if (!range) {
      range =
        this.video.sharedSzene.geteilteVideoszene.endzeit -
        this.video.sharedSzene.geteilteVideoszene.startzeit;
    }
    // absolute fallback
    if (!range) {
      range = 60;
    }
    range = Math.floor(range);
    return range.toFixed(0);
  }

  tmpPromise;
  hasPlayed = false;
  @action async timechange() {
    if (this.tmpPromise) {
      await this.tmpPromise;
    }
    if (this.hasPlayed) {
      this.tmpPromise = this.video.play();
      this.hasPlayed = false;
    }
    console.log('timechange');
  }
  @action async timeinput(event) {
    if (this.tmpPromise) {
      await this.tmpPromise;
    }
    // if (!this.video.player.paused) {
    //   this.hasPlayed = true;
    //   this.tmpPromise = this.video.player.pause();
    // }
    this.video.seekToSeconds(event.target.value);
    this.video.playerUpdateTime(event.target.value);
  }

  @action async videoActions(key) {
    switch (key) {
      case 'play':
        if (
          !this.model.aktivePlaylistszene &&
          this.model.getplaylistszenen.length > 0
        ) {
          await this.aktivierePlaylistszene(this.model.getplaylistszenen[0]);
        }
        this.video.play();
        break;
      case 'pause':
        this.video.pause();
        break;
      case 'next': {
        let index = this.model.aktivePlaylistszene
          ? this.model.getplaylistszenen.indexOf(this.model.aktivePlaylistszene)
          : 0;
        if (index + 1 < this.model.getplaylistszenen.length) {
          await this.aktivierePlaylistszene(
            this.model.getplaylistszenen[index + 1]
          );
        } else {
          await this.aktivierePlaylistszene(this.model.getplaylistszenen[0]);
        }
        this.video.pause();

        break;
      }
      case 'previous': {
        let index = this.model.aktivePlaylistszene
          ? this.model.getplaylistszenen.indexOf(this.model.aktivePlaylistszene)
          : 0;

        if (index - 1 >= 0) {
          await this.aktivierePlaylistszene(
            this.model.getplaylistszenen[index - 1]
          );
        } else {
          await this.aktivierePlaylistszene(this.model.getplaylistszenen[0]);
        }
        this.video.pause();
        break;
      }
      case 'replay': {
        if (this.video.playerRef) {
          await this.aktivierePlaylistszene(this.model.aktivePlaylistszene);
        }
        break;
      }
    }
  }

  @action async aktivierePlaylistszene(playszene) {
    this.video.drawLayer = null;
    await playszene;
    this.model.aktivePlaylistszene = playszene;
    await this.video.changeClipWithPromise(playszene.fake.clip);
    this.video.szene = playszene.fake.videoszene;
    this.video.playSzene = playszene;
    this.video.playlistSzene = playszene;

    if (playszene.geteiltevideoszene.kommentar) {
      this.isMetaOpen = true;
    }
  }

  @action toggleMeta() {
    this.isMetaOpen = !this.isMetaOpen;
  }

  get szeneMetaInfo() {
    if (this.video.sharedSzene) {
      const position = this.video.sharedSzene.geteiltevideoszene.position;
      const text = this.video.sharedSzene.geteiltevideoszene.gesamttext
        ? this.video.sharedSzene.geteiltevideoszene.gesamttext
        : this.video.sharedSzene.geteiltevideoszene.text;
      return htmlSafe(`${position}. ${text}`);
    }
    return htmlSafe('');
  }
}
