import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import * as Sentry from '@sentry/ember';

export default class LogoutRoute extends Route {
  @service addonUser;

  async beforeModel() {
    if (this.addonUser.authenticated()) {
      try {
        await this.addonUser.logoutSession();
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }
}
