import StandardModel from '../models/schema-element-basis';
import { attr } from '@ember-data/model';

export default class TagModel extends StandardModel {
  // typ : "tag",
  @attr('string', { defaultValue: 'tag' })
  typ;
  // athlet: attr('boolean', {
  //   defaultValue: false
  // })
  @attr('boolean', { defaultValue: false })
  mehrfach;
}
