import Model from '../classes/model';
import { attr, belongsTo } from '@ember-data/model';

class TeammemberFastModel extends Model {
  typ = 'teammember';
  @attr('string') nachname;
  @attr('string') vorname;

  get name() {
    return this.vorname + ' ' + this.nachname;
  }
  icon = 'person';
  @belongsTo('ordner') ordner;
}
TeammemberFastModel.reopenClass({
  documentType: 'teammember',
});

export default TeammemberFastModel;
