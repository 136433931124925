export default function (szene, labels) {
  if (!labels || Object.keys(labels).length === 0) {
    return true;
  }

  const tags = szene.get('tags');
  if (!tags || tags.length === 0) {
    return false;
  }

  const textgesamt = szene.get('textgesamt');
  if (!textgesamt) {
    return false;
  }

  const tagNames = new Set(
    tags.map((tag) => {
      const searchString = tag.textgesamt ? tag.textgesamt : tag.name;
      return searchString;
    })
  );

  const result = Object.values(labels).every((label) => {
    const searchString = label.textgesamt ? label.textgesamt : label.name;
    return tagNames.has(searchString);
  });

  return result;
}
