import Model from '../classes/model';
import { attr, belongsTo } from '@ember-data/model';

class BoardFastModel extends Model {
  @attr('boolean', { defaultValue: false }) highlight;
  get unhighlighted() {
    return !this.highlight;
  }

  @attr('string') name;

  @attr('mdate') datum;
  typ = 'board';

  icon = 'content_paste';

  @belongsTo('ordner') ordner;
}

BoardFastModel.reopenClass({
  documentType: 'board',
});

export default BoardFastModel;
