export default function (array, playlist, delta) {
  array = array.sort((a, b) => {
    const diff = a.get('position') - b.get('position');
    return diff;
  });

  const currentIndex = array.indexOf(playlist);
  if (currentIndex >= 0) {
    if (currentIndex + delta >= 0 && currentIndex + delta < array.length) {
      const [item] = array.splice(currentIndex, 1);
      array.splice(currentIndex + delta, 0, item);
    }

    let savePromises = [];

    array.forEach((element, index) => {
      element.set('position', index + 1);
      savePromises.push(element.save());
    });

    return Promise.all(savePromises);
  } else {
    return false;
  }
}
