import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import * as Sentry from '@sentry/ember';
import { Browser } from '@capacitor/browser';
import AttachmentImage from 'athlyzer-coach/components/modals/teams-hub/attachment-image';
import AttachmentPDF from 'athlyzer-coach/components/modals/teams-hub/attachment-pdf';

export default class HubSpaceFeedPostAttachmentsViewComponent extends Component {
  @service addonUser;
  @service addonCore;
  @service paperToaster;
  @service modals;

  @tracked attachmentType = null;
  @tracked attachmentImageUrl = null;
  @tracked attachmentPDFUrl = null;

  @action showPreviewAttachment(elem, [self]) {
    if (self.args.previewAttachment) {
      const file = self.args.previewAttachment;
      self.handlePreviewAttachment(file);
    } else if (self.args.attachment) {
      self.handlePostedAttachment(self.args.attachment);
    }
  }

  @action handlePostedAttachment(attachment) {
    if (attachment.fileType.includes('image')) {
      this.attachmentImageUrl = attachment.attachmentUrl;
      this.attachmentType = 'image';
      // } else if (attachment.fileType.includes('video')) {
      //   this.attachmentType = 'video';
      //   elem.src = attachment.attachmentUrl;
    } else if (attachment.fileType.includes('application/pdf')) {
      this.attachmentType = 'pdf';
      this.attachmentPDFUrl = attachment.attachmentUrl;
    } else {
      this.paperToaster.show('Dateityp wird nicht unterstützt.');
    }
  }

  @action handlePreviewAttachment(file) {
    if (file.type.includes('image')) {
      this.attachmentImageUrl = URL.createObjectURL(file);
      this.attachmentType = 'image';
      // } else if (file.type.includes('video')) {
      //   this.attachmentType = 'video';
      //   elem.src = URL.createObjectURL(file);
    } else if (file.type.includes('application/pdf')) {
      this.attachmentType = 'pdf';
    } else {
      this.paperToaster.show(this.intl.t('Dateityp wird nicht unterstützt.'));
    }
  }

  @action async openImageAttachment(attachment) {
    await this.modals.open(
      AttachmentImage,
      {
        imageSrc: attachment.attachmentUrl,
      },
      {
        className: 'modal--create-post-in-space',
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  }

  @action async openPDFAttachment(attachment) {
    if (this.addonCore.isApp) {
      await Browser.open({ url: attachment.attachmentUrl });
    } else {
      await this.modals.open(
        AttachmentPDF,
        {
          pdfSrc: attachment.attachmentUrl,
        },
        {
          className: 'modal--create-post-in-space',
          focusTrapOptions: {
            clickOutsideDeactivates: false,
          },
        }
      );
    }
  }
}
