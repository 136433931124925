import Cross from 'athlyzer-coach/cross/plugin';
import { tracked } from '@glimmer/tracking';
import FfmpegCommandBuilder from 'athlyzer-coach/cross/ffmpeg';
import * as Sentry from '@sentry/ember';
import deviceutil from '../classes/deviceutil';

export default class VideoCutter {
  @tracked finished = false;
  @tracked aborted = false;

  @tracked progressPercentage = 0;
  @tracked progressDetail = null;
  @tracked progressId = null;
  @tracked fileName = null;
  @tracked webURL = null;
  @tracked receivedSize = 0;
  @tracked fileSize = 0;
  @tracked taskKey = '';
  @tracked taskName = '';
  @tracked videourl = '';
  @tracked filepath = '';

  constructor(options) {
    this.taskKey = options.taskKey;
    this.progressId = options.progressId;
    this.taskName = options.taskName;
    this.fileName = options.fileName;
    this.name = options.name;
    this.webURL = options.webURL;
    this.cutInternalOptions = options;

    this.progressListener = null;
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
  }

  start() {
    this.initialize();
  }

  async initialize() {
    this.setProgressListener();

    try {
      console.log('cutInternalOptions: ', this.cutInternalOptions);
      const { filepath } = await Cross.videourlToAbsoluteFilepath({
        videourl: this.cutInternalOptions.videourl,
      });
      let outputPath;
      if (deviceutil.isElectron) {
        outputPath = (await Cross.folder()).videosexportpath + '/';
      } else if (deviceutil.isAndroid) {
        outputPath = (await Cross.folder()).path + '/';
      } else if (deviceutil.isApp) {
        outputPath = (await Cross.folder()).path;
      }

      let outputFileExtension = this.cutInternalOptions.outputFileExtension;

      if (outputFileExtension) {
        outputPath +=
          this.cutInternalOptions.fileName + '.' + outputFileExtension;
      } else {
        outputPath += this.cutInternalOptions.fileName + '.mp4';
      }

      console.log('inputPath: ', filepath);
      console.log('outputPath: ', outputPath);

      let command = FfmpegCommandBuilder.create(
        this.cutInternalOptions.beginnerFfmpegProfileName
      ).addVideoInput(filepath);

      if (
        this.cutInternalOptions.overlays?.length > 0 ||
        this.cutInternalOptions.coverOverlay
      ) {
        if (this.cutInternalOptions.overlays?.length > 0) {
          this.cutInternalOptions.overlays.forEach((overlay) => {
            command.addOverlay(
              overlay.url,
              overlay.position,
              overlay.percent,
              10,
              10
            );
          });
        }

        if (this.cutInternalOptions.coverOverlay) {
          command.addOverlay(
            this.cutInternalOptions.coverOverlay.url,
            'center',
            100
          );
        }
      } else {
        if (
          command.profile.width === this.cutInternalOptions.inputWidth ||
          command.profile.height === this.cutInternalOptions.inputHeight
        ) {
          command.pad();
        } else {
          command.scale().pad();
        }
      }

      command
        .cut(this.cutInternalOptions.start, this.cutInternalOptions.end)
        .makeMp4Streamable()
        .outputTo(outputPath, 'ts')
        .logCommand()
        .onProgress(this.handleProgress);

      let cutResult = await command.run();
      if (cutResult && !cutResult.failed) {
        const { videourl } = await Cross.absoluteFilepathToVideourl({
          filepath: outputPath,
        });
        this.videourl = videourl;
        this.filepath = outputPath;
      } else {
        this.videourl = false;
        this.filepath = false;
      }
      this.finished = true;
      this.removeProgressListener();
    } catch (error) {
      this.complete(error);
    }
  }

  complete = async (error) => {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      this.removeProgressListener();
      return;
    }

    if (error) {
      this.onErrorFn(error);
      this.removeProgressListener();

      try {
        let exists = await Cross.fileExists({
          videourl: this.cutProgressDetail.filePath,
        });
        if (exists.exists) {
          await Cross.deleteFile({
            file_path: this.cutProgressDetail.filePath,
          });
        }
      } catch (e) {
        Sentry.captureException(e);
      }
      return;
    }

    this.removeProgressListener();
    return;
  };

  handleProgress = (event) => {
    this.onProgressFn({ percentage: event.progress });
  };

  progressListenerFunction = async (options) => {
    if (options.detail.progress != null) {
      this.cutProgressDetail = options.detail;
      try {
        this.handleProgress(options.detail);
      } catch (error) {
        this.onErrorFn(error);
        this.removeProgressListener();
      }
    }
  };

  setProgressListener = () => {
    this.progressListener = this.progressListenerFunction.bind(this);
    window.addEventListener(`cut-${this.fileName}`, this.progressListener);
  };

  removeProgressListener = () => {
    if (this.progressListener) {
      window.removeEventListener(`cut-${this.fileName}`, this.progressListener);
    }
  };

  onProgress(onProgress) {
    this.onProgressFn = onProgress;
    return this;
  }

  async onError(onError) {
    this.onErrorFn = onError;
    return this;
  }
}
