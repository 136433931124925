import PouchDB from 'pouchdb-browser';
var db = new PouchDB('athlyzer-addon-hilfe', { adapter: 'indexeddb' });
var remote = new PouchDB(
  'https://ougstrildedshmenterthade:d02e0db18b5238c5daf7a2ac056d12256e383169@141cb786-8265-4cb3-9b30-200f87e2a2c3-bluemix.cloudantnosqldb.appdomain.cloud/admin-hilfe'
);

remote.replicate
  .to(db)
  .on('complete', function () {
    console.log('hilfe aktualisiert');
  })
  .on('error', function (err) {
    console.log('pouchErr', err);
  });

export default db;
