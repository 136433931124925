import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class BasicsSlideOverComponent extends Component {
  /*   args
  @isOpen : Boolean
  @onClose : function / action
  @title? : string
  */

  @action onClickBackdrop(e) {
    if (e.target && e.target.id && e.target.id == 'slide-over-backdrop') {
      this.args.onClose();
    }
  }
}
