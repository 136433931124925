import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class TeamsTeamAuthenticatedIndexController extends Controller {
  @service router;

  get filteredPlaylists() {
    let filtered = this.model
      .filter((element) => {
        if (element.get('softdeleted')) {
          return !element.get('softdeleted');
        } else {
          // preload szenen
          element.get('playlistszenen');
          return true;
        }
      })
      .sort((a, b) => {
        if (a.sharedate && b.sharedate) {
          return new Date(b.get('sharedate')) - new Date(a.get('sharedate'));
        } else {
          return 0;
        }
      });
    return filtered;
  }
}
