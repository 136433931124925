import Model from '../classes/model';
import { attr, belongsTo } from '@ember-data/model';

export default class ThemaModel extends Model {
  @belongsTo('team') team;

  @attr('string') name;
  @attr('string') text;
  @belongsTo('playlist') playlist;
}
