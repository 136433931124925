import { Clipboard } from '@capacitor/clipboard';
import config from 'athlyzer-coach/config/environment';
import Cross from 'athlyzer-coach/cross/plugin';
import { Browser } from '@capacitor/browser';
import deviceutil from './deviceutil';

customElements.define(
  'modal-share-link',
  class extends HTMLElement {
    connectedCallback() {
      const modalElement = document.getElementById('modal-share-link');
      let innerHTML = `
  <ion-header>
    <ion-toolbar>
    <ion-buttons slot="start">
        <ion-button id="prompt-modal-close">
          Zurück
        </ion-button>
      </ion-buttons>
      <ion-title>${modalElement.componentProps.title}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
  <form id="prompt-model-form">
        <ion-item>
        <ion-label>Link</ion-label>
            <ion-input type="numeric" min="0" id="prompt-modal-reps" value=${modalElement.componentProps.link} type="number"></ion-input>
        </ion-item>
        <ion-button id="prompt-modal-copy-to-clipboard" expand='full'>
          Link in Zwischenablage kopieren
        </ion-button>
    </form>
    <ion-button shape="round" id="prompt-modal-facebook-button">
      Facebook
    </ion-button>
    
    <ion-button shape="round" id="prompt-modal-whatsapp-button">
      Whatsapp
    </ion-button>
    <ion-button shape="round" id="prompt-modal-email-button">
Email
</ion-button>`;
      if (deviceutil.isNotElectron) {
        innerHTML += `<ion-button shape="round" id="prompt-modal-mehr-button">
Mehr
</ion-button>`;
      }
      innerHTML += `
  </ion-content>
  <ion-footer>
    <ion-button expand='full' id='prompt-modal-close-2'>Schliessen</ion-button>
  </ion-footer>`;
      this.innerHTML = innerHTML;
      const closeButton = modalElement.querySelector('#prompt-modal-close');
      const closeButton2 = modalElement.querySelector('#prompt-modal-close-2');
      const facebookButton = modalElement.querySelector(
        '#prompt-modal-facebook-button'
      );
      const whatsappButton = modalElement.querySelector(
        '#prompt-modal-whatsapp-button'
      );
      const emailButton = modalElement.querySelector(
        '#prompt-modal-email-button'
      );

      function openBrowser(url) {
        if (deviceutil.isElectron) {
          Cross.openBrowser({ url: url });
        } else {
          Browser.open({ url: url });
        }
      }

      facebookButton.addEventListener('click', () => {
        openBrowser(
          'https://www.facebook.com/sharer/sharer.php?u=' +
            modalElement.componentProps.linkEncoded
        );
      });

      whatsappButton.addEventListener('click', () => {
        openBrowser(
          'https://wa.me/?text=' + modalElement.componentProps.linkEncoded
        );
      });

      emailButton.addEventListener('click', () => {
        openBrowser('mailto: ?body=' + modalElement.componentProps.linkEncoded);
      });

      if (deviceutil.isNotElectron) {
        const mehrButton = modalElement.querySelector(
          '#prompt-modal-mehr-button'
        );
        mehrButton.addEventListener('click', () => {
          openBrowser(
            config.appurl +
              '/share?u=' +
              modalElement.componentProps.linkEncoded
          );
        });
      }

      closeButton.addEventListener('click', () => {
        modalElement.componentProps.dismiss();
      });
      closeButton2.addEventListener('click', () => {
        modalElement.componentProps.dismiss();
      });

      const copyToClipboardButton = modalElement.querySelector(
        '#prompt-modal-copy-to-clipboard'
      );
      copyToClipboardButton.addEventListener('click', async () => {
        await Clipboard.write({
          string: modalElement.componentProps.link,
        });
        copyToClipboardButton.innerHTML = 'Link wurde erfolgreich kopiert';
      });
    }

    disconnectedCallback() {}
  }
);

export default async function createModalShareLink(
  title,
  linkToShare,
  addonUser
) {
  let link = linkToShare;
  if (addonUser) {
    //share shortended link
    const response = await addonUser.getHttp().post('/s', {
      url: encodeURI(linkToShare),
    });

    if (response?.data?._id) {
      console.log('yes', config.hostaddress);
      link = config.hostaddress + '/s/' + response.data._id;
    }
  }

  const modalElement = document.createElement('ion-modal');
  modalElement.component = 'modal-share-link';
  modalElement.id = 'modal-share-link';
  modalElement.componentProps = {
    link,
    linkEncoded: encodeURI(link),
    title,
    dismiss: (opt) => {
      console.log('opt', opt);
      modalElement.dismiss(opt);
    },
  };
  document.body.appendChild(modalElement);
  await modalElement.present();
  const { data, role } = await modalElement.onDidDismiss();
  modalElement.parentElement.removeChild(modalElement);

  if (role === 'backdrop') {
    return { value: false, cancelled: true };
  } else {
    return data;
  }
}
