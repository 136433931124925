import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'athlyzer-coach/config/environment';

export default class TeamsTeamIndexController extends Controller {
  queryParams = ['key'];
  @tracked key;
  @tracked passwort = '';
  @service team;
  @service paperToaster;
  @service intl;
  @service router;
  @service addonCore;

  @action async login() {
    if (this.isLoading) {
      return;
    }
    if (!this.key || !this.passwort) {
      console.log(this.key, this.passwort);
      return this.paperToaster.show('Zugangscode eingeben');
    }
    this.passwort = this.passwort.trim();
    this.isLoading = true;
    let key = atob(this.key);
    let fullKey = window.location.href.split('key=')[1];
    key = atob(fullKey);
    let session = key + this.passwort;
    let base64session = btoa(`${session}`);
    let response = await fetch(config.hostaddress + '/teams/legacy-token', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        dbname: this.team.aktivesTeam,
        legacySession: fullKey,
      }),
    });
    const newToken = await response.json();

    if (newToken.token) {
      session = newToken.token;
      base64session = btoa(`${session}`);
    }
    var headers = new Headers();
    headers.append('Authorization', 'Basic ' + base64session);
    let test = await fetch(
      `${this.team.datenbankurl}/${this.team.aktivesTeam}`,
      {
        headers: headers,
      }
    );

    if (test.ok) {
      this.router.transitionTo('teams.team.authenticated', base64session);
    } else {
      this.paperToaster.show(this.intl.t('Passwort falsch!'));
    }
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }
}
