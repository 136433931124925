import CommandBuilder from '@jakope/ffmpeg-fo';
import Cross from 'athlyzer-coach/cross/plugin';
import { Directory, Filesystem, Encoding } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import uuid from 'athlyzer-coach/application/uuid';
import deviceutil from '../classes/deviceutil';
import * as Sentry from '@sentry/ember';
class FfmpegCommandBuilder extends CommandBuilder {
  static folder = null;

  static async fileExists(filepath, filename) {
    try {
      let exists;
      if (deviceutil.isElectron) {
        const fileCheck = await Cross.fileExists({
          videourl: filepath,
        });

        exists = fileCheck.exists;
      } else if (deviceutil.isAndroid) {
        const fileCheck = await Cross.fileExists({
          videourl: filepath,
        });

        exists = fileCheck.exists;
      } else {
        const fileCheck = await Cross.fileExists({
          videourl: filename,
        });

        exists = fileCheck.exists;
      }

      return exists;
    } catch (error) {
      return false;
    }
  }

  static async createFile(filepath, value) {
    if (deviceutil.isElectron) {
      const result = await Cross.writeStringToFile({
        filepath: filepath,
        value: value,
      });
      console.log('file created successfully ', result);
    } else {
      console.log('createFIle ios');
      const result = await Filesystem.writeFile({
        path: 'merge.txt',
        directory: Directory.Documents,
        data: value,
        encoding: Encoding.UTF8,
        recursive: true,
      });
      console.log('file created successfully ', result);
    }

    return;
  }

  static async createFolder(outputFilepath) {
    // const folder = path.dirname(outputFilepath);
    // await fs.promises.mkdir(folder, { recursive: true });
    // return folder;
  }

  static async deleteFile(outputFilepath) {
    // await fs.promises.rm(outputFilepath, { recursive: true });
  }

  static async deleteFolder(outputFilepath) {
    // const folder = path.dirname(outputFilepath);
    // await fs.promises.rm(folder + "/*", { recursive: true });
  }

  static async progressListenerFunction(event) {
    this.processStdOut(event.detail);
  }

  static async execute(commandArray, self, logErrors = true) {
    let error, success, eventListenerSet;

    try {
      const eventName = `ffmpeg-${uuid()}`;

      if (self) {
        self.progressListener =
          self.constructor.progressListenerFunction.bind(self);

        window.addEventListener(eventName, self.progressListener);
        eventListenerSet = true;
      }

      const answer = await Cross.executeFfmpegCommand({
        command: commandArray,
        eventName,
      });

      if (eventListenerSet) {
        window.removeEventListener(eventName, self.progressListener);
      }

      let success = true;
      let error = false;

      if (logErrors && answer instanceof Error) {
        console.log('FFMPEG Relevant Error', answer);
        success = false;
        error = true;
        Sentry.captureException(answer);
      }

      return {
        ...answer,
        success,
        error,
      };
    } catch (err) {
      console.log('catch', err);
      error = err;
      success = false;
      Sentry.captureException(err);
    }
    return {
      success,
      error,
    };
  }

  static async storeSettings(name, data) {
    console.log('store settings', name, data);
    await Preferences.set({ key: name, value: data });
    return true;
  }

  static async loadSettings(name) {
    const preferences = await Preferences.get({ key: name });
    console.log('ffmpeg load settings', preferences.value);
    return preferences.value;
  }
}

if (deviceutil.isNative) {
  FfmpegCommandBuilder.initialized = new Promise(async (resolve) => {
    let folder;
    if (deviceutil.isApp) {
      folder = (await Cross.folder()).path;
      // folder = folder.replace('file://', '');

      if (folder[folder.length - 1] === '/') {
        folder = folder.slice(0, -1);
      }
    } else if (deviceutil.isElectron) {
      folder = window.cross.getVideoexportPath();
    }

    const autoFindHwaccel = true;
    const forceAutoFindHwaccel = false; // should be false, only true for dev

    FfmpegCommandBuilder.initialize({
      folder,
      autoFindHwaccel,
      forceAutoFindHwaccel,
    })
      .then(() => {
        console.log(
          'FfmpegCommandBuilder InitializeCommandBuilder.after',
          FfmpegCommandBuilder.folder,
          FfmpegCommandBuilder.videocodex
        );
        resolve(true);
      })
      .catch((err) => {
        console.log('FfmpegCommandBuilder err1', err);
        resolve(false);
      });
  });
} else {
  console.log('FfmpegCommandBuilder is NOT Native');
  FfmpegCommandBuilder.initialized = new Promise((r) => r(true));
}

export default FfmpegCommandBuilder;
