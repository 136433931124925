import Model from '../classes/model';
import { attr, belongsTo } from '@ember-data/model';

class SchemaFastModel extends Model {
  typ = 'schema';
  @attr('string')
  name;

  icon = 'dashboard';

  @attr('boolean', { defaultValue: false }) highlight;
  get unhighlighted() {
    return !this.highlight;
  }

  @belongsTo('ordner')
  ordner;
}

SchemaFastModel.reopenClass({
  documentType: 'schema',
});

export default SchemaFastModel;
