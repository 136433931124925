import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
export default class NavigationService extends Service {
  @tracked selectedBasicTab = 0;

  @action setTab(tab) {
    this.selectedBasicTab = tab;
  }
}
