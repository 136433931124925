import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import config from 'athlyzer-coach/config/environment';

export default class TeamRoute extends Route {
  @service team;
  @service metrics;

  async model() {
    let host = await (await fetch(config.hostaddress + '/host')).json();
    this.team.datenbankurl = host.datenbankurl;
    return host;
  }

  afterModel() {
    this.metrics.track('Teams Session Started');
  }
}
