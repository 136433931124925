const chatlog = function (msg) {
  chatelement = document.getElementById('chatlog');
  newchatentry = document.createElement('p');
  newchatentry.textContent = '[' + new Date() + '] ' + msg;
  chatelement.appendChild(newchatentry);
  chatelement.scrollTop = chatelement.scrollHeight;
};

const createPeerConnection = function (lasticecandidate) {
  let peerConnection;
  let configuration = {
    iceServers: [
      {
        urls: [
          'stun:stun.l.google.com:19302',
          'stun:stun1.l.google.com:19302',
          'stun:stun2.l.google.com:19302',
          'stun:stun3.l.google.com:19302',
          'stun:stun4.l.google.com:19302',
        ],
      },
    ],
  };
  try {
    peerConnection = new RTCPeerConnection(configuration);
  } catch (err) {
    console.log('error', err);
    //   chatlog('error: ' + err);
  }
  peerConnection.onicecandidate = handleicecandidate(lasticecandidate);
  peerConnection.onconnectionstatechange = handleconnectionstatechange;
  peerConnection.oniceconnectionstatechange = handleiceconnectionstatechange;
  peerConnection.onsignalingstatechange = (ev) => {
    console.log('signalingState', peerConnection.signalingState);
  };
  return peerConnection;
};

const handleicecandidate = function (lasticecandidate) {
  return function (event) {
    if (event.candidate != null) {
      console.log('new ice candidate');
    } else {
      console.log('all ice candidates');
      lasticecandidate();
    }
  };
};

const handleconnectionstatechange = function (event) {
  console.log('handleconnectionstatechange');
  console.log(event);
};

const handleiceconnectionstatechange = function (event) {
  console.log('ice connection state: ' + event.target.iceConnectionState);
};

const datachannelopen = function () {
  console.log('datachannelopen');
  chatlog('connected');
  document.getElementById('chatinput').disabled = false;
  document.getElementById('chatbutton').disabled = false;
};

const datachannelmessage = function (message) {
  console.log('datachannelmessage');
  console.log(message);
  text = message.data;
  chatlog(text);
};

const chatbuttonclick = function () {
  console.log('chatbuttonclick');
  textelement = document.getElementById('chatinput');
  text = textelement.value;
  dataChannel.send(text);
  chatlog(text);
  textelement.value = '';
};

export { createPeerConnection, datachannelopen };
