import Model from '../classes/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class PlaylistModel extends Model {
  typ = 'playlist';
  @attr('string') name;

  @belongsTo('videobearbeitung') videobearbeitung;

  @belongsTo('videocollection') videocollection;

  @hasMany('playlistszene') playlistszenen;

  @attr('string') zugangscode;

  @tracked aktiveLabels = [];

  @tracked isChange = false;
  @tracked isOpen = false;

  @attr('number', { defaultValue: 0 }) position;

  @attr('number') version;

  @attr('', { defaultValue: () => null }) firstIntroImage;
  @attr('', { defaultValue: () => null }) secondIntroImage;
  @attr('', { defaultValue: () => null }) outroImage;

  get videoszenenIds() {
    if (this.playlistszenen && this.playlistszenen.length > 0) {
      return this.playlistszenen.map((ps) => ps.belongsTo('videoszene').id());
    } else {
      return [];
    }
  }

  get videoszenen() {
    if (this.playlistszenen && this.playlistszenen.length > 0) {
      return this.playlistszenen.map((ps) => ps.get('videoszene'));
    } else {
      return [];
    }
  }

  selected = []; // Hilfsvariable, die nicht online synchronisiert wird

  get playlistszenenSelected() {
    if (this.playlistszenen && this.playlistszenen.length > 0) {
      return this.playlistszenen.filter((vs) => vs.get('selected'));
    } else {
      return [];
    }
  }

  get isPlaylistSharable() {
    let sharable = true;
    if (this.playlistszenen && this.playlistszenen.length > 0) {
      this.playlistszenen.forEach((element) => {
        if (
          !element ||
          !element.get('videoszene') ||
          !element.get('videoszene').get('videoclip')
        ) {
          sharable = false;
        } else if (
          element.get('videoszene').get('videoclip').get('exists') === false
        ) {
          sharable = false;
        }
      });
    } else {
      sharable = false;
    }
    return sharable;
  }

  get isPlaylistExportable() {
    let playlistszenen = this.playlistszenenSelected;
    let exportable = 'fast';
    if (playlistszenen && playlistszenen.length > 0) {
      if (this.isChange || !this.isChange) {
        for (const element of playlistszenen) {
          if (!element) {
            exportable = false;
            break;
          }
          if (!element.belongsTo('videoszene').id()) {
            exportable = false;
            break;
          }
          const videoszene = element.get('videoszene');
          if (videoszene.get('exists') === false) {
            if (!videoszene.belongsTo('videoclip').id()) {
              exportable = false;
              break;
            }
            if (videoszene.get('videoclip').get('existsOnNAS')) {
              exportable = 'slow';
            } else {
              exportable = false;
              break;
            }
          }
        }
      }
    } else {
      exportable = false;
    }
    return exportable;
  }
}
