import deviceutil from 'athlyzer-coach/classes/deviceutil';
import { tracked } from '@glimmer/tracking';
import Cross from 'athlyzer-coach/cross/plugin';
import * as Sentry from '@sentry/ember';

export default class MultiPartUploader {
  @tracked finished = false;
  @tracked aborted = false;
  @tracked progressPercentage = 0;
  @tracked fileName = null;
  @tracked sentSize = 0;
  @tracked uploadedSize = 0;
  @tracked fileSize = 0;
  @tracked taskKey = '';
  @tracked taskName = '';
  @tracked taskInstanceMap = null;

  constructor(options) {
    this.taskKey = options.taskKey;
    this.weburl = '';
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.apiClient = options.apiClient;
    this.chunkSize = options.chunkSize || 1024 * 1024 * 5;
    this.fileSize = options.fileSize;
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity || 10, 10);
    this.file = options.file;
    this.fileName = options.fileName;
    this.taskName = options.taskName;
    this.taskInstanceMap = options.taskInstanceMap;

    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = null;
    this.fileKey = null;
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
  }

  start() {
    return this.initialize();
  }

  initialize = async () => {
    try {
      let filePathToFetchBlob;
      if (deviceutil.isElectron) {
        filePathToFetchBlob = `athlyzer-stream://${this.file.replace(
          'DOCUMENTS',
          'documents'
        )}`;
      } else if (deviceutil.isAndroid) {
        const { exists } = await Cross.fileExists({
          filepath: this.file,
        });
        if (exists) {
          const path = this.file;
          // remove double slashes form path
          let pathWithoutDoubleSlashes = path.replace(/\/\//g, '/');
          let uri = Capacitor.convertFileSrc(pathWithoutDoubleSlashes);
          if (uri.indexOf('localhost') === -1) {
            uri = uri.replace(
              /\b(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)(?::\d{0,4})?\b/,
              'localhost'
            );
          }
          filePathToFetchBlob = uri;
        }
      } else if (deviceutil.isIos) {
        const { exists } = await Cross.fileExists({
          videourl: this.file,
        });

        if (exists) {
          let directory = await Cross.folder();
          const path = directory.path + this.file;

          let uri = Capacitor.convertFileSrc(path);
          if (uri.indexOf('localhost') === -1) {
            uri = uri.replace('\b(?:[0-9]{1,3}.){3}[0-9]{1,3}\b', 'localhost');
          }
          filePathToFetchBlob = uri;
        }
      }
      this.filePathToFetchBlob = filePathToFetchBlob;
      console.log('filePathToFetchBlob', filePathToFetchBlob);

      // initializing the multipart request
      const videoInitializationUploadInput = {
        fileName: this.fileName,
      };

      const initializeReponse = await this.apiClient.request({
        url: '/user/videos/initializeMultipartUpload',
        method: 'POST',
        data: videoInitializationUploadInput,
      });

      const AWSFileDataOutput = initializeReponse.data;

      this.fileId = AWSFileDataOutput.fileId;
      this.fileKey = AWSFileDataOutput.fileKey;

      // retrieving the pre-signed URLs
      const numberOfparts = Math.ceil(this.fileSize / this.chunkSize);

      const AWSMultipartFileDataInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: numberOfparts,
      };

      const urlsResponse = await this.apiClient.request({
        url: '/user/videos/getMultipartPreSignedUrls',
        method: 'POST',
        data: AWSMultipartFileDataInput,
      });

      const newParts = urlsResponse.data.parts;

      this.parts.push(...newParts);
      // this.set('parts', this.parts.reverse());
      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  };

  sendNext = async () => {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (this.aborted) return;

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.pop();

    if (part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;

      const chunk = await this.fetchChunkFromLocalFile(
        sentSize,
        sentSize + this.chunkSize - 1
      );

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);

          this.complete(error);
        });
    }
  };

  complete = async (error) => {
    if (error && (error instanceof ProgressEvent || error.type === 'error')) {
      error = new Error('Failed chunk upload');
    }

    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
    } catch (error) {
      this.onErrorFn(error);
    }
  };

  sendCompleteRequest = async () => {
    if (this.fileId && this.fileKey) {
      const videoFinalizationMultiPartInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: this.uploadedParts,
      };

      try {
        const finalResponse = await this.apiClient.request({
          url: '/user/videos/finalizeMultipartUpload',
          method: 'POST',
          data: videoFinalizationMultiPartInput,
        });

        this.weburl = finalResponse?.data?.Location;
        this.finished = true;
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  };

  sendChunk = (chunk, part, sendChunkStarted) => {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  handleProgress = (part, event) => {
    if (this.filePathToFetchBlob) {
      if (
        event.type === 'progress' ||
        event.type === 'error' ||
        event.type === 'abort'
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === 'loadend') {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.fileSize);

      const total = this.fileSize;

      const percentage = Math.round((sent / total) * 100);

      this.progressPercentage = percentage;

      if (this.taskKey) {
        const progressElement = document.getElementById(
          `video-progress-${this.taskKey}`
        );
        if (progressElement) {
          progressElement.innerHTML = `${percentage.toFixed(1)}%`;
        }
      }

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  };

  fetchChunkFromLocalFile = async (lastSentByte, lastByte) => {
    let blob;

    if (deviceutil.isWeb) {
      if (lastByte >= this.fileSize) {
        return this.file.slice(lastSentByte);
      } else {
        return this.file.slice(lastSentByte, lastByte + 1);
      }
    } else {
      blob = fetch(this.filePathToFetchBlob, {
        headers: {
          Range: `bytes=${lastSentByte}-${lastByte}`,
        },
      });
    }

    const response = await blob;
    const reader = response.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        return pump();

        function pump() {
          return reader.read().then(({ done, value }) => {
            // When no more data needs to be consumed, close the stream
            if (done) {
              controller.close();
              return;
            }

            // Enqueue the next data chunk into our target stream
            controller.enqueue(value);
            return pump();
          });
        }
      },
    });
    const responseStream = new Response(stream);
    const responseBlob = responseStream.blob();

    return responseBlob;
  };

  upload = (file, part, sendChunkStarted) => {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        const xhr = (this.activeConnections[part.PartNumber - 1] =
          new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          part.PartNumber - 1
        );

        xhr.upload.addEventListener('progress', progressListener);

        xhr.addEventListener('error', progressListener);
        xhr.addEventListener('abort', progressListener);
        xhr.addEventListener('loadend', progressListener);

        xhr.open('PUT', part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 408) {
            reject(new Error('Request timeout'));
            delete this.activeConnections[part.PartNumber - 1];
          }

          if (xhr.readyState === 4 && xhr.status === 200) {
            const ETag = xhr.getResponseHeader('ETag');

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                ETag: ETag.replaceAll('"', ''),
              };

              this.uploadedParts.push(uploadedPart);
              resolve(xhr.status);
              delete this.activeConnections[part.PartNumber - 1];
            }
          }
        };

        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'));
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.send(file);
      }
    });
  };

  onProgress(onProgress) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError) {
    this.onErrorFn = onError;
    return this;
  }

  cancelTask = () => {
    const taskInstance = this.taskInstanceMap[this.taskKey];
    console.log('this.taskInstanceMap', this.taskInstanceMap);
    if (taskInstance && !taskInstance.isCanceled) {
      taskInstance.cancel();
    }
  };

  abort = () => {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  };

  getFinalWebUrl = () => {
    return 'https://s3-eu-central-1.ionoscloud.com/' + this.weburl;
  };
}
