export default function (date) {
  if (!date || date == 0) {
    return String('00:00');
  }
  let datum = new Date(date * 1000).toISOString();
  let hours = Number(datum.substr(11, 2));
  let minutes = Number(datum.substr(14, 2));
  let seconds = Number(datum.substr(17, 2));
  if (seconds <= 9) {
    seconds = String(seconds);
    seconds = '0' + seconds;
  }

  minutes = hours * 60 + minutes;
  if (minutes <= 9) {
    minutes = String(minutes);
    minutes = '0' + minutes;
  }
  let returnString = minutes + ':' + seconds;
  return String(returnString);
}
