export default {
  isTwitchVideoLink(link) {
    return link.indexOf('https://www.twitch.tv/videos/') == 0;
  },
  isYoutubeVideoLink(link) {
    return (
      link.indexOf('https://youtube.com') == 0 ||
      link.indexOf('https://youtu.be') == 0 ||
      link.indexOf('https://www.youtube.com') == 0
    );
  },
};
