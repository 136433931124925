const QUALITIES = {
  MAXIMUM: {
    BEGINNERFFMPEGPROFILE: 'FULLHDMAX',
    NAME: 'MAXIMUM',
    TEXT: 'Maximum - Full HD (1080p)',
    BITRATE: ['-b:v', '9M'],
    SCALING: [
      '-filter_complex',
      'scale=w=1920:h=1080:force_original_aspect_ratio=decrease',
    ],
    WIDTH: 1920,
    HEIGHT: 1080,
    ALERT_BUTTON: (resolve) => {
      return {
        text: QUALITIES.MAXIMUM.TEXT,
        handler: () => {
          const val = QUALITIES['MAXIMUM'];
          resolve({
            cancelled: false,
            value: QUALITIES.MAXIMUM,
            exportQuality: 'MAXIMUM',
          });
        },
      };
    },
  },
  HIGH: {
    BEGINNERFFMPEGPROFILE: 'FULLHD',
    NAME: 'HIGH',
    TEXT: 'Hoch - Full HD (1080p)',
    BITRATE: ['-b:v', '6M'],
    SCALING: [
      '-filter_complex',
      'scale=w=1920:h=1080:force_original_aspect_ratio=decrease',
    ],
    WIDTH: 1280,
    HEIGHT: 1080,
    ALERT_BUTTON: (resolve) => {
      return {
        text: QUALITIES.HIGH.TEXT,
        handler: () => {
          resolve({
            cancelled: false,
            value: QUALITIES.HIGH,
            exportQuality: 'HIGH',
          });
        },
      };
    },
  },
  MEDIUM: {
    BEGINNERFFMPEGPROFILE: 'HDREADY',
    NAME: 'MEDIUM',
    TEXT: 'Mittel - HD Ready (720p)',
    BITRATE: ['-b:v', '3M'],
    SCALING: [
      '-filter_complex',
      'scale=w=1280:h=720:force_original_aspect_ratio=decrease',
    ],
    WIDTH: 1280,
    HEIGHT: 720,
    ALERT_BUTTON: (resolve) => {
      return {
        text: QUALITIES.MEDIUM.TEXT,
        handler: () => {
          resolve({
            cancelled: false,
            value: QUALITIES.MEDIUM,
            exportQuality: 'MEDIUM',
          });
        },
      };
    },
  },
};

export default {
  QUALITIES,
  defaultQualityString: 'HIGH',
  isValidExportQualityString(str) {
    return Object.keys(QUALITIES).indexOf(str) > -1;
  },
  getExportQualityStrings() {
    return Object.keys(QUALITIES);
  },
  // TODO
  async getMaximumResolution(
    originalWidth,
    originalHeight,
    qualityOfOtherVideoclips
  ) {
    return {
      cancelled: false,
      value: QUALITIES.MAXIMUM,
      exportQuality: 'MAXIMUM',
    };
  },
  selectResolution(
    originalWidth,
    originalHeight,
    qualityOfOtherVideoclips,
    inputClipQualityRequirement
  ) {
    const isResolutionHigherThanFullHD = originalHeight >= 1080;

    return new Promise(async (resolve) => {
      const alert = document.createElement('ion-action-sheet');
      //alert.cssClass = 'my-custom-class';
      alert.header = 'Auflösung wählen';
      alert.mode = 'md';
      alert.buttons = [
        {
          text: 'Abbrechen',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            resolve({ cancelled: true, value: '', exportQuality: '' });
          },
        },
      ];

      if (qualityOfOtherVideoclips && qualityOfOtherVideoclips.height) {
        // es gibt andere Videoclips und es liegen Informationen zur Qualität vor
        if (
          !qualityOfOtherVideoclips.bitrate ||
          qualityOfOtherVideoclips.bitrate >= 3000000
        ) {
          alert.buttons = [
            ...alert.buttons,
            QUALITIES.MEDIUM.ALERT_BUTTON(resolve),
          ];
        }
        if (
          isResolutionHigherThanFullHD &&
          (!qualityOfOtherVideoclips.height ||
            qualityOfOtherVideoclips.height >= 1080)
        ) {
          if (
            !qualityOfOtherVideoclips.bitrate ||
            qualityOfOtherVideoclips.bitrate >= 6000000
          ) {
            alert.buttons = [
              ...alert.buttons,
              QUALITIES.HIGH.ALERT_BUTTON(resolve),
            ];
          }
          if (
            !qualityOfOtherVideoclips.bitrate ||
            qualityOfOtherVideoclips.bitrate >= 9000000
          ) {
            alert.buttons = [
              ...alert.buttons,
              QUALITIES.MAXIMUM.ALERT_BUTTON(resolve),
            ];
          }
        }
      } else if (inputClipQualityRequirement) {
        alert.buttons = [
          ...alert.buttons,
          QUALITIES.MEDIUM.ALERT_BUTTON(resolve),
        ];

        if (
          !inputClipQualityRequirement.height ||
          inputClipQualityRequirement.height >= 1080
        ) {
          if (
            !inputClipQualityRequirement.bitrate ||
            inputClipQualityRequirement.bitrate >= 6000000
          ) {
            alert.buttons = [
              ...alert.buttons,
              QUALITIES.HIGH.ALERT_BUTTON(resolve),
            ];
          }
          if (
            !inputClipQualityRequirement.bitrate ||
            inputClipQualityRequirement.bitrate >= 9000000
          ) {
            alert.buttons = [
              ...alert.buttons,
              QUALITIES.MAXIMUM.ALERT_BUTTON(resolve),
            ];
          }
        }
      } else {
        alert.buttons = [
          ...alert.buttons,
          QUALITIES.MAXIMUM.ALERT_BUTTON(resolve),
          QUALITIES.HIGH.ALERT_BUTTON(resolve),
          QUALITIES.MEDIUM.ALERT_BUTTON(resolve),
        ];
      }

      console.log('qualityOfOtherVideoclips', qualityOfOtherVideoclips);

      document.body.appendChild(alert);
      await alert.present();
    });
  },
};
