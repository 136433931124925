import { DateTime } from 'luxon';

export function getNewDateAsStringWithTime() {
  return String(DateTime.now().toFormat('dd.LL.yyyy, HH:mm'));
}

export function getNewDateAsString() {
  return String(DateTime.now().toFormat('dd.LL.yyyy'));
}

export function getNewDateAsTimeString() {
  return String(DateTime.now().toFormat('HH:mm'));
}
