import Service from './counters';
import Konva from 'konva';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';

Konva.pixelRatio = 1;
export default class VideoDrawService extends Service {
  // DRAW CONSTANTS
  MIN_DISTANCE_MULTIPLIER = 5;
  MIN_DISTANCE_FREE_ARROW = 60;
  ANGLE_MULTIPLIER = 2;
  OFFSET_FREE_LINE = 6;
  OFFSET_FREE_ARROW = 81;
  DRAW_MODE_NEW = 'new';
  DRAW_MODE_TRANSFORM = 'transform';
  TRANSFORMER_PREFIX = 'transformer';
  POLYGON_PREFIX = 'polygon';
  CLOSE_DISTANCE_THRESHOLD = 30;

  // Video Attributes
  @tracked isPausedForLayer = false;
  @tracked animationRequestID;
  @tracked preciseCurrentTime;
  temporaryIgnoreLayerTimestamp;
  @tracked drawLayer = null;

  //Zeichenelemente
  @tracked activeShape;
  @tracked allShapes = [];

  //Leinwand
  @tracked stage;
  backgroundLayer;
  @tracked layer;
  @tracked initWidth;
  @tracked initHeight;

  //Flags für das Zeichnen
  @tracked drawMode;
  @tracked needNewPointForFreeLine = false;
  @tracked needClickForNextPoint = false;
  @tracked showDrawGUI = window.innerHeight > 700 ? true : false;

  //Flag für Text
  @tracked addText;
  @tracked userText;
  @tracked inputText;

  //Daten für das Zeichnen
  @tracked pointOfOrigin;
  @tracked selectedShapeType;
  @tracked activeShapeType = 'noShape';
  @tracked currentlyTransforming = false;
  @tracked idCounter = 0;
  @tracked stroke = 'red';
  @tracked backgroundColor = 'black';
  @tracked circleBackgroundColor = 'transparent';
  @tracked rectangleBackgroundColor = 'transparent';
  @tracked polygonBackgroundColor = '#a3000066';
  @tracked wedgeBackgroundColor = '#a3000066';
  @tracked strokeWidth = 9;
  @tracked textFontSize = 18;
  @tracked angle = 120;

  @tracked currentDrawingSetting = null;
  @tracked strokeIcon = 'filter-t3';
  @tracked rotateX = 0;
  @tracked rotateY = 0;

  @tracked doentEditDraw = false;
  @tracked readOnlyDrawMode = false;

  lineWidths = [1, 3, 5, 7, 9];

  // HELPERS

  @action getPointerPosition() {
    let pointer = this.stage.getPointerPosition();
    pointer.x = pointer.x / this.stage.scaleX();
    pointer.y = pointer.y / this.stage.scaleY();

    return pointer;
  }

  createRandomString() {
    return Math.random().toString(36).substring(4);
  }

  @action getActiveShapeType() {
    let shapeID = this.activeShape.attrs.id;
    shapeID = shapeID.split('-');
    shapeID = shapeID[0];
    return shapeID;
  }

  // Transformer löschen
  @action destroyTransformer() {
    let anchorGroup = this.layer.findOne(
      (node) =>
        node.id().startsWith('transformer') &&
        (node instanceof Konva.Group || node instanceof Konva.Transformer)
    );

    if (anchorGroup) {
      anchorGroup.destroy();

      this.layer.draw();
      this.activeShape = null;
      this.selectedShapeType = null;
    }

    this.sendPaint();
  }

  @action whoAmI(target, stage) {
    // Directly comparing target with stage
    if (target === stage) {
      return 'stage';
    }

    // Using destructuring to retrieve the ID from target.attrs
    const targetId = target?.attrs?.id;

    // Check if the target ID matches the active shape ID
    if (this.activeShape && targetId === this.activeShape.attrs.id) {
      return 'activeShape';
    }

    // Check for transformer-related conditions
    if (
      targetId?.startsWith('transformer') ||
      target instanceof Konva.Transformer
    ) {
      return 'transformer';
    }

    // If the target has an ID, then it's not the active shape
    if (targetId) {
      return 'notActiveShape';
    }

    // Default return for unidentified targets
    return 'Unknown';
  }

  @action mousedownCanvasHandler(e) {
    if (this.doentEditDraw) return;

    let keyElement = this.whoAmI(e.target, this.stage);

    switch (keyElement) {
      case 'stage':
        this.handleStageMouseDown();
        break;
      case 'transformer':
        this.drawMode = this.DRAW_MODE_TRANSFORM;
        return false;
      case 'activeShape':
        this.handleActiveShapeMouseDown(e.target);
        break;
      case 'notActiveShape':
        this.activateShape(e.target);
        this.activeShapeType = this.getActiveShapeType();
        break;
      default:
        break;
    }

    this.layer.draw();
  }

  handleStageMouseDown() {
    if (!this.drawMode) {
      this.destroyTransformer(this.stage);
    }

    if (this.currentlyTransforming) {
      this.deactivateDrawings();
      this.currentlyTransforming = false;
    }

    if (
      this.selectedShapeType &&
      !this.currentlyTransforming &&
      !this.needClickForNextPoint
    ) {
      this.drawMode = this.DRAW_MODE_NEW;
      this.addNewElement();

      if (this.activeShape?.attrs?.id?.startsWith(this.POLYGON_PREFIX)) {
        this.needClickForNextPoint = true;
      } else if (this.activeShape) {
        this.needNewPointForFreeLine = true;
      }

      this.layer.add(this.activeShape);
      this.sendPaint();
    } else if (this.activeShape && this.needClickForNextPoint) {
      this.handlePolygonDrawing();
    }
  }

  handlePolygonDrawing() {
    const pointer = this.getPointerPosition();
    const points = this.activeShape.points();

    const distanceToFirstPoint = this.calculateDistanceBetweenPoints(
      points[0],
      points[1],
      pointer.x,
      pointer.y
    );

    if (
      points.length > 4 &&
      distanceToFirstPoint < this.CLOSE_DISTANCE_THRESHOLD
    ) {
      this.closePolygon();
    } else {
      points.push(pointer.x, pointer.y);
      this.activeShape.points(points);
    }
  }

  handleActiveShapeMouseDown(target) {
    if (
      this.activeShape &&
      this.needClickForNextPoint &&
      target.attrs?.id?.startsWith(this.POLYGON_PREFIX)
    ) {
      this.handlePolygonDrawing();
    } else {
      this.activateShape(target);
      this.activeShapeType = this.getActiveShapeType();
    }
  }

  closePolygon() {
    const points = this.activeShape.points();
    this.activeShape.points(points.slice(0, points.length - 2));
    this.activeShape.closed(true);
    this.activeShape.strokeWidth(this.strokeWidth);
    this.activeShape.fill(this.polygonBackgroundColor);
    this.needClickForNextPoint = false;
    this.removePolygonClosingHintCircle();
    this.sendPaint();
  }

  removePolygonClosingHintCircle() {
    let connectCircle = this.layer.findOne(
      (node) =>
        node.id() === 'polygon-connect-circle' && node instanceof Konva.Circle
    );

    if (connectCircle) {
      connectCircle.destroy();

      this.layer.draw();
    }
  }

  @action mousemoveCanvasHandler(e) {
    if (this.doentEditDraw) return;

    let pointer = this.getPointerPosition();

    if (this.needClickForNextPoint && this.isActiveShapePolygon()) {
      this.updatePolygonPoints(pointer);
      this.addPulseCircleForPolygonClosingHint();
    } else {
      let target = e.target;
      if (this.drawMode == this.DRAW_MODE_NEW) {
        this.drawAndSendShape(pointer);
      } else if (
        this.isTargetTransformer(target) &&
        this.drawMode === this.DRAW_MODE_TRANSFORM
      ) {
        this.transformAndSendShape(target, pointer);
      }
    }
  }

  isActiveShapePolygon() {
    return this.activeShape?.attrs?.id?.startsWith(this.POLYGON_PREFIX);
  }

  updatePolygonPoints(pointer) {
    const points = this.activeShape.points();
    if (points.length > 2) {
      points.splice(points.length - 2, 2);
    }
    points.push(pointer.x, pointer.y);
    this.activeShape.points(points);
  }

  addPulseCircleForPolygonClosingHint() {
    const points = this.activeShape.points();

    let connectCircle = this.layer.findOne(
      (node) =>
        node.id() === 'polygon-connect-circle' && node instanceof Konva.Circle
    );

    if (!connectCircle && points.length > 6) {
      var anchor = new Konva.Circle({
        x: points[0],
        y: points[1],
        radius: 30,
        stroke: '#dddddd',
        strokeWidth: 4,
        draggable: false,
        listening: false,
        id: 'polygon-connect-circle',
      });

      this.layer.add(anchor);

      const pulse = new Konva.Tween({
        node: anchor,
        scaleX: 1.4,
        scaleY: 1.4,
        duration: 0.5,
        easing: Konva.Easings.EaseInOut,
        onFinish: function () {
          // After expanding, start a reverse animation
          pulse.reverse();
        },
      });

      pulse.play();
    }
  }

  drawAndSendShape(pointer) {
    this.drawShape(pointer);

    if (this.activeShape) {
      this.layer.add(this.activeShape);
    }

    this.redrawAndSend();
  }

  isTargetTransformer(target) {
    return target?.attrs?.id?.startsWith(this.TRANSFORMER_PREFIX);
  }

  transformAndSendShape(target, pointer) {
    const [_, shapeID, anchorIndexStr] = target.id().split('+');
    const anchorIndex = Number(anchorIndexStr);

    const arrow = this.layer.findOne(`#${shapeID}`);
    const arrowPoints = arrow.points();

    arrowPoints[anchorIndex] = pointer.x;
    arrowPoints[anchorIndex + 1] = pointer.y;
    arrow.points(arrowPoints);

    target.x(pointer.x);
    target.y(pointer.y);

    this.redrawAndSend();
  }

  redrawAndSend() {
    this.layer.draw();
    this.sendPaint();
  }

  @action mouseupCanvasHandler() {
    if (this.activeShape) {
      //this.selectedShapeType = "";
      if (!this.needClickForNextPoint) {
        this.drawMode = null;
      }

      if (this.activeShape instanceof Konva.Label) {
        const textChild = this.activeShape.children.find((child) => {
          return child instanceof Konva.Text;
        });

        if (textChild) {
          this.activeShape = textChild;
        }
      }
    }
    this.sendPaint();
  }

  @action async loadLayer() {
    let layer = await this.loadSzeneLayer();

    if (typeof layer !== 'undefined' && layer !== null) {
      let deserializedLayer = JSON.parse(layer);
      let denormalizedLayer = this.denormalizeLayer(deserializedLayer);
      let newlayer = Konva.Node.create(denormalizedLayer);
      console.log('denormalizedLayer', denormalizedLayer);
      if (newlayer.children !== null) {
        for (const child of newlayer.children) {
          // if(child instanceof Konva.Image){
          //   console.log("child as image",child)
          //   const image = new window.Image();
          //   image.src = child.src;
          //   child.image = image;
          // }

          if (child instanceof Konva.Label) {
            child.on('transform', this.labelTransformListener);
            if (child.children !== null) {
              for (const subchild of child.children) {
                if (subchild instanceof Konva.Text) {
                  subchild.on('transform', this.labelTransformListener);
                  subchild.on('dragend', this.textAndLabelDragListener);
                }
              }
            }
          }

          if (child instanceof Konva.Line || child instanceof Konva.Arrow) {
            child.hitStrokeWidth(40);
          }
          // READ ONLY für Teams Player einfügen
          if (this.readOnlyDrawMode && child.draggable() === true) {
            child.draggable(false);
          }

          child.on('mouseenter', this.setStageMouseEnterPointer.bind(this));

          child.on('mouseleave', this.setStageMouseLeaveDefault.bind(this));
        }
      }

      this.layer.destroy();
      this.stage.add(newlayer);
      this.layer = newlayer;
    }

    if (this.secondScreen) {
      this.sendPaint();
    }
  }

  @action async saveLayer() {
    if (this.addonUser.checkForAccessToFeatureAndSetInfoModal('draw.full')) {
      if (this.playSzene) {
        this.destroyTransformer();

        let serializedLayer = JSON.parse(this.layer.toJSON());

        let normalizedLayer = this.normalizeLayer(serializedLayer);
        let stringifiedLayer = JSON.stringify(normalizedLayer);
        await this.saveSzeneLayer(stringifiedLayer, undefined);

        this.paperToaster.show(this.intl.t('Zeichnung gespeichert'));
      } else {
        this.paperToaster.show(
          this.intl.t('Du musst eine Szene zum Speichern auswählen')
        );
      }
    }
  }

  normalizeCoordinatesRelativeToStage(positionX, positionY, stage) {
    return {
      x: positionX / this.initWidth,
      y: positionY / this.initHeight,
    };
  }

  denormalizeCoordinatesRelativeToStage(
    positionX,
    positionY,
    targetWidth,
    targetHeight
  ) {
    return {
      x: positionX * targetWidth,
      y: positionY * targetHeight,
    };
  }

  @action normalizeLayer(layerJSON) {
    for (let child of layerJSON.children) {
      // NORMALIZE COORDINATES

      if (child.attrs.x && child.attrs.y) {
        let normalizedCoordinates = this.normalizeCoordinatesRelativeToStage(
          child.attrs.x,
          child.attrs.y,
          this.stage
        );
        child.attrs.x = normalizedCoordinates.x;
        child.attrs.y = normalizedCoordinates.y;
      } else if (child.attrs.points) {
        let normalizedPoints = [];

        for (let i = 0; i < child.attrs.points.length; i += 2) {
          let normalizedCoordinates = this.normalizeCoordinatesRelativeToStage(
            child.attrs.points[i],
            child.attrs.points[i + 1],
            this.stage
          );

          normalizedPoints.push(
            normalizedCoordinates.x,
            normalizedCoordinates.y
          );
        }

        child.attrs.points = normalizedPoints;
      }

      // NORMALIZE REPRESENTATION

      if (child.attrs.strokeWidth) {
        child.attrs.strokeWidth = child.attrs.strokeWidth / 3;
      }

      if (child.attrs.pointerLength && child.attrs.pointerWidth) {
        child.attrs.pointerLength = child.attrs.pointerLength / 3;
        child.attrs.pointerWidth = child.attrs.pointerWidth / 3;
      }

      if (child.attrs.radius) {
        child.attrs.radius = child.attrs.radius / 2;
      }

      if (child.attrs.fontSize) {
        child.attrs.fontSize = child.attrs.fontSize / 2.5;
      }

      if (child.attrs.width && child.attrs.height) {
        child.attrs.width = child.attrs.width / 4.5;
        child.attrs.height = child.attrs.height / 4.5;
      }
    }
    return layerJSON;
  }

  denormalizeLayer(layerJSON, initWidth, initHeight) {
    let targetWidth, targetHeight;

    if (initWidth === undefined && initHeight === undefined) {
      targetWidth = this.initWidth;
      targetHeight = this.initHeight;
    } else {
      targetWidth = initWidth;
      targetHeight = initHeight;
    }

    for (let child of layerJSON.children) {
      // DENORMALIZE COORDINATES

      if (child.attrs.src) {
        const image = new window.Image();
        image.src = child.attrs.src;
        child.attrs.image = image;
      }

      if (child.attrs.x && child.attrs.y) {
        let denormalizedCoordinates =
          this.denormalizeCoordinatesRelativeToStage(
            child.attrs.x,
            child.attrs.y,
            targetWidth,
            targetHeight
          );
        child.attrs.x = denormalizedCoordinates.x;
        child.attrs.y = denormalizedCoordinates.y;
      } else if (child.attrs.points) {
        let denormalizedPoints = [];

        for (let i = 0; i < child.attrs.points.length; i += 2) {
          let denormalizedCoordinates =
            this.denormalizeCoordinatesRelativeToStage(
              child.attrs.points[i],
              child.attrs.points[i + 1],
              targetWidth,
              targetHeight
            );

          denormalizedPoints.push(
            denormalizedCoordinates.x,
            denormalizedCoordinates.y
          );
        }

        child.attrs.points = denormalizedPoints;
      }

      // DENORMALIZE REPRESENTATION

      if (child.attrs.strokeWidth) {
        child.attrs.strokeWidth = child.attrs.strokeWidth * 3;
      }

      if (child.attrs.pointerLength && child.attrs.pointerWidth) {
        child.attrs.pointerLength = child.attrs.pointerLength * 3;
        child.attrs.pointerWidth = child.attrs.pointerWidth * 3;
      }

      if (child.attrs.radius) {
        child.attrs.radius = child.attrs.radius * 2;
      }

      if (child.attrs.fontSize) {
        child.attrs.fontSize = child.attrs.fontSize * 2.5;
      }

      if (child.attrs.width && child.attrs.height) {
        child.attrs.width = child.attrs.width * 4.5;
        child.attrs.height = child.attrs.height * 4.5;
      }

      if (child.attrs.dash) {
        child.attrs.dash = [30, 15];
      }
    }
    return layerJSON;
  }

  get drawLayerIfLayerIsAvailalable() {
    if (this.drawLayer) {
      this.loadLayer();
    } else if (this.layer) {
      this.deleteAll();
    }

    return htmlSafe(this.drawLayer != null);
  }

  // Draw GUI Settings

  @action linieSetzen(linie) {
    this.strokeWidth = linie * 3;
    if (this.activeShape) {
      this.activeShape.strokeWidth(this.strokeWidth);

      if (this.activeShape.pointerLength) {
        this.activeShape.pointerLength(3 * this.strokeWidth);
      }

      if (this.activeShape.pointerWidth) {
        this.activeShape.pointerWidth(3 * this.strokeWidth);
      }

      this.layer.draw();
    }

    console.log('Line Icon in Draw', 'filter-t' + parseInt(linie));
    this.strokeIcon = 'filter-t' + parseInt(linie);
    this.currentDrawingSetting = null;
  }

  @action setDrawingSetting(setting) {
    if (
      this.activeShape &&
      setting === 'rotation' &&
      this.currentDrawingSetting !== 'rotation'
    ) {
      document.getElementById('athlyzer-slider-rotation-x').value =
        this.activeShape.scaleX();
      document.getElementById('athlyzer-slider-rotation-y').value =
        this.activeShape.scaleY();
    }
    if (setting === this.currentDrawingSetting) {
      this.currentDrawingSetting = null;
    } else {
      this.currentDrawingSetting = setting;
    }
  }

  get coloring() {
    return htmlSafe('color: ' + this.stroke + ';');
  }

  @action farbwechsel(color) {
    this.stroke = color;
    if (this.activeShape) {
      this.activeShape.stroke(this.stroke);
      if (
        this.activeShape.fill() &&
        !(
          this.activeShape instanceof Konva.Circle ||
          this.activeShape instanceof Konva.Rect ||
          this.activeShape instanceof Konva.Wedge
        )
      ) {
        this.activeShape.fill(this.stroke);
      }
      this.layer.draw();
    }
    this.currentDrawingSetting = null;
  }

  @action toggleTwoSidedArrow() {
    if (this.activeShape && this.activeShape.pointerAtBeginning) {
      this.activeShape.pointerAtBeginning(
        !this.activeShape.pointerAtBeginning()
      );
    }
  }

  @action openFontSizeOption() {
    if (this.activeShape && this.fontSize) {
      this.textFontSize = this.fontSize() / 3;
    }

    this.setDrawingSetting('text-size');
  }

  @action setNewTextFontSize(fontSize) {
    const newFontSize = Number(fontSize);

    this.textFontSize = newFontSize;
    if (this.activeShape) {
      this.activeShape.fontSize(newFontSize * 3);
      this.layer.draw();
    }
  }

  @action setNewTextBackgroundColorFill(color) {
    this.backgroundColor = color;

    if (
      this.activeShape &&
      this.activeShape instanceof Konva.Text &&
      this.activeShape.parent &&
      this.activeShape.parent instanceof Konva.Label &&
      this.activeShape.parent.children !== null
    ) {
      let tag = this.activeShape.parent.children.find((child) => {
        if (child instanceof Konva.Tag) {
          return true;
        } else {
          return false;
        }
      });

      if (tag) {
        tag.fill(this.backgroundColor);
      }

      this.layer.draw();
    }
    this.currentDrawingSetting = null;
  }

  @action setNewBackgroundColorFill(color) {
    let newColorWithOpacity = color.indexOf('#') > -1 ? color + '33' : color;
    if (this.activeShapeType === 'circle') {
      this.circleBackgroundColor = newColorWithOpacity;
    } else if (this.activeShapeType === 'rectangle') {
      this.rectangleBackgroundColor = newColorWithOpacity;
    } else if (this.activeShapeType === 'wedge') {
      newColorWithOpacity = color.indexOf('#') > -1 ? color + '80' : color;
      this.wedgeBackgroundColor = newColorWithOpacity;
    } else if (this.activeShapeType === 'polygon') {
      this.polygonBackgroundColor = newColorWithOpacity;
    }

    if (
      this.activeShape &&
      (this.activeShape instanceof Konva.Circle ||
        this.activeShape instanceof Konva.Rect ||
        this.activeShape instanceof Konva.Wedge ||
        this.activeShape.attrs.id?.startsWith('polygon'))
    ) {
      this.activeShape.fill(newColorWithOpacity);

      this.layer.draw();
    } else if (this.activeShape && this.activeShape instanceof Konva.Text) {
      if (
        this.activeShape.parent &&
        this.activeShape.parent.children.length > 0
      ) {
        this.activeShape.parent.children.forEach((child) => {
          if (child instanceof Konva.Tag) {
            child.fill(color);
          }
        });
        this.layer.draw();
      }
    }

    this.currentDrawingSetting = null;
  }

  @action deleteActive() {
    let deletion = this.activeShape;
    this.destroyTransformer();

    if (deletion) {
      if (
        deletion instanceof Konva.Text &&
        deletion.parent &&
        deletion.parent instanceof Konva.Label &&
        deletion.parent.children !== null
      ) {
        deletion.parent.children.forEach((element) => {
          element.destroy();
        });
      }
      deletion.destroy();
    }
    this.activeShape = null;
    this.redrawAndSend();
  }

  @action deactivateDrawings() {
    this.selectedShapeType = '';
    this.activeShape = null;
    this.currentDrawingSetting = null;
    this.strokeWidth = 9;
    console.log(
      'Line Icon in Draw',
      'filter-t' + parseInt(this.strokeWidth / 3)
    );
    this.strokeIcon =
      'filter-t' + Math.max(Math.round(this.strokeWidth / 3), 1);
    this.activeShapeType = 'noShape';
  }

  @action setShapeType(type) {
    if (this.activeShape) {
      this.deactivateDrawings();
      this.destroyTransformer(this.stage);
      this.removePolygonClosingHintCircle();
      this.currentlyTransforming = false;
    }

    this.selectedShapeType = type;
    this.activeShapeType = type;
    this.drawMode = null;
    this.needClickForNextPoint = false;
    this.needNewPointForFreeLine = false;
  }

  // Layer Actions

  @action addTransformer(shape) {
    this.destroyTransformer();
    var tr;
    if (shape instanceof Konva.Circle && shape.attrs.id.startsWith('player')) {
      tr = new Konva.Transformer({
        resizeEnabled: false,
        rotateEnabled: false,
        padding: 12,
        borderStrokeWidth: 9,
        id: `transformer+${shape.attrs.id}`,
      });
      this.layer.add(tr);
      tr.nodes([this.activeShape]);
    } else if (
      shape instanceof Konva.Arrow ||
      (shape instanceof Konva.Line &&
        !shape.attrs.id.startsWith('spotlight') &&
        !shape.attrs.id.startsWith('field-vision'))
    ) {
      this.buildAnchors(shape);
    } else if (shape instanceof Konva.Text) {
      tr = new Konva.Transformer({
        padding: 5,
        anchorSize: 12,
        enabledAnchors: ['middle-left', 'middle-right'],
        rotationSnaps: [0, 90, 180, 270],
        boundBoxFunc: (oldBox, newBox) => {
          if (newBox.width < 20) {
            return oldBox;
          }
          return newBox;
        },
        id: `transformer+${shape.attrs.id}`,
      });
      this.layer.add(tr);
      let transformerNodes = [this.activeShape];
      if (shape.parent instanceof Konva.Label) {
        transformerNodes.push(shape.parent);
      }

      tr.nodes(transformerNodes);
    } else if (shape instanceof Konva.Wedge) {
      tr = new Konva.Transformer({
        centeredScaling: false,
        anchorSize: 0, // Anpassung auf Mobile!
        useSingleNodeRotation: false,
        rotateEnabled: false,
        borderEnabled: true,
        resizeEnabled: false,
        id: `transformer+${shape.attrs.id}`,
      });
      this.layer.add(tr);
      tr.nodes([this.activeShape]);
    } else {
      tr = new Konva.Transformer({
        centeredScaling: false,
        anchorSize: 12, // Anpassung auf Mobile!
        id: `transformer+${shape.attrs.id}`,
      });
      this.layer.add(tr);
      tr.nodes([this.activeShape]);
    }
    this.layer.draw();
  }

  @action buildAnchors(shape) {
    let anchorGroup = new Konva.Group({
      height: 0,
      width: 0,
      id: `transformer+${shape.attrs.id}`,
    });

    this.layer.add(anchorGroup);

    for (let i = 0; i < shape.attrs.points.length; i += 2) {
      var anchor = new Konva.Circle({
        x: shape.attrs.points[i],
        y: shape.attrs.points[i + 1],
        radius: 30,
        stroke: '#666',
        fill: '#dddddd80',
        strokeWidth: 4,
        id: `${anchorGroup.id()}+${i}`,
      });
      anchorGroup.add(anchor);
    }
  }

  @action activateShape(target) {
    // Setze shape type vorher, da mit setShapeType Transformer und activeShape gelöscht werden
    let shapeID = target.attrs.id;
    shapeID = shapeID.split('-')[0];
    this.setShapeType(shapeID);

    this.activeShape = target;
    this.pointOfOrigin = {
      x: Number(this.activeShape.x()),
      y: Number(this.activeShape.y()),
    };

    this.addTransformer(target);
    this.currentlyTransforming = true;
    this.activeShapeType = shapeID;

    if (this.activeShape && this.activeShape.strokeWidth == 'function') {
      this.strokeWidth = this.activeShape.strokeWidth();
    }

    this.strokeIcon =
      'filter-t' + Math.max(Math.round(this.strokeWidth / 3), 1);
    this.stroke = this.activeShape.stroke();
  }

  @action deleteAll() {
    if (this.layer) {
      this.layer.destroyChildren();
      this.layer.draw();
      this.sendPaint();
    }
  }

  @action saveShapeText(text) {
    if (this.activeShape) {
      this.activeShape.text(text);
      this.layer.draw();
    }
  }

  // Timeline Actions

  @action async saveSzeneLayer(
    stringifiedlayer,
    customRelativeTimestamp = null
  ) {
    let relativeClipLength;

    if (customRelativeTimestamp) {
      relativeClipLength = customRelativeTimestamp;
    } else {
      relativeClipLength =
        this.getCurrentTime() - this.playSzene.get('startzeit');
    }
    //console.log("this.getCurrent",this.getCurrentTime(),this.playSzene.get("startzeit"),relativeClipLength);

    let deserializedLayer;

    if (this.playlistSzene && this.playlistSzene.layer) {
      deserializedLayer = this.playlistSzene.get('layer');

      for (let timestamp of Object.keys(deserializedLayer)) {
        if (
          timestamp - 0.05 > relativeClipLength &&
          timestamp + 0.05 < relativeClipLength
        ) {
          relativeClipLength = timestamp;
        }
      }

      deserializedLayer[relativeClipLength] = stringifiedlayer;
    } else {
      deserializedLayer = {};
      deserializedLayer[relativeClipLength] = stringifiedlayer;
    }

    try {
      set(this.playlistSzene, 'layer', deserializedLayer);
      this.playlistSzene.toggleProperty('isChange');
      await this.playlistSzene.save();
      this.paperToaster.show(this.intl.t('Speichern erfolgreich!'));
    } catch (e) {
      this.paperToaster.show(this.intl.t('Fehler beim Speichern'));
    }
  }

  @action async loadSzeneLayer() {
    if (this.drawLayer) {
      let stringifiedLayer = this.drawLayer;
      return stringifiedLayer;
    }
  }

  @action async saveEmptyLayerAfterDrawing(layer) {
    if (this.addonUser.checkForAccessToFeatureAndSetInfoModal('draw.full')) {
      if (this.playSzene) {
        this.destroyTransformer();
        this.deleteAll();

        let serializedLayer = JSON.parse(this.layer.toJSON());

        let normalizedLayer = this.normalizeLayer(serializedLayer);
        let stringifiedLayer = JSON.stringify(normalizedLayer);

        await this.saveSzeneLayer(stringifiedLayer, Number(layer.time) + 0.2);

        this.paperToaster.show(
          this.intl.t('Zeichnung wird beim Fortfahren wieder gelöscht.')
        );
      } else {
        this.paperToaster.show(
          this.intl.t('Du musst eine Szene zum Speichern auswählen')
        );
      }
    }
  }

  @action async deleteSzeneLayer(timestamp) {
    let layer = this.playlistSzene.layer;

    delete layer[timestamp];

    if (Object.keys(layer).length === 0) {
      this.playlistSzene.layer = false;
    } else {
      this.playlistSzene.set('layer', layer);
    }

    this.playlistSzene.toggleProperty('isChange');

    await this.playlistSzene.save();
  }

  @action async goToDrawTimestamp(layer) {
    this.isPausedForLayer = false;
    this.drawLayer = layer.layer || null;
    let absoluteDrawTimestamp =
      Number(await this.playSzene.get('startzeit')) + Number(layer.time);
    this.seekToSeconds(absoluteDrawTimestamp);
  }

  // Drawings

  calculateDistanceBetweenPoints(x1, y1, x2, y2) {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  }

  calculateRelativeAngleToX(x1, y1, x2, y2) {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;

    const angleInRadians = Math.atan2(deltaY, deltaX);
    const angleInDegrees = (angleInRadians * 180) / Math.PI;

    return angleInDegrees;
  }

  // 3D TRANSFORMATIONEN
  @action rotateShapeX(event) {
    if (this.activeShape) {
      let rotation = Number(event.target.value);
      this.activeShape.scaleX(rotation);
      this.layer.draw();
    }
  }
  @action rotateShapeY(event) {
    if (this.activeShape) {
      let rotation = Number(event.target.value);
      this.activeShape.scaleY(rotation);
      this.layer.draw();
    }
  }

  @action async addNewElement() {
    switch (this.selectedShapeType) {
      case 'image':
        this.activeShape = await this.createImage();
        break;
      case 'freeLine':
        this.activeShape = this.createFreeLine();
        this.activeShape.tension(0.5);
        break;
      case 'freeArrow':
        this.activeShape = this.createFreeArrow();
        this.activeShape.tension(0.5);
        break;
      case 'straightLine':
        this.activeShape = this.createStraightLine();
        break;
      case 'straightArrow':
        this.activeShape = this.createStraightArrow();
        break;
      case 'straightArrowDashed':
        this.activeShape = this.createStraightArrowDashed();
        break;
      case 'player':
        this.activeShape = this.createPlayer(this.stroke);
        break;
      case 'player-outlined':
        this.activeShape = this.createPlayer('');
        break;
      case 'circle':
        this.activeShape = this.createCircle();
        break;
      case 'rectangle':
        this.activeShape = this.createRectangle();
        break;
      case 'polygon':
        this.activeShape = this.createPolygon();
        break;
      case 'wedge':
        this.activeShape = this.createWedge();
        break;
      case 'field-vision':
        this.activeShape = this.createFieldVision();
        break;
      case 'spotlight':
        this.activeShape = this.createSpotlight();
        break;
      case 'label':
        this.activeShape = this.createLabel();
        break;
      default:
        console.log(
          'ungültiger Draw Übergabeparameter! mousedown',
          this.selectedShapeType,
          this.activeShape
        );
        break;
    }

    this.activeShape.on(
      'mouseenter',
      this.setStageMouseEnterPointer.bind(this)
    );

    this.activeShape.on(
      'mouseleave',
      this.setStageMouseLeaveDefault.bind(this)
    );

    if (
      this.activeShape &&
      typeof this.activeShape.perfectDrawEnabled == 'function'
    ) {
      this.activeShape.perfectDrawEnabled(false);
    }
  }

  setStageMouseEnterPointer() {
    this.stage.container().style.cursor = 'pointer';
  }

  setStageMouseLeaveDefault() {
    this.stage.container().style.cursor = 'default';
  }

  calculateRelativeAngleBetweenTwoVectors() {
    let lastFourPoints = this.activeShape
      .points()
      .slice(this.activeShape.points().length - 6);

    let distanceBetweenPreviousPoints = this.calculateDistanceBetweenPoints(
      lastFourPoints[0],
      lastFourPoints[1],
      lastFourPoints[2],
      lastFourPoints[3]
    );

    let lineAngle = Math.asin(
      (lastFourPoints[3] - lastFourPoints[1]) / distanceBetweenPreviousPoints
    );
    return lineAngle;
  }

  @action drawShape(pointer) {
    if (!this.activeShapeType) return;

    switch (this.activeShapeType) {
      case 'freeLine':
      case 'freeArrow':
        this.handleFreeShape(pointer);
        break;
      case 'straightLine':
      case 'straightArrow':
      case 'straightArrowDashed':
        this.handleStraightShape(pointer);
        break;
      case 'circle':
        this.handleCircle(pointer);
        break;
      case 'rectangle':
        this.handleRectangle(pointer);
        break;
      case 'wedge':
        this.handleWedge(pointer);
        break;
      case 'field-vision':
        this.handleFieldVision(pointer);
        break;
      case 'spotlight':
        this.handleSpotlight(pointer);
        break;
      default:
        console.log(
          'ungültiger Draw Übergabeparameter! mousemove',
          this.activeShapeType,
          this.activeShape
        );
        break;
    }
  }

  handleFreeShape(pointer) {
    // Defensive checks for properties and methods
    if (
      !this.activeShape ||
      typeof this.activeShape.points !== 'function' ||
      typeof this.calculateDistanceBetweenPoints !== 'function' ||
      typeof this.calculateRelativeAngleBetweenTwoVectors !== 'function'
    ) {
      console.warn(
        'Required properties or methods are missing in handleFreeShape function.'
      );
      return;
    }

    const pointerX = Number(pointer.x);
    const pointerY = Number(pointer.y);

    if (this.needNewPointForFreeLine) {
      console.log('Need new point');
      this.handleNewPointForFreeLine(pointerX, pointerY);
    } else {
      console.log('Need no new point');
      this.handleExistingPointForFreeLine(pointerX, pointerY);
    }
  }

  handleNewPointForFreeLine(pointerX, pointerY) {
    const lastPoint = this.activeShape
      .points()
      .slice(Math.max(this.activeShape.points().length - 2, 0));

    const distance = this.calculateDistanceBetweenPoints(
      lastPoint[0],
      lastPoint[1],
      pointerX,
      pointerY
    );

    const angleToLastVector = Math.sin((lastPoint[1] - pointerY) / distance);

    if (
      distance >=
      this.MIN_DISTANCE_MULTIPLIER * Math.cos(angleToLastVector) +
        this.OFFSET_FREE_LINE
    ) {
      this.activeShape.points(
        this.activeShape.points().concat([pointerX, pointerY])
      );
      this.needNewPointForFreeLine = false;
    }
  }

  handleExistingPointForFreeLine(pointerX, pointerY) {
    const lastPoint = this.activeShape
      .points()
      .slice(Math.max(this.activeShape.points().length - 4, -2));

    const distance = this.calculateDistanceBetweenPoints(
      lastPoint[0],
      lastPoint[1],
      pointerX,
      pointerY
    );

    this.activeShape.points(
      this.activeShape
        .points()
        .slice(0, this.activeShape.points().length - 2)
        .concat([pointerX, pointerY])
    );

    let lineAngle = 0;
    if (this.activeShape.points().length > 4) {
      lineAngle = this.calculateRelativeAngleBetweenTwoVectors();
    }

    const angleToLastVector =
      Math.asin((pointerY - lastPoint[1]) / distance) - lineAngle;

    if (
      distance >=
      this.MIN_DISTANCE_FREE_ARROW *
        Math.cos(this.ANGLE_MULTIPLIER * Math.abs(angleToLastVector)) +
        this.OFFSET_FREE_ARROW
    ) {
      this.needNewPointForFreeLine = true;
    }
  }

  handleStraightShape(pointer) {
    // Defensive checks for properties and methods
    if (
      !this.activeShape ||
      !this.pointOfOrigin ||
      typeof this.activeShape.points !== 'function'
    ) {
      console.warn(
        'Required properties or methods are missing in handleStraightShape function.'
      );
      return;
    }

    const originX = Number(this.pointOfOrigin.x);
    const originY = Number(this.pointOfOrigin.y);
    const pointerX = Number(pointer.x);
    const pointerY = Number(pointer.y);

    this.activeShape.points([originX, originY, pointerX, pointerY]);
  }

  handleCircle(pointer) {
    // Defensive check: Ensure required properties and methods exist
    if (
      !this.activeShape ||
      !this.pointOfOrigin ||
      typeof this.activeShape.x !== 'function' ||
      typeof this.activeShape.y !== 'function' ||
      typeof this.activeShape.radius !== 'function'
    ) {
      console.warn(
        'Required properties or methods are missing in handleCircle function.'
      );
      return;
    }

    // Convert pointer coordinates to numbers once
    const pointerX = Number(pointer.x);
    const pointerY = Number(pointer.y);
    const originX = Number(this.pointOfOrigin.x);
    const originY = Number(this.pointOfOrigin.y);

    // Compute midpoints between pointer and origin
    const midPointX = (pointerX - originX) / 2;
    const midPointY = (pointerY - originY) / 2;

    // Update shape's x and y coordinates
    this.activeShape.x(originX + midPointX);
    this.activeShape.y(originY + midPointY);

    // Calculate radius based on the distance formula
    const distanceX = midPointX * midPointX;
    const distanceY = midPointY * midPointY;
    const radius = Math.sqrt(distanceX + distanceY);

    // Set the circle's radius
    this.activeShape.radius(radius);
  }

  handleRectangle(pointer) {
    // Defensive checks for properties and methods
    if (
      !this.activeShape ||
      !this.pointOfOrigin ||
      typeof this.activeShape.x !== 'function' ||
      typeof this.activeShape.y !== 'function' ||
      typeof this.activeShape.height !== 'function' ||
      typeof this.activeShape.width !== 'function'
    ) {
      console.warn(
        'Required properties or methods are missing in handleRectangle function.'
      );
      return;
    }

    const originX = Number(this.pointOfOrigin.x);
    const originY = Number(this.pointOfOrigin.y);
    const pointerX = Number(pointer.x);
    const pointerY = Number(pointer.y);

    this.activeShape.x(originX);
    this.activeShape.y(originY);
    this.activeShape.height(pointerY - originY);
    this.activeShape.width(pointerX - originX);
  }

  handleWedge(pointer) {
    this.activeShape.x(Number(this.pointOfOrigin.x));
    this.activeShape.y(Number(this.pointOfOrigin.y));
    this.activeShape.radius(
      this.calculateDistanceBetweenPoints(
        this.pointOfOrigin.x,
        this.pointOfOrigin.y,
        pointer.x,
        pointer.y
      )
    );
    this.activeShape.angle(this.angle);
    this.activeShape.rotation(
      this.calculateRelativeAngleToX(
        this.pointOfOrigin.x,
        this.pointOfOrigin.y,
        pointer.x,
        pointer.y
      )
    );
  }

  handleFieldVision(pointer) {
    const distanceToPointer = this.calculateDistanceBetweenPoints(
      this.pointOfOrigin.x,
      this.pointOfOrigin.y,
      pointer.x,
      pointer.y
    );
    const anglePointerToOrigin = this.calculateRelativeAngleToX(
      this.pointOfOrigin.x,
      this.pointOfOrigin.y,
      pointer.x,
      pointer.y
    );

    const fieldVisionPoints = this.calculateSpotlightPoints(
      Number(this.pointOfOrigin.x),
      Number(this.pointOfOrigin.y),
      distanceToPointer,
      15,
      anglePointerToOrigin
    );

    this.activeShape.fillLinearGradientStartPoint({
      x: fieldVisionPoints[0],
      y: fieldVisionPoints[1],
    });

    this.activeShape.fillLinearGradientEndPoint({
      x: fieldVisionPoints[2],
      y: fieldVisionPoints[3],
    });

    this.activeShape.points(fieldVisionPoints);
  }

  handleSpotlight(pointer) {
    const points = this.calculateSpotlightPoints(
      Number(this.pointOfOrigin.x),
      Number(this.pointOfOrigin.y),
      Math.max(pointer.y - this.pointOfOrigin.y, 5),
      15
    );

    this.activeShape.fillLinearGradientStartPoint({
      x: points[0],
      y: points[1],
    });

    this.activeShape.fillLinearGradientEndPoint({
      x: points[0],
      y: points[3],
    });

    this.activeShape.points(points);
  }

  // CREATE SHAPES

  @action createBaseShape(drawStroke = true) {
    this.idCounter += 1;
    this.pointOfOrigin = this.getPointerPosition();
    let baseShape = {
      id: `${this.selectedShapeType}-${this.createRandomString()}`,
      draggable: false,
    };

    if (drawStroke) {
      baseShape = Object.assign(baseShape, {
        stroke: this.stroke,
        strokeWidth: this.strokeWidth,
      });
    }

    return baseShape;
  }

  // DOT SHAPE FUNCTIONS
  @action createPlayer(fill) {
    let baseShape = this.createBaseShape();
    Object.assign(baseShape, {
      x: this.pointOfOrigin.x,
      y: this.pointOfOrigin.y,
      radius: 18,
      fill: fill,
      draggable: true,
    });

    return new Konva.Circle(baseShape);
  }

  // FREE SHAPE FUNCTIONS

  async selectImageWithMaximumPixelsize(maxSize) {
    console.log('selectImageWithMaximumPixelsize');
    return new Promise(async (resolve, reject) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.name = 'image-input';
      input.onchange = async () => {
        const file = input.files[0];
        if (!file) {
          reject({ success: false, cancelled: true });
        }
        //document.body.removeChild(input);
        const reader = new FileReader();
        reader.onload = function (event) {
          const img = new Image();
          img.src = event.target.result;
          img.onload = function () {
            let width = img.width;
            let height = img.height;

            if (width > maxSize || height > maxSize) {
              if (width > height) {
                height *= maxSize / width;
                width = maxSize;
              } else {
                width *= maxSize / height;
                height = maxSize;
              }
            }
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            const resizedBase64 = canvas.toDataURL('image/png'); // Change to 'image/png' if needed
            resolve({ success: true, cancelled: false, value: resizedBase64 });
          };
        };
        reader.readAsDataURL(file);
      };
      input.click();
    });
  }

  @action async createImage() {
    const response = await this.selectImageWithMaximumPixelsize(1280);
    if (response.success) {
      const image = new window.Image();
      image.src = response.value;
      let baseShape = this.createBaseShape(false);
      Object.assign(baseShape, {
        x: this.pointOfOrigin.x,
        y: this.pointOfOrigin.y,
        image: image,
        draggable: true,
        src: response.value,
      });
      return new Konva.Image(baseShape);
    }
  }

  @action createFreeLine() {
    let baseShape = this.createBaseShape();
    Object.assign(baseShape, {
      points: [this.pointOfOrigin.x, this.pointOfOrigin.y],
      hitStrokeWidth: 40,
      lineCap: 'round',
      lineJoin: 'round',
      draggable: false,
    });
    return new Konva.Line(baseShape);
  }

  @action createFreeArrow() {
    let baseShape = this.createBaseShape();
    Object.assign(baseShape, {
      points: [this.pointOfOrigin.x, this.pointOfOrigin.y],
      fill: this.stroke,
      pointerWidth: 3 * this.strokeWidth,
      pointerLength: 3 * this.strokeWidth,
      hitStrokeWidth: 40,
      lineCap: 'round',
      lineJoin: 'round',
      draggable: false,
    });
    return new Konva.Arrow(baseShape);
  }

  // SIMPLE SHAPE FUNCTIONS
  @action createStraightLine() {
    let baseShape = this.createBaseShape();
    Object.assign(baseShape, {
      points: [this.pointOfOrigin.x, this.pointOfOrigin.y],
      hitStrokeWidth: 40,
      lineCap: 'round',
      lineJoin: 'round',
    });
    return new Konva.Line(baseShape);
  }

  @action createStraightArrow() {
    let baseShape = this.createBaseShape();
    Object.assign(baseShape, {
      points: [this.pointOfOrigin.x, this.pointOfOrigin.y],
      fill: this.stroke,
      pointerWidth: 3 * this.strokeWidth,
      pointerLength: 3 * this.strokeWidth,
      hitStrokeWidth: 40,
      lineCap: 'round',
      lineJoin: 'round',
    });
    return new Konva.Arrow(baseShape);
  }

  @action createStraightArrowDashed() {
    let baseShape = this.createBaseShape();
    Object.assign(baseShape, {
      points: [this.pointOfOrigin.x, this.pointOfOrigin.y],
      fill: this.stroke,
      dash: [30, 15],
      pointerWidth: 3 * this.strokeWidth,
      pointerLength: 3 * this.strokeWidth,
      hitStrokeWidth: 40,
      lineCap: 'round',
      lineJoin: 'round',
    });
    return new Konva.Arrow(baseShape);
  }

  @action createCircle() {
    let baseShape = this.createBaseShape();
    Object.assign(baseShape, {
      x: this.pointOfOrigin.x,
      y: this.pointOfOrigin.y,
      fill: this.circleBackgroundColor,
      strokeWidth: this.strokeWidth,
      draggable: true,
    });
    return new Konva.Circle(baseShape);
  }

  @action createRectangle() {
    let baseShape = this.createBaseShape();
    Object.assign(baseShape, {
      x: this.pointOfOrigin.x,
      y: this.pointOfOrigin.y,
      hitStrokeWidth: 20,
      draggable: true,
    });

    return new Konva.Rect(baseShape);
  }

  @action createPolygon() {
    let baseShape = this.createBaseShape();

    Object.assign(baseShape, {
      points: [this.pointOfOrigin.x, this.pointOfOrigin.y],
      closed: false,
      hitStrokeWidth: 20,
      tension: 0,
    });

    return new Konva.Line(baseShape);
  }

  @action createWedge() {
    let baseShape = this.createBaseShape();
    Object.assign(baseShape, {
      x: this.pointOfOrigin.x,
      y: this.pointOfOrigin.y,
      fill: this.wedgeBackgroundColor,
      opacity: 0.7,
      draggable: true,
    });
    return new Konva.Wedge(baseShape);
  }

  @action createFieldVision() {
    let baseShape = this.createBaseShape();
    const points = this.calculateSpotlightPoints(
      this.pointOfOrigin.x,
      this.pointOfOrigin.y,
      0,
      15
    );

    Object.assign(baseShape, {
      points,
      closed: true,
      fillLinearGradientStartPoint: {
        x: this.pointOfOrigin.x,
        y: this.pointOfOrigin.y,
      },
      fillLinearGradientEndPoint: { x: 0, y: 5 },
      fillLinearGradientColorStops: [
        0,
        'white',
        0.4,
        '#FFFFFFCC',
        1,
        '#FFFFFF0D',
      ],
      stroke: 'white',
      strokeWidth: 0,
      fillPriority: 'linear-gradient',
      draggable: true,
    });

    return new Konva.Line(baseShape);
  }

  calculateSpotlightPoints(x, y, length, spreadAngle, angleRelativeToX = 90) {
    const angleInRadians = (spreadAngle / 2) * (Math.PI / 180);
    const angleRelativeToXInRadians = angleRelativeToX * (Math.PI / 180);

    const endX1 =
      x + length * Math.cos(angleRelativeToXInRadians + angleInRadians);
    const endY1 =
      y + length * Math.sin(angleRelativeToXInRadians + angleInRadians);
    const endX2 =
      x + length * Math.cos(angleRelativeToXInRadians - angleInRadians);
    const endY2 =
      y + length * Math.sin(angleRelativeToXInRadians - angleInRadians);

    return [x, y, endX1, endY1, endX2, endY2];
  }

  @action createSpotlight() {
    let baseShape = this.createBaseShape();
    const points = this.calculateSpotlightPoints(
      this.pointOfOrigin.x,
      this.pointOfOrigin.y,
      0,
      15
    );

    Object.assign(baseShape, {
      points,
      closed: true,
      fillLinearGradientStartPoint: {
        x: this.pointOfOrigin.x,
        y: this.pointOfOrigin.y,
      },
      fillLinearGradientEndPoint: { x: 0, y: 5 },
      fillLinearGradientColorStops: [
        0,
        'white',
        0.4,
        '#FFFFFFCC',
        0.97,
        '#FFFFFF0D',
        1,
        '#FFFFFF66',
      ],
      stroke: 'white',
      strokeWidth: 0,
      fillPriority: 'linear-gradient',
      draggable: true,
    });
    return new Konva.Line(baseShape);
  }

  @action createLabel() {
    let baseShape = this.createBaseShape();

    let label = new Konva.Label({
      x: this.pointOfOrigin.x,
      y: this.pointOfOrigin.y,
      draggable: true,
    });

    label.add(
      new Konva.Tag({
        fill: this.backgroundColor,
        lineJoin: 'round',
        lineCap: 'round',
        cornerRadius: 10,
      })
    );

    Object.assign(baseShape, {
      fontSize: this.textFontSize * 3,
      text: 'Text Box',
      fill: this.stroke,
      strokeWidth: 0,
      padding: (this.textFontSize / 2) * 3,
      fontFamily: 'Roboto, sans-serif',
    });

    const text = new Konva.Text(baseShape);
    text.on('transform', this.labelTransformListener);
    text.on('dragend', this.textAndLabelDragListener);

    label.add(text);
    label.on('transform', this.labelTransformListener);

    return label;
  }

  labelTransformListener(event) {
    if (event && event.target) {
      event.target.setAttrs({
        width: Math.max(event.target.width() * event.target.scaleX(), 20),
        scaleX: 1,
      });
    }
  }

  textAndLabelDragListener(event) {
    if (event && event.target) {
      event.target.setAttrs({
        x: 0,
        y: 0,
      });
    }
  }

  // ANIMATIONS

  startAnimationTracking() {
    this.preciseCurrentTime = this.getCurrentTime();

    let currentScene;
    if (this.playlistSzene) {
      currentScene = this.playlistSzene;
    }

    if (this.sharedSzene) {
      currentScene = this.sharedSzene;
    }

    if (currentScene && currentScene.layer && currentScene.showLayer) {
      let relativeCurrentTime;
      if (currentScene.constructor.modelName === 'playlistszene') {
        let videoszene = currentScene.get('videoszene');
        if (videoszene && videoszene.get('startzeit') >= 0) {
          relativeCurrentTime =
            this.preciseCurrentTime - videoszene.get('startzeit');
        } else {
          relativeCurrentTime = this.preciseCurrentTime;
        }
      } else {
        relativeCurrentTime = this.preciseCurrentTime;
      }

      if (this.temporaryIgnoreLayerTimestamp) {
        if (
          this.temporaryIgnoreLayerTimestamp < relativeCurrentTime - 0.05 ||
          this.temporaryIgnoreLayerTimestamp > relativeCurrentTime + 0.05
        ) {
          this.temporaryIgnoreLayerTimestamp = false;
        }
      }

      let layer;
      for (let timestamp of Object.keys(currentScene.layer)) {
        if (
          timestamp > relativeCurrentTime - 0.05 &&
          timestamp < relativeCurrentTime + 0.05 &&
          this.temporaryIgnoreLayerTimestamp != timestamp
        ) {
          layer = currentScene.layer[timestamp];
          this.temporaryIgnoreLayerTimestamp = timestamp;
          break;
        }
      }

      if (layer) {
        this.drawLayer = layer;
        let childrenLength = JSON.parse(layer).children.length;
        if (childrenLength > 0) {
          this.pause();
          this.isPausedForLayer = relativeCurrentTime;
        }
      }
    }

    const isAllowedToAccess =
      this.addonUser.checkForAccessToFeatureAndSetInfoModal('draw.full', false);

    if (isAllowedToAccess) {
      this.animationRequestID = requestAnimationFrame(
        this.startAnimationTracking.bind(this)
      );
    }
  }

  stopAnimationTracking() {
    if (this.animationRequestID) {
      cancelAnimationFrame(this.animationRequestID);
    }
  }
}
