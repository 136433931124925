import Model from '../classes/model';
import { attr, belongsTo } from '@ember-data/model';
import { alias } from '@ember/object/computed';
class TeamplaylistszeneModel extends Model {
  @attr('number') position;
  @attr() geteiltevideoszene;
  @attr('boolean') isVideochange; // triggert einen erneuten upload;
  @attr('string') web;
  @belongsTo('teamplaylist') playlist;
  @attr() layer;
  @alias('geteiltevideoszene') videoszene;
  showLayer = true;

  get fake() {
    return {
      clip: {
        web: this.web,
        existsOnWeb: true,
      },
      videoszene: this.geteiltevideoszene,
    };
  }
}

TeamplaylistszeneModel.reopenClass({
  documentType: 'playlistszene',
});

export default TeamplaylistszeneModel;
