import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Cross from 'athlyzer-coach/cross/plugin';
import { Preferences } from '@capacitor/preferences';
import { DateTime } from 'luxon';
import { next } from '@ember/runloop';
import clipsPosition from 'athlyzer-coach/helper-functions/clips-position';
import { htmlSafe } from '@ember/template';
import mmss from 'athlyzer-coach/helper-functions/mmss';
import { enqueueTask } from 'ember-concurrency';
import Shepherd from 'shepherd.js';

export default class UserBibliothekAnalyseOldController extends Controller {
  queryParams = ['mode'];
  modes = [
    'index',
    'tagging',
    'tagging-live',
    'vorbereiten',
    'veredeln',
    'praesentieren',
    'statistik',
    'capture',
    'szenen-clips-ausrichten',
    'import',
  ];

  @service paperToaster;
  @service addonCore;
  @service addonUser;
  @service intl;
  @service router;
  @service visual;
  @service metrics;

  @tracked mode = 'index';
  @tracked openModal = '';
  @tracked szenenAusrichten = [];

  @tracked allLabels = [];

  @tracked fileExportType = 'vaa';

  @tracked modifyLiveTagging = false;

  @tracked scenelist;

  @tracked isLoading = false;

  /* tagging */
  @tracked cols = 1;
  @tracked rows = 1;
  @tracked switchSchema = true;
  @tracked schemaAlt = '';
  @tracked videoHeight = 1;
  @tracked videoWidth = 1;
  @tracked zoom = 1;
  @tracked isZoomOpen = true;
  @tracked ansicht = '';
  @tracked taggingAnsicht;
  @tracked isOffListe = false;
  @tracked isOffSchema = false;
  @tracked showShortCuts = false;
  /* // tagging */

  /* rtsp */

  @tracked isRtspOpen = false;
  @tracked rtspurl;
  @tracked isRtspSpeichern = false;
  @tracked rtspName = '';
  @tracked rtsps;

  @tracked tmpAnalyse = null;

  /* // rtsp */

  @tracked isEditSzenenId = null;

  //Statistics
  @tracked eventListe;
  //Statistics

  @action editScene(szene, mode = 'edit') {
    if (szene == null) {
      this.isEditSzenenId = null;
    } else {
      this.video.szene = szene;
      this.video.playSzene = szene;

      set(this, 'isEditSzenenId', {
        mode,
        id: szene.get('id'),
      });
    }
    setTimeout(() => {
      this.addonCore.resize();
    }, 50);
  }

  @action resetEditSzenenId() {
    this.isEditSzenenId = null;
  }

  @action async importAnalyse(analysisToImportFrom) {
    const analyse = await this.store.findRecord(
      'videobearbeitung',
      analysisToImportFrom.id
    );
    const videoclips = await analyse.get('videoclips');

    const copyVideoclipsToAnalysis = videoclips.map(async (clip) => {
      const scenelist = await clip.get('videoszenen');

      let serialisierterVideoclip = clip.serialize({ includeId: false });
      serialisierterVideoclip = Object.assign(serialisierterVideoclip, {
        position: clipsPosition(this.model),
        videoszenen: [],
        videobearbeitung: this.model,
      });

      delete serialisierterVideoclip.rev;

      let newVideoclip = this.store.createRecord(
        'videoclip',
        serialisierterVideoclip
      );

      this.model.get('videoclips').pushObject(newVideoclip);

      const connectScenesToAnalysis = scenelist.map(async (scene) => {
        let serialisierteSzene = scene.serialize({ includeId: false });

        serialisierteSzene = Object.assign(serialisierteSzene, {
          playlistszenen: [],
          videoclip: newVideoclip,
          videobearbeitung: this.model,
        });

        delete serialisierteSzene.rev;

        let newSzene = this.store.createRecord(
          'videoszene',
          serialisierteSzene
        );

        newVideoclip.get('videoszenen').pushObject(newSzene);
        this.model.get('videoszenen').pushObject(newSzene);

        return newSzene.save();
      });

      await Promise.all(connectScenesToAnalysis);
      return newVideoclip.save();
    });

    await Promise.all(copyVideoclipsToAnalysis);
    await this.model.save();

    this.router.transitionTo('user.bibliothek.analyse-mobile.ausrichten');
  }

  @enqueueTask *saveReturn(scene) {
    yield scene.save();
  }

  @action async importScenelist(scenelist) {
    this.scenelist = scenelist;
    this.mode = 'import';
  }

  @action async zurueck() {
    this.addonCore.isGoingBack = true;
    this.isEditSzenenId = null;

    if (this.video.iscapture) {
      this.paperToaster.show(
        this.intl.t('Beende die Aufnahme bevor zu zurück gehst'),
        {
          position: 'top left',
        }
      );
      this.addonCore.isGoingBack = false;
      return;
    }

    if (this.router.currentRouteName == 'user.bibliothek.analyse.capture') {
      this.router.transitionTo('user.bibliothek.analyse.index');
    }

    //if (this.model.get('hasDirtyAttributes')) {
    await this.video.saveAnalyse.perform();
    //}
    this.video.pause();
    this.video.iscapture = false;
    this.video.szene = null;
    this.video.playSzene = null;
    if (this.mode == 'index') {
      try {
        this.video.unsetAnalyse();
        await this.backToIndex();
      } catch (error) {
        console.log(
          'something went wrong while going back to the index!',
          error
        );
        this.router.transitionTo('user.bibliothek.index');
      }
    } else {
      this.model.aktiveLabels = [];
      if (this.mode == 'tagging-live') {
        try {
          await this.checkForLooseEnds();
        } catch (error) {
          console.log('was not able to tie loose ends', error);
        }
        try {
          await this.saveCounters();
        } catch (error) {
          console.log('could not save counters as planned', error);
        }
      }
      try {
        await this.unselectPlayszene();
      } catch (error) {
        console.log('was not able to unselect the playscene', error);
      }

      this.mode = 'index';
      setTimeout(() => {
        this.addonCore.resize();
      }, 500);
    }
    this.addonCore.isGoingBack = false;
    setTimeout(async () => {
      await this.imitateRouteBehavior();
    }, 500);
  }

  async backToIndex() {
    let saves = [];
    let clips = await this.model.get('videoclips');
    for (let clip of clips.toArray()) {
      if (clip.get('hasDirtyAttributes')) {
        saves.push(clip.save());
      }
    }

    let vscenes = await this.model.get('videoszenen');
    for (let vscene of vscenes.toArray()) {
      if (vscene.get('hasDirtyAttributes')) {
        saves.push(vscene.save());
      }
    }

    let plists = await this.model.get('playlists');
    for (let plist of plists.toArray()) {
      let pscenes = await plist.get('playlistszenen');
      for (let pscene of pscenes.toArray()) {
        if (pscene.get('hasDirtyAttributes')) {
          saves.push(pscene.save());
        }
      }
      if (plist.get('hasDirtyAttributes')) {
        saves.push(plist.save());
      }
    }
    await Promise.all(saves);
    this.router.transitionTo('user.bibliothek.index');
  }

  async unselectPlayszene() {
    this.video.szene = null;
    if (this.video.playSzene) {
      this.video.playSzene = null;
    }
    if (this.video.playlistSzene) {
      this.video.playlistSzene = false;
    }

    await this.resetScenes();

    try {
      let timeFrame = document.getElementById('athlyzer-duration');
      let end = await this.video.clip.get('duration');
      if (timeFrame) {
        timeFrame.innerHTML = mmss(end);
      }
    } catch (error) {
      console.log('was not able to reset times');
    }
  }

  async resetScenes() {
    // reset of all vscenes
    let vscenes = await this.model.get('videoszenen');
    if (vscenes.toArray().length > 0) {
      for (let vscene of vscenes.toArray()) {
        vscene.set('showLabels', false);
        vscene.set('timeShift', false);
        vscene.set('isKommentieren', false);
        vscene.set('showPlaylists', false);
        vscene.set('isDelete', false);
      }
    }

    // reset of all pscenes
    let plists;
    try {
      plists = await this.model.get('playlists');
    } catch (error) {}

    if (plists && plists.toArray().length > 0) {
      for (let plist of plists.toArray()) {
        let pscenes;
        try {
          pscenes = await plist.get('playlistszenen');
        } catch (error) {}

        if (pscenes && pscenes.toArray().length > 0) {
          for (let pscene of pscenes.toArray()) {
            pscene.set('showLabels', false);
          }
        }
      }
    }
  }

  @action async imitateRouteBehavior() {
    /* Only allow Keyboard on  */
    switch (this.mode) {
      case 'tagging':
        await this.video.setSchema(await this.model.get('schema'));
        this.addonCore.schemaKeyboardFreigeben();
        break;
      case 'tagging-live':
        await this.video.setSchema(await this.model.get('schema'));
        this.addonCore.schemaKeyboardFreigeben();
        break;
      default:
        this.video.unsetSchema();
        this.addonCore.schemaKeyboardBlockieren();
    }
    window.dispatchEvent(new CustomEvent('custom-resize'));
  }

  @action async checkForLooseEnds() {
    let vscenes;
    try {
      vscenes = await this.model.get('videoszenen');
    } catch (error) {}
    if (vscenes && vscenes.toArray().length > 0) {
      for (let vscene of vscenes.toArray()) {
        if (vscene && !vscene.get('endzeit')) {
          const startZeit = Number(await vscene.get('startzeit'));
          if (this.video.playerRef) {
            const time =
              this.video.player.currentTime > startZeit
                ? this.video.player.currentTime
                : startZeit + 1;
            vscene.set('endzeit', time);
          } else {
            let delta = startZeit + 1;
            vscene.set('endzeit', delta);
          }
          await vscene.save();
        }
      }
    }
  }

  @action openImport() {
    this.openModal = 'import-modal';
  }

  @action async saveCounters() {
    let allClips = await this.model.get('videoclips');
    if (allClips.toArray().length > 0) {
      for (let clip of allClips.toArray()) {
        await clip.save();
      }
    }
  }

  @action clipSzenenAusrichten() {
    if (this.video.clip.videoszenen.length > 0) {
      this.szenenAusrichten = this.video.analyse
        .getVideoszenenByClip(this.video.clip.id)
        .sort((a, b) => {
          return a.startzeit - b.startzeit;
        });
      this.mode = 'szenen-clips-ausrichten';
      this.back = 'index';
    } else {
      this.mode = 'index';
    }
  }

  @action async operation(key, value) {
    switch (key) {
      case 'modal-schliessen':
        this.openModal = '';
        this.addonCore.keyboardFreigeben();
        break;
      case 'modal-oeffnen':
        this.addonCore.keyboardBlockieren();
        this.openModal = value;
        break;
      case 'schema-gewaehlt':
        this.model.set('schema', value);
        this.model.save();
        break;
      case 'ordner-gewaehlt':
        this.model.set('ordner', value);
        this.model.save();
        break;
      case 'rtsp-loeschen':
        await value.destroyRecord();
        break;
      case 'rtsp-setzen':
        this.rtspurl = value;
        break;
      case 'rtsp-is-speichern':
        this.isRtspSpeichern = !this.isRtspSpeichern;
        break;
      case 'rtsp-modal':
        this.isRtspOpen = false;
        break;
      case 'datei-importieren':
        document.getElementById('analyse-importieren').click();
        break;
      case 'modus-wechseln':
        if (value == 'statistik') {
          await this.getEvents();
        }
        this.mode = value;
        break;
      case 'connect-open':
        let response = await Cross.serverOpen();
        document.getElementById('qrcode').innerHTML = '';
        new QRCode(document.getElementById('qrcode'), response.url);

        document.getElementById('qrcodeContainer').style.height = '100vh';
        document.getElementById('qrcodeContainer').style.width = '100vw';
        document.getElementById('qrcodeContainer').style.visibility = 'visible';
        document.getElementById('qrcodeContainer').style.zIndex = '9999';
        document.getElementById('qrcodeContainer').style.backgroundColor =
          'white';
        break;
      case 'connect-close':
        document.getElementById('qrcodeContainer').style.visibility = 'hidden';
        document.getElementById('qrcodeContainer').style.zIndex = '0';
        document.getElementById('qrcodeContainer').style.height = '0px';
        document.getElementById('qrcodeContainer').style.width = '0px';
        break;
      default:
        break;
    }
    setTimeout(async () => {
      await this.imitateRouteBehavior();
    }, 500);
  }

  async getEvents() {
    let summe = {};
    let szenenListe = await this.model.get('videoszenen');
    for (var ereignis of szenenListe.toArray()) {
      if (!summe[ereignis.textgesamt]) {
        summe[ereignis.textgesamt] = {
          farbe: ereignis.farbe,
          count: 0,
          labels: {},
          zeiten: [],
          selected: ereignis.selected,
        };
      }
      summe[ereignis.textgesamt].count += 1;
      summe[ereignis.textgesamt].zeiten.push({
        start: ereignis.startzeit,
        ende: ereignis.endzeit,
      });
      if (ereignis.tags && Array.isArray(ereignis.tags)) {
        for (var label of ereignis.tags) {
          if (!summe[ereignis.textgesamt].labels[label.name]) {
            summe[ereignis.textgesamt].labels[label.name] = {
              name: label.name,
              farbe: label.farbe,
              count: 1,
              zeiten: [
                {
                  start: ereignis.startzeit,
                  ende: ereignis.endzeit,
                },
              ],
            };
          } else {
            summe[ereignis.textgesamt].labels[label.name].count += 1;
            summe[ereignis.textgesamt].labels[label.name].zeiten.push({
              start: ereignis.startzeit,
              ende: ereignis.endzeit,
            });
          }
        }
      }
    }
    let eventListe = Object.keys(summe).map(function (key) {
      // Using Number() to convert key to number type
      // Using obj[key] to retrieve key value
      let labels = Object.keys(summe[key].labels).map(function (keyLabel) {
        return {
          name: keyLabel,
          count: summe[key].labels[keyLabel].count,
          farbe: summe[key].labels[keyLabel].farbe,
          zeiten: summe[key].labels[keyLabel].zeiten,
        };
      });
      return {
        name: key,
        count: summe[key].count,
        farbe: summe[key].farbe,
        labels: labels,
        zeiten: summe[key].zeiten,
      };
    });
    this.eventListe = eventListe;
    return;
  }

  @action async save() {
    if (this.model.get('hasDirtyAttributes')) {
      await this.model.save();
      this.paperToaster.show(this.intl.t('Speichern erfolgreich!'));
    }
  }

  @tracked zoom = 1;

  @action zoomWork(zoom) {
    this.zoom = zoom;
    this.addonCore.resize();
  }

  @action layerAnzeigen(ansicht) {
    this.ansicht = ansicht;
    this.addonCore.resize();
  }

  // tagging
  async initialisierung(element, [self]) {
    if (self.addonCore.isApp) {
      Preferences.get({ key: 'tagging' })
        .then((storageResponse) => {
          if (storageResponse.value) {
            let tmp = JSON.parse(storageResponse.value);

            self.cols = tmp.cols;
            self.rows = tmp.rows;
            self.taggingAnsicht = tmp.taggingAnsicht;
          } else {
            self.cols = self.addonCore.colsPreferred;
            self.rows = self.addonCore.rowsPreferred;
            self.taggingAnsicht = self.cols + '-' + self.rows + '-1';
          }
        })
        .catch(() => {
          self.cols = self.addonCore.colsPreferred;
          self.rows = self.addonCore.rowsPreferred;
        })
        .finally(() => {
          self.addonCore.resizeFunction();
          next(self, function () {
            let zoom = self.video.schemaAutoFit(
              self.model.schema,
              self.ansicht
            );
            self.zoomWork(zoom);
          });
        });
    } else {
      let { value: ansicht } = await Preferences.get({ key: 'tagging' });
      if (ansicht) {
        ansicht = JSON.parse(ansicht);
        self.cols = ansicht.cols;
        self.rows = ansicht.rows;
        self.videoWidth = ansicht.videoWidth;
        self.videoHeight = ansicht.videoHeight;
        self.taggingAnsicht = ansicht.taggingAnsicht;
        self.switchSchema = ansicht.switchSchema;
        self.schemaAlt = ansicht.schemaAlt;
        setTimeout(function () {
          let zoom = self.video.schemaAutoFit(self.model.schema, self.ansicht);
          self.zoomWork(zoom);
        }, 500);
      }
    }
  }
  @action toggleOffListe() {
    this.isOffListe = !this.isOffListe;
  }
  get showisOffSchema() {
    return (
      (this.cols == 1 && this.rows == 1) ||
      (this.cols == 2 && this.rows == 1 && !this.switchSchema)
    );
  }

  get showisOffListe() {
    return (
      (this.cols == 1 && this.rows == 1) ||
      (this.cols == 2 && this.rows == 1 && this.switchSchema)
    );
  }

  @action toggleOffSchema() {
    this.isOffSchema = !this.isOffSchema;
    if (this.isOffSchema) {
      if (this.zoom < 0.1) {
        this.zoom = 1;
      }
      this.zoomWork(this.zoom);
    }
  }

  @service video;
  @service router;
  @service store;
  @service daten;

  @tracked url;

  @tracked isExport;
  @tracked isImport;
  @tracked isKeinSchema = false;

  @action importieren() {
    this.router.transitionTo('user.bibliothek.analyse.importieren');
  }

  @action async echo() {
    await Cross.pick();
  }

  @action testUrl() {
    let url = this.url;
    return new Promise((resolve, reject) => {
      let video = document.createElement('video');
      video.autoplay = true;
      video.oncanplay = function () {
        this.src = 'about:blank';
        document.body.removeChild(video);
        resolve();
      };
      video.onerror = (e) => {
        console.log('e', e);
        try {
          document.body.removeChild(video);
        } catch (e) {}
        reject(this.intl.t('video nicht kompatibel'));
      };
      document.body.appendChild(video);
      video.src = url;
    });
  }

  @action addUrl() {
    this.video.clip.web = this.url;
    return this.video.clip.save();
  }

  @action setAktiverVideoclip(clip) {
    this.video.clip = clip;
  }

  @action async addVideo() {}

  @tracked isOrdnerWaehlen = false;

  @action setOrdner(ordner) {
    this.model.ordner = ordner;
    this.model.save();
  }

  @action async ordnerWaehlenDialog(arg) {
    if (arg) {
      this.isOrdnerWaehlen = true;
    } else {
      this.isOrdnerWaehlen = false;
    }
  }

  @tracked isSchemaWaehlen = false;

  @action async setSchema(schema) {
    this.model.set('schema', schema);
    await this.model.save();
  }

  @action async schemaWaehlenDialog(arg) {
    if (arg) {
      this.isSchemaWaehlen = true;
      // this.tmpSchema = await this.model.schema;
      // this.tmpOrdner = await this.model.ordner;
    } else {
      this.isSchemaWaehlen = false;
    }
  }

  @action
  videoWaehlenDialog() {}

  @action
  videoExportierenDialog() {}

  @action exportDialog() {
    if (this.isExport) {
      this.isExport = false;
    } else {
      this.isExport = true;
    }
  }

  @tracked isLoeschen;
  @action
  loeschenDialog(bool) {
    this.isLoeschen = bool;
  }

  @action
  beforeLoeschen() {
    return this.model.destroyRecord();
  }

  @action
  afterLoeschen() {
    this.router.transitionTo('user.bibliothek.index');
  }
}
