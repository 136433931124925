import Model from '../classes/model';
import { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
export default class PlaylistszeneModel extends Model {
  @attr('number') position;

  @belongsTo('videoszene') videoszene;

  @attr() geteiltevideoszene;

  @attr('boolean') isVideochange; // triggert einen erneuten upload;

  @attr('string') web; // weburl des playlistszene

  @belongsTo('playlist') playlist;

  @attr('string') tmpID;

  @attr() layer;

  @tracked isChange = false;
  @tracked showLayer = true;
  @tracked selected = false;
}
