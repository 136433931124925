import { inject as service } from '@ember/service';
import config from 'athlyzer-coach/config/environment';
import { action } from '@ember/object';
import * as Sentry from '@sentry/ember';

import Service from './addon-user/workspaces';

export default class AddonUserService extends Service {
  @service store;
  @service router;
  @service intl;
  @service addonCore;
  @service metrics;

  showLoading() {
    clearTimeout(this.addonCoreTimer);
    if (!this.isSyncingFromRemoteDatabase) {
      this.isSyncingFromRemoteDatabase = true;
    }
    this.addonCoreTimer = setTimeout(() => {
      this.isSyncingFromRemoteDatabase = false;
    }, 1000);
  }

  @action openSentryUserFeedback() {
    const eventId = Sentry.captureMessage('Manual User Feedback');
    const vorname = this.settings.vorname || '';
    const nachname = this.settings.nachname || '';
    const email = this.settings.email || '';

    Sentry.showReportDialog({
      event_id: eventId,
      name: `${vorname} ${nachname}`,
      email: email,
      title: this.intl.t('Hast du einen Fehler in der App gefunden?'),
      subtitle: this.intl.t(
        'Wir sind dir für jede Fehlermeldung sehr dankbar!'
      ),
      subtitle2: this.intl.t(
        'Wenn Du uns helfen möchtest, teile uns bitte unten mit, was geschehen ist.'
      ),
      labelSubmit: this.intl.t('Fehlerbericht senden'),
    });
  }
}
