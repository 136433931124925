import Model from '../classes/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';

export default class OrdnerModel extends Model {
  icon = 'folder';
  typ = 'ordner';

  @attr('string') name;
  @hasMany('ordner', { inverse: 'parent' }) children;
  @belongsTo('ordner', { inverse: 'children', async: true }) parent;
  @attr('mdate') datum;
  @attr('boolean', { defaultValue: false }) highlight;

  get unhighlighted() {
    return !this.highlight;
  }
}
