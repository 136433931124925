import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AddonUserPasswordResetComponent extends Component {
  @service addonUser;
  @service paperToaster;
  @service intl;

  @tracked isOpen;
  @tracked token;
  @tracked password;
  @tracked passwordConfirm;
  @tracked message;

  @action initToken(element, [token, self]) {
    if (token) {
      self.token = token;
    }
  }

  @action close() {
    this.isOpen = false;
  }

  @action closeMessage() {
    this.message = '';
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.token = '';
    this.password = '';
    this.passwordConfirm = '';
  }

  @action async send(e) {
    e ? e.preventDefault() : null;
    try {
      await this.addonUser.resetPassword({
        token: this.token,
        password: this.password,
        confirmPassword: this.passwordConfirm,
      });
      this.isOpen = true;
    } catch (e) {
      console.log('e', e);
      let message = '';
      for (var v in e.validationErrors) {
        if (e.validationErrors.hasOwnProperty(v) && e.validationErrors[v][0]) {
          message += v + ': ' + e.validationErrors[v][0] + '\n';
        }
      }
      this.message = message;
      if (!message) {
        this.paperToaster.show(this.intl.t('Bitte überprüfe Deine Angaben'));
      }
    }
  }
}
