import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class TeamsShareFeedElementComponent extends Component {
  @service router;
  @service store;
  @service team;

  @tracked confirmDelete;

  @action toggleConfirmDelete() {
    this.confirmDelete = !this.confirmDelete;
  }

  @action async deletePlaylist() {
    let deletePromises = [];

    this.args.playlist.softdeleted = true;

    deletePromises.push(
      this.team.localDB.rel.save('playlist', this.args.playlist)
    );

    await Promise.all(deletePromises);
    this.args.reloadModel();
    this.toggleConfirmDelete();
  }

  get videoFakeClip() {
    console.log('firstPlaylistszene', this.firstPlaylistszene);
    return {
      existsOnWeb: true,
      web: this.firstPlaylistszene.web,
      get: function () {
        return false;
      },
    };
  }

  get firstPlaylistszene() {
    if (this.args.playlistszenen && this.args.playlistszenen.length > 0) {
      return this.args.playlistszenen
        .filter((playlistszene) => {
          return playlistszene.playlist === this.args.playlist.id;
        })
        .sort((a, b) => {
          return a.position - b.position;
        })[0];
    } else if (this.args.playlist) {
      console.log(
        'this.args.playlist',
        this.args.playlist.get('playlistszenen')
      );
      return this.args.playlist.get('playlistszenen')[0];
    } else {
      return {
        web: '',
      };
    }
  }

  @action async clickOnFeedElement(playlist) {
    if (!this.args.editMode) {
      this.router.transitionTo(
        'teams.team.authenticated.playlist',
        playlist.id
      );
    }
  }

  get feedElementCategories() {
    let categories = [];
    if (this.args.playlist) {
      categories.push('playlist');
    }
    return categories;
  }
}
