customElements.define(
  'modal-prompt-text',
  class extends HTMLElement {
    connectedCallback() {
      const modalElement = document.getElementById('modal-prompt-text');
      this.innerHTML = `
        <div class="flex w-[95vw] sm:w-[75vw] md:w-[60vw] lg:w-[50vw] sm:max-w-lg justify-center items-center no-mobile-inputs">
          <div
            class="flex w-full h-auto relative justify-center items-end bg-white px-4 pt-5 pb-4 max-h-screen transform transition-all sm:items-center sm:p-6"
          >
            <div class="w-full">
              <div class="px-0 md:px-4 text-lg font-medium">
              ${modalElement.componentProps.title}
              </div>
              <div class="w-full px-0 md:px-4 py-5 bg-white">
              <form id="modal-prompt-text-form">
                <label class="form-control w-full">
                  <div class="label">
                    <span class="label-text">${modalElement.componentProps.name}</span>
                  </div>
                  <input id="modal-input" type="text" class="input input-bordered w-full" value="${modalElement.componentProps.value}" />
                </label>     
              </form>
            </div>
            </div>
          </div>
        </div>
        <div class="grid gap-2 px-4 py-3 text-center grid-cols-2">
          <button id='modal-prompt-text-close' class="inline-flex justify-center items-center border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none transition ease-in-out duration-150 bg-white text-primary-600 hover:bg-primary-50 w-auto px-3 py-2" type="button">    
            Abbrechen
          </button>
          <button id="modal-prompt-text-confirm" class="inline-flex justify-center items-center border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none transition ease-in-out duration-150 text-white bg-primary-500 hover:bg-primary-700 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 w-auto px-3 py-2" type="button">
            OK
          </button> 
        </div>
        `;
      const closeButton = modalElement.querySelector(
        '#modal-prompt-text-close'
      );
      const confirmButton = modalElement.querySelector(
        '#modal-prompt-text-confirm'
      );
      const form = modalElement.querySelector('#modal-prompt-text-form');

      modalElement.querySelector('#modal-input').focus();

      const getDataAndCloseModal = () => {
        const value = modalElement.querySelector('#modal-input').value;
        console.log('value', value);
        modalElement.componentProps.dismiss({
          value,
          cancelled: false,
        });
      };

      form.addEventListener('submit', (e) => {
        e.preventDefault();
        getDataAndCloseModal();
      });

      confirmButton.addEventListener('click', () => {
        getDataAndCloseModal();
      });

      closeButton.addEventListener('click', () => {
        modalElement.componentProps.dismiss({
          value: false,
          cancelled: true,
        });
      });
    }

    disconnectedCallback() {}
  }
);

export default async function createModalPromptText(
  title,
  name,
  initialValue = ''
) {
  const modalElement = document.createElement('ion-modal');
  modalElement.component = 'modal-prompt-text';
  modalElement.id = 'modal-prompt-text';
  modalElement.componentProps = {
    value: initialValue,
    name,
    title,
    dismiss: (opt) => {
      console.log('opt', opt);
      modalElement.dismiss(opt);
    },
  };
  document.body.appendChild(modalElement);
  await modalElement.present();

  const { data, role } = await modalElement.onDidDismiss();
  modalElement.parentElement.removeChild(modalElement);

  if (role === 'backdrop') {
    return { value: false, cancelled: true };
  } else {
    return data;
  }
}
