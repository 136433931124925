import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AddonUserPasswortComponent extends Component {
  @service addonUser;
  @service router;

  @tracked btnIsActive = false;
  @tracked isPasswortVersendet = false;
  @tracked isPasswortError = false;
  @tracked usermail = '';

  checkForSpaces(usermail) {
    let laenge = usermail.length;
    if (usermail.charAt(laenge - 1) == ' ') {
      usermail = usermail.slice(0, laenge - 1);
    }
    return usermail;
  }

  @action
  async pwVergessen(e) {
    e ? e.preventDefault() : null;
    this.btnIsActive = true;
    try {
      let usermail = this.usermail;
      usermail = usermail.toLowerCase();
      if (usermail.charAt(usermail.length - 1) == ' ') {
        usermail = usermail.slice(0, -1);
      }
      await this.addonUser.forgotPassword(this.checkForSpaces(usermail));
      this.isPasswortVersendet = true;
    } catch (e) {
      this.isPasswortError = true;
      console.log('isPasswortError', e);
    } finally {
      this.btnIsActive = false;
    }
  }

  @action
  loginWechsel() {
    this.isPasswortVersendet = false;
    this.router.transitionTo('passwort-reset');
  }
}
