import Service from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class VisualService extends Service {
  @tracked isGridOpen = true;
  @tracked isLiveStatOpen = false;
  @tracked isSchemeOpen = false;
  @tracked folderLength = false;

  @tracked tappedVideo = false;

  @tracked topLeft = 50;
  @tracked analysisVisuals = {
    topLeft: {
      height: 50,
      width: 50,
    },
    topRight: {
      height: 50,
      width: 50,
    },
    bottomLeft: {
      height: 50,
      width: 50,
    },
    bottomRight: {
      height: 50,
      width: 50,
    },
  };

  //Bildschirmaufteilung
  @action closeGrid() {
    if (this.isGridOpen) {
      this.isGridOpen = false;
    }
  }
  @action toggleGrid() {
    console.log('toggleGrid');
    this.isGridOpen = !this.isGridOpen;
    if (this.isGridOpen) {
      this.isLiveStatOpen = false;
      this.isSchemeOpen = false;
    }
  }

  @action closeLivestat() {
    this.isLiveStatOpen && (this.isLiveStatOpen = false);
  }
  @action toggleLiveStat() {
    this.isLiveStatOpen = !this.isLiveStatOpen;
    if (this.isLiveStatOpen) {
      this.isGridOpen = false;
      this.isSchemeOpen = false;
    }
  }

  @action closeSchema() {
    this.isSchemeOpen && (this.isSchemeOpen = false);
  }
  @action toggleScheme() {
    this.isSchemeOpen = !this.isSchemeOpen;
    if (this.isSchemeOpen) {
      this.isGridOpen = false;
      this.isLiveStatOpen = false;
    }
  }

  setBox(location, box, type) {
    let overlord = document.getElementById('athlyzer-visual-overlord');
    overlord = overlord.getBoundingClientRect();
    console.log('box', location, box.width, overlord.width);
    let width = box.width / overlord.width;
    //let height = box.height / overlord.height;
    width = width * 100;
    //height = height * 100;
    console.log('width in percent', width.toFixed(0));
    this.analysisVisuals.topLeft.width = width;
    if (location.includes('left')) {
      this.topLeft = width.toFixed(0);
    }
    console.log('this.analysisvisuals', this.analysisVisuals.topLeft.width);
  }

  setFolderLength(length) {
    this.folderLength = length;
  }

  @action tapVideo() {
    this.tappedVideo = !this.tappedVideo;
  }

  @action resetTap() {
    this.tappedVideo = false;
  }
}
