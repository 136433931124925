customElements.define(
  'modal-delete',
  class extends HTMLElement {
    connectedCallback() {
      const modalElement = document.getElementById('modal-delete');
      this.innerHTML = `
        <div
          class="w-full h-full relative inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left max-h-screen transform transition-all sm:align-middle sm:max-w-lg sm:p-6"
        >
          <div class="mt-5 sm:mt-2 md:mt-0">
            <div class="text-lg font-medium">
            ${modalElement.componentProps.title}
            </div>
            <div class="py-5 bg-white">
              <p>
              ${modalElement.componentProps.name}
              </p>
            </div>
          </div>
        </div>
        <div class="grid gap-2 px-4 py-3 text-center grid-cols-2">
          <button id='modal-delete-close-2' class="inline-flex justify-center items-center border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none transition ease-in-out duration-150 bg-white text-primary-600 hover:bg-primary-50 w-auto px-3 py-2" type="button">    
            Abbrechen
          </button>
          <button id="modal-delete-confirm" class="inline-flex justify-center items-center border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none transition ease-in-out duration-150 text-white bg-primary-500 hover:bg-primary-700 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 w-auto px-3 py-2" type="button">
            Löschen
          </button> 
        </div>
     `;

      const closeButton2 = modalElement.querySelector('#modal-delete-close-2');
      const confirmButton = modalElement.querySelector('#modal-delete-confirm');
      const getDataAndCloseModal = () => {
        const value = true;
        modalElement.componentProps.dismiss({
          value,
          cancelled: false,
        });
      };
      confirmButton.addEventListener('click', () => {
        getDataAndCloseModal();
      });
      closeButton2.addEventListener('click', () => {
        modalElement.componentProps.dismiss({
          cancelled: true,
        });
      });
    }

    disconnectedCallback() {}
  }
);

export default async function createModalPromptText(title, name) {
  const modalElement = document.createElement('ion-modal');
  modalElement.component = 'modal-delete';
  modalElement.id = 'modal-delete';
  modalElement.componentProps = {
    name,
    title,
    dismiss: (opt) => {
      modalElement.dismiss(opt);
    },
  };
  document.body.appendChild(modalElement);
  await modalElement.present();
  const { data, role } = await modalElement.onDidDismiss();
  modalElement.parentElement.removeChild(modalElement);

  if (role === 'backdrop') {
    return { value: false, cancelled: true };
  } else {
    return data;
  }
}
