import Controller from '@ember/controller';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { action } from '@ember/object';
import { Keyboard } from '@capacitor/keyboard';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import * as Sentry from '@sentry/ember';

export default class ApplicationController extends Controller {
  @service addonCore;

  constructor(owner, args) {
    super(owner, args);
    if (
      Capacitor.getPlatform() == 'ios' ||
      Capacitor.getPlatform() == 'android'
    ) {
      this.initMobile();
    }

    if (typeof webserver != 'undefined') {
      this.initWebserver();
    }

    App.addListener('resume', () => {
      if (typeof webserver != 'undefined') {
        try {
          webserver.stop();
        } catch (error) {
          console.log('Could not stop webserver');
          Sentry.captureException(error);
        }
        this.initWebserver();
      }
    });
  }

  @action hideMobileBackdrop() {
    const el = window.document.querySelector('.input-absolute-top');
    el && el.blur();
  }

  hideKeyboardOnFocusout(event) {
    event.preventDefault();
    window.document.body.style.removeProperty('transform');
    Keyboard.hide();
  }

  hideKeyboardOnEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      window.document.activeElement.blur();
    }
  }

  waitForDocumentToExist() {
    return !Boolean(document.styleSheets[0]);
  }

  async initMobile() {
    do {
      await timeout(50);
    } while (this.waitForDocumentToExist());
    document.styleSheets[0].insertRule(
      '.md-panel-outer-wrapper {display: none !important;}',
      0
    );

    Keyboard.addListener('keyboardDidShow', (info) => {
      if (
        window.document.activeElement.tagName.toUpperCase() == 'INPUT' ||
        window.document.activeElement.tagName.toUpperCase() == 'TEXTAREA'
      ) {
        var viewportOffset =
          window.document.activeElement.getBoundingClientRect();
        var distanceToInputFromTop = viewportOffset.top;
        const keyboardHeight = info.keyboardHeight;

        const visibleAreaFromTop = window.innerHeight - keyboardHeight;

        if (this.addonCore.isIos) {
          if (visibleAreaFromTop < distanceToInputFromTop + 50) {
            window.document.body.setAttribute(
              'style',
              `transform: translateY(${
                visibleAreaFromTop - distanceToInputFromTop - 50
              }px);transition-duration: 0.5s;`
            );
          }
        } else if (this.addonCore.isAndroid) {
          // if (visibleAreaFromTop < distanceToInputFromTop + 50) {
          //   window.document.body.setAttribute(
          //     'style',
          //     `transform: translateY(${-(
          //       visibleAreaFromTop -
          //       distanceToInputFromTop -
          //       50
          //     )}px);transition-duration: 0.5s;`
          //   );
          // }
        }

        window.document.activeElement.addEventListener(
          'focusout',
          this.hideKeyboardOnFocusout
        );
        window.document.activeElement.addEventListener(
          'keyup',
          this.hideKeyboardOnEnter
        );
      }
    });
    Keyboard.addListener('keyboardWillHide', () => {
      if (
        window.document.activeElement.tagName.toUpperCase() == 'INPUT' ||
        window.document.activeElement.tagName.toUpperCase() == 'TEXTAREA'
      ) {
        window.document.activeElement.removeEventListener(
          'focusout',
          this.hideKeyboardOnFocusout,
          true
        );
        window.document.activeElement.removeEventListener(
          'keyup',
          this.hideKeyboardOnEnter,
          true
        );

        window.document.body.style.removeProperty('transform');
      }
    });
  }

  @action initWebserver() {
    webserver.onRequest(async (request) => {
      if (request.path && request.path.startsWith('/athlyzer/stream')) {
        let headers = {
          'Content-Type': 'video/mp4',
          'Accept-Ranges': 'bytes',
          'Access-Control-Allow-Origin': '*',
        };
        const query = request.query;

        let uri = query.split('=')[1];
        uri = uri.replace('file://', '');

        return webserver.sendResponse(
          request.requestId,
          {
            path: uri,
            headers: headers,
            // body : "ok",
            // headers : {
            //   "Content-Type" : "text/html"
            // }
          },
          () => {
            console.log('success on request');
          },
          (error) => {
            console.log('error on request', error);
          }
        );
      } else if (request.path && request.path == '/favicon.ico') {
        webserver.sendResponse(request.requestId, {
          status: 200,
          body: 'data:image/x-icon;base64,AAABAAEAEBAAAAAAAABoBAAAFgAAACgAAAAQAAAAIAAAAAEAIAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8AJCQk/yQkJP8AAAD/AAAA/wAAAP8AAAD/AAAA/wAAAP8AAAD/AAAA/0ZGRv8XFxf/////AP///wD///8A////AP///////////////+7u7v9OTk7/sbGx/11dXf9KSkr///////////+lpaX/4eHh/////wD///8A////AAAAAP8AAAD///////////+8vLz///////X19f/t7e3/gICA/5eXl////////////wAAAP8AAAD/////AKysrP8AAAD/AAAA/wAAAP+enp7/////////////////////////////////5OTk/wAAAP8AAAD/AAAA/6ysrP////8A////AP///wCurq6PlJSUzP///wD///8A////AP///wD///8A////AJSUlMyQkJDY////AP///wD///8A////AP///wD///8AAAAA/wAAAP////8A////AP///wD///8A////AP///wAAAAD/AAAA/////wD///8A////AP///wD///8AAAAA/wAAAP8AAAD/////AP///wD///8A////AP///wD///8AAAAA/wAAAP8AAAD/////AP///wD///8ACwsL/wAAAP8AAAD/lJSU2AAAAP8AAAD/cnJy/1hYWP8AAAD/AAAA/2FhYdgHBwf/AAAA/wAAAP////8A////AP///wD///8A////AP///wAAAAD/s7Oz/wAAAP8AAAD/x8fH/wAAAP////8A////AP///wD///8A////AP///wD///8A////AP///wAAAAD/AAAA/////wAAAAD/AAAA/////wAAAAD/AAAA/////wD///8A////AP///wD///8A////AP///wD///8AAAAA/wAAAP+qqqqlAAAA/wAAAP9lZWXjAAAA/wAAAP////8A////AP///wD///8A////AP///wD///8A////ALa2tv////8A9vb2UQAAAP8AAAD/////Uf///wDAwMD/////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wBcXFz/AAAA/////wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A//8AAP//AADAAwAAwAMAAIABAAAAAAAA5+cAAOfnAADH4wAAgAEAAPgfAADyTwAA8A8AAPZvAAD+fwAA//8AAA==',
          headers: {
            'Content-Type': 'image/x-icon',
            'Access-Control-Allow-Origin': '*',
          },
        });
      } else if (request.path && request.path != '/') {
        let headers = {
          'Content-Type': 'video/mp4',
          'Accept-Ranges': 'bytes',
          'Access-Control-Allow-Origin': '*',
        };

        if (request.path.indexOf('.mp4') > -1) {
          headers = {
            'Content-Type': 'video/mp4',
            'Accept-Ranges': 'bytes',
            'Access-Control-Allow-Origin': '*',
          };
        } else if (request.path.indexOf('.webm') > -1) {
          headers = {
            'Content-Type': 'video/webm',
            'Accept-Ranges': 'bytes',
            'Access-Control-Allow-Origin': '*',
          };
        } else if (request.path.toLowerCase().indexOf('.mov') > -1) {
          headers = {
            'Content-Type': 'video/quicktime',
            'Accept-Ranges': 'bytes',
            'Access-Control-Allow-Origin': '*',
          };
        } else if (request.path.indexOf('.m3u8') > -1) {
          headers = {
            'Content-Type': 'application/x-mpegURL',
            'Access-Control-Allow-Origin': '*',
          };
        }
        webserver.sendResponse(request.requestId, {
          path: request.path,
          headers: headers,
        });
      } else {
        // get all files
        console.log('ELSE ON REQUEST', request.path);
        try {
          let ret;
          if (request.query.indexOf('dir=data') > -1) {
            ret = await Filesystem.readdir({
              path: '',
              directory: Directory.Data,
            });
          } else {
            ret = await Filesystem.readdir({
              path: '',
              directory: Directory.Documents,
            });
          }
          if (request.query.indexOf('json=true') > -1) {
            webserver.sendResponse(request.requestId, {
              status: 200,
              body: JSON.stringify(ret.files),
              headers: {
                'Content-Type': 'application/json',
              },
            });
            return;
          }
          let links = [];
          for (const file of ret.files) {
            if (file.indexOf('.m3u8') > -1) {
              links.push(`<a href="/${file}">${file}</a>`);
            }
            if (file.indexOf('.ts') > -1) {
              links.push(`<a href="/${file}">${file}</a>`);
            }
            if (file.indexOf('.mp4') > -1) {
              links.push(`<a href="/${file}">${file}</a>`);
            }
          }
          webserver.sendResponse(request.requestId, {
            status: 200,
            body: '<html>' + links.join('<br>') + '</html>',
            headers: {
              'Content-Type': 'text/html',
            },
          });
        } catch (e) {
          webserver.sendResponse(request.requestId, {
            status: 200,
            body: '<html>list</html>',
            headers: {
              'Content-Type': 'text/html',
            },
          });
        }
      }
    });

    webserver.start(
      () => {
        console.log('Success');
      },
      () => {
        console.error('Error');
      },
      8080
    );
  }
}
