export default function (videoverarbeitung) {
  let clipsLength = Number(videoverarbeitung.get('videoclips').length);
  if (clipsLength > 0) {
    let max = Math.max(
      ...videoverarbeitung.get('videoclips').map((v) => v.get('position'))
    );
    if (max > clipsLength) {
      clipsLength = max;
    }
  }
  clipsLength++;
  console.log('clipsLength', clipsLength);
  return clipsLength;
}
