import MixinActionAccessRestrictionComponent from '../../../mixins/action-access-restriction';

export default class BasicsButtonIcon extends MixinActionAccessRestrictionComponent {
  get justifyPosition() {
    const tailwindPositions = {
      start: 'justify-start',
      center: 'justify-center',
      end: 'justify-end',
    };

    if (this.args.layout && tailwindPositions[this.args.layout]) {
      return tailwindPositions[this.args.layout];
    } else {
      return tailwindPositions.start;
    }
  }

  get hideBreakpointRestrictedIconPosition() {
    const fullCSS = {
      sm: 'sm:top-1 sm:right-1',
      md: 'md:top-2 md:right-2',
      lg: 'lg:top-2 lg:right-2',
    };

    if (this.args.hideTextBreakpoint) {
      return `top-0 right-0 ${fullCSS[this.args.hideTextBreakpoint]}`;
    } else {
      return 'top-0 right-0';
    }
  }

  get flexOrientationClasses() {
    const fullCSS = {
      sm: 'sm:space-y-2',
      md: 'md:space-y-2',
      lg: 'lg:space-y-2',
    };

    if (this.args.horizontal) {
      return 'flex-row space-x-2';
    } else if (this.args.text) {
      if (this.args.hideTextBreakpoint) {
        return `flex-col ${fullCSS[this.args.hideTextBreakpoint]}`;
      } else {
        return 'flex-col space-y-2';
      }
    } else {
      return '';
    }
  }

  get hideTextBreakpoint() {
    const fullCSS = {
      sm: 'sm:block',
      md: 'md:block',
      lg: 'lg:block',
    };

    if (this.args.hideTextBreakpoint) {
      return `${fullCSS[this.args.hideTextBreakpoint]} hidden`;
    } else {
      return 'block';
    }
  }
}
