import Service, { inject as service } from '@ember/service';
import { assert } from '@ember/debug';
import { isPresent } from '@ember/utils';
import mixpanel from 'mixpanel-browser';
import config from 'athlyzer-coach/config/environment';
import deviceutil from 'athlyzer-coach/classes/deviceutil';
import formbricks from '@formbricks/js';

export default class MetricsService extends Service {
  @service addonUser;
  @service addonCore;
  @service intl;

  constructor() {
    super(...arguments);

    mixpanel.init('afcb4dd1916a941c97a34fe662ca478a', {
      debug: config.environment === 'development',
      api_host: 'https://api-eu.mixpanel.com',
      opt_out_tracking_by_default: true,
    });
  }

  allowTracking() {
    if (!this.hasOptedInTracking()) {
      mixpanel.opt_in_tracking();
    }
    this.installGtag();
  }

  disallowTracking() {
    if (!this.hasOptedOutTracking()) {
      mixpanel.opt_out_tracking();
    }
    this.uninstallGtag();
  }

  initializeCookieBanner() {
    const cookieConsentBannerElement = document.querySelector(
      'cookie-consent-banner'
    );

    if (cookieConsentBannerElement) {
      cookieConsentBannerElement.availableCategories = [
        {
          description: this.intl.t(
            'Ermöglicht Dir die Navigation und die Nutzung der Grundfunktionen sowie das Speichern von Einstellungen.'
          ),
          key: 'technically_required',
          label: this.intl.t('Technisch notwendige Cookies'),
          isMandatory: true,
        },
        {
          description: this.intl.t(
            'Ermöglicht es uns festzustellen, wie Besucher mit unserem Produkt interagieren, um die Benutzererfahrung zu verbessern.'
          ),
          key: 'analytics',
          label: this.intl.t('Analytics Cookies'),
        },
        {
          description: this.intl.t(
            'Ermöglicht es uns relevante Inhalte und interessenbezogene Werbung anzubieten und auszuwerten.'
          ),
          key: 'marketing',
          label: 'Marketing',
        },
      ];

      cookieConsentBannerElement.addEventListener(
        'cookie_consent_preferences_restored',
        (event) => {
          console.log('cookie_consent_preferences_restored', event);
          if (
            event.detail.acceptedCategories &&
            event.detail.acceptedCategories.includes('analytics') &&
            this.isAllowedToTrack
          ) {
            this.allowTracking();
          } else {
            this.disallowTracking();
          }
        }
      );

      cookieConsentBannerElement.addEventListener(
        'cookie_consent_preferences_updated',
        (event) => {
          console.log('cookie_consent_preferences_updated', event);
          if (
            event.detail.acceptedCategories &&
            event.detail.acceptedCategories.includes('analytics') &&
            this.isAllowedToTrack
          ) {
            this.allowTracking();
          } else {
            this.disallowTracking();
          }
        }
      );
    }
  }

  get isAllowedToTrack() {
    let currentDeployTarget = this.addonUser.currentDeployTarget;

    if (
      currentDeployTarget === 'development' ||
      currentDeployTarget === 'alpha' ||
      currentDeployTarget === 'test'
    ) {
      return false;
    } else {
      return true;
    }
  }

  track(event, options) {
    const { platform, platformname } = deviceutil;
    let trackingOptions = {
      platform,
      platformname,
      paketName: this.addonUser.paketName,
    };
    trackingOptions = Object.assign(trackingOptions, options);

    if (this.isAllowedToTrack) {
      if (options) {
        mixpanel.track(event, trackingOptions);
      } else {
        mixpanel.track(event, trackingOptions);
      }
    } else {
      console.log('Mixpanel Track', event, trackingOptions);
    }
  }

  identify(distinct_id) {
    if (this.isAllowedToTrack) {
      mixpanel.identify(distinct_id);
    }
  }

  peopleSetOnce(hash) {
    if (this.isAllowedToTrack) {
      mixpanel.people.set_once(hash);
    }
  }

  peopleSet(hash) {
    if (this.isAllowedToTrack) {
      mixpanel.people.set(hash);
    }
  }

  hasOptedInTracking(options) {
    if (options) {
      return mixpanel.has_opted_in_tracking(options);
    } else {
      return mixpanel.has_opted_in_tracking();
    }
  }

  hasOptedOutTracking(options) {
    if (options) {
      return mixpanel.has_opted_out_tracking(options);
    } else {
      return mixpanel.has_opted_out_tracking();
    }
  }

  logoutIdentifiedUser() {
    try {
      formbricks.logout();
    } catch (e) {
      console.log('Couldnt logout Formbricks');
    }
    try {
      mixpanel.reset();
    } catch (e) {
      console.log('Couldnt reset Mixpanel');
    }
  }

  // FORMBRICKS

  async initFormbricks(userId) {
    if (typeof window !== 'undefined') {
      let initOptions = {
        environmentId: config.FORMBRICKS_ENVIRONMENT_ID,
        apiHost: 'https://surveys.athlyzer.com',
        debug: config.environment !== 'production', // remove when in production
      };

      if (userId) {
        initOptions.userId = userId;
      }

      await formbricks.init(initOptions);

      await formbricks.setEmail(this.addonUser.settings?.email);
      await formbricks.setAttribute('Plan', this.addonUser.paketName);
    }
  }

  trackFormbricks(event) {
    formbricks.track(event);
  }

  // GOOGLE ANALYTICS

  compact(opts) {
    return this.includeKeys(opts, (key) => isPresent(opts[key]));
  }

  removeFromDOM(selectors) {
    document.querySelectorAll(selectors).forEach((el) => {
      el.parentElement?.removeChild(el);
    });
  }

  installGtag() {
    if (!this.addonCore.isWeb) return;

    const id = config.GOOGLE_ANALYTICS_ID;
    const options = {
      anonymize_ip: true,
      debug_mode: config.environment === 'development',
    };

    assert(
      `[ember-metrics] You must pass a valid \`id\` to the ${this.toString()} adapter`,
      id
    );

    this.options = {
      send_page_view: true,
      ...options,
    };

    this._injectGtagScript(id);

    window.dataLayer = window.dataLayer || [];
    this.gtag('js', new Date());
    this.gtag('config', id, this.compact(this.options));
  }

  _injectGtagScript(id) {
    let script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;

    document.head.appendChild(script);
  }

  gtag() {
    if (!window.dataLayer) return;
    window.dataLayer.push(arguments);

    return arguments;
  }

  trackGtagEvent(options = {}) {
    if (!this.addonCore.isWeb) return;

    const compactedOptions = this.compact(options);
    const { event } = compactedOptions;

    if (!event) {
      return;
    }

    delete compactedOptions.event;

    return this.gtag('event', event, compactedOptions);
  }

  trackGtagPage(options = {}) {
    if (!this.addonCore.isWeb) return;

    if (this.options.send_page_view) {
      return;
    }

    if (options?.page && !options?.page_location) {
      options.page_location = options?.page;
      delete options.page;
    }

    if (options?.title && !options?.page_title) {
      options.page_title = options?.title;
      delete options.title;
    }

    return this.trackGtagEvent({
      event: 'page_view',
      ...options,
    });
  }

  uninstallGtag() {
    this.removeFromDOM('script[src*="gtag/js"]');

    delete window.dataLayer;
  }

  includeKeys(opts, include) {
    const newOpts = {};

    for (const key in opts) {
      if (include(key)) newOpts[key] = opts[key];
    }

    return newOpts;
  }
}
