import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
export default class SessionRoute extends Route {
  @service addonUser;
  @service router;

  queryParams = {
    t: '',
    p: '',
  };
  model(params) {
    const token = params.t;
    const password = params.p;
    if (!token || !password) {
      this.router.transitionTo('login');
    } else {
      return this.addonUser.loginExistingSession(token, password);
    }
  }
  afterModel() {
    this.router.transitionTo('index');
  }
}
