import Service from './download';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import uuid from 'athlyzer-coach/application/uuid';

import * as Sentry from '@sentry/ember';

import { task, timeout, enqueueTask, waitForProperty } from 'ember-concurrency';

import FfmpegCommandBuilder from 'athlyzer-coach/cross/ffmpeg';

export default class MultiPartUploadService extends Service {
  async startProcessingVideoOperations(operationsArray, cutInternalOptions) {
    const processVideoFilesTask = this.processVideoOperations.perform(
      operationsArray,
      cutInternalOptions
    );

    try {
      await processVideoFilesTask;
    } catch (error) {
      if (!error.name.includes('TaskCancelation')) {
        console.log('CutError', error);
        Sentry.captureException(error);
        return Promise.reject({ cancelled: false, errored: true });
      }
      return Promise.reject({ cancelled: true, errored: true });
    }

    return Promise.resolve(mergeVideoFilesTask.value);
  }

  processVideoOperations = task(
    { enqueue: 1, maxConcurrency: 1 },
    async (operationsArray, cutInternalOptions) => {
      let operationIndex = 0;
      let videourl = null;

      try {
        for (let operation of operationsArray) {
          switch (operation.type) {
            case 'merge':
              const mergeVideoFilesTask = await this.mergeVideoFiles.perform(
                operation.videosPathesToMerge,
                operation.videoexportpath,
                cutInternalOptions
              );
              break;
            case 'cut':
              break;
            case 'frame-to-image':
              break;
            case 'image-to-video-overlay':
              break;
            default:
              break;
          }

          operationIndex++;
        }
      } catch (e) {
        videourl = null;
        Sentry.captureException(e);
      } finally {
        // await this.cleanCompletedCutProcesses.perform(taskKey);
      }
      return { videourl: videourl, relativeVideourl: videourl };
    }
  );
}
