import Model from '../classes/model';
import { attr } from '@ember-data/model';

export default class ClipexportpresetModel extends Model {
  @attr('string') name;

  @attr('string', {
    defaultValue: 'Platzhalter 1',
  })
  topLeftName;

  @attr('string', {
    defaultValue: 'Platzhalter 2',
  })
  topRightName;

  @attr('string', {
    defaultValue: 'Platzhalter 3',
  })
  bottomLeftName;

  @attr('string', {
    defaultValue: 'Platzhalter 4',
  })
  bottomRightName;

  @attr('attachments', {
    defaultValue: function () {
      return [];
    },
  })
  images;

  @attr('string', { defaultValue: 'MEDIUM' }) exportQuality;

  get mergedImageAttachmentWithPosition() {
    const imagePositionedObjects = this.images.reduce(
      (accumulator, currentImage) => {
        accumulator[currentImage.name] = currentImage;
        return accumulator;
      },
      {}
    );
    return imagePositionedObjects;
  }
}
