import Service from './taskmanager/upload';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class TaskmanagerService extends Service {
  @service store;
  @service addonCore;
  @service addonUser;
  @service video;
  @service intl;
  @service metrics;

  @tracked isChange;

  constructor() {
    super(...arguments);
  }

  getUploadInfoFromVideoUrl(url) {
    var filename = url.substring(url.lastIndexOf('/') + 1).split('.');
    let extension = filename.pop();
    extension = extension.toLowerCase();
    filename = filename.join('.');
    return {
      filename,
      extension,
    };
  }

  get countAllQueuedTasks() {
    return (
      this.numConvertTasksEnqueued +
      this.numCutTasksEnqueued +
      this.uploadTaskInstances.length +
      this.downloadClipTaskInstances.length
    );
  }

  get areUploadsStillRunning() {
    const uploadsRunning = this.uploadProcesses.reduce(
      (running, currentUpload) => {
        if (running) {
          return running;
        }

        return currentUpload.progressPercentage < 100;
      },
      false
    );

    return uploadsRunning;
  }

  get areUploadsQueued() {
    return this.uploadProcesses.length > 0;
  }

  get areDownloadsStillRunning() {
    const downloadsRunning = this.downloadProcesses.reduce(
      (running, currentDownload) => {
        if (running) {
          return running;
        }

        return currentDownload.progressPercentage < 100;
      },
      false
    );
    return downloadsRunning;
  }

  get areDownloadsQueued() {
    return this.downloadProcesses.length > 0;
  }

  get areVideoTasksStillRunning() {
    const runningConvertAndCutTasks =
      this.numConvertTasksEnqueued + this.numCutTasksEnqueued > 0;

    return (
      this.areUploadsStillRunning ||
      this.areDownloadsStillRunning ||
      runningConvertAndCutTasks
    );
  }
}
