import CoreObject from '@ember/object/core';

export default class Pakete extends CoreObject {
  paketNamen = {
    free: ['Viewer'],
    rookie: ['Rookie'],
    professional: ['Professional', 'Allstars', '10 Days Free'],
    expert: ['Expert', 'Partner', 'Education', 'Trial'],
    level1: ['Esport Lvl 1'],
    level2: ['Esport Lvl 2'],
    level3: ['Esport Lvl 3'],
  };

  pakete = {
    scheme: {
      open: [
        ...this.paketNamen.rookie,
        ...this.paketNamen.professional,
        ...this.paketNamen.expert,
        ...this.paketNamen.level1,
        ...this.paketNamen.level2,
        ...this.paketNamen.level3,
      ],
      views: [
        ...this.paketNamen.professional,
        ...this.paketNamen.expert,
        ...this.paketNamen.level2,
        ...this.paketNamen.level3,
      ],
      hotkeys: [
        ...this.paketNamen.professional,
        ...this.paketNamen.expert,
        ...this.paketNamen.level2,
        ...this.paketNamen.level3,
      ],
      livestats: [...this.paketNamen.expert],
    },
    analysis: {
      open: [
        ...this.paketNamen.rookie,
        ...this.paketNamen.professional,
        ...this.paketNamen.expert,
        ...this.paketNamen.level1,
        ...this.paketNamen.level2,
        ...this.paketNamen.level3,
      ],
      videoclips: {
        ytdownload: [
          ...this.paketNamen.professional,
          ...this.paketNamen.expert,
          ...this.paketNamen.level2,
        ],
      },
      tagging: {
        views: [
          ...this.paketNamen.professional,
          ...this.paketNamen.expert,
          ...this.paketNamen.level2,
        ],
      },
      capture: [
        ...this.paketNamen.professional,
        ...this.paketNamen.expert,
        ...this.paketNamen.level2,
      ],
      evaluation: {
        basic: [
          ...this.paketNamen.professional,
          ...this.paketNamen.expert,
          ...this.paketNamen.level2,
        ],
        advanced: [...this.paketNamen.expert],
      },
      slides: [
        ...this.paketNamen.professional,
        ...this.paketNamen.expert,
        ...this.paketNamen.level2,
      ],
      halftimemode: [...this.paketNamen.expert],
    },
    draw: {
      full: [
        ...this.paketNamen.professional,
        ...this.paketNamen.expert,
        ...this.paketNamen.level2,
        ...this.paketNamen.level3,
      ],
    },
    videoexport: {
      single: [
        ...this.paketNamen.professional,
        ...this.paketNamen.expert,
        ...this.paketNamen.level2,
      ],
      powerpoint: [
        ...this.paketNamen.professional,
        ...this.paketNamen.expert,
        ...this.paketNamen.level2,
      ],
      brandedlogo: [
        ...this.paketNamen.professional,
        ...this.paketNamen.expert,
        ...this.paketNamen.level2,
      ],
      nologo: [...this.paketNamen.expert],
      drawings: {
        default: [...this.paketNamen.expert],
        teams: {
          default: [...this.paketNamen.professional, ...this.paketNamen.expert],
          save: [...this.paketNamen.expert],
        },
      },
    },
    playlist: {
      automated: [
        ...this.paketNamen.professional,
        ...this.paketNamen.expert,
        ...this.paketNamen.level2,
        ...this.paketNamen.level3,
      ],
    },
    collection: {
      open: [
        ...this.paketNamen.professional,
        ...this.paketNamen.expert,
        ...this.paketNamen.level2,
      ],
      automated: [...this.paketNamen.expert, ...this.paketNamen.level3],
    },
    board: {
      open: [
        ...this.paketNamen.professional,
        ...this.paketNamen.expert,
        ...this.paketNamen.level2,
        ...this.paketNamen.level3,
      ],
      record: [...this.paketNamen.expert, ...this.paketNamen.level3],
    },
    teams: {
      open: [
        ...this.paketNamen.rookie,
        ...this.paketNamen.professional,
        ...this.paketNamen.expert,
        ...this.paketNamen.level2,
        ...this.paketNamen.level3,
      ],
    },
  };
}
