import Model from '../classes/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';

export default class TeammemberModel extends Model {
  @attr('string') nachname;
  @attr('string') vorname;
  @attr('string') mail;
  @belongsTo('ordner') ordner;

  icon = 'person';

  get name() {
    let vollerName = this.vorname + ' ' + this.nachname;

    return vollerName;
  }

  @hasMany('teamposition', { save: true }) positions;
  @hasMany('team', { save: true }) teams;
}
