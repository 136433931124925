export const denormalizeCoordinatesRelativeToStage = function (
  positionX,
  positionY,
  initWidth,
  initHeight
) {
  return {
    x: positionX * initWidth,
    y: positionY * initHeight,
  };
};
export const denormalizedLayerForBoards = function (
  layerJSON,
  initWidth,
  initHeight
) {
  for (let child of layerJSON.children) {
    // DENORMALIZE COORDINATES
    if (child.attrs.x && child.attrs.y) {
      let denormalizedCoordinates = denormalizeCoordinatesRelativeToStage(
        child.attrs.x,
        child.attrs.y,
        initWidth,
        initHeight
      );
      child.attrs.x = denormalizedCoordinates.x;
      child.attrs.y = denormalizedCoordinates.y;
    } else if (child.attrs.points) {
      let denormalizedPoints = [];

      for (let i = 0; i < child.attrs.points.length; i += 2) {
        let denormalizedCoordinates = denormalizeCoordinatesRelativeToStage(
          child.attrs.points[i],
          child.attrs.points[i + 1],
          initWidth,
          initHeight
        );

        denormalizedPoints.push(
          denormalizedCoordinates.x,
          denormalizedCoordinates.y
        );
      }

      child.attrs.points = denormalizedPoints;
    }

    // DENORMALIZE REPRESENTATION

    if (child.attrs.strokeWidth) {
      child.attrs.strokeWidth = child.attrs.strokeWidth * 3;
    }

    if (child.attrs.pointerLength && child.attrs.pointerWidth) {
      child.attrs.pointerLength = child.attrs.pointerLength * 3;
      child.attrs.pointerWidth = child.attrs.pointerWidth * 3;
    }

    if (child.attrs.radius) {
      child.attrs.radius = child.attrs.radius * 2;
    }

    if (child.attrs.fontSize) {
      child.attrs.fontSize = child.attrs.fontSize * 2.5;
    }

    if (child.attrs.width && child.attrs.height) {
      child.attrs.width = child.attrs.width * 4.5;
      child.attrs.height = child.attrs.height * 4.5;
    }

    if (child.attrs.dash) {
      child.attrs.dash = [30, 15];
    }
  }

  return layerJSON;
};
export default {
  denormalizeCoordinatesRelativeToStage,
  denormalizedLayerForBoards,
};
