/*
title, 
properties [{
        text: String,
        role : 'string'
      },]
*/

export default async function createActionSheet(title, cancelText, properties) {
  const actionSheet = document.createElement('ion-action-sheet');
  actionSheet.header = title;
  //actionSheet.subHeader = 'Example subheader';
  actionSheet.buttons = [
    ...properties,
    {
      text: cancelText,
      role: 'cancel',
      data: {
        action: 'cancel',
      },
    },
  ];
  document.body.appendChild(actionSheet);
  await actionSheet.present();
  const result = await actionSheet.onDidDismiss();
  return result;
}
