export default function (ms) {
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / 1000 / 60) % 60);
  const hours = Math.floor((ms / 1000 / 3600) % 24);

  const humanized = [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0'),
  ].join(':');

  return humanized;
}
// try {
//   let datum = new Date(date).toISOString();
// let hours = Number(datum.substr(11, 2));
// let minutes = Number(datum.substr(14, 2));
// let seconds = Number(datum.substr(17, 2));
// if (seconds <= 9) {
//   seconds = String(seconds);
//   seconds = '0' + seconds;
// }

// minutes = hours * 60 + minutes;
// if (minutes <= 9) {
//   minutes = String(minutes);
//   minutes = '0' + minutes;
// }
// let returnString = minutes + ':' + seconds;
// return String(returnString);
// } catch (error) {
//   return 'invalid';
// }
// }
