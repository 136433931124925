import { registerPlugin, Capacitor } from '@capacitor/core';

const Cross = registerPlugin('Cross', {
  web: async () => {
    if (Capacitor.getPlatform() == 'electron') {
      const m = await import('./electron');
      return new m.CrossElectron();
    } else {
      const m = await import('./web');
      return new m.CrossWeb();
    }
  },
  electron: async () => {
    const m = await import('./electron');
    return new m.CrossElectron();
  },
});

export default Cross;
