import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class BasicsLayoutComponent extends Component {
  @service addonCore;

  get isIOS() {
    return this.addonCore.isIos;
  }

  get maxWidth() {
    if (this.addonCore.isIos) {
      this.addonCore.orientation;

      return `width:${window.innerWidth}px`;
    } else {
      return '';
    }
  }
}
