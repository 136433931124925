export const YOUTUBE_REGEX =
  /^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)\/(?!channel\/)(?!@)(.+)?$/;
export const YOUTUBE_REGEX_GLOBAL =
  /^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)\/(?!channel\/)(?!@)(.+)?$/g;

export const ATHLYZER_REGEX =
  /^(https?:\/\/)?(api.prod\.|share\.|localhost:[0-9]+)?(athlyzer\.com|localhost:[0-9]{4})\/(?!\s\/)(?!oembed)(.+)?/;
export const ATHLYZER_REGEX_GLOBAL =
  /^(https?:\/\/)?(api\.prod\.|share\.)?(athlyzer\.com|localhost:[0-9]{4})\/(?!\s\/)(?!oembed)(.+)?/g;

export const isValidAthlyzerUrl = (url) => {
  return url.match(ATHLYZER_REGEX);
};

export const isValidYoutubeUrl = (url) => {
  return url.match(YOUTUBE_REGEX);
};

export const getAthlyzerEmbedUrl = () => {
  return 'https://share.athlyzer.com/s/embed/';
};

export const getYoutubeEmbedUrl = (nocookie) => {
  return nocookie
    ? 'https://www.youtube-nocookie.com/embed/'
    : 'https://www.youtube.com/embed/';
};
export const getEmbedUrlFromAthlyzerUrl = (options) => {
  const { url, nocookie } = options;

  if (!isValidAthlyzerUrl(url)) {
    console.log('getEmbedUrlFromAthlyzerUrl', 'not valid');
    return null;
  }

  // if is already an embed url, return it
  if (url.includes('/embed/')) {
    return url;
  }
  console.log(
    'getAthlyzerEmbedUrl(nocookie)',
    `${getAthlyzerEmbedUrl(nocookie)}?url=${url}`
  );
  return `${getAthlyzerEmbedUrl(nocookie)}?url=${url}`;
};

export const getEmbedUrlFromYoutubeUrl = (options) => {
  const {
    url,
    allowFullscreen,
    autoplay,
    ccLanguage,
    ccLoadPolicy,
    controls,
    disableKBcontrols,
    enableIFrameApi,
    endTime,
    interfaceLanguage,
    ivLoadPolicy,
    loop,
    modestBranding,
    nocookie,
    origin,
    playlist,
    progressBarColor,
    startAt,
  } = options;

  if (!isValidYoutubeUrl(url)) {
    return null;
  }

  // if is already an embed url, return it
  if (url.includes('/embed/')) {
    return url;
  }

  // if is a youtu.be url, get the id after the /
  if (url.includes('youtu.be')) {
    const id = url.split('/').pop();

    if (!id) {
      return null;
    }
    return `${getYoutubeEmbedUrl(nocookie)}${id}`;
  }

  const videoIdRegex = /(?:v=|shorts\/)([-\w]+)/gm;
  const matches = videoIdRegex.exec(url);

  if (!matches || !matches[1]) {
    return null;
  }

  let outputUrl = `${getYoutubeEmbedUrl(nocookie)}${matches[1]}`;

  const params = [];

  if (allowFullscreen === false) {
    params.push('fs=0');
  }

  if (autoplay) {
    params.push('autoplay=1');
  }

  if (ccLanguage) {
    params.push(`cc_lang_pref=${ccLanguage}`);
  }

  if (ccLoadPolicy) {
    params.push('cc_load_policy=1');
  }

  if (!controls) {
    params.push('controls=0');
  }

  if (disableKBcontrols) {
    params.push('disablekb=1');
  }

  if (enableIFrameApi) {
    params.push('enablejsapi=1');
  }

  if (endTime) {
    params.push(`end=${endTime}`);
  }

  if (interfaceLanguage) {
    params.push(`hl=${interfaceLanguage}`);
  }

  if (ivLoadPolicy) {
    params.push(`iv_load_policy=${ivLoadPolicy}`);
  }

  if (loop) {
    params.push('loop=1');
  }

  if (modestBranding) {
    params.push('modestbranding=1');
  }

  if (origin) {
    params.push(`origin=${origin}`);
  }

  if (playlist) {
    params.push(`playlist=${playlist}`);
  }

  if (startAt) {
    params.push(`start=${startAt}`);
  }

  if (progressBarColor) {
    params.push(`color=${progressBarColor}`);
  }

  if (params.length) {
    outputUrl += `?${params.join('&')}`;
  }

  return outputUrl;
};
