import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import config from 'athlyzer-coach/config/environment';

export default class BasicsCookieBannerComponent extends Component {
  @service metrics;
  @service addonCore;

  @tracked showMoreDetailsBanner = false;

  initializeMetricsBanner(element, [self]) {
    if (self.addonCore.isIos) {
      return;
    } else {
      self.metrics.initializeCookieBanner();
    }
  }

  @action dispatchCookieBannerShowEvent() {
    document.dispatchEvent(new CustomEvent('cookie_consent_show'));
  }

  @action dispatchCookieBannerShowMoreEvent() {
    document.dispatchEvent(new CustomEvent('cookie_consent_details_show'));
    this.showMoreDetailsBanner = true;
  }

  get cookieDomain() {
    if (this.addonCore.isWeb) {
      return config.DEPLOY_TARGET_ENVIRONMENT === 'production'
        ? 'athlyzer.com'
        : 'localhost';
    } else if (this.addonCore.isIos || this.addonCore.isAndroid) {
      return 'capacitor';
    } else if (this.addonCore.isElectron) {
      return '-';
    }
  }

  @action openDataPrivacy() {
    this.addonCore.openBrowser(
      'https://www.iubenda.com/privacy-policy/74342558'
    );
  }
}
