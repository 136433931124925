import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { once } from '@ember/runloop';
import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';
import { Browser } from '@capacitor/browser';
import Cross from 'athlyzer-coach/cross/plugin';
import { Capacitor } from '@capacitor/core';
import Deviceutil from '../classes/deviceutil';

export default class AddonCoreService extends Service {
  @service paperToaster;
  @service addonUser;
  @service intl;
  @service router;

  @tracked isLoading;
  @tracked isLoadingCloseButton = false;

  @tracked infoMessage = '';
  @tracked infoInput = '';
  @tracked isInfo = false;
  @tracked text = '';
  @tracked dirtyAnalysis = false;

  //pricing
  @tracked isFeatureAccessRestrictedModalOpen = false;
  //pricing
  @tracked team;

  /* blocking back button */
  @tracked isGoingBack = false;

  @tracked device;

  @tracked orientation = 'portrait';

  @action setOrientation() {
    this.orientation =
      window.innerHeight > window.innerWidth ? 'portrait' : 'landscape';
  }

  get isLandscape() {
    return this.orientation == 'landscape';
  }

  get isPortrait() {
    return this.orientation != 'landscape';
  }

  @action showIsLoadingCloseButton() {
    this.isLoadingCloseButton = true;
  }
  @action hideIsLoadingCloseButton() {
    this.isLoading && (this.isLoading = false);
    this.isLoadingCloseButton = false;
  }

  setText(text) {
    this.text = text;
  }

  @action openBrowser(url) {
    if (this.isDesktop) {
      Cross.openBrowser({ url: url });
    } else if (this.isIos) {
      window.open(url, '_system');
    } else {
      Browser.open({ url: url });
    }
  }

  @action shareMarkdown(textcontent, mailingliste) {
    let title = this.intl.t('Neue Nachricht von Deinem Trainer!');
    let mailtoteam = '';
    let text = textcontent;

    encodeURIComponent(text);
    text = text.replace(/\n/g || [], '%0D%0A');
    if (this.isDesktop) {
      for (let member of mailingliste) {
        mailtoteam = mailtoteam + member + ',';
      }

      console.log('after render markdown', text);
      let mailto = `mailto:?bcc=${mailtoteam}&subject=${title}&body=${text}`;
      let win = window.open(mailto);
      setTimeout(() => {
        if (win) {
          win.close();
        }
      }, 150);
    } else {
      Share.share({
        title: title,
        text: text,
        dialogTitle: title,
      });
    }
  }

  @action mailTo(email) {
    const mailto = `mailto:${email}`;
    window.open(mailto);
  }

  @action async shareLink(link, title, passwort) {
    let text =
      this.intl.t('Ich habe für Dich eine neue Playlist zusammengestellt') +
      '. ' +
      '%0D%0A' +
      '%0D%0A' +
      this.intl.t('Folge dem Link: ') +
      '%0D%0A' +
      link +
      '%0D%0A' +
      '%0D%0A' +
      this.intl.t('Das Passwort dazu lautet: ') +
      passwort +
      '%0D%0A' +
      '%0D%0A' +
      this.intl.t('Viele Grüße') +
      '%0D%0A' +
      this.addonUser.settings.vorname +
      ' ' +
      this.addonUser.settings.nachname;
    if (this.isDesktop) {
      let teamlist = '';
      if (this.team) {
        let members = await this.team.get('teammembers');
        for (let member of members.toArray()) {
          teamlist += member.get('mail') + ',';
        }
      }
      title = encodeURIComponent(title);
      let mailto = `mailto:?bcc=${teamlist}&subject=${title}&body=${text}`;
      let win = window.open(mailto);
      setTimeout(() => {
        if (win) {
          win.close();
        }
      }, 300);
    } else {
      Share.share({
        title: title,
        text: text,
        url: link,
        dialogTitle: title,
      });
    }
  }

  @action
  kopiereInZwischenanlage(text) {
    Clipboard.write({
      string: text,
    });
    this.paperToaster.show(
      this.intl.t('Der Link wurde in die Zwischenablage kopiert')
    );
  }

  openInfo(message, input) {
    if (message) {
      this.infoMessage = message;
    }
    if (input) {
      this.infoInput = input;
    }
    this.isInfo = true;
  }
  @action async closeInfo() {
    this.infoMessage = '';
    this.infoInput = '';
    this.isInfo = false;
    return Promise.resolve();
  }

  @action closePurchase() {
    this.isFeatureAccessRestrictedModalOpen = false;
  }

  ///////////////////////////////////////////////
  // Purchases //////////////////////////////////
  ///////////////////////////////////////////////

  tmp;
  hls;
  @tracked onlineStatus = navigator.onLine;

  constructor() {
    super(...arguments);
    this.resizeInit();
    this.initDevice();
    this.determineOnlineStatus();
    this.setOrientation();
  }

  determineOnlineStatus() {
    window.addEventListener('online', () => {
      // Set hasNetwork to online when they change to online.
      this.onlineStatus = true;
    });

    window.addEventListener('offline', () => {
      // Set hasNetwork to offline when they change to offline.
      this.onlineStatus = false;
    });
  }

  sortBy(array, key = 'name', reverse) {
    if (reverse) {
      return array.sort((a, b) => {
        var x = a[key];
        var y = b[key];
        return x > y ? -1 : x < y ? 1 : 0;
      });
    } else {
      return array.sort((a, b) => {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    }
  }

  async initDevice() {
    set(this, 'device', {
      platform: Capacitor.getPlatform(),
    });
  }

  platform = Deviceutil.platform;
  platformname = Deviceutil.platformname;
  isNative = Deviceutil.isNative;
  isAndroid = Deviceutil.isAndroid;
  isIos = Deviceutil.isIos;
  isApp = Deviceutil.isApp;
  isElectron = Deviceutil.isElectron;
  isNotElectron = Deviceutil.isNotElectron;
  isWeb = Deviceutil.isWeb;
  isDesktop = Deviceutil.isElectron;

  reset() {
    this.isMinimiert = false;
    this.isMenu = false;
    this.isMainMenu = false;
  }

  /* resize */
  layout = 'l-l'; // s-p, s-l, m-p, m-l, l-p, l-l, xl-p, xl-l
  @tracked cols = [1];
  @tracked colsPreferred = 1;
  @tracked rowsPreferred = 1;
  @tracked rows = 1;
  @tracked size = false;

  @tracked isResize = false;
  @tracked resizeChange = 1;

  resizeTimer;

  resize(timer = 150) {
    if (!this.isResize) {
      this.isResize = true;
    }
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      this.resizeFunction();
      this.setOrientation();
      this.isResize = false;
      this.resizeChange++;
      console.log('resizeChange');
    }, timer);
  }
  resizeInit() {
    window.addEventListener('resize', () => {
      this.resize();
    });
    this.resizeFunction();
    this.setOrientation();
  }
  resizeFunction() {
    let rows = 1,
      columns = [1],
      colsPreferred = 1,
      rowsPreferred = 1;
    // if (window.innerWidth > window.innerHeight) {
    //   columns.push(2);
    //   portraitLandscape = "l";
    // } else
    rows = 2;
    rowsPreferred = 1;

    if (window.innerHeight > 700) {
      rowsPreferred = 2;
    }
    // if(window.innerWidth>600){} -> s
    if (window.innerWidth > 960 && columns.indexOf(2) == -1) {
      columns.push(2);
      colsPreferred = 2;
    }
    if (window.innerWidth > 1280 && columns.indexOf(2) == -1) {
      // m
      colsPreferred = 2;
    }
    if (window.innerWidth >= 1440 && columns.indexOf(3) == -1) {
      // l deviceSize = "l";
      columns.push(3);
      colsPreferred = 3;
    }

    if (
      this.cols.length != columns.length ||
      this.colsPreferred != colsPreferred ||
      this.rows != rows ||
      this.rowsPreferred != rowsPreferred
    ) {
      let customResizeEvent = new Event('resize');
      window.dispatchEvent(customResizeEvent);
      // console.log('EVENT DISPATCHED!');
    }

    // this.layout = deviceSize + "-" + portaitLandscape;
    this.cols = columns;
    this.colsPreferred = colsPreferred;
    this.rows = rows;
    this.rowsPreferred = rowsPreferred;
    let body = document.getElementsByTagName('body');
    let style = window.getComputedStyle(body[0]);
    let top = parseInt(style.getPropertyValue('padding-top')) || 0;
    let bottom = parseInt(style.getPropertyValue('padding-bottom')) || 0;
    let left = parseInt(style.getPropertyValue('padding-left')) || 0;
    let right = parseInt(style.getPropertyValue('padding-right')) || 0;

    this.size = {
      innerWidth: window.innerWidth - left - right,
      innerHeight: window.innerHeight - top - bottom,
    };
  }

  /* Tastatursteuerung */
  @tracked isKeyboardBlocked = false;
  @tracked isSchemaKeyboardBlocked = false;

  @action keyboardBlockieren() {
    this.isKeyboardBlocked = true;
  }

  @action
  keyboardFreigeben() {
    this.isKeyboardBlocked = false;
    try {
      let el = document.getElementById('athlyzer-video-player');
      if (el) {
        el.focus();
      }
    } catch (e) {
      console.log('konnte kein Video finden!', e);
    }
  }

  @action schemaKeyboardBlockieren() {
    this.isSchemaKeyboardBlocked = true;
  }

  @action schemaKeyboardFreigeben() {
    this.isSchemaKeyboardBlocked = false;
    try {
      let el = document.getElementById('img-wrapper-schema-taggen-test');
      if (el) {
        el.focus();
      }
    } catch (e) {
      console.log('konnte kein Schema finden!', e);
    }
  }
}
