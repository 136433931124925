import Service from './video-cut';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import deviceutil from 'athlyzer-coach/classes/deviceutil';
import FfmpegCommandBuilder from 'athlyzer-coach/cross/ffmpeg';
import Cross from 'athlyzer-coach/cross/plugin';

export default class VideoVideoMergeService extends Service {
  @action async mergeCuttedVideos(
    exportierteSzenenZumMergenOderAnzeigen,
    exportName,
    deleteFilesAfterMerge
  ) {
    const pathes = exportierteSzenenZumMergenOderAnzeigen.map((szene) => {
      return szene.videourl;
    });
    for (let i = 0; i < pathes.length; i++) {
      const { filepath } = await Cross.videourlToAbsoluteFilepath({
        videourl: pathes[i],
      });
      pathes[i] = filepath;
    }

    let outputPath;

    if (deviceutil.isElectron) {
      outputPath =
        (await Cross.folder()).videosexportpath + '/' + exportName + '.mp4';
    } else if (deviceutil.isApp) {
      outputPath = (await Cross.folder()).path + exportName + '.mp4';
    }

    const merged = await FfmpegCommandBuilder.create('FULLHD')
      .merge(pathes)
      .makeMp4Streamable()
      .outputTo(outputPath)
      .logCommand()
      .run();

    const { videourl } = await Cross.absoluteFilepathToVideourl({
      filepath: outputPath,
    });
    if (deleteFilesAfterMerge) {
      pathes.forEach((filepath) => {
        Cross.deleteFile({ file_path: filepath });
      });
    }
    console.log('videourl', videourl);
    return { videourl, filepath: outputPath };
  }
}
