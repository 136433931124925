import Model from '../classes/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

let codes = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  0: '0',
  ß: 'ß',
  '´': '´',
  Shift: 'Shift',
  '!': '1',
  '"': '2',
  '§': '3',
  $: '4',
  '%': '5',
  '&': '6',
  '/': '/',
  '(': '8',
  ')': '9',
  '=': '0',
  '?': 'ß',
  q: 'Q',
  w: 'W',
  e: 'E',
  r: 'R',
  t: 'T',
  z: 'Z',
  u: 'U',
  i: 'I',
  o: 'O',
  p: 'P',
  ü: 'Ü',
  '+': '+',
  Q: 'Q',
  W: 'W',
  E: 'E',
  R: 'R',
  T: 'T',
  Z: 'Z',
  U: 'U',
  I: 'I',
  O: 'O',
  P: 'P',
  Ü: 'Ü',
  '*': '+',
  a: 'A',
  s: 'S',
  d: 'D',
  f: 'F',
  g: 'G',
  h: 'H',
  j: 'J',
  k: 'K',
  l: 'L',
  ö: 'Ö',
  ä: 'Ä',
  '#': '#',
  A: 'A',
  S: 'S',
  D: 'D',
  F: 'F',
  G: 'G',
  H: 'H',
  J: 'J',
  K: 'K',
  L: 'L',
  Ö: 'Ö',
  Ä: 'Ä',
  //"'": ''
  '<': '<',
  y: 'Y',
  x: 'X',
  c: 'C',
  v: 'V',
  b: 'B',
  n: 'N',
  m: 'M',
  ',': ',',
  '.': '.',
  '-': '-',
  '>': '<',
  Y: 'Y',
  X: 'X',
  C: 'C',
  V: 'V',
  B: 'B',
  N: 'N',
  M: 'M',
  ';': ',',
  ':': '.',
  _: '-',
};

export default class SchemaElementBasisModel extends Model {
  @service intl;

  @attr({ defaultValue: () => [] }) intelPlaylist;

  @hasMany('videocollection', { async: true, inverse: null })
  automatedCollections;
  @attr({ defaultValue: () => {} }) automatedCollectionPlaylistMap;

  //   schema: belongsTo("schema"),
  @belongsTo('schema')
  schema;

  @attr('boolean', { defaultValue: false }) isCounter;
  @attr('boolean', { defaultValue: false }) isTimeCounter;
  @attr('number', { defaultValue: 0 }) countUp;
  @attr('number', { defaultValue: 0 }) lastTimeUpdate;
  @attr('number', { defaultValue: 1 }) delta;

  get nametext() {
    if (this.name && this.text) {
      return this.name + ' - ' + this.text;
    } else if (this.name) {
      return this.name;
    } else if (this.text) {
      return this.text;
    } else {
      return '-';
    }
  }

  @attr('string') name;

  @attr('number', { defaultValue: 18 }) nameFontSize;

  @attr('string') text;

  @attr('number', { defaultValue: 12 }) textFontSize;

  @attr('number') x;

  @attr('number') y;

  @attr('number', { defaultValue: 0 }) z;

  @attr('number') width;

  @attr('number') height;

  @attr('number', { defaultValue: 1 }) zIndex;

  @attr('number', { defaultValue: 0 }) rotation;

  @attr('boolean', { defaultValue: true }) show;

  @attr('number', { defaultValue: 1 }) xtablet;

  @attr('number', { defaultValue: 1 }) ytablet;

  @attr('number', { defaultValue: 0 }) ztablet;

  @attr('number', { defaultValue: 30 }) widthtablet;

  @attr('number', { defaultValue: 30 }) heighttablet;

  @attr('number', { defaultValue: 0 }) rotationtablet;

  @attr('boolean', { defaultValue: true }) showtablet;

  @attr('number', { defaultValue: 1 }) xmobil1;

  @attr('number', { defaultValue: 1 }) ymobil1;

  @attr('number', { defaultValue: 0 }) zmobil1;

  @attr('number', { defaultValue: 30 }) widthmobil1;

  @attr('number', { defaultValue: 30 }) heightmobil1;

  @attr('number', { defaultValue: 0 }) rotationmobil1;

  @attr('boolean', { defaultValue: true }) showmobil1;

  @attr('number', { defaultValue: 1 }) xmobil2;

  @attr('number', { defaultValue: 1 }) ymobil2;

  @attr('number', { defaultValue: 0 }) zmobil2;

  @attr('number', { defaultValue: 30 }) widthmobil2;

  @attr('number', { defaultValue: 30 }) heightmobil2;

  @attr('number', { defaultValue: 0 }) rotationmobil2;

  @attr('boolean', { defaultValue: true }) showmobil2;

  @attr('string', { defaultValue: '#D11149' }) farbe;

  @attr('string', { defaultValue: '#ffffff' }) farbeText;

  @attr('number', { defaultValue: 1 }) scaleX;

  @attr('number', { defaultValue: 1 }) scaleY;

  @attr('string') taste;

  @attr('string') kombinationsTaste;

  @computed('taste', 'kombinationsTaste')
  get shortCut() {
    let key;
    if (this.kombinationsTaste && this.taste) {
      switch (this.taste) {
        case 'ß':
          key = this.intl.t('ß');
          break;
        case '´':
          key = this.intl.t('´');
          break;
        case 'z':
          key = this.intl.t('Z');
          break;
        case 'ü':
          key = this.intl.t('Ü');
          break;
        case '+':
          key = this.intl.t('+');
          break;
        case 'ö':
          key = this.intl.t('Ö');
          break;
        case 'Ä':
          key = this.intl.t('Ä');
          break;
        case 'y':
          key = this.intl.t('Y');
          break;
        case '-':
          key = this.intl.t('-');
          break;
        default:
          key = codes['' + this.taste];
      }
      let combo = this.kombinationsTaste.toLowerCase();
      let key1;
      try {
        key1 = key.toLowerCase();
      } catch (error) {
        key1 = key;
      }
      return combo + '+' + key1;
    } else if (this.taste) {
      switch (this.taste) {
        case 'ß':
          key = this.intl.t('ß');
          break;
        case '´':
          key = this.intl.t('´');
          break;
        case 'z':
          key = this.intl.t('Z');
          break;
        case 'ü':
          key = this.intl.t('Ü');
          break;
        case '+':
          key = this.intl.t('+');
          break;
        case 'ö':
          key = this.intl.t('Ö');
          break;
        case 'Ä':
          key = this.intl.t('Ä');
          break;
        case 'y':
          key = this.intl.t('Y');
          break;
        case '-':
          key = this.intl.t('-');
          break;
        default:
          key = codes['' + this.taste];
      }
      let key1;
      try {
        key1 = key.toLowerCase();
      } catch (error) {
        key1 = key;
      }
      return key1;
    } else if (this.kombinationsTaste) {
      return this.kombinationsTaste;
    } else {
      return '';
    }
  }

  @computed('taste', 'kombinationsTaste')
  get tastenkombination() {
    let key;
    if (this.kombinationsTaste && this.taste) {
      switch (this.taste) {
        case 'ß':
          key = this.intl.t('ß');
          break;
        case '´':
          key = this.intl.t('´');
          break;
        case 'z':
          key = this.intl.t('Z');
          break;
        case 'ü':
          key = this.intl.t('Ü');
          break;
        case '+':
          key = this.intl.t('+');
          break;
        case 'ö':
          key = this.intl.t('Ö');
          break;
        case 'Ä':
          key = this.intl.t('Ä');
          break;
        case 'y':
          key = this.intl.t('Y');
          break;
        case '-':
          key = this.intl.t('-');
          break;
        default:
          key = codes['' + this.taste];
      }
      return this.kombinationsTaste + ' + ' + key;
    } else if (this.taste) {
      switch (this.taste) {
        case 'ß':
          key = this.intl.t('ß');
          break;
        case '´':
          key = this.intl.t('´');
          break;
        case 'z':
          key = this.intl.t('Z');
          break;
        case 'ü':
          key = this.intl.t('Ü');
          break;
        case '+':
          key = this.intl.t('+');
          break;
        case 'ö':
          key = this.intl.t('Ö');
          break;
        case 'Ä':
          key = this.intl.t('Ä');
          break;
        case 'y':
          key = this.intl.t('Y');
          break;
        case '-':
          key = this.intl.t('-');
          break;
        default:
          key = codes['' + this.taste];
      }
      return key;
    } else if (this.kombinationsTaste) {
      return this.kombinationsTaste;
    } else {
      return '';
    }
  }

  @attr('boolean', { defaultValue: true }) zeigeTastenkombination;
}
