export default function (date) {
  let datum = new Date(date * 1000).toISOString();
  let hours = Number(datum.substr(11, 2));
  let minutes = Number(datum.substr(14, 2));
  let seconds = Number(datum.substr(17, 2));
  let tenths = Number(datum.substr(20, 1));
  if (seconds <= 9) {
    seconds = String(seconds);
    seconds = '0' + seconds;
  }
  minutes = hours * 60 + minutes;
  let returnString = minutes + ':' + seconds;
  returnString = returnString + '.' + tenths;

  return String(returnString);
}
