export default function (szene, searchText = '') {
  const textgesamt = szene.get('textgesamt');
  if (!textgesamt) {
    return false;
  }

  const lowerCaseSearch = searchText.toLowerCase();
  if (textgesamt.toLowerCase().includes(lowerCaseSearch)) {
    return true;
  }

  return false;
}
