import Service from './video-merge';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import pptxgen from 'pptxgenjs';
import { DateTime } from 'luxon';
import { Share } from '@capacitor/share';
import write_blob from 'capacitor-blob-writer';
import { Directory } from '@capacitor/filesystem';

export default class VideoVideoShareService extends Service {
  @service popup;
  @service teamsHub;

  @action shareVideoclipWeblink(clip) {
    if (clip.web) {
      const popup = this.popup;
      var http = new XMLHttpRequest();
      http.open('HEAD', clip.web);
      http.onreadystatechange = function () {
        if (this.readyState == this.DONE) {
          if (this.status != 404) {
            // resource exists
            popup.createModalShareLink('Videolink', clip.web);
          }
        }
      };

      http.send();
    }
  }

  @action shareVideoclipTeams(clip) {
    if (clip.web) {
      const popup = this.popup;
      var http = new XMLHttpRequest();
      http.open('HEAD', clip.web);
      http.onreadystatechange = function () {
        if (this.readyState == this.DONE) {
          if (this.status != 404) {
            // resource exists
            popup.createTeamsVideoShareModal(clip.web);
          }
        }
      };

      http.send();
    }
  }

  @action async exportPpt(exportierteSzenen, name) {
    const nowString = DateTime.now().toFormat('yyyy-MM-dd_HH-mm-ss');
    const fileName = `${name}_${nowString}.pptx`;

    let pres = new pptxgen();
    pres.defineSlideMaster({
      title: 'MASTER_SLIDE',
      background: { color: 'FFFFFF' },
      objects: [
        {
          image: {
            x: 0,
            y: 0,
            w: '100%',
            h: '10%',
            path: '/assets/ppt/head.png',
          },
        },
        {
          image: {
            x: 0,
            y: '90%',
            w: '100%',
            h: '10%',
            path: '/assets/ppt/bottom.png',
          },
        },
      ],
      slideNumber: { x: '5%', y: '95%', color: 'FFFFFF' },
    });
    this.isPptExport = 'exporting';

    pres.addSection({ title: 'Media' });
    // exportierteSzenen[0].videourl);
    for (let index = 0; index < exportierteSzenen.length; index++) {
      const szene = exportierteSzenen[index];
      let szenePath;
      if (this.addonCore.isDesktop) {
        szenePath = 'athlyzer-stream://' + szene.videourl;
      } else if (this.addonCore.isWeb || this.addonCore.isApp) {
        //TODO fetch it without internet
        // let [ids, urls] = await this.uploadSzenes(exportierteSzenen);
        // szenePath = urls[index];
      } else {
        //szenePath = window.Ionic.WebView.convertFileSrc(szene.videourl);
      }

      let slide = pres.addSlide({
        sectionTitle: 'Media',
        masterName: 'MASTER_SLIDE',
      });
      slide.addText(szene.textgesamt, {
        x: '0%',
        y: '5%',
        w: '100%',
        color: 'FFFFFF',
        align: pres.AlignH.center,
      });
      slide.addMedia({
        x: '10%',
        y: '10%',
        w: '80%',
        h: '80%',
        type: 'video',
        path: szenePath,
        //path: urls[index],
      });
      if (szene.kommentar) {
        slide.addNotes(szene.kommentar);
      }
    }

    // 3. Add 1+ objects (Tables, Shapes, etc.) to the Slide

    // 4. Save the Presentation

    console.log('fileName', fileName);
    if (this.addonCore.isApp) {
      console.log('start ppt');
      const blob = await pres.write('blob');
      const uri = await write_blob({
        path: fileName,
        directory: Directory.Cache,
        blob: blob,
        on_fallback(error) {
          console.error('ERRRR', error);
        },
      });
      console.log('uri', uri);
      Share.share({
        title: 'PPT export',
        text: fileName,
        url: uri,
      });
    } else {
      pres.writeFile({ fileName, compression: false });
    }
  }
}
