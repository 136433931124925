import { helper } from '@ember/component/helper';
import { DateTime } from 'luxon';

export function formatDateHelper([dateString]) {
  // Use your date formatting library to format the date string
  const formattedDate = DateTime.fromISO(dateString).toLocaleString(
    DateTime.DATETIME_MED,
    { locale: 'de' }
  );

  if (formattedDate === 'Invalid DateTime') {
    return 'TBD';
  }

  return formattedDate;
}

export default helper(formatDateHelper);
