import Service from './draw';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class VideoKeysService extends Service {
  @service addonCore;
  /** HOTKEY SETTINGS */
  @tracked leftHotkey = {
    icon: 'fast',
    function: 1,
  };
  @tracked rightHotkey = {
    icon: 'fast',
    function: 1,
  };
  @tracked upHotkey = {
    icon: 'fast',
    function: 1,
  };
  @tracked downHotkey = {
    icon: 'fast',
    function: 1,
  };

  /** HOTKEY DELAY */
  @tracked lastLeftHotkeyClick = null;
  @tracked lastRightHotkeyClick = null;
  @tracked lastUpHotkeyClick = null;
  @tracked lastDownHotkeyClick = null;

  setHotkey(hotkey, type) {
    switch (type) {
      case 'left':
        this.leftHotkey = hotkey;
        break;
      case 'right':
        this.rightHotkey = hotkey;
        break;
      case 'up':
        this.upHotkey = hotkey;
        break;
      case 'down':
        this.downHotkey = hotkey;
        break;
      default:
        console.log('no position of hotkey');
        break;
    }
  }

  initKeys() {
    let tmpPlayrate;

    window.addEventListener('keydown', (e) => {
      if (
        (document.activeElement &&
          (document.activeElement instanceof HTMLInputElement ||
            document.activeElement instanceof HTMLTextAreaElement ||
            document.activeElement.className.includes('ProseMirror'))) ||
        this.addonCore.isKeyboardBlocked
      ) {
        return;
      }

      if (this.isRecording == 'live') {
        e.stopPropagation();
      }

      switch (e.code) {
        case 'Space':
          e.preventDefault();

          break;
        case 'ArrowLeft':
          e.preventDefault();

          if (this.playerRef) {
            if (this.state.playing) {
              this.pauseBeforeRewind.perform(this);
            }

            if (
              !this.lastLeftHotkeyClick ||
              Date.now() - this.lastLeftHotkeyClick > 250
            ) {
              let tempZeit = this.getCurrentTime();
              if (tempZeit > 0) {
                tempZeit = Math.max(0, tempZeit - this.leftHotkey.function);
                this.seekToSeconds(tempZeit);
              }

              this.lastLeftHotkeyClick = Date.now();
            }
          }
          break;
        case 'ArrowRight':
          e.preventDefault();

          if (this.playerRef) {
            if (
              !this.lastRightHotkeyClick ||
              Date.now() - this.lastRightHotkeyClick > 250
            ) {
              let tempZeit = this.getCurrentTime();
              tempZeit = tempZeit + this.rightHotkey.function;
              this.seekToSeconds(tempZeit);
              this.lastRightHotkeyClick = Date.now();
            }
          }
          break;
        case 'ArrowUp':
          e.preventDefault();

          if (this.playerRef && !tmpPlayrate) {
            if (
              !this.lastUpHotkeyClick ||
              Date.now() - this.lastUpHotkeyClick > 250
            ) {
              tmpPlayrate = this.state.playbackRate;
              this.updatePlayer({
                playbackRate: this.upHotkey.function,
              });
              this.lastUpHotkeyClick = Date.now();
            }

            return false;
          }
          break;
        case 'ArrowDown':
          e.preventDefault();

          if (this.playerRef && !tmpPlayrate) {
            if (
              !this.lastDownHotkeyClick ||
              Date.now() - this.lastDownHotkeyClick > 250
            ) {
              tmpPlayrate = this.state.playbackRate;
              this.updatePlayer({
                playbackRate: this.downHotkey.function,
              }); // TODO: Nur ausführen, wenn Playback sich wirklich verändert
              this.lastDownHotkeyClick = Date.now();
            }
          }
          break;
        case 'Escape':
          e.preventDefault();

          if (this.activeShape) {
            this.destroyTransformer(this.stage);
          }

          if (this.currentlyTransforming || this.activeShape) {
            this.deactivateDrawings();
            this.currentlyTransforming = false;
          }

          if (this.layer && this.activeShape) {
            this.removePolygonClosingHintCircle();
          }

          this.needClickForNextPoint = false;
          this.needNewPointForFreeLine = false;
          this.drawMode = null;
          break;
        case 'Delete':
          e.preventDefault();

          if (this.stage && this.layer) {
            if (this.activeShape) {
              this.deleteActive();
            } else {
              this.deleteAll();
            }
          }
          break;
        case 'Backspace':
          e.preventDefault();

          if (this.stage && this.layer) {
            if (this.activeShape) {
              this.deleteActive();
            } else {
              this.deleteAll();
            }
          }
          break;
        default:
          break;
      }
    });

    window.addEventListener('keyup', (e) => {
      if (
        document.activeElement &&
        (document.activeElement instanceof HTMLInputElement ||
          (document.activeElement instanceof HTMLTextAreaElement &&
            !this.addonCore.isKeyboardBlocked) ||
          document.activeElement.className.includes('ProseMirror'))
      ) {
        return;
      }

      if (this.isRecording == 'live') {
        e.stopPropagation();
      }

      switch (e.code) {
        case 'Space':
          e.preventDefault();

          if (this.playerRef && !this.addonCore.isKeyboardBlocked) {
            this.playpause();
          }
          break;
        case 'ArrowLeft':
          e.preventDefault();

          if (this.playerRef) {
            if (!this.state.playing && this.videoPlayedOnPress) {
              this.playAfterRewind.perform(this);
            }

            this.lastLeftHotkeyClick = null;
          }
          break;

        case 'ArrowRight':
          e.preventDefault();

          this.lastRightHotkeyClick = null;
          break;
        case 'ArrowUp':
          e.preventDefault();

          if (this.playerRef) {
            this.updatePlayer({
              playbackRate: tmpPlayrate || 1,
            });

            tmpPlayrate = null;
            this.lastUpHotkeyClick = null;
            return false;
          }
          break;
        case 'ArrowDown':
          e.preventDefault();

          if (this.playerRef) {
            this.updatePlayer({
              playbackRate: tmpPlayrate || 1,
            });
            tmpPlayrate = null;
            this.lastDownHotkeyClick = null;
          }
          break;
        default:
          break;
      }
    });
  }
}
