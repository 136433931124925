import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import PouchDB from 'pouchdb-browser';

export default class NewwindowTaggenRoute extends Route {
  @service store;

  queryParams = {
    db: '',
    schema: '',
  };
  async model({ db, schema }) {
    console.log('db', schema, db);
    const localDB = new PouchDB(db, { adapter: 'indexeddb' });
    this.store.adapterFor('application').changeDb(localDB);
    return this.store.findRecord('schema', schema);
  }
}
