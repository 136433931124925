import Service from './multi-part-upload';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import axios from 'axios';
import { timeout, enqueueTask } from 'ember-concurrency';

import Cross from 'athlyzer-coach/cross/plugin';

export default class TaskmanagerUploadService extends Service {
  @service addonUser;

  @enqueueTask({ maxConcurrency: 3 }) uploadClip = {
    progress: 0,
    name: null,
    taskKey: null,
    weburl: '',

    stateStyle: null,

    *perform(apiClient, file, name = 'Upload', videoclipid) {
      let ignore = false;
      yield timeout(0);
      set(this, 'name', name);
      let { extension, filename } =
        this.context.getUploadInfoFromVideoUrl(file);
      const taskmanager = this.context;
      const eListener = (options) => {
        if (ignore) {
          return;
        }
        this.name = options.name || 'Upload';
        if (options.detail.progress != null) {
          try {
            const number = Number(options.detail.progress);
            //this.progress = number;
            set(this, 'progress', number);
            // set(this, 'stateStyle', htmlSafe(`width:${number}%;`));

            taskmanager.isChange = !Boolean(taskmanager.isChange);
            if (videoclipid) {
              const progressElement = document.getElementById(
                `video-progress-${videoclipid}`
              );
              if (progressElement) {
                progressElement.innerHTML = `${number.toFixed(1)}%`;
              }
            }
          } catch (error) {
            console.log('error', error);
          }
        }
      };

      try {
        const url = 'user/videos/sign/upload';
        let signedResponse = yield apiClient.post(url, {
          key: filename + '.' + extension,
        });
        const blob = yield fetch(url, { method: 'GET' });
        const formData = new FormData();
        formData.append('file', blob, filename);
        const options = {
          url: signedResponse.data.uploadURL,
          method: 'PUT',
          data: formData.get('file'),
          onUploadProgress: (p) => {
            console.log('p', p);
          },
        };
        const answer = yield axios.request(options);
        this.weburl = signedResponse.data.url;
      } catch (e) {
        this.weburl = null;
        Sentry.captureException(e);
      } finally {
        ignore = true;
        console.log('afterFinally');
        if (videoclipid) {
          const progressElement = document.getElementById(
            `video-progress-${videoclipid}`
          );
          if (progressElement) {
            progressElement.innerHTML = ``;
          }
        }

        console.log('beforeCancel');

        try {
          yield Cross.uploadCancel({
            uploadkey: this.taskKey,
          });
        } catch (error) {
          Sentry.captureException(error);
        }

        console.log('afterCancel');

        window.removeEventListener(`upload-${this.taskKey}`, eListener, true);

        if (this.progress === 100) {
          yield this.context.cleanUpTasks.perform(this.taskKey, 2000);
        } else {
          yield this.context.cleanUpTasks.perform(this.taskKey, 200);
        }

        console.log('afterCleanup');
      }
      return { weburl: this.weburl };
    },
  };

  async startUpload(file, name, videoclipid) {
    const apiClient = this.addonUser.getHttp();
    const uploadTask = this.uploadClip.perform(
      apiClient,
      file,
      name,
      videoclipid
    );

    try {
      await uploadTask;
    } catch (error) {
      console.log('error on uploading', error);
      Promise.reject();
    }

    return Promise.resolve({ weburl: uploadTask.value.weburl });
  }

  async uploadBlob(blob, filename, extension) {
    let url = 'user/videos/sign/upload';
    let signedResponse = await this.addonUser.getHttp().post(url, {
      key: filename + '.' + extension,
    });
    const formData = new FormData();
    formData.append('file', blob, filename);
    const options = {
      url: signedResponse.data.uploadURL,
      method: 'PUT',
      data: formData.get('file'),
      onUploadProgress: (p) => {
        console.log('p', p);
      },
    };
    const answer = await axios.request(options);
    return { weburl: signedResponse.data.url };
  }
}
