import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import matchScenesWithSearchString from 'athlyzer-coach/helper-functions/match-scene-with-search-string';
import matchScenesWithLabels from 'athlyzer-coach/helper-functions/match-scene-with-labels';
import { task, enqueueTask, keepLatestTask, timeout } from 'ember-concurrency';

export default class TransferService extends Service {
  @service store;
  @service intl;
  @service video;
  @service paperToaster;

  @tracked aktiveAnalyse;
  @tracked aktivePlaylist;

  /** In-App Transfervariablen */

  @tracked convertLiveTaggingToAnalysisSzenen = [];

  analyseSelectAll(analyse, suchtext) {
    let szenen = analyse.get('videoszenenSortiert').filter((videoszene) => {
      const sceneMatchesSearchText = matchScenesWithSearchString(
        videoszene,
        suchtext
      );
      const sceneMatchesLabels = matchScenesWithLabels(
        videoszene,
        analyse.aktiveLabels
      );

      return sceneMatchesSearchText && sceneMatchesLabels;
    });

    for (let szene of szenen) {
      szene.set('selected', true);
    }
  }

  syncSelectAll(szenen) {
    for (let szene of szenen.toArray()) {
      szene.set('selected', true);
    }
  }

  analyseDeselectAll(analyse, suchtext) {
    let szenen = analyse.get('videoszenenSortiert').filter((videoszene) => {
      const sceneMatchesSearchText = matchScenesWithSearchString(
        videoszene,
        suchtext
      );
      const sceneMatchesLabels = matchScenesWithLabels(
        videoszene,
        analyse.aktiveLabels
      );

      return sceneMatchesSearchText && sceneMatchesLabels;
    });

    for (let szene of szenen) {
      set(szene, 'selected', false);
    }
  }

  syncDeselectAll(szenen) {
    for (let szene of szenen.toArray()) {
      szene.set('selected', false);
    }
  }

  playlistSelectAll(playlist, suchtext) {
    let szenen = playlist.get('playlistszenen').filter((playlistszene) => {
      let videoszene = playlistszene.get('videoszene');

      const sceneMatchesSearchText = matchScenesWithSearchString(
        videoszene,
        suchtext
      );
      const sceneMatchesLabels = matchScenesWithLabels(
        videoszene,
        playlist.aktiveLabels
      );

      return sceneMatchesSearchText && sceneMatchesLabels;
    });

    for (let szene of szenen) {
      szene.set('selected', true);
    }
  }

  statistikSelectAll(eventliste, suchtext) {
    for (let event of eventliste.toArray()) {
      if (event.name.toLowerCase().includes(suchtext.toLowerCase())) {
        set(event, 'selected', true);
      }
    }
  }

  playlistDeselectAll(playlist, suchtext) {
    let szenen = playlist.get('playlistszenen').filter((playlistszene) => {
      let videoszene = playlistszene.get('videoszene');

      const sceneMatchesSearchText = matchScenesWithSearchString(
        videoszene,
        suchtext
      );
      const sceneMatchesLabels = matchScenesWithLabels(
        videoszene,
        playlist.aktiveLabels
      );

      return sceneMatchesSearchText && sceneMatchesLabels;
    });

    for (let szene of szenen) {
      szene.set('selected', false);
    }
  }

  async transferToPlaylist(szenen, playlist) {
    szenen.sort((a, b) => {
      if (
        a.get('videoclip').get('position') > b.get('videoclip').get('position')
      ) {
        return 1;
      }
      if (
        a.get('videoclip').get('position') < b.get('videoclip').get('position')
      ) {
        return -1;
      }

      if (a.get('startzeit') > b.get('startzeit')) {
        return 1;
      }
      if (a.get('startzeit') < b.get('startzeit')) {
        return -1;
      }

      if (a.get('laenge') > b.get('laenge')) {
        return 1;
      }
      if (a.get('laenge') < b.get('laenge')) {
        return -1;
      }
    });

    let pcounter = 1;
    let videoszenenIds = [];
    if (playlist.get('playlistszenen').length > 0) {
      pcounter =
        Math.max(
          ...playlist.get('playlistszenen').map((ps) => ps.get('position'))
        ) + 1 || Number(playlist.get('playlistszenen').length) + 1;
      videoszenenIds = playlist
        .get('playlistszenen')
        .map((ps) => ps.get('videoszene.id'));
    }

    let promises = [];
    for (let szene of szenen) {
      szene.set('selected', false);
      if (videoszenenIds.indexOf(szene.get('id')) == -1) {
        let playlistszene = this.store.createRecord('playlistszene', {
          videoszene: szene,
          position: pcounter,
          playlist: playlist,
        });
        szene.get('playlistszenen').pushObject(playlistszene);
        playlist.playlistszenen.pushObject(playlistszene);
        await playlistszene.save();
        await szene.save();
        promises.push(playlistszene);
      }
      pcounter++;
    }
    await Promise.all(promises);
    try {
      await this.toastAddedToPlaylist.perform(playlist);
    } catch (error) {
      console.log('err56234', error);
    }

    //await playlist.save();
  }

  @task({ drop: true, maxConcurrency: 1 }) *toastAddedToPlaylist(playlist) {
    // yield this.paperToaster.show(this.intl.t('Zu Playlist hinzugefügt'));
    yield timeout(1000);
    yield playlist.save();
    playlist.toggleProperty('isChange');
  }

  clean() {
    if (this.aktiveAnalyse) {
      this.aktiveAnalyse.videoszenen.forEach((szene) => {
        if (szene.get('hasDirtyAttributes')) {
          szene.rollbackAttributes();
        }
      });
    }
  }

  setAktiveAnalyse(analyse) {
    this.aktiveAnalyse = analyse;
  }

  @keepLatestTask *savePlaylistszene(playlistszene) {
    yield timeout(250);
    yield playlistszene.save();
  }

  @enqueueTask *saveSzene(szene) {
    yield szene.save();
  }

  // wenn sich eine videoszene verändert, dann muss die playlistszene neu upgeploaded werden;
  async playlistszenenchange(szene) {
    let playlistszenen = szene.get('playlistszenen');
    if (playlistszenen && playlistszenen.length > 0) {
      playlistszenen.forEach((playlistszene) => {
        playlistszene.set('isVideochange', true);
        this.savePlaylistszene.perform(playlistszene);
      });
    }
  }

  async videoStart(szene, time, origin) {
    time = Number(time);
    let startzeit;
    if (this.video.isPlayerLoaded()) {
      if (time == 0 && origin == 'bearbeiten') {
        startzeit = this.video.getCurrentTime();
      } else {
        startzeit = Number(szene.get('startzeit'));
      }

      startzeit = startzeit + time;

      //Ende des Videos erreicht
      const duration = this.video.getVideoDuration();
      if (startzeit > duration) {
        startzeit = duration - 1;
      }

      //Ende soll vor Anfang liegen
      if (Number(szene.get('endzeit')) <= startzeit) {
        set(szene, 'endzeit', startzeit + 1);
      }

      //Startzeit wird ins Negative geschoben
      if (startzeit <= 0) {
        startzeit = 0.00001;
      }

      //Startzeit wird ans Ende geschoben
      if (startzeit >= duration) {
        startzeit = duration - 1;
        set(szene, 'endzeit', duration);
      }
    } else {
      console.log('no player!');
    }

    if (startzeit > Number(szene.get('endzeit'))) {
      set(szene, 'endzeit', startzeit + 1);
    }
    let formerStart = await szene.get('startzeit');
    set(szene, 'startzeit', startzeit);
    await this.saveSzene.perform(szene);
    await this.playlistszenenchange(szene);
    return szene;
  }

  async videoEnde(szene, time, origin) {
    szene = this.store.peekRecord('videoszene', szene.get('id'));
    time = Number(time);
    let endzeit;
    if (this.video.isPlayerLoaded()) {
      if (time == 0 && origin == 'bearbeiten') {
        endzeit = this.video.getCurrentTime();
      } else {
        endzeit = Number(szene.get('endzeit'));
      }
      endzeit = endzeit + time;

      //Ende des Videos erreicht
      const duration = this.video.getVideoDuration();
      if (endzeit > duration) {
        endzeit = duration;
      }

      //Ende soll vor Anfang liegen
      if (endzeit <= Number(szene.get('startzeit'))) {
        set(szene, 'startzeit', endzeit - 1);
      }

      //Startzeit wird ins Negative geschoben
      if (szene.get('startzeit') <= 0) {
        set(szene, 'startzeit', 0.00001);
      }

      //Endzeit wird ins Negative geschoben
      if (endzeit <= 0) {
        endzeit = 1.0;
      }
    } else {
      console.log('no player!');
    }

    set(szene, 'endzeit', endzeit);
    await this.saveSzene.perform(szene);
    await this.playlistszenenchange(szene);
    return szene;
  }

  async videoSchieben(szene, time) {
    time = Number(time);
    let startzeit;
    let endzeit;
    if (time == 0) {
      startzeit = this.video.getCurrentTime();
      endzeit = Number(startzeit + szene.get('laenge'));
    } else {
      startzeit = Number(szene.get('startzeit')) + time;
      endzeit = Number(szene.get('endzeit')) + time;
    }

    if (startzeit <= 0) {
      startzeit = 0.00001;
      if (endzeit - startzeit < szene.laenge) {
        endzeit = startzeit + szene.laenge;
      }
    }

    const duration = this.video.getVideoDuration();
    if (endzeit >= duration) {
      endzeit = duration;
      if (endzeit - startzeit < szene.laenge && time == 0) {
        startzeit = this.video.getCurrentTime();
      } else if (endzeit - startzeit < szene.laenge) {
        startzeit = endzeit - szene.laenge;
      }
    }

    set(szene, 'startzeit', startzeit);
    set(szene, 'endzeit', endzeit);
    await this.saveSzene.perform(szene);
    await this.playlistszenenchange(szene);
    return szene;
  }

  async syncSzenen(szene) {
    let player;
    try {
      player = this.video.player;
    } catch (error) {
      console.log('konnte Player nicht finden', error);
    }
    let startzeit = Number(player.currentTime);
    let endzeit = Number(startzeit + (await szene.get('laenge')));
    if (endzeit > Number(player.duration)) {
      return false;
    } else {
      set(szene, 'startzeit', startzeit);
      set(szene, 'endzeit', endzeit);
      return true;
    }
  }
}
