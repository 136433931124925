import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class RegisterErfolgreichRoute extends Route {
  @service router;
  @action didTransition() {
    setTimeout(() => {
      this.router.transitionTo('login');
    }, 3000);
  }
}
