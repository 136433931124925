import StandardModel from '../models/schema-element-basis';
import { attr } from '@ember-data/model';

export default class HandlungModel extends StandardModel {
  // vorlaufzeit : attr('number', {defaultValue : 0}),
  @attr('number', { defaultValue: 0 })
  vorlaufzeit;
  // nachlaufzeit : attr('number', { defaultValue : 0}),
  @attr('number', { defaultValue: 5 })
  nachlaufzeit;
  // typ : "handlung",
  @attr('string', { defaultValue: 'handlung' })
  typ;
  // isClickBeenden : attr('boolean', {defaultValue : false})
  @attr('boolean', { defaultValue: false })
  isClickBeenden;

  @attr('string', { defaultValue: 'none' }) clickType;
}
