import { createPeerConnection } from './common';

function lasticecandidate() {
  console.log('lasticecandidate');
  // let answer = peerConnection.localDescription;
  //console.log('let answer', answer);
}

export default {
  async createanswer(offer, localStream) {
    console.log('createanswer1', typeof offer);
    let peerConnection = createPeerConnection(lasticecandidate);
    const videoElement = document.getElementById('video-pip');
    videoElement.setAttribute('style', 'visibility:visible;max-width:300px;');
    videoElement.srcObject = localStream;
    videoElement.onerror = (error) => {
      console.log('video error', error);
    };
    videoElement.load();
    peerConnection.ontrack = ({ streams: [stream] }) => {
      videoElement.srcObject = stream;
      console.log('ontrack');
    };
    peerConnection.ondatachannel = (event) => {
      console.log('handledatachannel');
      let dataChannel = event.channel;
      dataChannel.onopen = () => {
        console.log('datachannelopen');
      };
      dataChannel.onmessage = (message) => {
        console.log('datachannelmessage', message);
      };
    };
    localStream.getTracks().forEach((track) => {
      console.log('tracks added', typeof track);
      peerConnection.addTrack(track, localStream);
    });
    await peerConnection.setRemoteDescription(offer);
    let answer = await peerConnection.createAnswer();
    // console.log("typof answer",typeof answer);
    await peerConnection.setLocalDescription(answer);
    console.log('aafterSetLocalDescrption');
    return answer;
  },
};
