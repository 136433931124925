import MixinActionAccessRestrictionComponent from '../../../mixins/action-access-restriction';
import { htmlSafe } from '@ember/template';

export default class BasicsButtonPrimary extends MixinActionAccessRestrictionComponent {
  get buttonColorStyle() {
    if (this.args.colorStyle === 'inverted') {
      return htmlSafe(
        'bg-white text-primary-600 hover:bg-primary-50 focus:ring-2 focus:ring-offset-2 focus:ring-primary-50'
      );
    } else if (this.args.colorStyle === 'secondary') {
      return htmlSafe(
        'text-white bg-venice-blue-500 hover:bg-venice-blue-400 focus:ring-2 focus:ring-offset-2 focus:ring-venice-blue-500'
      );
    } else {
      return htmlSafe(
        'text-white bg-primary-500 hover:bg-primary-700 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
      );
    }
  }

  get justifyPosition() {
    const tailwindPositions = {
      start: 'justify-start',
      center: 'justify-center',
      end: 'justify-end',
    };

    if (this.args.layout && tailwindPositions[this.args.layout]) {
      return tailwindPositions[this.args.layout];
    } else {
      return tailwindPositions.center;
    }
  }
}
