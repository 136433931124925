import { WebPlugin } from '@capacitor/core';

export class CrossElectron extends WebPlugin {
  constructor() {
    super();
  }

  async writeStringToFile(options) {
    const response = await this.sendToPlugin('writeStringToFile', options);
    return response;
  }

  async videourlToAbsoluteFilepath(options) {
    const response = await this.sendToPlugin(
      'videourlToAbsoluteFilepath',
      options
    );
    return response;
  }

  async absoluteFilepathToVideourl(options) {
    const response = await this.sendToPlugin(
      'absoluteFilepathToVideourl',
      options
    );
    return response;
  }

  async executeFfmpegCommand(options) {
    const response = await this.sendToPlugin('executeFfmpegCommand', options);
    return response;
  }

  async copyAndRepairMeta(options) {
    const respone = await this.sendToPlugin('copyAndRepairMeta', options);
    return respone;
  }

  async getVideofileMetadata(options) {
    const response = await this.sendToPlugin('getVideofileMetadata', options);
    return response;
  }

  // async processes
  async sendToPlugin(url, data) {
    return await window.cross.pluginsend(url, data);
  }

  async crosswrite(a, b, c) {
    return await window.cross.write(...arguments);
  }

  async echo(options) {
    const os = await this.sendToPlugin('echo', options);
    return await os;
  }

  async createImageFromVideo(options) {
    const response = await this.sendToPlugin('createImageFromVideo', options);
    return response;
  }

  // electron only
  async diskSpace() {
    const response = await this.sendToPlugin('diskSpace');
    return response;
  }

  async setUserForSentryNative(options) {
    const response = await this.sendToPlugin('setUserForSentryNative', options);
    return response;
  }

  // electron only
  async youtubeDownload(options) {
    console.log('youtubeDownload', options);
    const response = await this.sendToPlugin('youtubeDownload', options);
    console.log('youtubeDownload', response);
    return response;
  }

  // electron only
  async twitchDownload(options) {
    console.log('twitchDownload', options);
    const response = await this.sendToPlugin('twitchDownload', options);
    console.log('twitchDownload', response);
    return response;
  }

  // electron only
  async twitchDownloadVOD(options) {
    console.log('twitchDownloadVOD', options);
    const response = await this.sendToPlugin('twitchDownloadVOD', options);
    console.log('twitchDownloadVOD', response);
    return response;
  }

  async testCodec(options) {
    const response = await this.sendToPlugin('testCodec', options);
    return response;
  }

  async abortTaskByPID(options) {
    const response = await this.sendToPlugin('abortTaskByPID', options);
    return response;
  }

  async uploadCancel(options) {
    const response = await this.sendToPlugin('uploadCancel', options);
    return response;
  }

  async upload(options) {
    const response = await this.sendToPlugin('upload', options);
    return response;
  }

  async getFileSize(options) {
    const response = await this.sendToPlugin('getFileSize', options);
    return response;
  }

  async fileExists(options) {
    const response = await this.sendToPlugin('fileExists', options);
    return response;
  }

  async absoluteFilepathExists(options) {
    const response = await this.sendToPlugin('absoluteFilepathExists', options);
    return response;
  }

  async saveAs(options) {
    const response = await this.sendToPlugin('saveAs', options);
    return response;
  }

  async rename(options) {
    const response = await this.sendToPlugin('rename', options);
    return response;
  }

  async deleteFile(options) {
    const response = await this.sendToPlugin('deleteFile', options);
    return response;
  }

  async download(options) {
    const response = await this.sendToPlugin('download', options);
    return response;
  }

  async downloadCancel(options) {
    const response = await this.sendToPlugin('downloadCancel', options);
    return response;
  }

  async pick(options) {
    const response = await this.sendToPlugin('pick', options);
    return response;
  }

  async pickImage(options) {
    const response = await this.sendToPlugin('pickImage', options);
    return response;
  }

  async canPlay(options) {
    const response = await this.sendToPlugin('canPlay', options);
    return response;
  }

  async getDuration(options) {
    const response = await this.sendToPlugin('getDuration', options);
    return response;
  }

  async copyInternalToExternal(options) {
    const response = await this.sendToPlugin('copyInternalToExternal', options);
    return response;
  }

  async convert(options) {
    const response = await this.sendToPlugin('convert', options);
    return response;
  }

  async cutsubstitute(options) {
    const response = await this.sendToPlugin('cutsubstitute', options);
    return response;
  }

  async cut(options) {
    const response = await this.sendToPlugin('cut', options);
    return response;
  }
  async save(options) {
    const response = await this.sendToPlugin('save', options);
    return response;
  }
  async merge(options) {
    const response = await this.sendToPlugin('merge', options);
    return response;
  }

  async addIntroAndOutroToVideo(options) {
    const response = await this.sendToPlugin(
      'addIntroAndOutroToVideo',
      options
    );
    return response;
  }

  async folder() {
    const response = await this.sendToPlugin('folder');
    return response;
  }

  async listAllFilesInDocumentsFolder() {
    const response = await this.sendToPlugin('listAllFilesInDocumentsFolder');
    return response;
  }

  async openBrowser(options) {
    const response = await this.sendToPlugin('openBrowser', options);
    return response;
  }

  async getInternalVideoFolderSize(options) {
    const response = await this.sendToPlugin(
      'getInternalVideoFolderSize',
      options
    );
    return response;
  }

  async openfolderSelectItem(options) {
    const response = await this.sendToPlugin('openfolderSelectItem', options);
    return response;
  }

  async fetchWebsiteContent(options) {
    const response = await this.sendToPlugin('fetchWebsiteContent', options);
    return response;
  }

  toJSON() {
    return [
      {
        name: 'echo',
      },
    ];
  }
}
