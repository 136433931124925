export default async function getMax(schema, ansicht) {
  let max = {
    breite: 0,
    hoehe: 0,
  };

  await schema.get('handlungen');
  await schema.get('tags');
  const alle = await schema.get('alle');

  alle.forEach((element) => {
    let breite = element.get(`x${ansicht}`) + element.get(`width${ansicht}`);
    let hoehe = element.get(`y${ansicht}`) + element.get(`height${ansicht}`);
    max.breite < breite && (max.breite = breite);
    max.hoehe < hoehe && (max.hoehe = hoehe);
  });

  max.breite == 0 && (max.breite = schema.get(`breite${ansicht}`));
  max.hoehe == 0 && (max.hoehe = schema.get(`hoehe${ansicht}`));
  return max;
}
