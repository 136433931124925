import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from './config/environment';
import * as Sentry from '@sentry/ember';
// import { RewriteFrames as RewriteFramesIntegration } from '@sentry/integrations';

Sentry.init({
  environment: config.DEPLOY_TARGET_ENVIRONMENT,
  dist: config.APP.version.split('+')[1].toString(),
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.1,
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  beforeSend(event) {
    if (
      event.environment === 'development' ||
      event.environment === 'test' ||
      config.DEPLOY_TARGET_ENVIRONMENT === 'preview'
    ) {
      console.log('Sentry Development Event', event);
      return null;
    } else {
      return event;
    }
  },
  integrations: [
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
    // new RewriteFramesIntegration({
    //   // root path that will be stripped from the current frame's filename by the default iteratee if the filename is an absolute path
    //   iteratee: (frame) => {
    //     if (frame.filename.startsWith('capacitor-electron://-/')) {
    //       frame.filename = frame.filename.replace(
    //         'capacitor-electron://-',
    //         'app://'
    //       );
    //     } else if (frame.filename.startsWith('capacitor://localhost')) {
    //       frame.filename = frame.filename.replace(
    //         'capacitor://localhost',
    //         'app://'
    //       );
    //     }
    //     return frame;
    //   },
    // }),
  ],
});

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
