import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { htmlSafe } from '@ember/template';
import mmss from 'athlyzer-coach/helper-functions/mmss';
import { keepLatestTask, timeout } from 'ember-concurrency';

export default class MixinVideoszenen extends Component {
  @service video;
  @service transfer;
  @service store;
  @service addonCore;
  @service addonUser;
  @service analyse;

  @tracked aktiveSzene;
  @tracked isLoeschen;
  @tracked isKommentieren = htmlSafe('display:none');
  @tracked isLast = false;
  @tracked isSortable = false;
  @tracked isProcessing;
  @tracked suche = '';
  @tracked isSearchbar = false;

  commentClicked = false;

  // mögliche Übergabeparameter
  // @isDirectPlay -> 1 klick abspielen einer Szene;

  @action didInsert(element, [self]) {
    self.isSortable = true;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.isSortable = false;
  }

  @action toogleSearchInOptionBar() {
    if (this.isSearchbar) {
      this.isSearchbar = false;
      this.suche = '';
      let aktiveLabel = [];
      this.args.model.aktiveLabels = aktiveLabel;
    } else {
      this.isSearchbar = true;
      this.suche = '';
      let aktiveLabel = [];
      this.args.model.aktiveLabels = aktiveLabel;
    }
    this.addonCore.resize();
  }

  async setVideoszene(szene) {
    await szene;

    if (this.args.isDirectPlay) {
      this.video.szene = szene;
      this.video.playVideoszene(szene);
    } else if (this.video.szene == szene) {
      this.video.playVideoszene(szene);
    } else {
      this.video.szene = szene;
    }
  }

  @action async clickPlaySzene(playszene) {
    await playszene;
    let szene = await this.store.peekRecord(
      'videoszene',
      playszene.get('videoszene.id')
    );

    this.video.setPlaylistszene(playszene);

    this.setVideoszene(szene);
  }

  setDurationTime(szene) {
    let timeFrame = document.getElementById('athlyzer-duration');
    if (szene.get('endzeit')) {
      let end = mmss(szene.get('endzeit'));
      if (timeFrame) {
        timeFrame.innerHTML = end;
      }
    }
  }

  @action async clickSzene(szene) {
    await szene;
    this.isLoeschen = false;
    this.isKommentieren = htmlSafe('display:none');
    this.isPlay = false;
    this.setDurationTime(szene);
    if (this.args.displayMode == 'present') {
      let szenen = this.args.szenen.toArray();
      for (let szene1 of szenen) {
        if (szene1.get('videoszene').get('id') == szene.get('id')) {
          this.video.setPlaylistszene(szene1);
          break;
        }
      }
    } else {
      this.video.setPlaylistszene(false);
    }
    this.setVideoszene(await szene);
  }

  @action kommentieren(szene) {
    this.video.szene = szene;
    if (this.commentClicked) {
      this.commentClicked = false;
      this.isKommentieren = htmlSafe('display:none');
    } else {
      let query = 'comment-button-' + szene.get('id');
      let dom = document.getElementById(query).getBoundingClientRect();
      let uberDom = document
        .getElementById('scene-table')
        .getBoundingClientRect();
      let top = dom.top - uberDom.top + 54;
      if (dom.top - uberDom.top < 1) {
        top = dom.top - uberDom.top + 54;
      } else {
        top = dom.top - uberDom.top + 6;
      }
      this.commentClicked = true;
      let styleString =
        'top: ' + top + 'px; height: 42px; width: 88%; margin-left: 6%';
      this.isKommentieren = htmlSafe(styleString);
      setTimeout(() => {
        document.getElementById('szene-kommentieren-input').focus();
      }, 300);
    }
  }

  @action async kommentarSpeichern(szene) {
    await szene.save();
    this.commentClicked = false;
    this.isKommentieren = htmlSafe('display:none');
  }

  @action async favorite(szene) {
    if (szene.stern) {
      set(szene, 'stern', false);
    } else {
      set(szene, 'stern', true);
    }
    await szene.save();
  }

  @action loeschenModus(szene) {
    if (this.isLoeschen) {
      this.isLoeschen = false;
    } else {
      this.isLoeschen = szene;
    }
  }

  @action async loeschen(szene) {
    if (this.args.model) {
      this.args.model.videoszenen.removeObject(szene);
      await szene.destroyRecord();
      await this.args.model.save();
    }
  }

  @action async selectSzene(szene) {
    if (!szene.get('selected') || szene.get('selected') == undefined) {
      set(szene, 'selected', true);
    } else {
      set(szene, 'selected', false);
    }
  }

  @action removeActive() {
    this.video.unplay();
    this.isLoeschen = false;
    this.isKommentieren = htmlSafe('display:none');
  }

  @action sendeSzenen() {
    let szenen = [];
    for (let szene of this.args.szenen) {
      if (szene.selected) {
        szenen.push(szene);
      }
    }
    if (this.args.szenenArbeit) {
      this.args.szenenArbeit(szenen);
    }
  }

  @action async setTime(operation, szene) {
    let player = this.video.player;
    let currentTime = player.currentTime;
    switch (operation) {
      case 'start':
        if (!player) {
          break;
        }
        szene.set('startzeit', currentTime);
        break;
      case 'end':
        if (!player) {
          break;
        }
        if (currentTime >= szene.startzeit) {
          if (szene.get('endzeit') == null) {
            szene.set('isStartzeitSet', false);
          }
        }
        break;
      default:
        break;
    }
    await szene.save();
  }

  // playlist only
  @action async reorderItems({ detail }) {
    /***
      Reorder Items by saving parent array in the right order.
    ***/
    let playlist = this.args.playlist;
    const scene = playlist.playlistszenen.objectAt(detail.from);
    playlist.playlistszenen.removeObject(scene);
    if (detail.to < playlist.playlistszenen.toArray().length) {
      playlist.playlistszenen = playlist.playlistszenen.insertAt(
        detail.to,
        scene
      );
    } else {
      playlist.playlistszenen.pushObject(scene);
    }

    this.saveSortedPlaylist.perform(playlist);
    return detail.complete();
  }

  @keepLatestTask *saveSortedPlaylist(playlist) {
    yield timeout(100);
    yield playlist.save();
  }

  @tracked isDelBox;
  @tracked commentClicked;
  @service transfer;
  @service navigation;
  @tracked view = 'full';

  @action toggleView() {
    if (this.view == 'full') {
      this.view = 'reduced';
    } else {
      this.view = 'full';
    }
  }

  @action toggleTimeShiftEdit(szene) {
    if (szene.get('timeShift')) {
      szene.set('timeShift', false);
    } else {
      szene.set('timeShift', true);
    }
  }

  @action async editTimeEnd(szene) {
    if (szene.get('endzeit')) {
      this.toggleTimeShiftEdit(szene);
    } else {
      await this.transfer.videoEnde(szene, 0, 'bearbeiten');
    }
  }

  @action async setComment(szene) {
    await szene;
    if (szene.get('isKommentieren')) {
      szene.set('isKommentieren', false);
      console.log(szene);
      await szene.save();
    } else {
      szene.set('isKommentieren', true);
      setTimeout(() => {
        document.getElementById('kommentieren-szene-' + szene.id).focus();
      }, 150);
    }
  }

  @action async setCommentPlaylist(playszene) {
    let szene = await playszene.get('videoszene');
    if (szene.get('isKommentieren')) {
      szene.set('isKommentieren', false);
      console.log(szene);
      await szene.save();
    } else {
      szene.set('isKommentieren', true);
    }
  }

  @action async timeShiftStart(szene, delta) {
    await this.transfer.videoStart(szene, delta);
    if (this.args.mode && this.args.mode != 'tagging-live') {
      if (this.video.playerRef) {
        this.video.pause();
        this.video.seekToSeconds(szene.get('startzeit'));
      }
    }
  }

  @action async timeShiftEnd(szene, delta) {
    await this.transfer.videoEnde(szene, delta);
    if (this.args.mode && this.args.mode != 'tagging-live') {
      if (this.video.playerRef) {
        this.video.pause();
        this.video.seekToSeconds(szene.get('endzeit'));
        this.setDurationTime(szene);
      }
    }
  }

  @action async delete(szene) {
    if (szene.get('isDelete')) {
      this.args.model.videoszenen.removeObject(szene);

      let playlistszenen = await szene.get('playlistszenen');

      if (playlistszenen.length > 0) {
        let deletePlaylistzenenPromises = playlistszenen.map(
          (playlistszene) => {
            return playlistszene.destroyRecord();
          }
        );

        await Promise.all(deletePlaylistzenenPromises);
      }

      if (this.video.szene && this.video.szene.get('id') == szene.get('id')) {
        this.video.szene = null;
        this.video.playSzene = null;
      }
      await szene.destroyRecord();
      this.video.saveAnalyse.perform();
    } else {
      szene.set('isDelete', true);
    }
  }

  @action abortDelete(szene) {
    szene.set('isDelete', false);
  }

  @action closeLabels(szene) {
    szene.set('showLabels', false);
    szene.set('showPlaylists', false);
  }

  @action showLabels(szene) {
    szene.set('showLabels', true);
  }

  @action async removeLabel(label, szene) {
    if (label.intelPlaylist.length > 0) {
      await this.removeScenes(szene, label);
    }

    // TODO: Find -> Mehrfachlabels nur einmal löschen
    for (var i = 0; i < szene.tags.length; i++) {
      let seeker = szene.tags[i];
      if (seeker.name == label.name) {
        //slice
        let tags = szene.get('tags');
        tags.splice(i, 1);
        szene.set('tags', tags);
        szene.toggleProperty('change');
        await szene.save();
      }
    }
  }

  async removeScenes(scene, label) {
    let playlists = label.intelPlaylist;
    let pscenes = await scene.get('playlistszenen');
    for (let pscene of pscenes.toArray()) {
      let playlist = await pscene.get('playlist');
      if (playlists.includes(playlist.name)) {
        pscene.destroyRecord();
      }
    }
  }

  @action async removePlaylistLabel(label, pszene) {
    let scene = await pszene.get('videoszene');
    await this.removeLabel(label, scene);
  }

  @action async deselectScene(szene) {
    console.log(szene);
    await szene.save();
    this.video.szene = null;
    this.video.playSzene = null;
  }

  @action async deselectPlayScene(pscene) {
    let scene = await pscene.get('videoszene');
    await this.deselectScene(scene);
  }

  @action showLabelTab(szene) {
    this.clickSzene(szene);
    this.navigation.setTab(2);
  }

  @action showPlaylists(szene) {
    if (szene.get('showPlaylists')) {
      szene.set('showPlaylists', false);
    } else {
      szene.set('showPlaylists', true);
    }
  }

  @action showPLabels(pscene) {
    pscene.set('showLabels', true);
    console.log('pscene', pscene);
  }

  @action closePLabels(pscene) {
    pscene.set('showLabels', false);
  }
}
