import { SplashScreen } from '@capacitor/splash-screen';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { registerWarnHandler, registerDeprecationHandler } from '@ember/debug';
registerWarnHandler(() => {});
registerDeprecationHandler(() => {});

import PouchDB from 'pouchdb-browser';
import PouchDBFind from 'pouchdb-find';
import PouchDBRelational from './relational-pouch';
import PouchDBIndexeddb from 'pouchdb-adapter-indexeddb';
import deviceutil from 'athlyzer-coach/classes/deviceutil';

PouchDB.plugin(PouchDBIndexeddb).plugin(PouchDBFind).plugin(PouchDBRelational);

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import * as Sentry from '@sentry/ember';
import Konva from 'konva';
Konva.pixelRatio = 1;

export default class ApplicationRoute extends Route {
  @service addonCore;
  @service addonUser;
  @service intl;
  @service network;
  @service router;

  constructor() {
    SplashScreen.hide();
    super(...arguments);
  }

  async beforeModel(transition) {
    try {
      if (deviceutil.isApp) {
        await KeepAwake.keepAwake();
      }
    } catch (error) {
      Sentry.captureException(error);
    }
    // bevor die app richtig startet wird die Internetleitung überprüft;
    await this.network.checkCurrentNetworkStatus();
    await this.addonUser.restoreSession();

    if (
      transition.to?.name.startsWith('index') ||
      transition.to?.name.startsWith('user') ||
      transition.to?.name.startsWith('hub')
    ) {
      if (!this.addonUser.authenticated()) {
        this.router.transitionTo('login');
      } else {
        if (this.network.isConnectedToInternet) {
          await this.addonUser.checkRefresh();
        }
      }
    }
  }

  async model() {
    let sprache = localStorage.getItem('sprache') || 'de-de';
    this.intl.setLocale(sprache);
    return Promise.resolve();
  }
}
