export default {
  filterTypes: [
    {
      title: 'Alle',
      icon: 'outlined/view-list',
      class: '',
      code: 'alle',
    },
    {
      title: 'Analyse',
      icon: 'outlined/custom-model-icons/videobearbeitung',
      class: 'videobearbeitung',
      code: 'analyse',
    },
    {
      title: 'Schema',
      icon: 'outlined/custom-model-icons/schema',
      class: 'scheme-display',
      code: 'schema',
    },
    {
      title: 'Collection',
      icon: 'outlined/custom-model-icons/videocollection',
      class: 'videocollection',
      code: 'collection',
    },
    {
      title: 'Boards',
      icon: 'outlined/custom-model-icons/board',
      class: 'board',
      code: 'board',
    },
  ],
  filterWays: [
    {
      title: 'Alphabetisch aufsteigend',
      icon: 'outlined/sort-by-alpha',
      class: 'border-0 border-t border-solid border-gray-700',
      code: 'name:desc',
    },
    {
      title: 'Alphabetisch absteigend',
      icon: 'outlined/sort-by-alpha',
      class: 'border-0 border-b border-solid border-gray-700',
      code: 'name:asc',
    },
    {
      title: 'Nach Datum aufsteigend',
      icon: 'outlined/today',
      class: 'border-0 border-t border-solid border-gray-700',
      code: 'datumjs:asc',
    },
    {
      title: 'Nach Datum absteigend',
      icon: 'outlined/today',
      class: 'border-0 border-b border-solid border-gray-700',
      code: 'datumjs:desc',
    },
  ],
};
