import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
export default helper(function styleSzene(params /*, hash*/) {
  // let px = 3;
  // // if (params[2]) {
  // //   px = params[2];
  // // }
  if (params[1] == 'full') {
    return htmlSafe(
      'border-right: 4px solid ' +
        params[0] +
        '; border-left: 10px solid ' +
        params[0] +
        ';'
    );
  }

  if (params[1] == 'left') {
    return htmlSafe('border-left: 6px solid ' + params[0] + ';');
  }

  if (params[1] == 'left-highlight') {
    if (!params[0]) {
      let styleString = htmlSafe('');
      return styleString;
    }

    let color = params[0];
    if (params[0] == 'green') {
      color = '#008000';
    }
    let num = 16;
    if (color) {
      num = parseInt(color.replace('#', ''), 16);
    }
    let amt = Math.round(2.55 * 70);
    let r = (num >> 16) + amt;
    let b = ((num >> 8) & 0x00ff) + amt;
    let g = (num & 0x0000ff) + amt;

    let hex = (
      0x1000000 +
      (r < 255 ? (r < 1 ? 0 : r) : 255) * 0x10000 +
      (b < 255 ? (b < 1 ? 0 : b) : 255) * 0x100 +
      (g < 255 ? (g < 1 ? 0 : g) : 255)
    )
      .toString(16)
      .slice(1);
    let styleString = 'background-color: #' + hex;
    styleString = styleString + '; border-left: 6px solid ' + params[0] + ';';
    styleString = htmlSafe(styleString);
    return styleString;
  }

  if (params[1] == 'bottom-right') {
    return htmlSafe(
      'border-bottom: 2px solid ' +
        params[0] +
        '; border-right: ' +
        params[2] +
        'px solid ' +
        params[0] +
        ';'
    );
  }

  if (params[1] == 'full-highlight') {
    if (!params[0]) {
      let styleString = htmlSafe('');
      return styleString;
    }

    let color = params[0];
    if (params[0] == 'green') {
      color = '#008000';
    }
    let num = 16;
    if (color) {
      num = parseInt(color.replace('#', ''), 16);
    }
    let amt = Math.round(2.55 * 70);
    let r = (num >> 16) + amt;
    let b = ((num >> 8) & 0x00ff) + amt;
    let g = (num & 0x0000ff) + amt;

    let hex = (
      0x1000000 +
      (r < 255 ? (r < 1 ? 0 : r) : 255) * 0x10000 +
      (b < 255 ? (b < 1 ? 0 : b) : 255) * 0x100 +
      (g < 255 ? (g < 1 ? 0 : g) : 255)
    )
      .toString(16)
      .slice(1);
    let styleString = 'background-color: #' + hex;
    styleString =
      styleString +
      '; border-right: 4px solid ' +
      params[0] +
      '; border-left: 10px solid ' +
      params[0] +
      ';';
    styleString = htmlSafe(styleString);
    return styleString;
  }

  if (params[3]) {
    return htmlSafe(
      `background-color:${params[0]};border-${params[1]}:2px solid ${params[0]};border-bottom:2px solid ${params[0]};`
    );
  } else {
    return htmlSafe(
      `border-${params[1]}:${params[2]}px solid ${params[0]};border-bottom:2px solid ${params[0]};`
    );
  }
});
