import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class TeamsTeamRoute extends Route {
  @service team;
  async model(params) {
    console.log('param.team', params.team);
    this.team.aktivesTeam = params.team;
    return params.team;
  }
}
