import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class NewwindowVideoController extends Controller {
  @service addonCore;
  @service video;
  @tracked zoom = 1;

  get resizeChange() {
    this.addonCore.resizeChange;
    this.video
      .schemaAutoFit(this.model, '', 'full-scree-schema')
      .then((zoom) => {
        this.zoom = zoom;
      });
    return '';
  }
}
