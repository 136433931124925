import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import deviceutil from 'athlyzer-coach/classes/deviceutil';
export default class MixinActionAccessRestrictionComponent extends Component {
  @service addonUser;
  @service paperToaster;
  @service intl;

  get isButtonDisabledByAccessRestrictions() {
    let canExecute = true;
    if (this.args.disabledDevices) {
      let isDisabledForDevice = this.disabledForDevice(
        this.args.disabledDevices
      );
      if (isDisabledForDevice) return null;
    }

    if (this.args.featureString) {
      canExecute = this.addonUser.checkForAccessToFeatureAndSetInfoModal(
        this.args.featureString,
        false
      );
    }

    if (this.args.disabled) {
      canExecute = false;
    }

    return !canExecute;
  }

  @action checkAccessAndThenExecuteOnClickFunction(onClickArgument) {
    let canExecute = true;
    if (this.args.disabledDevices) {
      let isDisabledForDevice = this.disabledForDevice(
        this.args.disabledDevices
      );
      if (isDisabledForDevice) return null;
    }

    if (this.args.featureString) {
      canExecute = this.addonUser.checkForAccessToFeatureAndSetInfoModal(
        this.args.featureString
      );
    }

    if (
      canExecute ||
      (!canExecute && this.args.allowOnClickExecutionWithoutAccess)
    ) {
      if (typeof this.args.before === 'function') {
        this.args.before();
      }

      if (typeof this.args.onClick === 'function') {
        this.args.onClick(onClickArgument);
      } else if (typeof this.args.buttonAction === 'function') {
        this.args.buttonAction(onClickArgument);
      }

      if (typeof this.args.after === 'function') {
        this.args.after();
      }
    }
  }

  get isDisabled() {
    if (this.args.disabledDevices) {
      let isDisabledForDevice = this.disabledForDevice(
        this.args.disabledDevices,
        false
      );
      return isDisabledForDevice;
    }

    if (this.args.disabled) {
      return this.args.disabled;
    } else if (this.args.loading) {
      return this.args.loading;
    } else {
      return false;
    }
  }

  @action showDisabledButtonInfo() {
    if (this.isDisabled) {
      if (this.args.disabledDevices) {
        this.disabledForDevice(this.args.disabledDevices);
      }
    }
  }

  @action disabledForDevice(disabledDevicesArray, showInfoToaster = true) {
    if (disabledDevicesArray.includes('ios') && deviceutil.isIos) {
      if (showInfoToaster) {
        this.paperToaster.show(
          this.intl.t(
            'Diese Funktion ist auf iOS noch nicht verfügbar. Verwende die Mac oder Windows App, um diese Funktion nutzen zu können.'
          )
        );
      }
      return true;
    }

    if (disabledDevicesArray.includes('android') && deviceutil.isAndroid) {
      if (showInfoToaster) {
        this.paperToaster.show(
          this.intl.t(
            'Diese Funktion ist auf Android noch nicht verfügbar. Verwende die Mac oder Windows App, um diese Funktion nutzen zu können.'
          )
        );
      }

      return true;
    }

    if (disabledDevicesArray.includes('web') && deviceutil.isWeb) {
      if (showInfoToaster) {
        this.paperToaster.show(
          this.intl.t(
            'Diese Funktion ist in der Web-Version noch nicht verfügbar. Verwende die Mac oder Windows App, um diese Funktion nutzen zu können.'
          )
        );
      }

      return true;
    }

    if (disabledDevicesArray.includes('desktop') && deviceutil.isElectron) {
      if (showInfoToaster) {
        this.paperToaster.show(
          this.intl.t(
            'Diese Funktion ist auf Mac & Windows noch nicht verfügbar. Verwende die iOS oder Android App, um diese Funktion nutzen zu können.'
          )
        );
      }
      return true;
    }

    return false;
  }

  get showLockedIconBecauseActionIsRestricted() {
    if (this.args.featureString) {
      let canExecute = this.addonUser.checkForAccessToFeatureAndSetInfoModal(
        this.args.featureString,
        false
      );

      if (
        canExecute ||
        (!canExecute && this.args.allowOnClickExecutionWithoutAccess)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
