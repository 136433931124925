import Service, { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

import * as Sentry from '@sentry/ember';
import FfmpegCommandBuilder from 'athlyzer-coach/cross/ffmpeg';
import { task, waitForProperty } from 'ember-concurrency';

import Cross from 'athlyzer-coach/cross/plugin';
import VIDEOEXPORT from 'athlyzer-coach/classes/videoexport';

export default class TaskmanagerConvertService extends Service {
  @service paperToaster;
  @service intl;
  @service video;
  @service metrics;

  @tracked convertProcesses = A([]);
  @tracked convertTaskInstances = A([]);

  @tracked convertStatus = '';
  @tracked convertProgress = 0;
  @tracked convertProgressDetail = null;
  @tracked convertSpeedAverage = 0;
  @tracked numConvertTasksEnqueued = 0;

  convertClip = task(
    { enqueue: true, maxConcurrency: 1 },
    async (url, filename, videoclipid, exportQuality = 'FULLHD') => {
      let videourl = '',
        filepath = '';
      this.convertStatus = '';
      this.convertProgress = 0;
      this.convertProgressDetail = null;

      let ignore = false;
      let eListener = async (options) => {
        console.log('eListener', options);
        if (ignore) return;

        if (options.progress != null) {
          this.convertProgressDetail = options;
          try {
            const number = Number(options.progress);

            this.set('convertProgress', number > 0 ? number : 0);

            this.set(
              'convertSpeedAverage',
              options.speedAverage?.toFixed(2) || 0
            );

            if (videoclipid) {
              const progressElement = document.getElementById(
                `video-progress-${videoclipid}`
              );
              if (progressElement) {
                progressElement.innerHTML = `${number.toFixed(0)}%`;
              }
            }
          } catch (error) {
            Sentry.captureException(error);
          }
        }
      };

      try {
        console.log('@taskmanager/convert.js: url', url);
        let videourlToAbsoluteFilepathResponse;

        // INCLUDE BOOKMARKS FOR IOS HERE
        if (!this.addonCore.isIos) {
          videourlToAbsoluteFilepathResponse =
            await Cross.videourlToAbsoluteFilepath({
              videourl: url,
            });
        } else {
          videourlToAbsoluteFilepathResponse = { filepath: url };
        }
        console.log(
          '@taskmanager/convert.js: videourlToAbsoluteFilepathResponse',
          JSON.stringify(videourlToAbsoluteFilepathResponse)
        );

        let outputFolder = (await Cross.folder()).path;
        if (outputFolder[outputFolder.length - 1] !== '/') {
          outputFolder += '/';
        }

        const outputPath = outputFolder + filename + '.mp4';
        console.log('inputPath: ', videourlToAbsoluteFilepathResponse.filepath);
        console.log('outputPath: ', outputPath);

        let command = FfmpegCommandBuilder.create(exportQuality).addVideoInput(
          videourlToAbsoluteFilepathResponse.filepath
        );

        if (exportQuality === 'COPY') {
          command
            .copyCodecToMp4()
            .makeMp4Streamable()
            .outputTo(outputPath)
            .logCommand()
            .onProgress(eListener);
        } else {
          command
            .scale()
            .makeMp4Streamable()
            .outputTo(outputPath)
            .logCommand()
            .onProgress(eListener);
        }
        let convertResult = await command.run();

        this.metrics.track('taskmanager.video.convert.speed', {
          convertSpeedAverage: this.convertSpeedAverage,
        });

        console.log('CONVERT RESULT', convertResult);
        if (convertResult && !convertResult.failed) {
          const absoluteFilepathToVideourlResponse =
            await Cross.absoluteFilepathToVideourl({
              filepath: outputPath,
            });

          videourl = absoluteFilepathToVideourlResponse.videourl;
          filepath = outputPath;
        } else {
          videourl = false;
          filepath = false;
        }
        console.log('100% Converted!');
      } catch (e) {
        videourl = false;
        console.log('e.name', e);
        Sentry.captureException(e);
      } finally {
        ignore = true;
        if (videoclipid) {
          const progressElement = document.getElementById(
            `video-progress-${videoclipid}`
          );

          if (progressElement) {
            progressElement.innerHTML = ``;
          }
        }

        if (
          this.convertProgressDetail &&
          this.convertProgressDetail.progress < 100
        ) {
          try {
            let exists = await Cross.fileExists({
              videourl: this.convertProgressDetail.filePath,
            });
            if (exists.exists) {
              await Cross.deleteFile({
                file_path: this.convertProgressDetail.filePath,
              });
            }
          } catch (e) {
            Sentry.captureException(e);
          }
          videourl = '';
        }
      }
      return { videourl, relativeVideourl: videourl, filepath };
    }
  );

  async convertVideoclip(clip, targetQualityCode) {
    if (clip.get('state') == 'convert') {
      this.paperToaster.show('Das Video wird bereits konvertiert');
      return;
    }

    if (this.addonCore.isDesktop && clip.nas) {
      let nasExists = await Cross.fileExists({ videourl: clip.nas });
      if (nasExists.exists) {
        // wenn nas existiert, dann wird die Datei von dort aus konvertiert
        clip.set('state', 'converting');
        let convertPromise = this.startConvert(
          clip.nas,
          clip.id,
          clip.id,
          targetQualityCode
        );

        convertPromise
          .catch((e) => {
            console.log('Catched Convert Issue', e);
            clip.set('state', 'idle');
          })
          .finally(() => {
            console.log('Convert finally');
            clip.set('state', 'idle');
          });
        try {
          let response = await convertPromise;
          //clip.set('state', 'idle');
          console.log('Response Convert', response);
          if (response.videourl) {
            clip.set('desktop', response.videourl);
            clip.set('exists', true);
            await clip.save();
            this.paperToaster.show(this.intl.t('Konvertierung erfolgreich!'));
          }
        } catch (error) {
          clip.set('state', 'idle');
        }

        this.video.updateClipsmanager();

        return Promise.resolve(); // hier wird gebreaked
      } else {
        // hier passiert nichts, der nächste Prozess wird nun ausprobiert
      }
    } else if (this.addonCore.isAndroid && clip.androidexternal) {
      let nasExists = await Cross.fileExists({
        filepath: clip.androidexternal,
      });
      if (nasExists.exists) {
        // wenn nas existiert, dann wird die Datei von dort aus konvertiert
        clip.set('state', 'converting');
        let convertPromise = this.startConvert(
          clip.androidexternal,
          clip.id,
          clip.id,
          targetQualityCode
        );
        convertPromise.finally(() => {
          clip.set('state', 'idle');
        });
        let response = await convertPromise;
        //clip.set('state', 'idle');
        if (response.videourl) {
          clip.set('android', response.videourl);
          clip.set('exists', true);
          await clip.save();
        }
        this.paperToaster.show(this.intl.t('Konvertierung erfolgreich!'));
        this.video.updateClipsmanager();

        return Promise.resolve(); // hier wird gebreaked
      }
    } else if (this.addonCore.isIos && clip.iosexternal) {
      try {
        const iosexternal = await clip.get('iosexternal');
        const { videourl } = await Cross.getBookmark({
          videourl: iosexternal,
        });
        const { exists } = await Cross.fileExists({
          filepath: videourl,
        });

        if (exists) {
          await this.video.iosConvert(videourl, clip, targetQualityCode);
          this.video.updateClipsmanager();
        } else {
          console.log('no vonbert');
        }
      } catch (error) {
        console.log('error', error);
      }

      return Promise.resolve(); // hier wird gebreaked
    }
  }

  async startConvert(url, filename, videoclipid, qualityCode = 'FULLHD') {
    const videoclip = await this.store.findRecord('videoclip', videoclipid);

    await FfmpegCommandBuilder.initialized;
    const videocodec = FfmpegCommandBuilder.videocodex;

    this.metrics.track('taskmanager.video.convert.start', {
      exportQuality: qualityCode,
      videocodec,
    });

    if (qualityCode) {
      const convertTask = this.convertClip.perform(
        url,
        filename,
        videoclipid,
        qualityCode
      );
      this.incrementProperty('numConvertTasksEnqueued');

      this.convertTaskInstances.pushObject(convertTask);

      try {
        await convertTask;

        try {
          let mediaInfo;

          if (this.addonCore.isIos) {
            const iosPath =
              (await Cross.folder()).path + convertTask.value.videourl;
            mediaInfo = await this.video.getVideoMediaInfo({
              filepath: iosPath,
            });
          } else {
            mediaInfo = await this.video.getVideoMediaInfo({
              videourl: convertTask.value.videourl,
            });
          }

          let convertedMetadata = {
            width: mediaInfo.width,
            height: mediaInfo.height,
            bitrate: mediaInfo.bitrate,
          };

          videoclip.set('convertedVideoBitrate', convertedMetadata.bitrate);
          videoclip.set('convertedVideoWidth', convertedMetadata.width);
          videoclip.set('convertedVideoHeight', convertedMetadata.height);
          videoclip.set('convertRequired', false);
        } catch (e) {
          console.log('MediaInfo Error', e);
        }

        await videoclip.save();
        this.decrementProperty('numConvertTasksEnqueued');

        this.metrics.track('taskmanager.video.convert.finished', {
          exportQuality: qualityCode,
          videocodec,
        });
      } catch (error) {
        if (!error.name.includes('TaskCancelation')) {
          Sentry.captureException(error);
          return Promise.reject({ cancelled: false, errored: true });
        }
        return Promise.reject({ cancelled: true, errored: false });
      }

      return Promise.resolve(convertTask.value);
    }
    return Promise.reject({ cancelled: true, errored: false });
  }
}
