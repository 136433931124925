import Model from '../classes/model';
import { attr, belongsTo } from '@ember-data/model';

export default class BoardModel extends Model {
  typ = 'board';
  icon = 'content_paste';

  @attr('boolean', { defaultValue: false }) highlight;

  @attr('string') name;
  @attr('string') notes;
  @attr('string') layer;
  @attr('string') notizen;
  @attr('string') board_background_path;
  @attr('string', { defaultValue: '' }) mode;
  @attr('string') web;

  @belongsTo('videobearbeitung') videobearbeitung; // for video export

  get path() {
    if (this.mode) {
      return this.board_background_path.replace('/logo', '/logo/' + this.mode);
    } else {
      return this.board_background_path;
    }
  }

  @attr('mdate') datum;
  @belongsTo('ordner') ordner;

  get datumjs() {
    if (this.datum) {
      return this.datum.toFormat('yyyy-LL-dd');
    } else {
      return null;
    }
  }
}
