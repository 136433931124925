import deviceutil from 'athlyzer-coach/classes/deviceutil';
import { tracked } from '@glimmer/tracking';
import Cross from 'athlyzer-coach/cross/plugin';

export default class Uploader {
  @tracked finished = false;
  @tracked aborted = false;
  @tracked progressPercentage = 0;
  @tracked fileName = null;
  @tracked sentSize = 0;
  @tracked uploadedSize = 0;
  @tracked fileSize = 0;
  @tracked taskKey = '';
  @tracked taskName = '';

  constructor(options) {
    this.taskKey = options.taskKey;
    this.weburl = '';
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.apiClient = options.apiClient;
    this.fileSize = options.fileSize;
    // number of parallel uploads
    this.file = options.file;
    this.fileName = options.fileName;
    this.taskName = options.taskName;

    this.progressCache = {};
    this.fileId = null;
    this.fileKey = null;
    this.formData = null;
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
  }

  start() {
    return this.initialize();
  }

  initialize = async () => {
    try {
      // initializing the multipart request
      const filename = this.fileName;

      let filePathToFetchBlob;
      if (deviceutil.isElectron) {
        filePathToFetchBlob = `athlyzer-stream://${this.file.replace(
          'DOCUMENTS',
          'documents'
        )}`;
      } else if (deviceutil.isIos) {
        const { exists } = await Cross.fileExists({
          videourl: this.file,
        });
        if (exists) {
          let directory = await Cross.folder();
          const path = directory.path + this.file;

          let uri = Capacitor.convertFileSrc(path);

          if (uri.indexOf('localhost') === -1) {
            uri = uri.replace('\b(?:[0-9]{1,3}.){3}[0-9]{1,3}\b', 'localhost');
          }
          filePathToFetchBlob = uri;
        }
      }
      this.filePathToFetchBlob = filePathToFetchBlob;

      const url = 'user/videos/sign/upload';
      let response = await this.apiClient.post(url, {
        key: filename,
      });

      let options = {
        filename: filename,
        filepath: this.file,
        uploadUrl: response.data.uploadURL,
        url: response.data.url,
        key: response.data.fields.key,
        bucket: response.data.fields.bucket,
        algorithm: response.data.fields['X-Amz-Algorithm'],
        credential: response.data.fields['X-Amz-Credential'],
        date: response.data.fields['X-Amz-Date'],
        policy: response.data.fields['Policy'],
        signature: response.data.fields['X-Amz-Signature'],
        acl: 'public-read',
      };

      this.weburl = `${options.url}`;

      this.formData = {
        url: options.uploadUrl,
        key: options.key,
        bucket: options.bucket,
        acl: 'public-read',
        'X-Amz-Algorithm': options.algorithm,
        'X-Amz-Credential': options.credential,
        'X-Amz-Date': options.date,
        Policy: options.policy,
        'X-Amz-Signature': options.signature,
      };

      const chunk = await this.fetchLocalFile();

      try {
        await this.sendFile(chunk);
      } catch (error) {
        this.complete(error);
      }
    } catch (error) {
      await this.complete(error);
    }
    this.complete();
  };

  complete = async (error) => {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }
    this.progressPercentage = 100;
    this.finished = true;
  };

  sendFile = (chunk) => {
    return new Promise((resolve, reject) => {
      this.upload(chunk)
        .then((status) => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  handleProgress = (event) => {
    if (this.file) {
      if (
        event.type === 'progress' ||
        event.type === 'error' ||
        event.type === 'abort'
      ) {
        this.progressCache = event.loaded;
      }

      if (event.type === 'loadend') {
        this.uploadedSize += this.progressCache || 0;
        this.progressCache = null;
      }

      const sent = Math.min(this.uploadedSize, this.fileSize);

      const total = this.fileSize;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  };

  fetchLocalFile = async () => {
    let blob;
    console.log('@fetchLocalFile this.file', this.file, deviceutil.isWeb);
    if (deviceutil.isWeb) {
      return this.file;
    } else {
      blob = fetch(this.filePathToFetchBlob);
    }

    const response = await blob;
    const reader = response.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        return pump();

        function pump() {
          return reader.read().then(({ done, value }) => {
            // When no more data needs to be consumed, close the stream
            if (done) {
              controller.close();
              return;
            }

            // Enqueue the next data chunk into our target stream
            controller.enqueue(value);
            return pump();
          });
        }
      },
    });
    const responseStream = new Response(stream);
    const responseBlob = responseStream.blob();

    return responseBlob;
  };

  upload = (file) => {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      const xhr = (this.activeConnection = new XMLHttpRequest());

      const progressListener = this.handleProgress.bind(this);

      xhr.upload.addEventListener('progress', progressListener);

      xhr.addEventListener('error', progressListener);
      xhr.addEventListener('abort', progressListener);
      xhr.addEventListener('loadend', progressListener);

      xhr.open('PUT', this.formData.url);

      xhr.onreadystatechange = () => {
        console.log('xhr.readyState', xhr.readyState);
        if (xhr.readyState === 4 && xhr.status === 200) {
          resolve(xhr.status);
        }
      };

      xhr.onerror = (error) => {
        reject(error);
      };

      xhr.onabort = () => {
        reject(new Error('Upload canceled by user'));
      };

      xhr.send(file);
    });
  };

  onProgress(onProgress) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError) {
    this.onErrorFn = onError;
    return this;
  }

  abort = () => {
    this.activeConnection.abort();
    this.aborted = true;
  };

  getFinalWebUrl = () => {
    return this.weburl;
  };
}
