import Model from '../classes/model';
import { attr, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

class TeamplaylistModel extends Model {
  @tracked aktivePlaylistszene;

  @attr('string') name;

  @hasMany('teamplaylistszene') playlistszenen;

  @attr('date') sharedate;

  @attr('boolean', { defaultValue: false }) softdeleted;

  @attr('string') sharemarkdown;

  get getplaylistszenen() {
    let playlistszenen = this.playlistszenen.toArray();
    if (playlistszenen.length == 0) {
      return [];
    }
    let counter = playlistszenen.map(
      (szene) => szene.geteiltevideoszene.position
    );
    counter = Number(Math.max(counter)) || 1;
    for (let szene of playlistszenen) {
      if (
        szene.geteiltevideoszene.position == null ||
        szene.geteiltevideoszene.position == undefined ||
        Number.isNaN(szene.geteiltevideoszene.position)
      ) {
        szene.geteiltevideoszene.position = counter;
        counter = counter + 1;
      }
    }
    playlistszenen = playlistszenen.sort((a, b) => {
      return a.geteiltevideoszene.position - b.geteiltevideoszene.position;
    });

    return playlistszenen;
  }
}

TeamplaylistModel.reopenClass({
  documentType: 'playlist',
});

export default TeamplaylistModel;
