import { A } from '@ember/array';
import deviceutil from './deviceutil';
import write_blob from 'capacitor-blob-writer';
import { task, taskGroup, timeout } from 'ember-concurrency';
import uuid from 'athlyzer-coach/application/uuid';
import * as Sentry from '@sentry/ember';

export default {
  // @taskGroup writingBlobsGroup;
  // @task writingBlob({ group: 'writingBlobsGroup' }) = {

  // },
  mediaRecorder: null,
  writtenBlobs: [],
  async stop() {
    this.mediaRecorder.stop();

    timeout(1100);

    await Promise.all(this.writtenBlobs);

    return this.writtenBlobs.length;
  },
  record(stream, mimeType) {
    let count = 0;
    let fileEnding = 'mp4';
    if (mimeType.indexOf('webm') > -1) {
      fileEnding = 'webm';
    }

    let fileName = uuid() + '.' + fileEnding;

    try {
      this.mediaRecorder = new MediaRecorder(stream, {
        videoBitsPerSecond: 64000000, // 2MB pro sekunde
        audioBitsPerSecond: 128000,
      });

      this.mediaRecorder.ondataavailable = function (event) {
        if (event.data && event.data.size > 0) {
          let actualCount = parseInt(count);
          if (deviceutil.isApp) {
            writtenBlobs.push(
              write_blob({
                path: actualCount,
                directory: Directory.Data,
                blob: event.data,
                recursive: true,
                on_fallback(error) {
                  console.error(error);
                },
              })
            );
          } else if (deviceutil.isWeb) {
            writtenBlobs.push(blob);
          } else {
            if (count == 0) {
              window.cross.write(fileName, event.data);
            } else {
              window.cross.append(fileName, event.data);
            }
          }

          count++;
        }
      };
      // this.mediaRecorder.athlyzerFileName = fileName;
    } catch (e) {
      console.log('Exception while creating MediaRecorder:', e);
      Sentry.captureException(e);
    }

    this.mediaRecorder.start(1000);

    return {
      mediaRecorder: this.mediaRecorder,
      recordingStart,
    };
  },
  findAvailableDevices: async function () {
    // Handles being called several times to update labels. Preserve values.
    let permission = false;
    try {
      const panTiltZoomPermissionStatus = await navigator.permissions.query({
        name: 'camera',
        panTiltZoom: true,
      });

      if (panTiltZoomPermissionStatus.state == 'granted') {
        // User has granted access to the website to control camera PTZ.
        console.log('camera with zoom granted');
        permission = true;
      } else {
        console.log(
          'camera with zoom not granted',
          panTiltZoomPermissionStatus
        );
      }
    } catch (error) {
      console.log(error);
    }

    if (!permission) {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: 'camera',
        });

        if (permissionStatus.state == 'granted') {
          console.log('camera granted');
          // User has granted access to the website to control camera PTZ.
        } else {
          console.log('camera not granted', JSON.stringify(permissionStatus));
        }
      } catch (error) {
        console.log(error);
      }
    }

    let deviceInfos = await navigator.mediaDevices.enumerateDevices();

    const audioInputs = A([]);
    const audioOutputs = A([]);
    const videoInputs = A([]);

    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const option = {};
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text =
          deviceInfo.label || `microphone ${audioInputs.length + 1}`;
        audioInputs.pushObject(option);
      } else if (deviceInfo.kind === 'audiooutput') {
        option.text = deviceInfo.label || `speaker ${audioOutputs.length + 1}`;
        audioOutputs.pushObject(option);
      } else if (deviceInfo.kind === 'videoinput') {
        option.text = deviceInfo.label || `camera ${videoInputs.length + 1}`;
        videoInputs.pushObject(option);
      }
    }

    return {
      audioInputs,
      audioOutputs,
      videoInputs,
    };
  },
  getConstraints(videoInputs, videoInputIndex) {
    let constraints = {
      audio: false,
      video: true,
    };
    let supportedConstraints = navigator.mediaDevices.getSupportedConstraints();

    if (
      supportedConstraints.hasOwnProperty('width') ||
      supportedConstraints.hasOwnProperty('height') ||
      supportedConstraints.hasOwnProperty('deviceId') ||
      supportedConstraints.hasOwnProperty('facingMode')
    ) {
      constraints.video = {};
    }

    if (supportedConstraints.hasOwnProperty('height')) {
      constraints.video['height'] = { min: 720, ideal: 1080, max: 1080 };
    }

    if (supportedConstraints.hasOwnProperty('width')) {
      constraints.video['width'] = { min: 1280, ideal: 1920, max: 1920 };
    }

    if (deviceutil.isApp && supportedConstraints.hasOwnProperty('facingMode')) {
      constraints.video.facingMode = 'environment';
    } else if (
      supportedConstraints.hasOwnProperty('deviceId') &&
      videoInputs &&
      videoInputIndex !== null &&
      videoInputIndex !== undefined
    ) {
      const videoSelect = videoInputs.objectAt(videoInputIndex);
      const videoSource = videoSelect.value;
      constraints.video['deviceId'] = { exact: videoSource };
    }

    return constraints;
  },
  getSupportedMimeTypes() {
    const VIDEO_TYPES = ['webm', 'mp4', 'x-matroska', 'ogg'];
    const VIDEO_CODECS = [
      'vp8',
      'vp8.0',
      'vp9',
      'vp9.0',
      'h264',
      'h.264',
      'avc1',
      'av1',
      'h265',
      'h.265',
      'opus',
    ];

    const supportedTypes = [];
    const supportedCodecs = [];

    VIDEO_TYPES.forEach((videoType) => {
      const type = `video/${videoType}`;
      VIDEO_CODECS.forEach((codec) => {
        const variations = [
          `${type};codecs=${codec}`,
          `${type};codecs:${codec}`,
          `${type};codecs=${codec.toUpperCase()}`,
          `${type};codecs:${codec.toUpperCase()}`,
        ];
        variations.forEach((variation) => {
          if (MediaRecorder.isTypeSupported(variation))
            supportedCodecs.push(variation);
        });
      });
      if (MediaRecorder.isTypeSupported(type)) supportedTypes.push(type);
    });

    if (supportedTypes.length === 0 || supportedCodecs.length === 0) {
      Sentry.captureMessage('No supported video types found');
    }

    return { supportedTypes, supportedCodecs };
  },
};
