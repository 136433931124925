import Model from '../classes/model';
import { tracked } from '@glimmer/tracking';
import { attr, belongsTo, hasMany } from '@ember-data/model';

export default class TeamModel extends Model {
  @attr('string') name;
  typ = 'team';

  icon = 'people';

  @attr('boolean', { defaultValue: false }) highlight;

  @attr('string') notizen;

  @belongsTo('ordner') ordner;

  @hasMany('playlist') playlist;
  @hasMany('playlistszene') playlistszene;
  @hasMany('teamnachricht') teamnachricht;

  @attr('string') teamHubSpaceId;

  @hasMany('teamplayer', { save: true }) teammembers; //Gesamter Kader

  @belongsTo('teamlineup') teamlineup;

  @attr('string') zugangscode;

  @attr('attachment', {
    defaultValue: function () {
      return null;
    },
  })
  logo;

  @tracked isChange;

  get sortedTeammembers() {
    this.isChange;
    if (this.teammembers) {
      return this.teammembers.sortBy('name');
    } else {
      return [];
    }
  }
}
