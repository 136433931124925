import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ShareModal from 'athlyzer-coach/components/modals/share';
import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';

export default class PopupService extends Service {
  @service modals;
  @service addonCore;
  @service addonUser;
  @service teamsHub;
  @service paperToaster;
  @service intl;

  @action async createModalShareLink(title, linkToShare) {
    let link = encodeURI(linkToShare);

    const response = await this.addonUser.getHttp().post('/s', {
      url: encodeURI(linkToShare),
    });

    if (response?.data?._id) {
      link = 'https://share.athlyzer.com' + '/s/' + response.data._id;
    }

    let shortLink = link.replace('https://', '');

    const answer = await this.modals.open(
      ShareModal,
      {
        title,
        link,
        shortLink,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );

    if (!answer || answer.cancelled) return;

    this.share(answer.link, answer.type);

    return answer;
  }

  @action async createTeamsVideoShareModal(linkToShare) {
    let link = encodeURI(linkToShare);

    const response = await this.addonUser.getHttp().post('/s', {
      url: encodeURI(linkToShare),
    });

    if (response?.data?._id) {
      link = 'https://share.athlyzer.com' + '/s/' + response.data._id;
    }

    this.teamsHub.postToSpace(link);
  }

  @action async share(link, type) {
    switch (type) {
      case 'browser':
        this.addonCore.openBrowser(link);
        break;
      case 'teamathlyzer':
        this.teamsHub.postToSpace(link);
        break;
      case 'share':
        Share.share({
          title: 'Ein Video für dich',
          url: link,
        });
        break;
      case 'copy':
        await Clipboard.write({
          string: link,
        });
        break;
      case 'facebook':
        this.addonCore.openBrowser(
          'https://www.facebook.com/sharer/sharer.php?u=' + link
        );
        break;
      case 'whatsapp':
        this.addonCore.openBrowser('https://wa.me/?text=' + link);
        break;
      case 'mail':
        this.addonCore.openBrowser('mailto: ?body=' + link);
        break;
      case 'clipboard':
        await Clipboard.write({
          string: link,
        });

        this.paperToaster.show(
          this.intl.t('Link wurde in die Zwischenablage kopiert'),
          {
            duration: 2000,
          }
        );
        break;
      default:
        break;
    }
  }
}
