import Service from './merge';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import uuid from 'athlyzer-coach/application/uuid';

import * as Sentry from '@sentry/ember';
import deviceutil from 'athlyzer-coach/classes/deviceutil';

import { task, timeout, enqueueTask, waitForProperty } from 'ember-concurrency';

import Cross from 'athlyzer-coach/cross/plugin';
import MultiPartUploader from 'athlyzer-coach/utils/multi-part-uploader';
import Uploader from 'athlyzer-coach/utils/uploader';

export default class MultiPartUploadService extends Service {
  @service intl;
  @service addonUser;

  @tracked uploadProcesses = A([]);
  @tracked uploadTaskInstances = A([]);
  @tracked uploadProcessTaskMap = {};

  @tracked uploadStatus = '';
  @tracked uploadProgress = 0;
  @tracked uploadProgressDetail = null;
  @tracked uploaderInstance = null;
  @tracked numUploadTasksEnqueued = 0;

  get numUploadTasksEnqueued() {
    return this.uploadTaskInstances.length - this.uploadProcesses.length;
  }

  get queuedUploadTaskInstances() {
    return this.uploadTaskInstances.filter((taskInstance) => {
      return !taskInstance.hasStarted;
    });
  }

  cleanCompletedUploads = task(
    { enqueue: true, maxConcurrency: 1 },
    async () => {
      this.uploadProcesses = this.uploadProcesses.filter((uploadProcess) => {
        return !uploadProcess.finished && !uploadProcess.aborted;
      });

      this.uploadTaskInstances = this.uploadTaskInstances.filter(
        (uploadTaskInstance) => {
          return !uploadTaskInstance.isError && !uploadTaskInstance.isFinished;
        }
      );
    }
  );

  multiUploadTask = task(
    { enqueue: true, maxConcurrency: 1 },
    async (options) => {
      let taskUUID = options.taskUUID;
      let fileSize = 0;
      let chunkSize = Math.min(options.chunkSize || 1024 * 1024 * 6, fileSize);
      let finalWebUrl = null;
      let uploader = null;
      let fileName = '';
      let threadsQuantity = Math.min(options.threadsQuantity || 2, 15);
      let file = options.file;
      let taskName = options.name || this.intl.t('Upload');

      const fileSizeObject = await Cross.getFileSize({
        videourl: options.file,
      }); //TODO: Das kann ggf. auch in die Uploader Klasse?!

      console.log(
        'taskmanager/upload, @task multiUploadTask, file: ',
        file,
        fileSizeObject
      );

      if (fileSizeObject.found) {
        fileSize = fileSizeObject.size;

        if (deviceutil.isWeb) {
          fileName =
            options.videoclipid + '.' + options.file.name.split('.').pop();
        } else {
          console.log(
            'taskmanager/upload, @task multiUploadTask getUploadInfoFromVideoUrl'
          );
          let { extension, filename } = this.getUploadInfoFromVideoUrl(file);
          fileName = filename + '.' + extension;
        }
        console.log(
          'taskmanager/upload, @task multiUploadTask, fileName, fileSize ',
          fileName,
          fileSize
        );

        if (fileSize > 5000000) {
          uploader = new MultiPartUploader({
            taskKey: taskUUID,
            chunkSize,
            fileSize,
            threadsQuantity,
            file,
            fileName,
            taskName,
            apiClient: this.addonUser.getHttp(),
            taskInstanceMap: this.uploadProcessTaskMap,
          });
        } else {
          uploader = new Uploader({
            taskKey: taskUUID,
            fileSize,
            file,
            fileName,
            taskName,
            apiClient: this.addonUser.getHttp(),
            taskInstanceMap: this.uploadProcessTaskMap,
          });
        }
      } else {
        return { weburl: null };
      }

      uploader.onError((error) => {
        if (
          !error.message ||
          !error.message.includes('Upload canceled by user')
        ) {
          Sentry.captureException(error);
        }
      });

      try {
        uploader.start();
        this.uploadProcesses.pushObject(uploader);

        await waitForProperty(uploader, 'progressPercentage', 100);
        await waitForProperty(uploader, 'finished', true);

        finalWebUrl = uploader.getFinalWebUrl();
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        if (uploader && !uploader.finished) {
          uploader.abort();
          this.uploadProcesses.removeObject(uploader);
        }

        if (uploader.taskKey) {
          const progressElement = document.getElementById(
            `video-progress-${uploader.taskKey}`
          );
          console.log('progressElement', progressElement);
          if (progressElement) {
            progressElement.innerHTML = '';
          }
        }

        return { weburl: finalWebUrl };
      }
    }
  );

  async startMultipartUpload(options) {
    let taskUUID = uuid();

    if ('videoclipid' in options) {
      taskUUID = options.videoclipid;
    }

    if ('id' in options) {
      taskUUID = options.id;
    }

    options.threadsQuantity = 10;
    options = Object.assign(options, {
      taskUUID,
    });

    const uploadTask = this.multiUploadTask.perform(options);

    this.uploadProcessTaskMap[taskUUID] = uploadTask;

    try {
      this.metrics.track('taskmanager.video.upload.start');
      this.uploadTaskInstances.pushObject(uploadTask);

      await uploadTask;
    } catch (error) {
      if (!error.name.includes('TaskCancelation')) {
        console.log('UploadError', error);
        Sentry.captureException(error);
      } else {
        this.uploadTaskInstances.removeObject(uploadTask);
        delete this.uploadProcessTaskMap[taskUUID];
      }

      return Promise.resolve({ weburl: null });
    }

    this.metrics.track('taskmanager.video.upload.finished');

    return Promise.resolve({ weburl: uploadTask.value.weburl });
  }
}
