import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AnalyseService extends Service {
  @tracked suche;

  setSuche(input) {
    this.suche = input;
  }
}
