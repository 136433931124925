import Controller from './analyse-old';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import DeviceUtil from 'athlyzer-coach/classes/deviceutil';
import Shepherd from 'shepherd.js';

export default class UserBibliothekAnalyseOldTaggenController extends Controller {
  device = DeviceUtil;
  @tracked isOffSchema = true;
  @tracked isOffListe = false;
  @tracked tagging_window = false;

  @action showAnalysisTaggingProductTour(elem, [self]) {
    const tourName = 'analyse-tagging';
    const tourFinished = self.addonUser.checkIfTourAlreadyFinished(tourName);

    if (!tourFinished) {
      const tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
          classes: 'shadow-lg',
          scrollTo: { behavior: 'smooth', block: 'center' },
          modalOverlayOpeningPadding: 5,
          cancelIcon: { enabled: true },
        },
        classPrefix: 'tw-',
      });

      tour.addStep({
        title: self.intl.t('Taggen deiner Videos'),
        text: self.intl.t(
          'Du befindest dich jetzt im Tagging Bereich der Analyse. Hier kannst du deine Videos anhand deines Schemas analysieren und Szenen taggen. <br /> Du kannst unterschiedliche Taggingansichten deiner Wahl auswählen.'
        ),
        buttons: [
          {
            action() {
              self.metrics.track(`producttour.${tourName}.cancel`);
              return tour.cancel();
            },
            classes: 'tw-shepherd-button-secondary',
            text: self.intl.t('Nicht jetzt'),
          },
          {
            action() {
              self.metrics.track(`producttour.${tourName}.start`);
              return tour.next();
            },

            classes: 'tw-shepherd-button',
            text: self.intl.t('Weiter'),
          },
        ],
      });

      tour.addStep({
        title: self.intl.t('Pfeiltasten'),
        text: self.intl.t(
          'Verändere die Kurztasten-Befehle deiner Pfeiltasten auf der Tastatur. <br/> Die Pfeile Hoch/Runter sorgen bei Klick für eine Zeitraffer- oder für Slow-Motion-Abspielgeschwindigkeit. <br/> Mit den Pfeiltasten Rechts/Links kannst du im Video springen.'
        ),
        attachTo: {
          element: '.shepherd-video-actionbar-keys',
          on: 'top',
        },
        buttons: [
          {
            action() {
              self.metrics.track(`producttour.${tourName}.back`);
              return tour.back();
            },
            classes: 'tw-shepherd-button-secondary',
            text: self.intl.t('Zurück'),
          },
          {
            action() {
              self.metrics.track(`producttour.${tourName}.next`);
              return tour.next();
            },

            classes: 'tw-shepherd-button',
            text: self.intl.t('Weiter'),
          },
        ],
      });

      tour.addStep({
        title: self.intl.t('Wechsel des Videoclips'),
        text: self.intl.t(
          'Klicke hier, um zwischen den Videoclips deiner Analyse zu wechseln.'
        ),
        attachTo: {
          element: '.shepherd-analysis-videoclip-preview',
          on: 'top',
        },
        buttons: [
          {
            action() {
              self.metrics.track(`producttour.${tourName}.back`);
              return tour.back();
            },
            classes: 'tw-shepherd-button-secondary',
            text: self.intl.t('Zurück'),
          },
          {
            action() {
              self.metrics.track(`producttour.${tourName}.next`);
              return tour.next();
            },

            classes: 'tw-shepherd-button',
            text: self.intl.t('Weiter'),
          },
        ],
      });

      tour.addStep({
        title: self.intl.t('Second Screen'),
        text: self.intl.t(
          'Öffne in unserer Desktop-Version ein zweites Fenster, das nur das Video anzeigt. So kannst du zum Beispiel in einer Präsentation nur die relevanten Videos zeigen, ohne, dass deine Kommentare und Szenenlisten sichtbar sind. <br /> Ziehe das zweite Fenster ganz einfach auf deinen Beamer oder Smart TV.'
        ),
        attachTo: {
          element: '.shepherd-video-actionbar-second-screen',
          on: 'top',
        },
        buttons: [
          {
            action() {
              self.metrics.track(`producttour.${tourName}.back`);
              return tour.back();
            },
            classes: 'tw-shepherd-button-secondary',
            text: self.intl.t('Zurück'),
          },
          {
            action() {
              self.metrics.track(`producttour.${tourName}.next`);
              return tour.next();
            },

            classes: 'tw-shepherd-button',
            text: self.intl.t('Weiter'),
          },
        ],
      });

      tour.addStep({
        title: self.intl.t('Schemalayout'),
        text: self.intl.t(
          'Hier kannst du den Zoom, die Größe und die Geräteansicht deines Schematas verändern. <br /> Verwende den Auto-Zoom, um das gesamte Schema anzuzeigen.'
        ),
        attachTo: {
          element: '.shepherd-video-actionbar-zoom',
          on: 'top',
        },
        buttons: [
          {
            action() {
              self.metrics.track(`producttour.${tourName}.back`);
              return tour.back();
            },
            classes: 'tw-shepherd-button-secondary',
            text: this.intl.t('Zurück'),
          },
          {
            action() {
              self.metrics.track(`producttour.${tourName}.next`);
              return tour.next();
            },

            classes: 'tw-shepherd-button',
            text: this.intl.t('Weiter'),
          },
        ],
      });

      tour.addStep({
        title: self.intl.t('Dein Schema'),
        text: self.intl.t(
          'Hier siehst du dein aktuelles Schema. Starte jetzt das Video über Play/Pause oder die Leertaste auf deiner Tastatur und lege mit dem Taggen los.'
        ),
        attachTo: {
          element: '.shepherd-analysis-tagging-panel',
          on: 'left',
        },
        buttons: [
          {
            action() {
              self.metrics.track(`producttour.${tourName}.back`);
              return tour.back();
            },
            classes: 'tw-shepherd-button-secondary',
            text: self.intl.t('Zurück'),
          },
          {
            action() {
              self.addonUser.saveAndAddFinishedProductTour(tourName);
              self.metrics.track(`producttour.${tourName}.completed`);
              return tour.complete();
            },

            classes: 'tw-shepherd-button',
            text: self.intl.t('Fertig'),
          },
        ],
      });

      tour.start();
    }
  }

  @action didInsertVideo(video, [self]) {
    setTimeout(() => {
      self.video.schemaAutoFit(self.video.schema, '', 'schema-taggen');
    }, 1000);
  }

  get getContainerClass() {
    return 'analyse-container-grid-1-1';
  }

  @action openZoom() {
    !this.isOffSchema && (this.isOffSchema = true);
    this.visual.isSchemeOpen = true;
  }

  @action toggleNewSchemaWindow() {
    if (this.tagging_window) {
      this.closeNewSchemaWindow();
    } else {
      this.openNewSchemaWindow();
    }
  }

  @action closeNewSchemaWindow() {
    try {
      this.tagging_window.close();
    } catch (error) {}
    this.tagging_window = false;
  }

  dispatchKeystrokePressEvent(originalEvent, type = 'keyup') {
    const keysToForwardToMainWindow = [
      'Space',
      'ArrowLeft',
      'ArrowUp',
      'ArrowRight',
      'ArrowDown',
    ];

    if (keysToForwardToMainWindow.includes(originalEvent.code)) {
      window.dispatchEvent(
        new KeyboardEvent(type, {
          code: originalEvent.code,
        })
      );
    }
  }

  @action async openNewSchemaWindow() {
    const db = this.addonUser.aktiveDatenbank;
    const schema = await this.video.analyse.belongsTo('schema').id();
    this.video.schema = this.video.analyse.schema;
    console.log('schema', schema, db);
    const url =
      window.location.href.split('#')[0] +
      `#/newwindow/taggen?db=${db}&schema=${schema}`;
    const tagging_window = window.open(url, '_capture', 'nodeIntegration=true');
    tagging_window.onunload = () => {
      this.isSchemaWindow = false;
    };
    tagging_window.onload = () => {
      this.isSchemaWindow = true;
      tagging_window.addEventListener('tagging-external-window', async (e) => {
        console.log('gotit', e.detail);
        const handlung = await this.store.findRecord(
          e.detail.modelName,
          e.detail.modelId
        );
        console.log('gotit2', handlung);
        if (handlung.get('typ') == 'handlung') {
          this.video.addEventToAnalysis(handlung);
        } else if (handlung.get('typ') == 'tag') {
          this.video.addLabelToScene(handlung);
        }

        console.log('Capture Window Loaded');
      });

      tagging_window.addEventListener('keyup', (event) => {
        this.dispatchKeystrokePressEvent(event, 'keyup');
      });

      tagging_window.addEventListener('keydown', (event) => {
        this.dispatchKeystrokePressEvent(event, 'keydown');
      });
    };
    this.tagging_window = tagging_window;
    //this.captureWindow = captureWindow;
    return this.isSchemaWindow;
  }
}
