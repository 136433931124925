import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import * as Sentry from '@sentry/ember';

export default class HubSpaceFeedPostAttachmentsCreateComponent extends Component {
  @service addonUser;
  @service paperToaster;

  @tracked file = null;

  @action async didLoadAttachment(event) {
    let [file] = event.target.files;

    if (!file) return;

    if (file.size > 5000000) {
      this.paperToaster.show(
        this.intl.t(
          'Die Datei ist zu groß. Bitte wähle eine kleinere Datei aus.'
        )
      );
      return;
    }

    if (this.args.onAttachmentLoaded) {
      this.args.onAttachmentLoaded(file);
    }
  }
}
