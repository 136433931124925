import Service from './schema';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class VideoSecondScreenService extends Service {
  @tracked secondScreen;
  secondScreenVideoTag;

  //Versenden der Zeichung auf den SecondScreen - in Echtzeit!
  sendPaint(mode) {
    if (this.secondScreen) {
      let sourceContainer = document.getElementById(
        mode === 'image'
          ? 'image-box-malen'
          : 'athlyzer-video-player-draw-container'
      );

      if (sourceContainer) {
        let sourceCanvas = sourceContainer.getElementsByTagName('canvas')[0];

        if (sourceCanvas) {
          let ctx = this.secondScreen.document
            .getElementById(mode === 'image' ? 'board-canvas' : 'canvas')
            .getContext('2d');
          let image = this.secondScreen.document
            .getElementById(mode === 'image' ? 'image' : 'video')
            .getBoundingClientRect();
          ctx.clearRect(0, 0, image.width, image.height);
          ctx.drawImage(sourceCanvas, 0, 0, image.width, image.height);
        }
      }
    }
  }

  @action closeSecondScreen() {
    try {
      this.secondScreen.close();
    } catch (error) {}
    this.secondScreen = false;
  }

  @action updateSecondScreen() {
    if (this.secondScreen) {
      this.captureVideostreamFromVideoplay();
    }
  }

  @action captureVideostreamFromVideoplay() {
    const videoTag = this.playerRef.getInternalPlayer();
    const stream = videoTag.captureStream();
    this.secondScreenVideoTag.srcObject = stream;
  }

  @action openSecondScreen(options) {
    console.log('optjons', options);
    if (this.playerRef) {
      const displayWidth = window.screen.width;
      let windowWidth = displayWidth * 0.5;
      let windowHeight = windowWidth * (9 / 16);
      const windowName = options?.windowName || 'about:blank';
      const title = options?.frameName || 'ATHLYZER SecondScreen';
      const features =
        options?.features || `width=${windowWidth},height=${windowHeight}`;
      console.log('window', window);
      let myWindow = window.open(windowName, title, features);

      myWindow.document.write(
        "<div       id='container'  style='height:100vh;width:100vw;position:fixed; display: flex; justify-content: center;overflow:hidden;white-space:nowrap;margin: -8px;'>"
      );
      myWindow.document.write(
        "<img       id='image'      style='height:auto;max-height:100vh;width:auto;max-width:100vw;position:fixed !important;visibility: hidden;'>"
      );
      myWindow.document.write(
        "<video     id='video'      style='height:auto;width:100vw;position:fixed !important;visibility: visible;' muted></video>"
      );
      myWindow.document.write(
        "<canvas    id='canvas'     style='position:fixed !important;'></canvas>"
      );

      myWindow.document.write(
        "<canvas    id='board-canvas'     style='position:fixed !important;'></canvas>"
      );

      myWindow.document.write('</div>');

      const video = myWindow.document.getElementById('video');
      const image = myWindow.document.getElementById('image');

      video.oncanplay = () => {
        console.log('oncanplay');
        video.play();
      };
      this.secondScreenVideoTag = video;
      this.captureVideostreamFromVideoplay();

      video.onloadedmetadata = () => {
        let canvas = myWindow.document.getElementById('canvas');
        //var scale = window.devicePixelRatio;
        let rect = video.getBoundingClientRect();

        canvas.width = Math.floor(rect.width);
        canvas.height = Math.floor(rect.height);

        this.sendPaint();
      };

      image.onload = () => {
        console.log('Image loaded');

        let canvas = myWindow.document.getElementById('board-canvas');
        //var scale = window.devicePixelRatio;
        let rect = image.getBoundingClientRect();

        let scaleX = Number(rect.width) / Number(canvas.width);
        let scaleY = Number(rect.height) / Number(canvas.height);

        canvas.width = Math.floor(rect.width);
        canvas.height = Math.floor(rect.height);

        let ctx = canvas.getContext('2d');
        if (scaleX != 1) {
          ctx.scale(scaleX, scaleY);
        } else {
          ctx.scale(scaleY, scaleY);
        }

        this.sendPaint('image');
      };

      myWindow.onunload = () => {
        this.secondScreen = false;
      };

      myWindow.addEventListener('resize', () => {
        let canvas = myWindow.document.getElementById('canvas');
        // var scale = window.devicePixelRatio;
        let rect;
        rect = video.getBoundingClientRect();

        let scaleX = Number(rect.width) / Number(canvas.width);
        let scaleY = Number(rect.height) / Number(canvas.height);

        canvas.width = Math.floor(rect.width);
        canvas.height = Math.floor(rect.height);

        let ctx = canvas.getContext('2d');
        if (scaleX != 1) {
          ctx.scale(scaleX, scaleY);
        } else {
          ctx.scale(scaleY, scaleY);
        }

        this.sendPaint();
        setTimeout(() => {
          let canvas = myWindow.document.getElementById('canvas');
          // var scale = window.devicePixelRatio;
          let rect;
          rect = video.getBoundingClientRect();

          let scaleX = Number(rect.width) / Number(canvas.width);
          let scaleY = Number(rect.height) / Number(canvas.height);

          canvas.width = Math.floor(rect.width);
          canvas.height = Math.floor(rect.height);

          let ctx = canvas.getContext('2d');
          if (scaleX != 1) {
            ctx.scale(scaleX, scaleY);
          } else {
            ctx.scale(scaleY, scaleY);
          }

          this.sendPaint();
        }, 1000);
      });

      myWindow.addEventListener('resize', () => {
        let canvas = myWindow.document.getElementById('board-canvas');
        // var scale = window.devicePixelRatio;
        let rect;

        rect = image.getBoundingClientRect();

        let scaleX = Number(rect.width) / Number(canvas.width);
        let scaleY = Number(rect.height) / Number(canvas.height);

        canvas.width = Math.floor(rect.width);
        canvas.height = Math.floor(rect.height);

        let ctx = canvas.getContext('2d');
        if (scaleX != 1) {
          ctx.scale(scaleX, scaleY);
        } else {
          ctx.scale(scaleY, scaleY);
        }

        this.sendPaint('image');
        setTimeout(() => {
          let canvas = myWindow.document.getElementById('board-canvas');
          // var scale = window.devicePixelRatio;
          let rect;

          rect = image.getBoundingClientRect();

          let scaleX = Number(rect.width) / Number(canvas.width);
          let scaleY = Number(rect.height) / Number(canvas.height);

          canvas.width = Math.floor(rect.width);
          canvas.height = Math.floor(rect.height);

          let ctx = canvas.getContext('2d');
          if (scaleX != 1) {
            ctx.scale(scaleX, scaleY);
          } else {
            ctx.scale(scaleY, scaleY);
          }

          this.sendPaint('image');
        });
      });

      if (video.style.visibility === 'visible') {
        this.sendPaint();
      } else {
        this.sendPaint('image');
      }

      myWindow.focus();

      this.secondScreen = myWindow;
    } else {
      this.paperToaster.show(this.intl.t('Aktuell ist kein Clip ausgewählt.'));
    }
  }

  // if (this.secondScreen) {
  //     const video = this.secondScreen.document.getElementById('video');
  //     const stream = video.srcObject;
  //     if (stream) {
  //       const tracks = stream.getTracks();
  //       tracks.forEach(function (track) {
  //         track.stop();
  //       });
  //     }
  //     const videoTag = document.getElementById('athlyzer-video-player');
  //     const newStream = videoTag.captureStream();
  //     video.srcObject = newStream;
  //   }
}
