import Model from '../classes/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { union } from '@ember/object/computed';

export default class SchemaModel extends Model {
  typ = 'schema';
  @attr('string')
  name;

  icon = 'dashboard';

  @attr('boolean', { defaultValue: false }) highlight;

  // ordner : belongsTo("ordner"),
  @belongsTo('ordner')
  ordner;

  // handlungen : hasMany("handlung"),
  @hasMany('handlung')
  handlungen;

  get sortedHandlungen() {
    return this.handlungen.sortBy('name');
  }

  get sortedTags() {
    return this.tags.sortBy('name');
  }

  get handlungCount() {
    return this.handlungen.length;
  }

  // tags : hasMany("tag"),
  @hasMany('tag')
  tags;

  get tagCount() {
    return this.tags.length;
  }

  // listen : attr('number', {defaultValue : 2}),
  @attr('number', { defaultValue: 2 })
  listen;

  // alle: union('handlungen','tags'),
  @union('handlungen', 'tags')
  alle;

  // breite : attr("number",{defaultValue : 700}),
  @attr('number', { defaultValue: 700 })
  breite;
  // hoehe : attr("number",{defaultValue : 700}),
  @attr('number', { defaultValue: 700 })
  hoehe;
  //
  @attr('mdate') datum;

  @attr('boolean', { defaultValue: true }) show;
  @attr('boolean', { defaultValue: true }) showtablet;
  @attr('boolean', { defaultValue: true }) showmobil1;
  @attr('boolean', { defaultValue: true }) showmobil2;

  // breitetablet : attr("number",{defaultValue : 1280}),
  @attr('number', { defaultValue: 1280 })
  breitetablet;
  // hoehetablet : attr("number",{defaultValue : 700}),
  @attr('number', { defaultValue: 700 })
  hoehetablet;
  //
  // breitemobil1 : attr("number",{defaultValue : 800}),
  @attr('number', { defaultValue: 400 })
  breitemobil1;
  // hoehemobil1 : attr("number",{defaultValue : 1200}),
  @attr('number', { defaultValue: 600 })
  hoehemobil1;
  //
  // breitemobil2 : attr("number",{defaultValue : 800}),
  @attr('number', { defaultValue: 400 })
  breitemobil2;
  // hoehemobil2 : attr("number",{defaultValue : 1200}),
  @attr('number', { defaultValue: 600 })
  hoehemobil2;

  @attr('string') web;

  get max() {
    let max = {
      breite: 0,
      hoehe: 0,
    };
    this.alle.forEach((element) => {
      let breite = element.x + element.width;
      let hoehe = element.y + element.height;
      max.breite < breite && (max.breite = breite);
      max.hoehe < hoehe && (max.hoehe = hoehe);
    });
    max.breite == 0 && (max.breite = this.breite);
    max.hoehe == 0 && (max.hoehe = this.breite);
    return max;
  }

  get intelPlaylists() {
    let playlists = [];
    for (let marker of this.alle) {
      for (let list of marker.intelPlaylist) {
        if (!playlists.includes(list)) {
          playlists.push(list);
        }
      }
    }
    return this.intelPlaylists;
  }

  get intelPlaylistsMitHandlungenUndTags() {
    const playlists = {};

    const addMarkerToPlaylist = (marker, playlistName) => {
      if (!playlists[playlistName]) {
        playlists[playlistName] = {
          name: playlistName,
          handlungen: [],
          tags: [],
        };
      }

      if (marker.typ === 'handlung') {
        playlists[playlistName].handlungen.push(marker);
      } else {
        playlists[playlistName].tags.push(marker);
      }
    };

    this.alle.forEach((marker) => {
      if (marker.intelPlaylist && marker.intelPlaylist.length > 0) {
        marker.intelPlaylist.forEach((playlist) => {
          addMarkerToPlaylist(marker, playlist);
        });
      }
    });

    return Object.values(playlists);
  }

  get hasMoreThanOneAnsicht() {
    return this.showtablet || this.showmobil1 || this.showmobil2;
  }
}
