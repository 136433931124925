export default function (string) {
  // Beispiel: const str = 'ÁÉÍÓÚáéíóúâêîôûàèìòùÇç/.,~!@#$%&_-12345';
  const parsed = string
    .normalize('NFD')
    .replace(/([\u0300-\u036f])/g, '-')
    .replace(/([\u0020-\u002f])/g, '-')
    .replace(/([^0-9a-zA-Z])/g, '_')
    .replace(/([\u002f|\u005c])/g, '_');
  return parsed;
}
