import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import PouchDB from 'pouchdb-browser';

export default class TeamsTeamAuthenticatedRoute extends Route {
  // hier muss der pouchdb kram rein als Promise
  @service team;
  @service store;
  @service metrics;

  async model(params) {
    console.log('params', params);
    let auth = atob(params.session);
    console.log('auth', auth);

    let remoteLink = this.team.datenbankurl.replace('//', `//${auth}@`);
    let datenbankName = this.team.aktivesTeam;
    remoteLink += `/${datenbankName}`;
    return this.replicateFrom(datenbankName, remoteLink);
  }

  afterModel() {
    this.metrics.track('Teams Session Authenticated');
  }

  async replicateFrom(datenbankName, remoteLink) {
    this.store.adapterFor('application').db.close();
    console.log('datenbankName', datenbankName);
    let db = new PouchDB(datenbankName, { adapter: 'indexeddb' });
    await this.store.adapterFor('application').changeDb(db);
    return db.replicate.from(remoteLink);
  }
}
