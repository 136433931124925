import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { Editor } from '@tiptap/core';
import Youtube from 'athlyzer-coach/classes/tiptap/youtube';
import Athlyzer from 'athlyzer-coach/classes/tiptap/athlyzer-oembed';
import Placeholder from '@tiptap/extension-placeholder';
import OrderedList from '@tiptap/extension-ordered-list';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import StarterKit from '@tiptap/starter-kit';
import axios from 'axios';
import * as Sentry from '@sentry/ember';

export default class HubSpaceFeedPostCreateComponent extends Component {
  @service addonUser;
  @service paperToaster;

  @tracked postTitle = '';
  @tracked postContent = '';
  @tracked postRichTextData = { type: 'doc', content: [{ type: 'paragraph' }] };
  @tracked isPosting = false;
  @tracked attachmentsToUpload = [];
  @tracked uploadedAttachments = [];

  tipTapEditor = null;

  @action initializeTTEditor(elem, [self]) {
    self.editorElement = elem;
    self.tipTapEditor = new Editor({
      element: elem,
      extensions: [
        StarterKit,
        Youtube.configure({
          nocookie: true,
        }),
        Athlyzer,
        Placeholder.configure({
          HTMLAttributes: {
            class: 'text-wrap text-lg',
          },
          placeholder: 'Verfasse deinen Beitrag...',
        }),
        BulletList,
        OrderedList,
        ListItem,
        ,
      ],
      editorProps: {
        attributes: {
          class: '!prose dark:prose-invert m-2 focus:outline-none',
        },
      },
      content: this.postRichTextData,
      autofocus: false,
      editable: true,
      class: 'w-full h-96 overflow-y-scroll',
      onUpdate({ editor }) {
        self.postRichTextData = editor.getJSON();
      },
    });

    if (this.args.predefinedAthlyzerVideo) {
      self.tipTapEditor.commands.setContent(
        '<p>Schaut euch dieses Video an:</p>'
      );
      self.tipTapEditor.commands.setAthlyzerVideo({
        src: this.args.predefinedAthlyzerVideo,
      });
    }
  }

  @action textBold() {
    this.tipTapEditor.chain().focus().toggleBold().run();
  }

  @action textItalic() {
    this.tipTapEditor.chain().focus().toggleItalic().run();
  }

  @action textUnderline() {
    this.tipTapEditor.chain().focus().toggleUnderline().run();
  }

  @action textStrike() {
    this.tipTapEditor.chain().focus().toggleStrike().run();
  }

  @action textLink() {
    this.tipTapEditor
      .chain()
      .focus()
      .toggleLink({ href: 'https://example.com' })
      .run();
  }

  @action textUnlink() {
    this.tipTapEditor.chain().focus().toggleLink({ href: null }).run();
  }

  @action textH1() {
    this.tipTapEditor.chain().focus().setHeading({ level: 1 }).run();
  }

  @action textH2() {
    this.tipTapEditor.chain().focus().setHeading({ level: 2 }).run();
  }

  @action textH3() {
    this.tipTapEditor.chain().focus().setHeading({ level: 3 }).run();
  }

  @action textBulletList() {
    this.tipTapEditor.chain().focus().toggleBulletList().run();
  }

  @action textOrderedList() {
    this.tipTapEditor.chain().focus().toggleOrderedList().run();
  }

  @action onAttachmentLoaded(file) {
    this.attachmentsToUpload = [...this.attachmentsToUpload, file];
  }

  @action async uploadAttachment(file) {
    const apiClient = this.addonUser.getApiClient();
    const extension = file.type.split('/')[1];
    const filename = `attachment_` + Date.now() + '.' + extension;

    try {
      let signedResponse = await apiClient.post(
        `user/hub/spaces/${this.args.spaceId}/file/upload`,
        {
          key: filename,
        }
      );

      console.log('signedResponse', signedResponse.data, file);

      await axios.put(signedResponse.data.uploadURL, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      const attachmentData = {
        key: signedResponse.data.bucketKey,
        fileName: filename,
        fileType: file.type,
        fileSize: file.size,
        bucket: signedResponse.data.bucket,
      };

      this.uploadedAttachments = [...this.uploadedAttachments, attachmentData];
    } catch (e) {
      console.log('Error', e);
      this.paperToaster.show(
        this.intl.t(
          'Speichern fehlgeschlagen. Bitte überprüfe deine Internetverbindung.'
        )
      );
    }
  }

  @action async postTextToSpace(spaceId) {
    this.isPosting = true;
    try {
      for (let i = 0; i < this.attachmentsToUpload.length; i++) {
        this.uploadingAttachments = true;
        await this.uploadAttachment(this.attachmentsToUpload[i]);
      }
      this.uploadingAttachments = false;
    } catch (error) {
      Sentry.captureException(error);
      this.isPosting = false;
      this.uploadingAttachments = false;
      this.paperToaster.show(
        'Beim Hochladen der Anhänge ist ein Fehler aufgetreten.'
      );
      return;
    }

    try {
      let postCreateData = {
        title: this.postTitle,
        content: this.postContent,
        richTextData: this.postRichTextData,
      };

      if (this.uploadedAttachments.length > 0) {
        postCreateData.attachments = this.uploadedAttachments;
      }

      const apiClient = this.addonUser.getApiClient();
      const { data: postData } = await apiClient.post(
        `/user/hub/spaces/${spaceId}/posts`,
        postCreateData
      );

      this.postTitle = '';
      this.tipTapEditor.commands.clearContent();
      this.isPosting = false;
      this.uploadedAttachments = [];
      this.attachmentsToUpload = [];

      if (typeof this.args.addNewPostAfterCreation === 'function') {
        this.args.addNewPostAfterCreation(postData);
      }

      if (typeof this.args.onPostCreated === 'function') {
        this.args.onPostCreated();
      }
    } catch (error) {
      console.error(error);
      this.isPosting = false;
      this.paperToaster.show('Beitrag konnte nicht erstellt werden');
      Sentry.captureException(error);
    }
  }
}
