import { mergeAttributes, Node, nodePasteRule } from '@tiptap/core';

import {
  ATHLYZER_REGEX_GLOBAL,
  getEmbedUrlFromAthlyzerUrl,
  isValidAthlyzerUrl,
} from './utils.js';

const Athlyzer = Node.create({
  name: 'athlyzer',

  addOptions() {
    return {
      addPasteHandler: true,
      allowFullscreen: true,
      autoplay: false,
      ccLanguage: undefined,
      ccLoadPolicy: undefined,
      controls: true,
      disableKBcontrols: false,
      enableIFrameApi: false,
      endTime: 0,
      height: 480,
      interfaceLanguage: undefined,
      ivLoadPolicy: 0,
      loop: false,
      modestBranding: false,
      HTMLAttributes: {},
      inline: false,
      nocookie: false,
      origin: '',
      playlist: '',
      progressBarColor: undefined,
      width: 640,
    };
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? 'inline' : 'block';
  },

  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      start: {
        default: 0,
      },
      width: {
        default: this.options.width,
      },
      height: {
        default: this.options.height,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-athlyzer-video] iframe',
      },
    ];
  },

  addCommands() {
    return {
      setAthlyzerVideo:
        (options) =>
        ({ commands }) => {
          if (!isValidAthlyzerUrl(options.src)) {
            return false;
          }

          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addPasteRules() {
    if (!this.options.addPasteHandler) {
      return [];
    }

    return [
      nodePasteRule({
        find: ATHLYZER_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match) => {
          return { src: match.input };
        },
      }),
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const embedUrl = getEmbedUrlFromAthlyzerUrl({
      url: HTMLAttributes.src,
      nocookie: this.options.nocookie,
    });
    HTMLAttributes.src = embedUrl;

    return [
      'div',
      {
        'data-athlyzer-video': '',
        class: 'aspect-w-16 aspect-h-9',
        sandbox: '',
      },
      [
        'iframe',
        mergeAttributes(
          this.options.HTMLAttributes,
          {
            width: this.options.width,
            height: this.options.height,
            allowfullscreen: this.options.allowFullscreen,
            origin: this.options.origin,
          },
          HTMLAttributes
        ),
      ],
    ];
  },
});

export default Athlyzer;
