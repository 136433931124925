export default function (milliseconds) {
  let time = new Date(Number(milliseconds) * 1000).toISOString();
  let hours = Number(time.substr(11, 2));
  let minutes = Number(time.substr(14, 2));
  let seconds = Number(time.substr(17, 2));
  if (seconds <= 9) {
    seconds = String(seconds);
    seconds = '0' + seconds;
  }
  minutes = hours * 60 + minutes;
  if (minutes <= 9) {
    minutes = String(minutes);
    minutes = '0' + minutes;
  }
  let returnString = minutes + ':' + seconds;
  return returnString;
}
