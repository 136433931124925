import Model from '../classes/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import deviceutil from 'athlyzer-coach/classes/deviceutil';

export default class VideoclipModel extends Model {
  @tracked isSelected = false;
  @tracked state = 'idle';
  @tracked exists = false;
  @tracked existsOnNAS = false;
  @tracked existsOnWeb = false;
  @tracked availableOnWeb = false;
  @tracked onlineCheckFailed = false;
  @tracked tmp;
  @tracked isSelected = false;

  @belongsTo('videobearbeitung', { inverse: 'videoclips' }) videobearbeitung;
  @belongsTo('videocollection', { inverse: 'sharedVideoclips' })
  videocollection;
  @belongsTo('videobearbeitung', { inverse: 'sharedVideoclips' })
  videobearbeitungShared;

  @attr('boolean', { default: false }) isSynced;

  @attr('number', { defaultValue: 0 }) duration;
  @attr('string') name;
  @attr('number', { defaultValue: 0 }) position;

  @attr('string') exID;
  @attr('string') web;
  @attr('string') originalLink; //für youtube und twitch

  @attr('string') ios;
  @attr('string') android;
  @attr('string') desktop;
  @attr('string') androidexternal;
  @attr('string') iosexternal;

  @attr('string') nas; // nur für videobearbeitung gedacht

  @attr('boolean') islive;
  @attr('mdate') start; // => start des Livetaggings / Capture
  @attr('mdate') end; //=> ende des Livetaggings / Capture

  @attr('string') connect;
  @hasMany('videoszene', { async: true, inverse: 'videoclip' }) videoszenen;

  @attr('number') convertedVideoBitrate;
  @attr('number') convertedVideoWidth;
  @attr('number') convertedVideoHeight;

  @attr('number') externalVideoBitrate;
  @attr('number') externalVideoWidth;
  @attr('number') externalVideoHeight;
  @attr('boolean') convertRequired;

  get shortName() {
    if (this.name && this.name.length > 30) {
      return this.name.substring(0, 30) + '...';
    } else if (this.name) {
      return this.name;
    } else {
      return '';
    }
  }

  get device() {
    return this.web;
  }

  get internalVideo() {
    if (deviceutil.isElectron && this.desktop) {
      return this.desktop;
    } else if (deviceutil.isIos && this.ios) {
      return this.ios;
    } else if (deviceutil.isAndroid && this.android) {
      return this.android;
    } else {
      return;
    }
  }

  get internalVideoIfAvailabe() {
    if (deviceutil.isElectron && this.desktop && this.exists) {
      return this.desktop;
    } else if (deviceutil.isIos && this.ios && this.exists) {
      return this.ios;
    } else if (deviceutil.isAndroid && this.android && this.exists) {
      return this.android;
    } else {
      return;
    }
  }

  get externalVideoIfAvailable() {
    if (deviceutil.isElectron && this.nas && this.existsOnNAS) {
      return this.nas;
    } else if (deviceutil.isIos && this.iosexternal && this.existsOnNAS) {
      return this.iosexternal;
    } else if (
      deviceutil.isAndroid &&
      this.androidexternal &&
      this.existsOnNAS
    ) {
      return this.androidexternal;
    } else {
      return;
    }
  }

  get internalOrExternalVideo() {
    return this.internalVideoIfAvailabe || this.externalVideoIfAvailable;
  }
  get externalOrInternalVideo() {
    return this.externalVideoIfAvailable || this.internalVideoIfAvailabe;
  }

  get webVideo() {
    return this.existsOnWeb ? this.web : undefined;
  }

  get anyAvailableVideo() {
    return (
      this.internalVideoIfAvailabe ||
      this.externalVideoIfAvailable ||
      this.webVideo
    );
  }

  get videoszenenSortiert() {
    if (this.videoszenen.length > 0) {
      return this.videoszenen.sortBy('startzeit');
    } else {
      return [];
    }
  }

  get isLiveTaggingClip() {
    return (
      this.web ===
      'https://athlyzer.s3.eu-central-1.amazonaws.com/files/time.mp4'
    );
  }
}
